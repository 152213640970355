import React, {memo, useEffect, useMemo, useRef, useState} from "react";
import s from "./index.module.less";
import CustomIcon from "src/common/CustomIcon";
import StrangeRule from "@/utils/strangeRule";

const basicLHeight = 42;
const basicMHeight = 31;
const basicSHeight = 25;
const basicL02Height = 55;
const l1AspectRatio = 5.696 // 712/125
const m1AspectRatio = 3.7957 // 353/93
const m2AspectRatio = 4.7979 // 451/94
const m3AspectRatio = 5.6702 // 533/94
const s1AspectRatio = 1.4474 // 110/76
const s2AspectRatio = 2.1842 // 166/76
const s3AspectRatio = 2.7632 // 210/76
const l2AspectRatio = 2.7636 // 210/76
const ButtonFactory = (
    {
        className,
        children,
        color = 1,//1:yellow, 2:blue, 3:white
        isMirror = false,
        scale = 1,
        size = 1,//1:L, 2:M, 3:S, 11:L1, 21:M1, 22:M2, 23:M3, 31:S1, 32:S2, 33:S3
        style = {},
        onClick = () => {
        },
        disabled = false,
        loading = false,//TODO
        isFixed = false,
        fixedStyle = {
            useHeight: false,
            height: 30,
        },
        iconName = null,
        haveShadow = true,
        useRule = true,
    }
) => {
    const childrenRef = useRef(null);
    const backGroundStyle = useMemo(() => {
        let colorStr = 'Yellow';
        if (disabled || color === 3) {
            colorStr = 'White';
        } else if (color === 2) {
            colorStr = 'Blue';
        }

        let sizeStr = 'L_01';
        let bgHeight = 0;
        let aspectRatio = 0;
        let basicHeight = basicMHeight;
        if (size === 1 || size === 11) {
            sizeStr = 'L_01';
            aspectRatio = l1AspectRatio;
            basicHeight = basicLHeight;
        } else if (size === 2) {
            if (isFixed) {
                sizeStr = 'M_02';
                aspectRatio = m2AspectRatio;
                basicHeight = basicMHeight;
            } else {
                bgHeight = basicMHeight * scale;
                let m1Width = bgHeight * m1AspectRatio;
                let m2Width = bgHeight * m2AspectRatio;
                basicHeight = basicMHeight;
                if (childrenRef?.current) {
                    let childrenWidth = childrenRef?.current?.offsetWidth * 1.2;
                    if (childrenWidth < m1Width) {
                        sizeStr = 'M_01';
                        aspectRatio = m1AspectRatio;
                    } else if (childrenWidth < m2Width) {
                        sizeStr = 'M_02';
                        aspectRatio = m2AspectRatio;
                    } else {
                        sizeStr = 'M_03';
                        aspectRatio = m3AspectRatio;
                    }
                } else {
                    sizeStr = 'M_02';
                    aspectRatio = m2AspectRatio;
                }
            }
        } else if (size === 3) {
            if (isFixed) {
                sizeStr = 'S_02';
                aspectRatio = s2AspectRatio;
                basicHeight = basicSHeight;
            } else {
                bgHeight = basicSHeight * scale;
                let s1Width = bgHeight * s1AspectRatio;
                let s2Width = bgHeight * s2AspectRatio;
                basicHeight = basicSHeight;
                if (childrenRef?.current) {
                    let childrenWidth = childrenRef?.current?.offsetWidth * 1.2;
                    if (childrenWidth < s1Width) {
                        sizeStr = 'S_01';
                        aspectRatio = s1AspectRatio;
                    } else if (childrenWidth < s2Width) {
                        sizeStr = 'S_02';
                        aspectRatio = s2AspectRatio;
                    } else {
                        sizeStr = 'S_03';
                        aspectRatio = s3AspectRatio;
                    }
                } else {
                    sizeStr = 'S_02';
                    aspectRatio = s2AspectRatio;
                }
            }
        } else if (size === 12) {
            sizeStr = 'L_02';
            aspectRatio = l2AspectRatio;
            basicHeight = basicL02Height;
        } else if (size === 21) {
            sizeStr = 'M_01';
            aspectRatio = m1AspectRatio;
            basicHeight = basicMHeight;
        } else if (size === 22) {
            sizeStr = 'M_02';
            aspectRatio = m2AspectRatio;
            basicHeight = basicMHeight;
        } else if (size === 23) {
            sizeStr = 'M_03';
            aspectRatio = m3AspectRatio;
            basicHeight = basicMHeight;
        } else if (size === 31) {
            sizeStr = 'S_01';
            aspectRatio = s1AspectRatio;
            basicHeight = basicSHeight;
        } else if (size === 32) {
            sizeStr = 'S_02';
            aspectRatio = s2AspectRatio;
            basicHeight = basicSHeight;
        } else if (size === 33) {
            sizeStr = 'S_03';
            aspectRatio = s3AspectRatio;
            basicHeight = basicSHeight;
        }

        if (isFixed && fixedStyle?.useHeight) {
            bgHeight = fixedStyle?.height;
        } else {
            bgHeight = basicHeight * scale;
        }

        return {
            height: bgHeight,
            aspectRatio: aspectRatio,
            backgroundImage: `url('/UI/Button/UI_Button_${colorStr}-${sizeStr}.png')`,
        }
    }, [color, scale, size, disabled, isFixed, fixedStyle])

    const prefixIconStyle = useMemo(() => {
        if (iconName) {
            let iconSize = 50;
            let left = 0;
            if (size === 1) {
                iconSize = backGroundStyle?.height * 1;
            } else if (size === 2 || (size > 19 && size < 30)) {
                iconSize = backGroundStyle?.height * 1;
                //left = `${2*scale}px`
            } else if (size === 3 || (size > 29 && size < 40)) {
                iconSize = backGroundStyle?.height * 1;
            }
            return {
                left: left,
                width: `${iconSize}px`,
                height: `${iconSize}px`,
                backgroundImage: `url('/UI/Picture/${iconName}.png')`
            };
        } else {
            return {};
        }
    }, [iconName, size, backGroundStyle]);

    const childrenStyle = useMemo(() => {
        let fontSize = 18;
        if (size === 1 || size === 11) {
            fontSize = 18;
        } else if (size === 2 || size === 21 || size === 22 || size === 23) {
            fontSize = 16;
        } else {
            fontSize = 14;
        }
        return {
            fontSize: `${fontSize * scale}px`
        }
    }, [scale, size]);

    const clickFn = (e) => {
        if (!disabled) {
            onClick(e);
        }
    }

    const isFixedButton = () => {
        return isFixed || size === 1 || size > 10;
    }

    const getPaddingBottom = () => {
        let res = 0;
        /*if (size === 1) {
            res = 0;
        } else if (size === 2 || (size > 19 && size < 30)) {
            res = backGroundStyle?.height * 0.1;
        } else if (size === 3 || (size > 29 && size < 40)) {
            res = backGroundStyle?.height * 0.1;
        }*/
        return res;
    }

    const getFontColor = () => {
        if (disabled) {
            //return '#B3B3B3';
            return 'rgba(255,255,255,1)';
        } else if (color === 2) {
            return 'rgba(255,255,255,1)';
        } else {
            return 'rgba(0,0,0,1)';
        }
    }

    return (
        <div className={`${s.wrap} ${className}`}
             style={{
                 ...style,
                 height: backGroundStyle?.height,
                 aspectRatio: backGroundStyle?.aspectRatio,
                 cursor: 'pointer',
             }}
             onClick={clickFn}
        >
            <div className={s.content} style={{
                paddingBottom: getPaddingBottom(),
            }}>
                <div className={s.btnBg} style={{
                    ...backGroundStyle,
                    filter: `${disabled ? 'brightness(25%) ' : ''}${haveShadow ? 'drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.5))' : ''}`,
                    transform: isMirror ? `translate(-50%, -50%) rotateY(180deg)` : 'translate(-50%, -50%)'
                }}>
                </div>
                {
                    isFixedButton() ?
                        <div
                            className={`${s.childrenContent} fb ${(disabled && color !== 2) ? 'buttonFactoryIconDisable' : ''}`}
                            style={{
                                color: getFontColor(), ...childrenStyle,
                                filter: `brightness(${disabled ? 0.7 : 1})`,
                            }}>
                                {loading && <CustomIcon rotating={true} className="mr5" width={18} height={18}
                                    imgName={'Picture/UI_Picture_Loading_01'} name={'Picture/UI_Picture_Loading_01'}/>}
                            {
                                useRule ?
                                <StrangeRule>{children}</StrangeRule>:
                                <>{children}</>
                            }
                        </div> :
                        <div className={`${s.childrenContent}`}>
                            <div className={`${s.childrenUnfixed} fb`} ref={childrenRef}
                                 style={{
                                     color: getFontColor(), ...childrenStyle,
                                     filter: `brightness(${disabled ? 0.7 : 1})`,
                                 }}>
                                {loading && <CustomIcon rotating={true} className="mr5" width={18} height={18}
                                    imgName={'Picture/UI_Picture_Loading_01'} name={'Picture/UI_Picture_Loading_01'}/>}
                                {
                                    useRule ?
                                    <StrangeRule>{children}</StrangeRule>:
                                    <>{children}</>
                                }
                            </div>
                        </div>
                }
                {
                    iconName &&
                    <div className={s.prefixIcon} style={{...prefixIconStyle}}>
                    </div>
                }
            </div>
        </div>
    )
}
export default memo(ButtonFactory);
