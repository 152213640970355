import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// import { BrowserRouter } from 'react-router-dom';
import {HashRouter} from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
// import { AliveScope } from 'react-activation'

import {PrivyProvider, addRpcUrlOverrideToChain } from '@privy-io/react-auth';
import {polygon, polygonAmoy, baseSepolia, base } from 'viem/chains';

import {envConfig, PolygonRPC, PolygonAmoyRPC, BaseRPC} from "@/utils/env"

import thinkingdata from "thinkingdata-browser";
import {
    taPush,
    GetSupportedChainList,
    polygonChain,
    moveEVMChain,
} from "@/utils/common";

const root = ReactDOM.createRoot(document.getElementById('root'));

function getUrlParams(key) {
    let href = window.location.href;
    let urlStr = href.substring(href.indexOf('?') + 1);
    if (!urlStr) {
        return ''
    }
    let obj = {};
    let paramsArr = urlStr.split('&')
    for (let i = 0, len = paramsArr.length; i < len; i++) {
        let str = paramsArr[i];
        let key = str.substring(0, str.indexOf("="));
        let value = str.substring(str.indexOf("=") + 1);
        obj[key] = value;
    }
    return obj[key] || ''
}

let userInfo = decodeURIComponent(getUrlParams('userinfo')) || {};
let bindRsult = decodeURIComponent(getUrlParams('result')) || '';
console.log("bindInfo", userInfo, bindRsult);
userInfo = JSON.parse(JSON.stringify(userInfo) === '{}' ? '{}' : userInfo + '');

if (JSON.stringify(userInfo) !== '{}') {
    // console.log(userInfo);
    localStorage.setItem('_TT_bindRsult', bindRsult)
    localStorage.setItem('_TT_userInfo', JSON.stringify(userInfo?.user))
    localStorage.setItem('_TT_token', JSON.stringify({token: userInfo?.token}))
    localStorage.setItem('_TT_initTopUpAmount', userInfo?.initTopUpAmount)
    window.location.href = window.location.origin + '/';
}

window.addEventListener('beforeinstallprompt', (e) => {
    e.preventDefault();
    window.deferredPrompt = e;
});

window.addEventListener('touchmove', e => {
    if (e.target.classList.contains('no-scroll')) {
        e.preventDefault();
    }
}, {passive: false})

if (envConfig.taEnable) {
    let config = {
        appId: envConfig.taAppId,
        serverUrl: envConfig.taPushUrl,
        showLog:false,
        autoTrack: {
            pageShow: true,
            pageHide: true,
        }
    };
    thinkingdata.init(config);

    window.ta = thinkingdata;

    var superProperties = {};
    superProperties["pwa"] = window.navigator && (window.matchMedia("(display-mode: standalone)").matches || !0 === window.navigator.standalone || window.navigator.userAgent.includes("standalone"));
    superProperties["have_token"] = !!JSON.parse(localStorage.getItem('_TT_token'));
    window.ta.setSuperProperties(superProperties);

    const userInfo = JSON.parse(localStorage.getItem('_TT_userInfo'));
    if (userInfo && userInfo?.userId) {
        window.ta.login(envConfig.taRegionId + "-" + userInfo?.userId);
    }

    taPush('open_window');
}

root.render(
    <HashRouter>
        <PrivyProvider
            appId={envConfig.privyAppId}
            config={
                {
                    appearance:
                        {
                            "accentColor": "#6A6FF5",
                            "theme": "dark",
                            "showWalletLoginFirst": true,
                        },
                    //loginMethods: ["google", "apple", "email"],
                    captchaEnabled : false,
                    defaultChain: moveEVMChain,
                    supportedChains: GetSupportedChainList(),
                    embeddedWallets: {
                        createOnLogin: "all-users",
                        requireUserPasswordOnCreate: false,
                        noPromptOnSignature: true, // defaults to false
                        // priceDisplay:{
                        //     primary: 'native-token', // Defaults to 'fiat-currency'
                        //     secondary: null, // Defaults to 'native-token'
                        // }
                    }
                }
            }
        >
            <App/>
        </PrivyProvider>
    </HashRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
