import React, {memo, useEffect, useMemo, useState} from "react";
import {useTranslation, Trans} from 'react-i18next';
import {connect} from "react-redux";
import {Checkbox, Drawer, Modal, Radio, Tabs} from 'antd';
import s from "./index.module.less"
import {
    getearninfo,
    getProfile,
    formatNumberWithUnit,
    getfriendtrade_rank_selfdata,
    getAvatarFromUserInfo
} from "@/utils/common";
import CustomIcon from "src/common/CustomIcon";
import {useNavigate} from "react-router-dom";
import CrackList from "src/common/CrackList";
import ModelDeposit from "src/model/ModelDeposit";
import ModelRankReward from 'src/model/ModelRankReward';
import RankReward from '@/utils/json/RankReward.json';
import MainHeader from "@/common/MainHeader";
import Countdown from "src/common/Countdown";
import BattleHireFilter from "@/drawer/BattleHireFilter";
import AntModal from "@/common/AntModal";

const BattleLeaderBoard = (
    {
        userInfo,
        earnInfo,
    }
) => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const {
        selfData,
        platformData,
        walletAddr,
    } = useMemo(() => userInfo || {}, [userInfo]);
    const {lfgBalance, tierId} = useMemo(() => {
        return selfData || {}
    }, [selfData])
    const {
        power,
    } = useMemo(() => {
        return earnInfo || {}
    }, [earnInfo])
    const [claimInfo, setClaimInfo] = useState({});
    const [reFresh, setReFresh] = useState(false)
    const [showDepositPanel, setShowDepositPanel] = useState(false)
    const [showRankRewardPanel, setShowRankRewardPanel] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [rankType, setRankType] = useState(0);
    const [filterLevel, setFilterLevel] = useState(() => {
        const localData = localStorage.getItem('battle_filterLevel');
        let level1 = Math.max(1, tierId - 2);
        let level2 = Math.max(1, tierId + 2);
        return localData ? JSON.parse(localData) : [level1, level2];
    });

    useEffect(() => {
        JSON.stringify(userInfo) !== '{}' && getearninfoFn();
        getClaimInfo();
    }, [])
    const getearninfoFn = () => {
        getearninfo().then(res => {

        }).catch(e => {
            console.log(e);
        })
    }
    const {rankIdx, employeeAllNum, canLootEmployeeNum, winRatio, protectCD, leftSendRewardSec} = useMemo(() => {
        return claimInfo || {}
    }, [claimInfo])

    const {displayName, platformType, accountName} = useMemo(() => {
        return getProfile(platformData) || {}
    }, [platformData])

    const {avatarUrl, originalAvatarUrl} = useMemo(() => {
        return getAvatarFromUserInfo(userInfo)
    }, [platformData, userInfo])

    const getClaimInfo = () => {
        setReFresh(false);
        getfriendtrade_rank_selfdata().then(res => {
            setClaimInfo(res)
        }).catch(err => {
            console.error(err.message);
        })
    }

    useEffect(() => {
        reFresh && getClaimInfo()
    }, [reFresh])
    
    return (
        <div className={`${s.wrap} navBg`}>
            <MainHeader title={t('LEADERBOARD')} headerType={2} isGameHeader={true} rightMenuIcon ={"Button/UI_Button-D_Home_On"}></MainHeader>
            <div className={`${s.headContent} flex_center_between`}>
                {
                    rankType === 1 ?
                        <>
                            <div className={`${s.headContentLabel} flex_center_start`}>
                                {t('Filter By Club Level')}:&nbsp;
                                <div className={`color-yellow fb`}>
                                    {filterLevel?.[0] || 0}
                                </div>
                                &nbsp;{t('to')}&nbsp;
                                <div className={`color-yellow fb`}>
                                    {filterLevel?.[1] || 0}
                                </div>
                            </div>
                            <CustomIcon onClick={() => setShowFilter(true)} width={24} height={24} className="iconShadow"
                                        imgName={'Button/UI_Button_ThreeTieRod_01'}/>
                        </> :
                    rankType === 2 ?
                        <>
                            <div className={`${s.headContentLabel} flex_center_start`}>
                                {t('Sort By Battle Level')}
                                <CustomIcon width={16} height={16} className="ml10" 
                                        imgName={'Button/UI_Button_Rank_Down_01'}/>
                            </div>
                            <CustomIcon onClick={() => setShowFilter(true)} width={24} height={24} className="iconShadow"
                                        imgName={'Button/UI_Button_ThreeTieRod_01'}/>
                        </> :
                    rankType === 3 ? 
                        <>
                            <div className={`${s.headContentLabel} flex_center_start`}>
                                {t('Filter Only NFTs')}
                                <CustomIcon width={16} height={16} className="ml10" 
                                        imgName={'Button/UI_Button_Rank_Down_01'}/>
                            </div>
                            <CustomIcon onClick={() => setShowFilter(true)} width={24} height={24} className="iconShadow"
                                        imgName={'Button/UI_Button_ThreeTieRod_01'}/>
                        </> :
                        <>
                            <div className={`${s.headContentLabel} flex_center_start`}>
                                {t('Sort By $LFG Pool')}
                                <CustomIcon width={16} height={16} className="ml10" 
                                        imgName={'Button/UI_Button_Rank_Down_01'}/>
                            </div>
                            <CustomIcon onClick={() => setShowFilter(true)} width={24} height={24} className="iconShadow"
                                        imgName={'Button/UI_Button_ThreeTieRod_01'}/>
                        </>
                }
            </div>
            <div className={`${s.content} mt5`}>
                <CrackList setReFresh={setReFresh} rankType={rankType} showFilter={showFilter} filterLevel={filterLevel}
                                 setShowFilter={setShowFilter}/>
            </div>
            {(rankIdx > 0 && rankIdx) && leftSendRewardSec && rankType === 0 &&
                <div className={s.infoBar} style={{backgroundImage: `url(/UI/Picture/UI_Picture-Main_Down_01.png)`}}>
                    <div className={`${s.rankPart} flex_center_center`}>
                        <CustomIcon className='ml5 mr5' width={18} height={18}
                                    imgName={'Picture/UI_Picture_Trophy_01'}/>
                        {(rankIdx > 10000 ? '9999+' : rankIdx) || '--'}
                    </div>
                    <div className={`${s.rewardPart} flex_center_center`}>
                        {t('Est. $UP Points')}
                        <CustomIcon className='ml5 mr5' width={18} height={18}
                                    imgName={'Picture/UI_Picture-Currency_UP_01'}/>
                        {formatNumberWithUnit(RankReward.find(v => rankIdx >= v.rankMin && rankIdx <= v.rankMax)?.rewardNum) || '--'}
                    </div>
                    <span className="fs14 color-white textnowrap" style={{minWidth: '90px'}}>
                        <Trans
                            i18nKey="IN sometime"
                            defaults="IN <time/>"
                            components={{time: leftSendRewardSec && <Countdown leftSecond={leftSendRewardSec}/>}}
                        />
                    </span>
                    <CustomIcon width={18} className='ml5' height={18} imgName={'Button/UI_Button_QuestionMark_01'}
                                onClick={() => {
                                    setShowRankRewardPanel(true);
                                }}/>
                </div>}

            <AntModal
                width='330px'
                title={t('LEADERBOARD & $UP POINTS')}
                className="confirmModalWrap"
                centered={true}
                open={showRankRewardPanel}
                destroyOnClose={true}
                onOk={() => setShowRankRewardPanel(false)}
                onCancel={() => setShowRankRewardPanel(false)}
            >
                <ModelRankReward/>
            </AntModal>
            <AntModal
                width='330px'
                title={t('GET MORE MATIC')}
                className="confirmModalWrap"
                centered={true}
                open={showDepositPanel}
                onOk={() => setShowDepositPanel(false)}
                onCancel={() => setShowDepositPanel(false)}
            >
                <ModelDeposit walletAddr={walletAddr} setShowDepositPanel={setShowDepositPanel}/>
            </AntModal>
            <Drawer
                title={t('Drawer with extra actions')}
                placement={'right'}
                onClose={() => setShowFilter(false)}
                width={300}
                open={showFilter}
                destroyOnClose={true}
                className="hireFilter"
            >
                <BattleHireFilter
                    closeHandle={() => {
                        setShowFilter(false);
                    }}
                    currentLevel={tierId}
                    filterLevel={filterLevel}
                    setFilterLevel={setFilterLevel}
                    rankType={rankType}
                    setRanktype={setRankType}
                />
            </Drawer>
        </div>

    )
}
const mapStateToProps = ({app}) => {
    return {
        tranList: app.tranList,
        userInfo: app.userInfo,
        earnInfo: app.earnInfo
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setTranNowType: (params) => {
            return dispatch({
                type: "app/setTranNowType",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(BattleLeaderBoard));
