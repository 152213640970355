import React, {memo, useState, useMemo, useEffect, useRef} from "react";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import {Tooltip, Modal, Button, message, Input} from "antd";
import {DatePicker} from "antd-mobile";
import {post_live_announce_replace} from "@/utils/common";
import enUS from 'antd-mobile/es/locales/en-US'
import {setDefaultConfig} from 'antd-mobile';
import ButtonFactory from "src/common/ButtonFactory";
import AntButton from "@/common/AntButton";

const {TextArea} = Input;

setDefaultConfig({
    locale: enUS,
});

document.documentElement.setAttribute(
    'data-prefers-color-scheme',
    'dark'
)
Date.prototype.format = function (fmt) {
    var o = {
        "M+": this.getMonth() + 1,
        "d+": this.getDate(),
        "h+": this.getHours(),
        "m+": this.getMinutes(),
        "s+": this.getSeconds(),
        "q+": Math.floor((this.getMonth() + 3) / 3),
        "S": this.getMilliseconds()
    };
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    }
    for (var k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        }
    }
    return fmt;
}

const ModelLiveScheduleTime = (
    {
        setOpenLiveScheduleTimePanel,
        updateAnnounceTime,
        groupType,
        keyId
    }
) => {
    const {t, i18n} = useTranslation();
    // const onOk = (value: DatePickerProps['value'] | RangePickerProps['value']) => {
    //     console.log('onOk: ', value);
    // };


    const NowDate = new Date();
    const DateNextH = new Date();
    const DateNextM = new Date();
    DateNextH.setHours(NowDate.getHours() + 1);
    DateNextM.setMonth(NowDate.getMonth() + 1);

    const [visiblDateTime, setVisibleDateTime] = useState(false);
    const [nowDateTime, setNowDateTime] = useState(DateNextH.format("yyyy-MM-dd hh"));
    const unixTimestamp = useRef(parseInt(DateNextH.getTime() / 1000));
    const [title, setTitle] = useState("");

    const submitTime = () => {
        post_live_announce_replace({
            startTime: unixTimestamp.current,
            title: title,
            groupType: parseInt(groupType),
            keyId: parseInt(keyId),
        }).then((data) => {
            updateAnnounceTime();
            setOpenLiveScheduleTimePanel(false);
        }).catch((err) => {
            if (err === 700) {
                message.error("Please select a future time slot");
            } else if (err === 701) {
                message.error("time too long");
            } else if (err === 702) {
                message.error("title too long");
            }
            console.log(err)
        })
    }

    const titleHandler = (event) => {
        setTitle(event.target.value)
    }


    return (
        <div className={s.wrap}>
            <TextArea
                placeholder={t('What do you want to talk about?')}
                value={title}
                onChange={titleHandler}
                style={{width: '270px'}}
                rows={1}
                className="whiteInput"
            />
            <div className={s.btnwrap}>
                <AntButton className={s.btn}
                           onClick={() => {
                               setVisibleDateTime(true)
                           }}
                >
                    {nowDateTime}
                </AntButton>
                <DatePicker
                    visible={visiblDateTime}
                    onClose={() => {
                        setVisibleDateTime(false)
                    }}
                    precision='hour'
                    locale={enUS}
                    min={DateNextH}
                    max={DateNextM}
                    defaultValue={DateNextH}

                    onConfirm={val => {
                        unixTimestamp.current = val.getTime() / 1000;
                        setNowDateTime(val.format("yyyy-MM-dd hh"))
                    }}
                    style={{zIndex: 3020}}
                />
                <div style={{height: '20px'}}></div>
            </div>
            <ButtonFactory className={`modelBottomBtn`} shape="round" onClick={() => {
                submitTime();
            }}>
                {t('Schedule')}
            </ButtonFactory>
        </div>
    )
}
export default memo(ModelLiveScheduleTime);
