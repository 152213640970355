import React, { memo, useEffect, useMemo, useState } from "react";
import s from "./index.module.less";
import { useTranslation, Trans } from 'react-i18next';
import CustomIcon from "@/common/CustomIcon";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import ButtonFactory from "@/common/ButtonFactory";
import { Tabs } from "antd";
import List from "./List";
import AntModal from "@/common/AntModal";
import { getTopicRanklist,
    getUserRanklist } from "@/utils/xFarm";
import Countdown from "@/common/Countdown";
import ModelUpMultiplyCardList from "@/model/ModelUpMultiplyCardList";
const maxAmount = 50
const LeaderShip = (
    {
        userInfo,
        setPath,
        wheelInfo
    }
) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const {multiplyCardNum} = useMemo(()=>{
        return userInfo?.selfData || {}
    },[userInfo])
    const [activeKey, setActiveKey] = useState('1');
    const [leftSecond,setLeftSecond] = useState(wheelInfo);
    const [mentionList, setMentionList] = useState([])
    const [modelUpMultiplyCardList,setModelUpMultiplyCardList] = useState(false)
    const [scoreList, setScoreList] = useState([])
    const [showAmount, setShowAmount] = useState({
        "1":10,
        "2":10
    })
   

    const items = [
        {
            key: '1',
            label: `${t('Top Mentioned')}`,
            children: <List list={mentionList} showAmount={showAmount} type={'1'}/>,
        },
        {
            key: '2',
            label: `${t('Top Score picker')}`,
            children: <List list={scoreList} showAmount={showAmount} type={'2'}/>,
        }
    ];

    useEffect(()=>{
        getTopicRanklist().then(res=>{
            setMentionList(res?.rankList || [])
        }).catch(e=>{
            console.error(e);
        })
        getUserRanklist().then(res=>{
            setScoreList(res?.userRankList || [])
        }).catch(e=>{
            console.error(e);
        })
    },[])

    return (
        <div className={`${s.wrap}`}>
            <div className={s.content}>
                <Tabs 
                    defaultActiveKey={'1'} 
                    activeKey={activeKey}
                    className={`tptabs`}
                    items={items}
                    onChange={(key)=>{
                        setActiveKey(key)
                    }}
                >
                        
                </Tabs>
            </div>

            <div className={s.bottom}>
                {showAmount[activeKey] !== maxAmount && ((activeKey === '1' && mentionList?.length>10) || (activeKey === '2' && scoreList?.length>10)) && <ButtonFactory
                    size={21}
                    scale={1.5}
                    color={3}
                    
                    onClick={() => {
                        const obj = {}
                        obj[activeKey] = maxAmount
                        setShowAmount({
                            ...showAmount,
                            ...obj
                        })
                       
                    }}
                >
                    {t('Show more')}
                </ButtonFactory>}
                <div className="mt10 mb20">{t('Reset in')} {wheelInfo?.leftSecond ? <Countdown leftSecond={wheelInfo?.leftSecond} refresh={()=>{
                    setLeftSecond(0)
                }}></Countdown> : t('Ended')} </div>
                {activeKey === '1' && 
                    <div className={s.booster}>
                        <div className={s.boosterL}>
                            <CustomIcon className={s.icon} width={40} height={40} imgName={"Picture/UI_Picture_Title-Blue_Rocket_01"} />
                            <div className={`color-yellow ${s.multiplyCardNum} fs16`}>X {multiplyCardNum || 0}</div>
                        </div>
                        <div className="f1 tll ml10">{t('Holding Cards of Top 10 mentioned user given Booosters everyday!')}</div>
                        <div className="ml10" onClick={() => setModelUpMultiplyCardList(true)}><CustomIcon width={25} height={25} imgName={"Button/UI_Button_YellowRound-CopyNumberWordName_01"} /></div>
                    </div>
                }
            </div>
            <AntModal
                width='330px'
                title={t('Booster Acquisition Log')}
                className="confirmModalWrap"
                centered={true}
                open={modelUpMultiplyCardList}
                destroyOnClose={true}
                onOk={() => setModelUpMultiplyCardList(false)}
                onCancel={() => setModelUpMultiplyCardList(false)}
            >
                <ModelUpMultiplyCardList />
            </AntModal>
        </div >

    )
}

const mapStateToProps = ({ app }) => {
    return {
        userInfo: app.userInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {}
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(LeaderShip));
