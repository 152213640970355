import React, {memo, useCallback, useEffect, useMemo, useRef} from "react";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import CustomIcon from "../../../common/CustomIcon";
import {connect} from "react-redux";
import {useState} from "react";
import {Input, Button, Modal, Spin, Popover} from 'antd';
import {useLocation, useNavigate} from "react-router-dom";
import Banner from "../../../common/Banner";
import {
    joinChartPre,
    taPush,
    getAvatarFromProfile,
    displayFilter,
    copyFn,
    getProfile,
    post_batch_users_profile,
    convertWalletBalance,
    getAvatarFromUserInfo, sliceStr, convertScore, countDownTime, getConfig
} from "@/utils/common"
import sharewinImg from '@/assets/images/bgImg/sharewinrewards.webp';
import linkshare from '@/assets/images/linkshare.png';
import InfiniteScroll from "react-infinite-scroll-component";
import AvatarInfo from "src/common/AvatarInfo";
import ShareDetailQuestExpand from "@/views/games/ShareDetail/QuestExpand";
import moment from "moment";
import {getPointDetail} from "@/utils/questScore";
import ModelShareHelp from "src/model/ModelShareHelp";
import ModelTweetsShare from "src/model/ModelTweetsShare";
import ModelPointLog from "src/model/ModelPointLog";
import {twitterShareEndTime} from "@/utils/env";
import ModelShareTips from "src/model/ModelShareTips";
import {linkPath, mainHomePath} from "@/routes/config";
import MainHeader from "@/common/MainHeader";
import AntButton from "@/common/AntButton";
import AntModal from "@/common/AntModal";
import {AutoStrangeRule} from "@/utils/strangeRule";


const ShareDetail = (
    {
        userInfo,
    }
) => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const {
        platformData, userId
    } = userInfo;
    const selfProfile = getProfile(platformData);

    const {platformMap, useType, defaultName} = useMemo(() => {
        return platformData || {}
    }, [platformData])

    const {uid} = useMemo(() => {
        if (platformMap && JSON.stringify(platformMap) !== '{}') {
            return platformMap[useType]
        } else {
            return {
                displayName: defaultName
            }
        }
    }, [platformMap, useType, defaultName])

    const {avatarUrl, originalAvatarUrl} = useMemo(() => {
        return getAvatarFromUserInfo(userInfo)
    }, [platformMap, useType, userInfo])

    const location = useLocation()
    const colsePage = () => {
        if (location.key !== "default") {
            navigate(-1);
        } else {
            navigate(mainHomePath, {replace: true});
        }
    }

    function getNextDayUTCTimestamp() {
        return moment().utc().add(1, 'days').startOf('day').valueOf();
    }

    const customTimestamp = getNextDayUTCTimestamp() / 1000;

    const [loading, setLoading] = useState(true);


    const getMore = () => {

    }

    const nextCursor = useMemo(() => {
        return -1
    }, [])
    const [list, setList] = useState([])
    const [scoreData, setScoreData] = useState({})
    const [estLFG, setEstLFG] = useState('--')
    const [sumPoint, setSumPoint] = useState('--')
    const [selfRank, setSelfRank] = useState(0)
    const [showChangeAvatar, setShowChangeAvatar] = useState(false);
    useEffect(() => {
        getPointDetail(uid).then(r => {
            setScoreData(r)
            setEstLFG(r?.self_data?.est_lfg)
            setSumPoint(r?.self_data?.sum_points)

            const showNum = r?.list?.length > 50 ? 50 : r?.list?.length;

            let ids = [];
            let idsMap = {};
            for (let i = 0; i < showNum; i++) {
                ids.push(r?.list[i]?.author_id)
                idsMap[r?.list[i]?.author_id] = r?.list[i];
            }

            post_batch_users_profile(1, ids).then(data => {
                let userProfiles = {};
                for (const element of data?.userProfiles) {
                    userProfiles[element?.twitterUID] = element?.profile;
                }

                let newList = [];
                let rankDel = 0;
                let selfRank = r?.self_data?.rank;
                for (let i = 0; i < showNum; i++) {
                    const author_id = r?.list[i]?.author_id;
                    if (userProfiles[author_id]) {
                        newList.push({
                            author_id: idsMap[author_id]?.author_id,
                            sum_points: idsMap[author_id]?.sum_points,
                            est_lfg: idsMap[author_id]?.est_lfg,
                            rank: idsMap[author_id]?.rank - rankDel,
                            profile: userProfiles[author_id],
                        })

                    } else {
                        rankDel++;
                        if (r?.self_data?.rank > idsMap[author_id]?.rank) {
                            selfRank--;
                        }
                    }

                    if (newList.length >= 20) break;
                }
                setSelfRank(selfRank);
                setList(newList);
                setLoading(false)
            }).catch(() => {
                setLoading(false);
            })
        }).catch(() => {
            setLoading(false);
        })
    }, [])
    const [helpModal, setHelpModal] = useState(false);
    const [logModal, setLogModal] = useState(false);
    const [tipsModal, setTipsModal] = useState(false);
    const [openSharePanel, setOpenSharePanel] = useState(false);

    const [rewardTimer, setRewardTimer] = useState('');

    useEffect(() => {
        const timer = setInterval(() => {
            setRewardTimer(countDownTime(customTimestamp * 1000, 2));
        }, 1000)

        return () => {
            clearInterval(timer)
        }
    }, [customTimestamp])

    return (
        <div className={`${s.modalWrap}`}>
            <MainHeader leftTitle={t('Earn $UP Points')} isGameHeader={true} hasBackBtn={false} rightMenuIcon ={"Button/UI_Button-D_Home_On"}></MainHeader>
            <div style={{marginTop: '0', width: '100%'}}>
                <Banner bgUrl={sharewinImg} title={<div className="mt25" style={{fontSize: '20px'}}>
                    <Trans
                        i18nKey="Share TURNUP on X every day"
                        defaults="<sp>Share on X to</sp><br></br> Earn 50000 $UP Points!"
                        components={{
                            sp: <span style={{fontSize: '20px'}}></span>,
                            br: <br/>
                        }}
                    />
                </div>}
                        showTimerShare={true}
                        hasCurndown={true}
                        customTimestamp={twitterShareEndTime}
                />
            </div>
            <div className={s.modal}>
                <div className={s.content}>
                    <div className={s.wrap}>
                        {uid && <>
                            <div className={`mt15 ${s.userinfo}`}>
                                <div className={s.left}>
                                    <AvatarInfo size={52} src={originalAvatarUrl || avatarUrl}/>
                                </div>
                                <div className={s.right}>
                                    <div className={`ml10 fs12 ${s.value}`}>
                                        {t('TURNUP Score')}
                                        <CustomIcon width={15} height={15} className="ml10"
                                                    imgName={'Button/UI_Button_QuestionMark_01'}
                                                    name={'Button/UI_Button_QuestionMark_01'}
                                                    onClick={() => {
                                                        setHelpModal(true);
                                                    }}/>
                                        <br/>
                                        <div className={`aic mt10 ${s.itemInfo}`}>
                                            <div
                                                className="fs16 color-white tlr w100p">{convertWalletBalance(sumPoint)}</div>
                                        </div>
                                    </div>
                                    <div className={`ml10 fs12 ${s.value}`}>
                                        {t('Est. $UP Points')}<br/>
                                        <div className={`aic mt10 ${s.itemInfo}`}>
                                            <div className="fs16 w100p df jc-spaceBetween alc">
                                                <CustomIcon
                                                    width={22}
                                                    height={22}
                                                    imgName={"Picture/UI_Picture-Currency_UP_01"}
                                                    name={"Picture/UI_Picture-Currency_UP_01"}
                                                    className="mr5"
                                                /> <span className="fs16 tlr">{Math.trunc(estLFG)}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`ml10 ${s.sharebtn}`} onClick={() => {
                                        setLogModal(true);
                                    }}>
                                        <CustomIcon width={31} height={31}
                                                    imgName={'Button/UI_Button_YellowRound-CopyNumberWordName_01'}
                                                    name={'Button/UI_Button_YellowRound-CopyNumberWordName_01'}/>
                                    </div>
                                </div>
                            </div>
                            <div className={`mt15 ${s.shareBtn}`} onClick={() => {
                                setOpenSharePanel(true);
                            }}>
                                <AntButton className="btn_public" style={{width: '250px'}}>
                                    <CustomIcon width={20} height={20}
                                                imgName={'Button/UI_Button_Share_02'}
                                                name={'Button/UI_Button_Share_02'}/>
                                    <span className="fs16">POST X(Twitter) Tweet</span>
                                </AntButton>
                            </div>
                            <div className={`${s.bt} mt15`}>
                                <div className={s.btl}>
                                    <div className={`${s.partTitle} fb`}>
                                        <CustomIcon width={17} height={39} className=""
                                                    imgName={'Picture/UI_Picture_Title-Yellow_01'}
                                                    name={'Button/UI_Button_Arrow_01'}/>
                                        {AutoStrangeRule(t('Your Daily TURNUP Score'))}
                                        <CustomIcon width={15} height={15} className="ml10"
                                                    imgName={'Button/UI_Button_QuestionMark_01'}
                                                    name={'Button/UI_Button_QuestionMark_01'}
                                                    onClick={() => {
                                                        setTipsModal(true);
                                                    }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={`df jc-spaceBetween mb15 ${s.container}`}>
                                <div className="w100p">
                                    <div className={s.tweetsbox}>
                                        <ShareDetailQuestExpand
                                            tweets={scoreData?.self_data?.tweets}
                                            type={"Tweets"}
                                            total={3}
                                            placement="bottomLeft"
                                        />
                                        <ShareDetailQuestExpand
                                            tweets={scoreData?.self_data?.replies}
                                            type={"Replies"}
                                            total={5}
                                            placement="bottom"
                                        />
                                        <ShareDetailQuestExpand
                                            tweets={scoreData?.self_data?.quotes}
                                            type={"Quote"}
                                            total={5}
                                            placement="bottom"
                                        />
                                    </div>
                                </div>
                            </div>
                        </>}
                        {!uid && <>

                            <div className={`mt15 mb15 fs14 ${s.linksharetext}`}>
                                <img src={linkshare} style={{width: "80%"}}/>
                                <p>{t('Connect your X to earn $UP points')}</p>
                            </div>
                            <AntButton onClick={() => navigate(linkPath)}
                                       className={`btn_public widthAuto mb15`}
                            >{t('+ Connect Socials')}</AntButton>
                        </>}
                        <div className={s.toplist}>
                            {!loading ? list.length > 3 &&
                                <>
                                    <div className={`${s.bt}`}>
                                        <div className={s.btl}>
                                            <div className={`${s.partTitle} fb`}>
                                                <CustomIcon width={17} height={39} className=""
                                                            imgName={'Picture/UI_Picture_Title-Yellow_01'}
                                                            name={'Button/UI_Button_Arrow_01'}/>
                                                {AutoStrangeRule(t('Leaderboards'))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={s.toplistmain}>
                                        <div className={s.top2}>
                                            <div className="fs16">{t('Top 2')}</div>
                                            <div className={`mt20 mb5 ${s.topbg}`}>
                                                <div style={{marginTop: "-15px"}}><AvatarInfo size={48}
                                                                                              src={getAvatarFromProfile(list[1].profile)}/>
                                                </div>
                                            </div>
                                            {/*<div className="fs14">{list[1].profile.displayName}</div>*/}
                                        </div>
                                        <div className={s.top1}>
                                            <div className="fs18">{t('Top 1')}</div>
                                            <div className={`mt20 mb5 ${s.topbg}`}>
                                                <div style={{marginTop: "-15px"}}><AvatarInfo size={48}
                                                                                              src={getAvatarFromProfile(list[0].profile)}/>
                                                </div>
                                            </div>
                                            {/*<div className="fs14">{list[0].profile.displayName}</div>*/}
                                        </div>
                                        <div className={s.top3}>
                                            <div className="fs16">{t('Top 3')}</div>
                                            <div className={`mt20 mb5 ${s.topbg}`}>
                                                <div style={{marginTop: "-15px"}}><AvatarInfo size={48}
                                                                                              src={getAvatarFromProfile(list[2].profile)}/>
                                                </div>
                                            </div>
                                            {/*<div className="fs14">{list[2].profile.displayName}</div>*/}
                                        </div>
                                    </div>
                                </>

                                : <div className="">{t('Loading...')}</div>
                            }
                        </div>
                        <div className={s.inviteCode}>
                            <div className="fs20">
                                {t('Global TURNUP Score: ')}
                                <span className="mb10 fs26 color-yellow">
                                    {convertScore(scoreData?.global_points || 0)}
                                </span>
                            </div>
                            <div className="mb10 fs14">
                                {t('Reward Countdown: ')}
                                <span className="mb10 fs13">
                                    {rewardTimer}
                                </span>
                            </div>
                            {!loading ?
                                <div className={s.list}>
                                    <div className={`df jc-spaceBetween fs12 mb5 ${s.listtit}`}>
                                        <span className="ml30 fs12 color-white"
                                              style={{width: "29%"}}>{t('USER')}</span>
                                        <span className="mr10 fs12 color-white">{t('TURNUP Score')}</span>
                                        <span className="mr10 fs12 color-white">{t('Est. $UP Points')}</span>
                                    </div>
                                    <InfiniteScroll
                                        dataLength={list?.length || 0}
                                        next={getMore}
                                        hasMore={nextCursor !== -1}
                                        loader={<Spin/>}
                                        height={160}
                                        endMessage={
                                            <div style={{
                                                textAlign: "center",
                                                width: "100%",
                                                color: "var(--color-gray)",
                                                fontSize: '12px'
                                            }}></div>
                                        }
                                    >
                                        <div className={s.scrollY}>
                                            {
                                                list.map((item, ind) => {
                                                    const {author_id, sum_points, est_lfg} = item;
                                                    const {displayName, accountName, platformType, uid} = item?.profile;
                                                    const avatarUrl = getAvatarFromProfile(item?.profile);
                                                    return (
                                                        <>
                                                            <div className={s.item} key={author_id}>
                                                                <div className={s.rank}>
                                                                    {ind + 1 === 1 &&
                                                                        <CustomIcon className="ml5" width={24}
                                                                                    height={24}
                                                                                    imgName={'Picture/UI_Picture_NO-First_01'}
                                                                                    name={'Picture/UI_Picture_NO-First_01'}/>}
                                                                    {ind + 1 === 2 &&
                                                                        <CustomIcon className="ml5" width={24}
                                                                                    height={24}
                                                                                    imgName={'Picture/UI_Picture_NO-Second_01'}
                                                                                    name={'Picture/UI_Picture_NO-Second_01'}/>}
                                                                    {ind + 1 === 3 &&
                                                                        <CustomIcon className="ml5" width={24}
                                                                                    height={24}
                                                                                    imgName={'Picture/UI_Picture_NO-Third_01'}
                                                                                    name={'Picture/UI_Picture_NO-Third_01'}/>}
                                                                    {ind + 1 > 3 && (ind + 1)}
                                                                </div>
                                                                <div className={s.info}>
                                                                    <AvatarInfo size={30} src={avatarUrl}
                                                                                className="roundAvatar"/>
                                                                    <div className="ml5 tll">
                                                                        {ind + 1 < 4 ?
                                                                            <div>
                                                                                {sliceStr(displayName, 9, true)}
                                                                            </div> :
                                                                            <div>
                                                                                {sliceStr(displayName, 9, true)}
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className={s.points}>
                                                                    {ind + 1 < 4 ?
                                                                        <div>
                                                                            {convertScore(sum_points)}
                                                                        </div> :
                                                                        <div>
                                                                            {convertScore(sum_points)}
                                                                        </div>
                                                                    }
                                                                </div>
                                                                <div className={s.estLFG}
                                                                     style={{
                                                                         display: 'flex',
                                                                         alignItems: "center",
                                                                         justifyContent: 'center'
                                                                     }}>
                                                                    <CustomIcon
                                                                        width={18}
                                                                        height={18}
                                                                        imgName={"Picture/UI_Picture-Currency_UP_01"}
                                                                        name={"Picture/UI_Picture-Currency_UP_01"}
                                                                        className="mr5"
                                                                    />
                                                                    {ind + 1 < 4 ?
                                                                        <span>
                                                                            {Math.trunc(est_lfg)}
                                                                        </span> :
                                                                        <span>
                                                                            {Math.trunc(est_lfg)}
                                                                        </span>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                })
                                            }
                                        </div>
                                    </InfiniteScroll>

                                </div>
                                : <div className="">{t('Loading...')}</div>}
                        </div>
                    </div>

                    <div className={s.bottom}>
                        <div className="fs12 mb5" style={{
                            width: "100%",
                            textAlign: "center"
                        }}>{t('TURNUP score is updated every 15 minutes.')}</div>
                    </div>
                </div>
            </div>

            <AntModal
                width='330px'
                className="confirmModalWrap"
                centered={true}
                open={helpModal}
                destroyOnClose={true}
                onOk={() => setHelpModal(false)}
                onCancel={() => setHelpModal(false)}
            >
                <ModelShareHelp/>
            </AntModal>
            <AntModal
                width='330px'
                title=""
                className="confirmModalWrap tweetShareModal noImageTitle"
                centered={true}
                open={openSharePanel}
                onOk={() => setOpenSharePanel(false)}
                onCancel={() => setOpenSharePanel(false)}
            >
                <ModelTweetsShare/>
            </AntModal>
            <AntModal
                width='330px'
                title={t('My $UP Points')}
                className="confirmModalWrap"
                centered={true}
                open={logModal}
                destroyOnClose={true}
                onOk={() => setLogModal(false)}
                onCancel={() => setLogModal(false)}
            >
                <ModelPointLog/>
            </AntModal>
            <AntModal
                width='330px'
                title={t('SCORE BREAKDOWN')}
                className="confirmModalWrap"
                centered={true}
                open={tipsModal}
                destroyOnClose={true}
                onOk={() => setTipsModal(false)}
                onCancel={() => setTipsModal(false)}
            >
                <ModelShareTips/>
            </AntModal>
        </div>

    )
}

const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
        defaultInviteCode: app.defaultInviteCode
    };
};

const mapDispatchToProps = (dispatch) => {
    return {}
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(ShareDetail));
