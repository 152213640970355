import {memo, useMemo, useState} from "react";
import s from "./index.module.less";
import {useNavigate, useLocation} from "react-router-dom";
import CustomIcon from "src/common/CustomIcon";
import {getearninfo, unit} from "@/utils/common";
import {connect} from "react-redux";
import {Modal} from "antd";
import ModelSettingsMenu from "../../model/ModelSettingsMenu";
import ModelDeposit from "../../model/ModelDeposit";
import ModelSwitchLanguage from "../../model/ModelSwitchLanguage";
import ModalSwapLfg from "../../model/ModalSwapLfg";
import {useTranslation} from 'react-i18next';
import ModelWithdraw from "../../model/ModelWithdraw";
import {backFn} from "@/utils/mianPublic";
import StrangeRule from "@/utils/strangeRule";
import AntModal from "@/common/AntModal";
import { mainHomePath } from "@/routes/config";
// import ModelSettingsAccountLink from "@/components/ModelSettingsAccountLink";

const MainHeader = (
    {
        userInfo,
        hasBackBtn = true,
        title = '',
        hasRightMenu = true,
        headerType = 1,
        isGameHeader = false,
        onBack = null,
        onClickRightIcon = null,
        rightMenuIcon = '',
        leftTitle = ''  //
    }
) => {
    const navigate = useNavigate();
    const location = useLocation();
    const {
        walletAddr,
        topUpCode,
    } = useMemo(() => {
        return userInfo || {}
    }, [userInfo]);
    const {t} = useTranslation();

    const [showMenuDialog, setShowMenuDialog] = useState(false)
    const [showDepositPanel, setShowDepositPanel] = useState(false);
    const [showSwitchLanguage, setShowSwitchLanguage] = useState(false);
    const [showSwapLfg, setShowSwapLfg] = useState(false);
    const [showWithdrawPanel, setShowWithdrawPanel] = useState(false);
    const [showAccountLink, setShowAccountLink] = useState(false);

    const getearninfoFn = () => {
        getearninfo().then(res => {
            // setBoostLeftSec(res.boostLeftSec)
            // setLeftSecLoading(false)
        }).catch(e => {
            console.log(e);
            // setLeftSecLoading(false)
        })
    }
    return (
        <div className={`${s.mainHeader} ${headerType === 2 ? s.type2 : ''}`}>
            <div className={s.moreMenu}>
                {hasRightMenu &&
                    <CustomIcon className="ml5" width={48} height={30} imgName={`${rightMenuIcon || 'Picture/UI_Picture_SetUp_01'}`}
                                onClick={() => {rightMenuIcon ? navigate(mainHomePath, {replace: true}) : setShowMenuDialog(true)}}/>
                }
            </div>
            <div className={`fs22 fb ${s.title}`}><StrangeRule>{title}</StrangeRule></div>
            <div className={`${s.back}`}>
                {hasBackBtn && <CustomIcon width={29} onClick={() => {
                    onBack ? onBack() : backFn(location, navigate)
                }} height={30}
                                           imgName={'Button/UI_Button_Exit_01'}/>}
                {leftTitle && <div className="fs28 fb"><StrangeRule>{leftTitle}</StrangeRule></div>}

            </div>


         
            <AntModal
                width={330}
                className="confirmModalWrap noImageTitle"
                centered={true}
                open={showMenuDialog}
                onOk={() => setShowMenuDialog(false)}
                onCancel={() => setShowMenuDialog(false)}
            >
                <ModelSettingsMenu 
                    unit={unit} 
                    setShowDepositPanel={setShowDepositPanel}
                    setShowWithdrawPanel={setShowWithdrawPanel}
                    setShowSwitchLanguage={setShowSwitchLanguage}
                    isGameHeader={isGameHeader}
                    navigate={navigate}
                    setShowSwapLfg={setShowSwapLfg}
                    setShowAccountLinking={setShowAccountLink}/>
            </AntModal>
            <AntModal
                width='330px'
                title={t('Get More {{coin}}', {coin: 'Crypto'})}
                className="confirmModalWrap"
                centered={true}
                open={showDepositPanel}
                onOk={() => setShowDepositPanel(false)}
                onCancel={() => setShowDepositPanel(false)}
                zIndex={2000}
            >
                <ModelDeposit 
                    topUpCode={topUpCode} 
                    walletAddr={walletAddr} 
                    setShowDepositPanel={setShowDepositPanel}
                    chainTokenInfo={null}/>
            </AntModal>
            <AntModal
                width='330px'
                title={t('WITHDRAW xx', {unit: unit})}
                className="confirmModalWrap"
                centered={true}
                open={showWithdrawPanel}
                onOk={() => setShowWithdrawPanel(false)}
                onCancel={() => setShowWithdrawPanel(false)}
            >
                <ModelWithdraw getearninfoFn={getearninfoFn} topUpCode={topUpCode} userInfo={userInfo}
                               setShowWithdrawPanel={setShowWithdrawPanel}/>
            </AntModal>
            <AntModal
                width='330px'
                title={t('SWITCH LANGUAGE')}
                className="confirmModalWrap"
                centered={true}
                open={showSwitchLanguage}
                onOk={() => setShowSwitchLanguage(false)}
                onCancel={() => setShowSwitchLanguage(false)}
            >
                <ModelSwitchLanguage setShowSwitchLanguage={setShowSwitchLanguage}/>
            </AntModal>
            <AntModal
                width='330px'
                title={t('SWAP $MATIC AND $LFG')}
                className="confirmModalWrap"
                centered={true}
                open={showSwapLfg}
                onOk={() => setShowSwapLfg(false)}
                onCancel={() => setShowSwapLfg(false)}
                destroyOnClose={true}
                zIndex={2000}
            >
                <ModalSwapLfg userInfo={userInfo} closeFn={() => {
                    setShowSwapLfg(false)
                }}/>
            </AntModal>
        </div>
    );
};
const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,

    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setCurRtcInfo: (params) => {
            return dispatch({
                type: "app/setCurRtcInfo",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(MainHeader));
