
import http from "@/utils/axios";
import { url } from "@/utils/configUri";
import memeChainToken from "@/utils/json/memeChainToken.json" // New Data
import memePriceFormular from "@/utils/json/memePriceFormular.json" // New Data
import memeImageTemplate from "@/utils/json/memeImageTemplate.json"
import memeCreateTemplate from "@/utils/json/memeCreateTemplate.json"
import memeNFTAttrTemplate from "@/utils/json/memeNFTAttrTemplate.json" // Attr Data
import {
    getWeb3Config,
} from "@/utils/common";
import {
    isOnlineEnv,
    LfgMainnetId,
    LfgTestnetId,
} from "@/utils/env";
import {
    getLfgAllowance,
} from "@/utils/bidNftWeb3";
import {
    lfgApprove,
} from "@/utils/lfgStake";
import {getGasConfig} from "@/utils/gas";
import {ethers} from "ethers";
import {abi_memeClub} from "@/utils/abi";
import memeSettingValue from "@/utils/json/memeSettingValue.json" // Price Data

const coinTotalSupplyData = memeSettingValue.find(item => item?.value === 'coinTotalSupply')
const airdropPecentageData = memeSettingValue.find(item => item?.value === 'airdropPecentage')
const coinToNFTPecentageData = memeSettingValue.find(item => item?.value === 'coinToNFTPecentage')
const gentlePreferPecentageData = memeSettingValue.find(item => item?.value === 'gentlePreferPecentage')
const excitingPreferPecentageData = memeSettingValue.find(item => item?.value === 'excitingPreferPecentage')
const coinEachPackRangeRatioData = memeSettingValue.find(item => item?.value === 'coinEachPackRangeRatio')
const airdropTypeData = {
    1: gentlePreferPecentageData,
    2: excitingPreferPecentageData
}

const swapPresetPackNum = [1,5,10];
const swapPresetSellArr = [0.25,.5,.75,1]
const defaultAttr = memeNFTAttrTemplate.filter(item => item?.isDefault === 1)
const formatAttr = (attrs) => {
    let attr = []
    if(!Array.isArray(attrs)){
        attrs = [attrs]
    }
    attrs?.map(i => {
        const { Enum, Max, Min, TraitName, Type } = i;
        let enums = []
        if (Type === 'Categorical') {
            Enum.map(t => {
                const { trait_type, value } = t;
                if (value) {
                    enums.push({
                        enumName: value,
                        weight: 10
                    })
                }

            })
        }
        attr.push({
            attrName: TraitName,
            type: Type === 'Categorical' ? 2 : 1,
            minNum: Min,
            maxNum: Max,
            enumStrs: enums
        })
    })
    return attr
}

let preferencesItems = [],preferencesObj = {};

const websiteAfterAddon = {
    'x':'https://x.com/',
    't':'https://telegram.me/'
}

memeCreateTemplate.map(i=>{
    const { typeName, type} = i;
    if (preferencesObj[type]) {
        preferencesObj[type].push({
            ...i
        })
    } else {
        let obj = {}
        obj['' + type] = [
            {
                ...i
            }
        ]
        preferencesObj = {
            ...preferencesObj,
            ...obj
        }
        preferencesItems.push({
            value: type,
            label: typeName,
        })
    }
})
let attrMap = {};
memeNFTAttrTemplate.map(i=>{
    const {Template} = i;
    if (attrMap[Template]) {
        attrMap[Template].push(
            ...formatAttr(i)
        )
    } else {
        let obj = {}
        obj['' + Template] = [
            ...formatAttr(i)
        ]
        attrMap = {
            ...attrMap,
            ...obj
        }
    }
    

})

console.log(`attrMap------>`,attrMap);



const calcTotalValue = (calcObj, a, b, n) => {
    const {formulaEnum:type,divParamC:c,mulParamD:d} = calcObj
    switch (type) {
        case 1:
            return (n * (n + 1) / 2 * a + n * b) / c * d;
        case 2:
            return (n * (n + 1) * (2 * n + 1) / 6 * a + n * (n + 1) / 2 * b) / c * d;
        case 3:
            return n * a / c * d;
    }
}

const getChainInfo = (chainId) => {
    return memeChainToken.find(i => i?.ID === chainId)
}
const detailInfoShowHolderNum = 10;
const _isOnlineEnv = isOnlineEnv();
const creatClub = ({
    coinId,
    clubName,
    symbolName,
    imageUrl,
    symbolImageUrl,
    description,
    cardsTotalSupply,
    curveType,
    airdropPercent,
    openPackRatio,
    anonymous,
    isOpenCoinToNFT,
    coinToNFTRatio,
    withRarity,
    ftTotalSupply,
    attrs,
    isFT,
    preBuyCardNum,
    paramA,
    paramB,
    twitterLink,
    tgLink
}) => {
    return new Promise((resolve, reject) => {
        http('post', url.meme_create, {
            coinId,
            clubName,
            symbolName,
            imageUrl,
            symbolImageUrl,
            description,
            cardsTotalSupply,
            curveType,
            paramA,
            paramB,
            ftTotalSupply,
            airdropPercent,
            openPackRatio:Number(openPackRatio),
            anonymous,
            isOpenCoinToNFT,
            coinToNFTRatio,
            isFT,
            withRarity,
            attrs,
            preBuyCardNum,
            twitterLink,
            tgLink
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e?.toString() || 'Create Failed');
        })
    })

}
// New Data
let coinData = {}, chainData = [], coinList = [];
memeChainToken.map(item => {
    const { 
        isNative, 
        name, 
        coinName, 
        chainId, 
        isEnable, 
        isTestChain, 
        ID, 
        icon,
        kotBanner,
    } = item
    if (isEnable === 1 && ((_isOnlineEnv && !isTestChain) || (!_isOnlineEnv && isTestChain))) {
        if (coinData[chainId]) {
            coinData[chainId].push({
                value: isNative,
                label: coinName
            })
        } else {
            let obj = {}
            obj['' + chainId] = [
                {
                    value: isNative,
                    label: coinName
                }
            ]
            coinData = {
                ...coinData,
                ...obj
            }
            chainData.push({
                value: chainId,
                label: name,
            })
        }
        coinList.push({
            value: ID,
            name,
            coinName,
            label: `${name}-${coinName}`,
            icon,
            kotBanner: kotBanner,
        })
    }

})

console.log(`coinList`,coinList)

const memeClubList = (obj) => {
    return new Promise((resolve, reject) => {
        http('post', url.meme_clublist, obj).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const getReferralcodeMemetradeReflist = (cursor = 0) => {
    return new Promise((resolve, reject) => {
        http('post', url.referralcode_memetrade_reflist, {
            cursor: cursor
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const getMemeTgeNftRankList = ({
    clubUserId,
    rankType,
    rarityRange,
    onlyHeld,
    isAsc,
    cursor,
}) => {
    return new Promise((resolve, reject) => {
        http('post', url.meme_tge_nftranklist, {
            clubUserId,
            rankType,
            rarityRange,
            onlyHeld,
            isAsc,
            cursor,
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const getMemeTgeNftInfo = ({
    clubUserId,
    tokenId
}) => {
    return new Promise((resolve, reject) => {
        http('post', url.meme_tge_nftinfo, {
            clubUserId,
            tokenId
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const getMemeTgeNftCount = ({
    clubUserId
}) => {
    return new Promise((resolve, reject) => {
        http('post', url.meme_tge_nftcount, {
            clubUserId
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const getMemeGenImage = ({keyWord, genType = 1}) =>{
    return new Promise((resolve, reject) => {
        http('post', url.meme_genimage, {
            keyWord,
            genType,
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const getMemeTgeNoticeList = () => {
    return new Promise((resolve, reject) => {
        http('post', url.meme_tge_noticelist).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const setMemeTgeNoticeRead = (clubUserIds) => {
    return new Promise((resolve, reject) => {
        http('post', url.meme_tge_noticesetread, {
            clubUserIds: clubUserIds
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const memeTgeClublist = (obj) => {
    return new Promise((resolve, reject) => {
        http('post', url.meme_tgeclublist, obj).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const memeClubDetail = () => {
    return new Promise((resolve, reject) => {
        http('post', url.nft_auction_refund, {}).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const memeClubInfo = (clubUserId) => {
    return new Promise((resolve, reject) => {
        http('post', url.meme_clubinfo, {
            clubUserId: Number(clubUserId)
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const memeMintCoin = (clubUserId, openCardNum) => {
    return new Promise((resolve, reject) => {
        http('post', url.meme_mintcoin, {
            clubUserId: Number(clubUserId),
            openCardNum: Number(openCardNum)
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const delay = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

const setFavorite = (userId, favorite) => {
    return new Promise((resolve, reject) => {
        http('post', url.meme_setfavor, {
            clubUserId: parseInt(userId),
            favorite,
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const getRecommendClub = () => {
    return new Promise((resolve, reject) => {
        http('post', url.meme_topprogress, {}).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}


const searchclub = (searchKey, cursor, searchType) => {
    return new Promise((resolve, reject) => {
        http('post', url.meme_searchclub, {
            searchKey,
            searchType,
            cursor
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const getMemeTradeInfo = (clubUserId, cardNum, isBuy) =>{
    return new Promise((resolve, reject) => {
        http('post', url.meme_tradeInfo, {
            clubUserId: clubUserId,
            cardNum: cardNum,
            isBuy: isBuy,
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const getMemeTradevolInfo = () => {
    return new Promise((resolve, reject) => {
        http('post', url.meme_tradevol_info, {
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const getMemeClubPackGasPrice = (wallets, coinInfo) =>{
    return new Promise(async (resolve, reject) => {
        try {
            const embeddedWallet = wallets.find((wallet) => (wallet.walletClientType === 'privy'));
            const chainId = coinInfo?.chainId;
            await embeddedWallet.switchChain(chainId);
            const provider = await embeddedWallet.getEthersProvider();
            const signer = provider.getSigner();
            const gasData = await getGasConfig(signer);
            const value = ethers.utils.formatEther(gasData?.maxFeePerGas?.toString());
            resolve(value);
        } catch (e) {
            console.log("buyShares e:" + e);
            if (e.message.indexOf('(reading \'switchChain\')') > 0) {
                console.log("privy need login")
                reject('need login');
            } else {
                reject(e);
            }
        }
    })
}

const buyMemeClubPack = (wallets, clubId, amount, expectedPrice, coinInfo) => {
    return new Promise(async (resolve, reject) => {
        try {
            const embeddedWallet = wallets.find((wallet) => (wallet.walletClientType === 'privy'));

            const web3Config = await getWeb3Config();

            const contractInfo = web3Config?.contractList?.find((item) => (item.coinId === coinInfo?.ID));

            if (!contractInfo){
                reject("get config error");
                return;
            }

            const chainId = coinInfo?.chainId;
            const contractAddr = contractInfo?.memeFactoryContract;

            await embeddedWallet.switchChain(chainId);
            console.log("contractAddr: " + contractAddr, "chainId: " + chainId);

            console.log(embeddedWallet);

            const provider = await embeddedWallet.getEthersProvider();

            const signer = provider.getSigner(); // ethers signer object

            const gasData = await getGasConfig(signer);

            const contract = new ethers.Contract(contractAddr, abi_memeClub, provider).connect(signer);

            if (coinInfo?.isNative === 0 && (coinInfo?.ID === LfgMainnetId || coinInfo?.ID === LfgTestnetId)) {
                let currentAllowance = await getLfgAllowance(embeddedWallet, contractAddr, contractInfo?.coinContract);
                console.log('[getLfgAllowance]', {currentAllowance: ethers.utils.formatEther(currentAllowance), expectedPrice});
                if (ethers.utils.formatEther(currentAllowance) < expectedPrice) {
                    let amount = ethers.utils.parseEther(Number.MAX_SAFE_INTEGER.toString());
                    await lfgApprove(wallets, amount, contractInfo?.coinContract, coinInfo?.chainId, contractAddr); //approve lfg for auction As MANY as Possible
                }
            }

            const cfg = {
                ...gasData,
            };
            if (coinInfo?.isNative){
                cfg.value = ethers.utils.parseEther(expectedPrice);
            }

            contract.populateTransaction.buyCard(clubId, amount, ethers.utils.parseEther(expectedPrice), cfg
            ).then(unsignedTx => {
                console.log("unsignedTx", unsignedTx);
                signer.sendTransaction(unsignedTx).then(
                    resp => resolve(resp)
                ).catch(
                    e => reject(e)
                );

            }).catch(e => {
                console.log("buyShares e:" + e);
                reject(e);
            });
        } catch (e) {
            console.log("buyShares e:" + e);
            if (e.message.indexOf('(reading \'switchChain\')') > 0) {
                console.log("privy need login")
                reject('need login');
            } else {
                reject(e);
            }
        }
    })
}

const sellMemeClubPack = (wallets, clubId, amount, expectedPrice, coinInfo) => {
    return new Promise(async (resolve, reject) => {
        try {
            const embeddedWallet = wallets.find((wallet) => (wallet.walletClientType === 'privy'));

            const web3Config = await getWeb3Config();

            const contractInfo = web3Config?.contractList?.find((item) => (item.coinId === coinInfo?.ID));

            if (!contractInfo){
                reject("get config error");
                return;
            }
            const chainId = coinInfo?.chainId;
            const contractAddr = contractInfo?.memeFactoryContract;

            await embeddedWallet.switchChain(chainId);
            console.log("contractAddr: " + contractAddr, "chainId: " + chainId);

            console.log(embeddedWallet);

            const provider = await embeddedWallet.getEthersProvider();

            const signer = provider.getSigner(); // ethers signer object

            const gasData = await getGasConfig(signer);

            const contract = new ethers.Contract(contractAddr, abi_memeClub, provider).connect(signer);

            contract.populateTransaction.sellCard(clubId, amount, {
                ...gasData,
            }).then(unsignedTx => {
                console.log("unsignedTx", unsignedTx);
                signer.sendTransaction(unsignedTx).then(
                    resp => resolve(resp)
                ).catch(
                    e => reject(e)
                );

            }).catch(e => {
                console.log("buyShares e:" + e);
                reject(e);
            });
        } catch (e) {
            console.log("buyShares e:" + e);
            if (e.message.indexOf('(reading \'switchChain\')') > 0) {
                console.log("privy need login")
                reject('need login');
            } else {
                reject(e);
            }
        }
    })
}

const getActiveList = (clubUserId, cursor) => {
    return new Promise((resolve, reject) => {
        http('post', url.meme_activity, {
            clubUserId:parseInt(clubUserId),
            cursor
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
        
    })
}

const getMemeKingTgeList = ({coinIds}) => {
    return new Promise((resolve, reject) => {
        http('post', url.meme_kingtgelist, {
            coinIds: coinIds
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
        
    })
}

const getMemeKingTgeWinnerList = ({coinId, cursor}) => {
    return new Promise((resolve, reject) => {
        http('post', url.meme_kingtgewinnerlist, {
            coinId: coinId,
            cursor: cursor
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
        
    })
}

const getHolderList = (clubUserId,cursor) => {
    return new Promise((resolve, reject) => {
        http('post', url.meme_holderlist, {
            clubUserId:parseInt(clubUserId),
            cursor
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
        
    })
}

const getMemeSelfHoldList = ({cursor, skipClubUserId}) => {
    return new Promise((resolve, reject) => {
        http('post', url.meme_selfholdlist, {
            cursor: cursor, 
            skipClubUserId: skipClubUserId,
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const getMemeClubDntList = ({cursor}) => {
    return new Promise((resolve, reject) => {
        http('post', url.meme_club_dnt_list, {
            
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const getMemeTgeCanopenList = ({cursor}) => {
    return new Promise((resolve, reject) => {
        http('post', url.meme_tgecanopenlist, {
            cursor: cursor
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const userTabList = ({rankTabType,rankSortType,isAsc,cursor}) => {
    return new Promise((resolve, reject) => {
        http('post', url.meme_userTabList, {
            rankTabType,
            rankSortType,
            isAsc,
            cursor
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
        
    })
}

const uploadImage = ({imageType,imageContent}) => {
    return new Promise((resolve, reject) => {
        http('post', url.upload_image, {
            imageType,
            imageContent,
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
        
    })
}

const memeGenattrs = ({keyWord}) => {
    return new Promise((resolve, reject) => {
        http('post', url.meme_genattrs, {
            keyWord,
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
        
    })
}

const setAnony = (anonymous)=>{
    return new Promise((resolve, reject) => {
        http('post', url.meme_setanonymous, {
            anonymous,
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
        
    })
}

const checkAttrs = (attrs)=>{
    const _attrs = [];
    if(!attrs || attrs?.length ===0) return _attrs
    attrs?.map(i=>{
        const {enumStrs,maxNum,minNum,type,attrName} = i;
        const _attrName = attrName.replace(/[^a-zA-Z0-9\s]/g, '');
        let _enumStrs = []
        if(type === 2){
            _enumStrs = enumStrs?.map(item => (
                {
                    ...item,
                    enumName: item?.enumName.replace(/[^a-zA-Z0-9\s]/g, '')
                }
            ))
        }
        _attrs.push({
            enumStrs:_enumStrs,
            maxNum,
            minNum,
            type,
            attrName:_attrName
        })
    })
    return _attrs

}

const getMemeChatUserList = ()=>{
    return new Promise((resolve, reject) => {
        http('post', url.meme_ownclublist, {
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
        
    })
}

const calcNumberCardVal = (type, a, b, x, c, d) => {
    switch (type) {
        case 1:
            return (a * x + b) / c * d
        case 2:
            return (a * Math.pow(x, 2) + b * x) / c * d;
        case 3:
            return a / c * d;
    }
}

const meme_tradelog = (params)=>{
    return new Promise((resolve, reject) => {
        http('post', url.meme_tradelog, params).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
        
    })
}

export {
    creatClub,
    memeClubList,
    setFavorite,
    getRecommendClub,
    searchclub,
    memeClubInfo,
    memeMintCoin,
    getChainInfo,
    getActiveList,
    getMemeTradeInfo,
    getHolderList,
    getMemeClubPackGasPrice,
    buyMemeClubPack,
    sellMemeClubPack,
    getMemeSelfHoldList,
    getMemeClubDntList,
    userTabList,
    memeTgeClublist,
    getMemeTgeCanopenList,
    getMemeKingTgeList,
    getMemeKingTgeWinnerList,
    getMemeTgeNoticeList,
    setMemeTgeNoticeRead,
    getMemeTgeNftRankList,
    getMemeTradevolInfo,
    getMemeTgeNftInfo,
    getMemeTgeNftCount,
    getMemeGenImage,
    getReferralcodeMemetradeReflist,
    coinTotalSupplyData,
    airdropPecentageData,
    airdropTypeData,
    coinToNFTPecentageData,
    detailInfoShowHolderNum,
    memePriceFormular,
    coinData,
    swapPresetPackNum,
    chainData,
    coinList,
    swapPresetSellArr,
    memeChainToken,
    calcTotalValue,
    memeImageTemplate,
    preferencesItems,
    preferencesObj,
    coinEachPackRangeRatioData,
    uploadImage,
    defaultAttr,
    formatAttr,
    memeGenattrs,
    setAnony,
    checkAttrs,
    attrMap,
    websiteAfterAddon,
    calcNumberCardVal,
    getMemeChatUserList,
    meme_tradelog
}