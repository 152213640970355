import React, {memo, useEffect, useMemo, useRef, useState} from "react";
import {connect} from "react-redux";
import {Tabs, Modal, Button, Checkbox, Slider, Spin, Drawer} from 'antd';
import s from "./index.module.less";
import {
    post_hire_list,
    getAvatarFromProfile,
    displayFilter,
    stringToNumber,
    chgFn,
    getBattlePointRankImg,
    joinChat,
} from "@/utils/common";
import CustomIcon from "src/common/CustomIcon";
import {useNavigate, useLocation, useParams} from "react-router-dom";
import {useTranslation, Trans} from 'react-i18next';
import ButtonFactory from "@/common/ButtonFactory";
import MainHeader from "src/common/MainHeader";
// import bannerBg from "@/assets/images/COQ.png";
import Banner from "src/common/Banner";
import HireSort from "@/drawer/HireSort";
import HireFilter from "@/drawer/HireFilter";
import {
    mainNftNftPanelPath,
    publicChatsPath,
} from "@/routes/config";
import {isNumber} from "mathjs";
import InfiniteScroll from "react-infinite-scroll-component";
import AvatarInfo from "@/common/AvatarInfo";
import HireModelTrans from "src/model/HireModelTrans";
import ModalSweepClub from "src/model/ModalSweepClub";
import HireList from "src/common/HireList";

const SweepClubs = (
    {
        userInfo,
        setUserInfo,
        earnInfo
    }
) => {
    const {t, i18n} = useTranslation();
    const {userId} = userInfo;
    const {cardType} = useParams();
    const maxBatchSelectNum = 10;

    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [list, setList] = useState([]);

    const [showModelTrans, setShowModelTrans] = useState(false);
    const [showModelTransSign, setShowModelTransSign] = useState(false);
    const [showSort, setShowSort] = useState(false);
    const [showFilter, setShowFilter] = useState(false);

    const [disableHire, setDisableHire] = useState(true);
    const [checkedArr, setCheckedArr] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [done, setDone] = useState(false);
    const [subLoading, setSubLoading] = useState(false);

    const onChange = (checkedValues) => {
        if (checkedValues.length > maxBatchSelectNum) {
            return;
        }
        setCheckedArr([...checkedValues]);
        setDisableHire(checkedValues.length === 0);
    };
    const onChangeRank = (value) => {
        let arr = [];
        if (value) {
            for (let i = 0; i < value; i++) {
                arr.push(list[i]?.userId)
            }
        }
        setCheckedArr([...arr])
        setDisableHire(arr.length === 0);
    }

    useEffect(() => {
        let price = 0;
        checkedArr?.length > 0 && checkedArr.map(item => {
            const u = list.find(i => i.userId === item);
            price += Number(u?.needBuyTotalPrice);
        })
        setTotalPrice(price);
    }, [checkedArr]);

    const nextCursor = useRef(0);
    const [cursor, setCursor] = useState("")
    //---------------------------------------------------------------------------------//
    const [sortKey, setSortKey] = useState(() => {
        const localData = localStorage.getItem('all_club_sortKey');
        return localData ? JSON.parse(localData) : 1;
    });
    useEffect(() => {
        localStorage.setItem('all_club_sortKey', JSON.stringify(sortKey));
    }, [sortKey])
    //---------------------------------------------------------------------------------//
    const [sortVol, setSortVol] = useState(() => {
        const localData = localStorage.getItem('all_club_sortVol');
        return localData ? JSON.parse(localData) : 0;
    });
    useEffect(() => {
        localStorage.setItem('all_club_sortVol', JSON.stringify(sortVol));
    }, [sortVol])
    //---------------------------------------------------------------------------------//
    const [filterPrice, setFilterPrice] = useState(() => {
        const localData = localStorage.getItem('all_club_filterPrice');
        return localData ? JSON.parse(localData) : [0, 100];
    });
    useEffect(() => {
        localStorage.setItem('all_club_filterPrice', JSON.stringify(filterPrice));
    }, [filterPrice])
    //---------------------------------------------------------------------------------//
    const [filterHolding, setFilterHolding] = useState(() => {
        const localData = localStorage.getItem('all_club_filterHolding');
        return localData ? JSON.parse(localData) : [0, 200];
    });
    useEffect(() => {
        localStorage.setItem('all_club_filterHolding', JSON.stringify(filterHolding));
    }, [filterHolding])
    //---------------------------------------------------------------------------------//
    const [filterHolder, setFilterHolder] = useState(() => {
        const localData = localStorage.getItem('all_club_filterHolder');
        return localData ? JSON.parse(localData) : [0, 200];
    });
    useEffect(() => {
        localStorage.setItem('all_club_filterHolder', JSON.stringify(filterHolder));
    }, [filterHolder])
    //---------------------------------------------------------------------------------//
    const [filterLevel, setFilterLevel] = useState(() => {
        const localData = localStorage.getItem('all_club_filterLevel');
        return localData ? JSON.parse(localData) : [1, 15];
    });
    useEffect(() => {
        localStorage.setItem('all_club_filterLevel', JSON.stringify(filterLevel));
    }, [filterLevel])
    //---------------------------------------------------------------------------------//
    const [filterTwitterFollower, setFilterTwitterFollower] = useState(() => {
        const localData = localStorage.getItem('all_club_filterTwitterFollower');
        return localData ? JSON.parse(localData) : [0, 200000];
    });
    useEffect(() => {
        localStorage.setItem('all_club_filterTwitterFollower', JSON.stringify(filterTwitterFollower));
    }, [filterTwitterFollower])
    //---------------------------------------------------------------------------------//
    const [selMyholders, setSelMyholders] = useState(() => {
        const localData = localStorage.getItem('all_club_selMyholders');
        return localData ? JSON.parse(localData) : false;
    });
    useEffect(() => {
        localStorage.setItem('all_club_selMyholders', JSON.stringify(selMyholders));
    }, [selMyholders])
    //---------------------------------------------------------------------------------//
    const [selMyholding, setSelMyholding] = useState(() => {
        const localData = localStorage.getItem('all_club_selMyholding');
        return localData ? JSON.parse(localData) : false;
    });
    useEffect(() => {
        localStorage.setItem('all_club_selMyholding', JSON.stringify(selMyholding));
    }, [selMyholding])
    //---------------------------------------------------------------------------------//
    const [selIgnoreGuestUsers, setSelIgnoreGuestUsers] = useState(() => {
        const localData = localStorage.getItem('all_club_selIgnoreGuestUsers_D15');
        return localData ? JSON.parse(localData) : true;
    });
    useEffect(() => {
        localStorage.setItem('all_club_selIgnoreGuestUsers_D15', JSON.stringify(selIgnoreGuestUsers));
    }, [selIgnoreGuestUsers])
    //---------------------------------------------------------------------------------//
    const [selIgnoreWishUsers, setSelIgnoreWishUsers] = useState(() => {
        const localData = localStorage.getItem('all_club_selIgnoreWishUsers_D15');
        return localData ? JSON.parse(localData) : true;
    });
    useEffect(() => {
        localStorage.setItem('all_club_selIgnoreWishUsers_D15', JSON.stringify(selIgnoreWishUsers));
    }, [selIgnoreWishUsers])
    //---------------------------------------------------------------------------------//

    const setSort = (type, key) => {
        if (type === 'v') {
            setSortVol(key)
        } else {
            setSortKey(key)
        }
    }

    const getMoreHireList = (cs = "") => {
        const priceRange = filterPrice[1] === 100 ? [filterPrice[0], -1] : filterPrice
        const holdersRange = filterHolder[1] === 200 ? [filterHolder[0], -1] : filterHolder
        const holdingRange = filterHolding[1] === 200 ? [filterHolding[0], -1] : filterHolding
        const levelRange = filterLevel
        const twitterFollowerRange = filterTwitterFollower[1] === 200000 ? [filterTwitterFollower[0], -1] : filterTwitterFollower
        post_hire_list({
            cursor: cs === "" ? "" : `${cursor}`,
            sortType: sortKey,
            isAsc: !sortVol,
            priceRange,
            holdersRange,
            holdingRange,
            levelRange,
            twitterFollowerRange,
            prioritizeMyHolders: false,
            prioritizeMyHolding: false,
            prioritizeIgnoreGuestUsers: selIgnoreGuestUsers,
            prioritizeIgnoreWishUsers: selIgnoreWishUsers,
        }).then(data => {
            setList(cs === "" ? (data?.usersData || []) : [...list, ...data?.usersData || []]);
            let _nextCursor = data?.nextCursor;
            nextCursor.current = _nextCursor;
            setCursor(_nextCursor)
            setLoading(false);
        }).catch(err => {
            setLoading(false);
        });
    }

    useEffect(() => {
        getMoreHireList();
    }, []);

    const refreshHireList = () => {
        setCursor("");
        setList([]);
        setCheckedArr([]);
        setDisableHire(true);
        getMoreHireList("");
    }

    const buyList = useRef({})
    const buyData = useRef([])
    const [singleBuy, setSingleBuy] = useState(null)
    const HireClick = () => {
        buyList.current = {};
        buyData.current = [];
        checkedArr?.length > 0 && checkedArr.map(item => {
            const u = list.find(i => i.userId === item);
            buyList.current[item] = u?.needBuyKeyNum < 1 ? 1 : u?.needBuyKeyNum;
            buyData.current.push(u)
        })
        setShowModelTrans(true);
    }
    const onSingleBuy = (userId, needBuyKeyNum) => {
        const params = {}
        params[userId] = needBuyKeyNum < 1 ? 1 : needBuyKeyNum
        setSingleBuy(params)
        setShowModelTransSign(true);
    }

    function followerNumString(num) {
        if (!isNumber(num)) return '';
        if (num >= 1000000) {
            return (num / 1000000).toFixed(1) + 'M';
        } else if (num >= 1000) {
            return (num / 1000).toFixed(1) + 'K';
        } else {
            return num.toString();
        }
    }

    return (
        <div className={`${s.wrap}`}>
            <MainHeader title={t('Sweep')} headerType={2} hasRightMenu={false}></MainHeader>
            <div className={s.content}>
                <div className={s.topPart}>
                    <CustomIcon onClick={() => {
                        setShowSort(true);
                    }} className="mr10 iconShadow" width={30} height={30} imgName={'Button/UI_Button_UpAndDown_01'}/>
                    <CustomIcon onClick={() => {
                        setShowFilter(true);
                    }} className="iconShadow" width={30} height={30} imgName={'Button/UI_Button_ThreeTieRod_01'}/>
                </div>
                <div className={s.hireList}>
                    <HireList cardType={Number(cardType)} showFilter={showFilter} setShowFilter={setShowFilter} showSort={showSort}
                              setShowSort={setShowSort}/>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
        earnInfo: app.earnInfo
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setUserInfo: (params) => {
            return dispatch({
                type: "app/setUserInfo",
                payload: params
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(SweepClubs));
