import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import s from "./index.module.less";
import { useTranslation, Trans } from 'react-i18next';
import CustomIcon from "@/common/CustomIcon";
import { connect } from "react-redux";
import { Input } from "antd";

import ButtonFactory from "@/common/ButtonFactory";
import { numberClamp } from "@/utils/common";

const ModelSetSlippage = (
    {
        val,
        setVal,
        max=100,
        min=0,
        closeFn=()=>{}
    }
) => {
    const { t, i18n } = useTranslation();

    const inputRef = useRef(null);

    const amountChangeHandler = (e) => {
        let value = e.target.value.replace(/[^\d]/g, '');
        setVal(Number(value));
    }

    const amountBlurHandler = (e) => {
        let tempKeyNum = val;
        tempKeyNum = numberClamp(tempKeyNum, min, max);
       
        setVal(Number(tempKeyNum));
    }
    const controlAmount = (control) => {
        if (control === 0) {
            if (val > min){
                let _keyNum = val - 1;
                setVal(_keyNum);
            }
        }
        else if (control === 1) {
            if (val < max){
                let _keyNum = val + 1;
                setVal(_keyNum)
            }
        }
    }
    return (
        <div className={`${s.box}`}>
            {/* <div>{t('Set max. Slippage (%)')}</div> */}
            <div className={s.amountWrap}>
                <CustomIcon width={35} height={35} imgName={'Button/UI_Button_Minus-White-Irr_01'}
                    
                    onClick={() => controlAmount(0)} />
                <div className="df aic jcc" style={{
                    background: 'rgba(0, 0, 0, 0.3)',
                    width: '100px'
                }}>
                    <Input ref={inputRef} onChange={amountChangeHandler} onBlur={amountBlurHandler}
                        value={val}
                     />

                </div>
                <CustomIcon width={35} height={35} imgName={'Button/UI_Button_Plus-Yellow-Irr_01'}
                    onClick={() => controlAmount(1)} />
            </div>
            <div className="fs12">{t('this is the maximum amount of slippage you are willing to accept when palceing trades')}</div>
            <ButtonFactory
                onClick={closeFn}
            >
                {t('Confirm')}
            </ButtonFactory>
        </div>

    )
}

const mapStateToProps = ({ app }) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => {
    return {}
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(ModelSetSlippage));
