const _public = 'public';
const _main = 'main';
const _game = 'game';

const mainHome = 'home';
const mainExplore = 'explore';
const mainMyclubs = 'myclubs';
const mainProfile = 'profile';
const _mainNft = "nft";
const _mainClub = "club";
const _battle = "battle";

const mainHomePath = '/' + _main + '/home';
const mainExplorePath = '/' + _main + '/explore';
const mainMyclubsPath = '/' + _main + '/myclubs';
const mainProfilePath = '/' + _main + '/profile';

const gameTrillionaire = 'trillionaire'
const gameTrillionaireMission = "mission";
const gameTrillionaireMissionDetail = "detail";
const gameTrillionaireBattle = "battle";
const gameTrillionaireBattleLeaderBoard = "battleleaderboard";
const gameTrillionaireBattling = "battling";
const gameTrillionaireEcosystem = "ecosystem";
const gameTrillionaireEcosystemDetail = "detail";
const gameTrillionaireAssets = "assets";



const mainNftNftPanel = "nftpanel";
const mainNftBidMyBids = "mybids";
const mainNftBidHistory = "history";
const mainNftBidMynft = "mynft";
const mainNftBidSweep = "sweep";
const mainNftBidDetail = "detail";
const mainClubAllClubs = "allclubs";
const mainClubSweepClubs = "sweepclubs";


const gameTrillionaireMissionPath = '/' + _game + '/trillionaire/mission';
const gameTrillionaireMissionDetailPath = gameTrillionaireMissionPath + '/detail';
const gameTrillionaireBattlePath = '/' + _game + '/trillionaire/battle';
const gameTrillionaireBattleLeaderBoardPath = '/' + _game + '/'+ gameTrillionaire + '/' + _battle + '/' + gameTrillionaireBattleLeaderBoard;
const gameTrillionaireBattlingPath = '/' + _game + '/'+ gameTrillionaire + '/' + _battle + '/' + gameTrillionaireBattling;
const gameTrillionaireEcosystemPath = '/' + _game + '/trillionaire/ecosystem';
const gameTrillionaireEcosystemDetailPath = '/' + _game + '/trillionaire/ecosystem/detail';
const gameTrillionaireAssetsPath = '/' + _game + '/trillionaire/assets';
const mainNftNftPanelPath = '/' + _main + '/' + _mainNft + '/' + mainNftNftPanel;
const mainNftBidMyBidsPath ='/' + _main + '/' + _mainNft + '/' + mainNftBidMyBids;
const mainNftBidHistoryPath = '/' + _main + '/' + _mainNft + '/' + mainNftBidHistory;
const mainNftBidMynftPath = '/' + _main + '/' + _mainNft + '/' + mainNftBidMynft;
const mainNftBidSweepPath = '/' + _main + '/' + _mainNft + '/' + mainNftBidSweep;
const mainNftBidDetailPath = '/' + _main + '/' + _mainNft + '/' + mainNftBidDetail;
const mainClubAllClubsPath = '/' + _main + '/' + _mainClub + '/' + mainClubAllClubs;
const mainClubSweepClubsPath = '/' + _main + '/' + _mainClub + '/' + mainClubSweepClubs;


const gameShareDetail = "share-detail";
const gameShareDetailPath = '/' + _game + "/share-detail";

const gameShareUpPoint = "sharepoint"
const gameShareUpPointPath = '/' + _game + "/" + gameShareUpPoint;

const gameReferral = "referral";
const gameReferralPath = '/' + _game + "/referral";

const gameStake = 'stake';
const gameStakePath = '/' + _game + '/' + gameStake;

const gameMemeClub = "meme";
const gameMemeClubPath = '/' + _game + '/' + gameMemeClub;
const gameMemeClubCreat = "create";
const gameMemeClubCreatPath = gameMemeClubPath + '/' + gameMemeClubCreat;
const gameMemeClubDetail = "memedetail";
const gameMemeClubDetailPath = gameMemeClubPath + '/' + gameMemeClubDetail;
const gameMemeClubNftDetail = "nftdetail";
const gameMemeClubNftDetailPath = gameMemeClubPath + '/' + gameMemeClubNftDetail;

const gameMemeClubUserDetail = "memeuserdetail";
const gameMemeClubUserDetailPath = gameMemeClubPath + '/' + gameMemeClubUserDetail;

const gameMemeClubChat = "chat";
const gameMemeClubChatPath = gameMemeClubPath + '/' + gameMemeClubChat;

const gameMemeClubTge = "tge";
const gameMemeClubTgePath = gameMemeClubPath + '/' + gameMemeClubTge;

const gameMemeClubAssets = "assets";
const gameMemeClubAssetsPath = gameMemeClubPath + '/' + gameMemeClubAssets;

const gameMemeClubRevealMemePack = "unpack";
const gameMemeClubRevealMemePackPath = gameMemeClubPath + '/' + gameMemeClubRevealMemePack;

const gameMemeClubLauncher = "launcher";
const gameMemeClubLauncherPath = gameMemeClubPath + '/' + gameMemeClubLauncher;

const publicChats = "chats";
const publicWishCreate = "wish/create";
const publicWishLink = "wish/link";
const publicWishClaim = "wish/claim";
const publicWishes = "wishes";
const publicNftContent = "nfts";

const publicChatsPath = '/' + _public + '/chats';
const publicWishCreatePath = '/' + _public + '/wish/create';
const publicWishLinkPath = '/' + _public + '/wish/link';
const publicWishClaimPath = '/' + _public + '/wish/claim';
const publicWishesPath = '/' + _public + '/wishes';
const publicNftContentPath = '/' + _public + '/' + publicNftContent;

const link = 'link';
const linkPath = '/link'; 

const callback = 'callback';
const callbackPath = '/callback'; 

const balance = 'balance';
const balancePath = '/balance'; 

const invite = 'invite';
const invitePath = '/invite';




export  {
    _public,
    _main,
    _game,
    mainHome,
    mainExplore,
    mainMyclubs,
    mainProfile,
    mainHomePath,
    mainExplorePath,
    mainMyclubsPath,
    mainProfilePath,
    gameTrillionaireMission,
    _battle,
    gameTrillionaireBattle,
    gameTrillionaireBattleLeaderBoard,
    gameTrillionaireBattling,
    gameTrillionaireBattlingPath,
    gameTrillionaireEcosystem,
    gameTrillionaireEcosystemDetail,
    gameTrillionaireAssets,
    gameTrillionaireMissionPath,
    gameTrillionaireBattlePath,
    gameTrillionaireBattleLeaderBoardPath,
    gameTrillionaireEcosystemPath,
    gameTrillionaireEcosystemDetailPath,
    gameTrillionaireAssetsPath,
    gameReferralPath,
    gameShareDetailPath,
    gameShareDetail,
    gameReferral,
    publicChats,
    publicWishCreate,
    publicWishLink,
    publicWishClaim,
    publicWishes,
    publicNftContent,
    publicChatsPath,
    publicWishCreatePath,
    publicWishLinkPath,
    publicWishClaimPath,
    publicWishesPath,
    publicNftContentPath,
    _mainNft,
    mainNftNftPanel,
    mainNftBidMyBids,
    mainNftBidHistory,
    mainNftBidMynft,
    mainNftBidSweep,
    mainNftBidDetail,
    mainNftNftPanelPath,
    mainNftBidMyBidsPath,
    mainNftBidHistoryPath,
    mainNftBidMynftPath,
    mainNftBidSweepPath,
    mainNftBidDetailPath,
    gameTrillionaire,
    _mainClub,
    mainClubAllClubs,
    mainClubAllClubsPath,
    mainClubSweepClubs,
    mainClubSweepClubsPath,
    link,
    linkPath,
    callback,
    callbackPath,
    balance,
    balancePath,
    invite,
    invitePath,
    gameStake,
    gameStakePath,
    gameTrillionaireMissionDetail,
    gameShareUpPoint,
    gameShareUpPointPath,
    gameTrillionaireMissionDetailPath,
    gameMemeClub,
    gameMemeClubCreat,
    gameMemeClubDetail,
    gameMemeClubNftDetail,
    gameMemeClubUserDetail,
    gameMemeClubLauncher,
    gameMemeClubPath,
    gameMemeClubCreatPath,
    gameMemeClubDetailPath,
    gameMemeClubNftDetailPath,
    gameMemeClubUserDetailPath,
    gameMemeClubLauncherPath,
    gameMemeClubTge,
    gameMemeClubAssets,
    gameMemeClubRevealMemePack,
    gameMemeClubRevealMemePackPath,
    gameMemeClubTgePath,
    gameMemeClubAssetsPath,
    gameMemeClubChat,
    gameMemeClubChatPath
}