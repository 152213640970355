import { BigNumber, ethers } from "ethers";
import { abi_memeClub, abi_token } from "@/utils/abi";
import { getConfig } from "./common";
import { getGasConfig } from "@/utils/gas";
import memeChainToken from "@/utils/json/memeChainToken.json";
import {
    getLfgAllowance,
} from "@/utils/bidNftWeb3";
import {
    LfgMainnetId,
    LfgTestnetId,
} from "@/utils/env";
import {
    getWeb3Config,
} from "@/utils/common";
import {
    lfgApprove,
} from "@/utils/lfgStake";
import { erc721Abi } from "viem";
function stringToHex(str) {
    const val = [...str].map(c => c.charCodeAt(0).toString(16).padStart(2, 0)).join``
    return '0x' + val;
}


function limitDecimals(value, decimals) {
    const parts = value.split('.');
    if (parts.length === 2 && parts[1].length > decimals) {
        parts[1] = parts[1].slice(0, decimals);
        return parts.join('.');
    }
    return value;
}

async function getmemeClubContract(wallets, coinId, isWriter) {

    const embeddedWallet = wallets.find((wallet) => (wallet.walletClientType === 'privy'));
    const { chainId } = memeChainToken.find(i => i?.ID === coinId)
    const web3Config = await getWeb3Config();
    const contractInfo = web3Config?.contractList?.find((item) => (item.coinId === coinId));
    let memeFactoryContract = contractInfo?.memeFactoryContract;
    console.log("[memeClub contract Addr]", memeFactoryContract, "[chainId]", chainId);
    //console.log(embeddedWallet);
    await embeddedWallet.switchChain(chainId);

    const provider = await embeddedWallet.getEthersProvider();

    if (isWriter) {
        const signer = provider.getSigner();

        const gasConfig = await getGasConfig(signer);
        gasConfig.gasLimit = 25000000;

        const contract = new ethers.Contract(memeFactoryContract, abi_memeClub, provider).connect(signer);
        return { contract, addr: embeddedWallet.address, gasConfig, embeddedWallet, coinContract: contractInfo?.coinContract, contractInfo, signer: signer };
    } else {
        const signer = provider.getSigner();

        const contract = new ethers.Contract(memeFactoryContract, abi_memeClub, provider);
        return { contract, addr: embeddedWallet.address, embeddedWallet, coinContract: contractInfo?.coinContract, contractInfo, signer: signer };
    }
}

const mintMemeToken = ({ wallets, coinId, callId, clubId, amount, timestamp, validFor, signature }) => {
    return new Promise(async (resolve, reject) => {
        try {
            const { contract, addr, gasConfig } = await getmemeClubContract(wallets, coinId, true);
            console.log("mintMemeTokenParam", callId, clubId, ethers.BigNumber.from(clubId), amount, ethers.utils.parseEther(amount), timestamp, validFor, signature, stringToHex(atob(signature)), gasConfig);

            contract.mintMemeToken(callId, ethers.BigNumber.from(clubId), ethers.utils.parseEther(amount), timestamp, validFor, stringToHex(atob(signature)), gasConfig).then(res => {
                console.log("[mintMemeToken] ", res);
                resolve(res)
            }).catch(e => {
                console.log("[mintMemeToken exception]", e);
                if (e.message.indexOf('(reading \'switchChain\')') > 0) {
                    console.log("privy need login")
                    reject('need login');
                } else {
                    reject('Transaction Failed');
                }
            });
        } catch (error) {
            console.log("[mintMemeToken exception]", error);
            if (error.message.indexOf('(reading \'switchChain\')') > 0) {
                console.log("privy need login")
                reject('need login');
            } else {
                reject('Transaction Failed');
            }
        }
    })
}

const checkTransaction = (wallets, transactionHash, chainId) => {
    return new Promise(async (resolve, reject) => {
        try {
            const embeddedWallet = wallets.find((wallet) => (wallet.walletClientType === 'privy'));
            await embeddedWallet.switchChain(chainId);

            const provider = await embeddedWallet.getEthersProvider();

            const transaction = await provider.getTransaction(transactionHash);

            console.log("checkNftInfo transaction", transaction);

            resolve(transaction);
        } catch (error) {
            console.log("[checkTransaction exception]", error);
            if (error.message.indexOf('(reading \'switchChain\')') > 0) {
                console.log("privy need login")
                reject('need login');
            } else {
                reject('Transaction Failed');
            }
        }
    })
}

const getAssetTransfers = (wallets, chainId, transaction) => {
    return new Promise(async (resolve, reject) => {
        try {
            const embeddedWallet = wallets.find((wallet) => (wallet.walletClientType === 'privy'));
            await embeddedWallet.switchChain(chainId);

            const provider = await embeddedWallet.getEthersProvider();

            const blockNumberString = '0x' + transaction.blockNumber.toString(16).toUpperCase();
            const result = await provider.send('alchemy_getAssetTransfers', [
                {
                    category: ["erc20", "erc721"],
                    toAddress: transaction.from.toString(),
                    fromBlock: blockNumberString,
                    toBlock: blockNumberString
                }
            ]);
            resolve(result);
        } catch (error) {
            console.log("[getAssetTransfers exception]", error);
            if (error.message.indexOf('(reading \'switchChain\')') > 0) {
                console.log("privy need login")
                reject('need login');
            } else {
                reject('Transaction Failed');
            }
        }
    })
}

const getNftMetadata = (wallets, contractAddr, tokenId, chainId) => {
    return new Promise(async (resolve, reject) => {
        try {
            const embeddedWallet = wallets.find((wallet) => (wallet.walletClientType === 'privy'));
            await embeddedWallet.switchChain(chainId);

            const provider = await embeddedWallet.getEthersProvider();

            const contract = new ethers.Contract(contractAddr, erc721Abi, provider);

            contract.tokenURI(tokenId).then(res => {
                console.log("[getNftMetadata] ", res);
                resolve(res);
            }).catch(e => {
                console.log("[getNftMetadata exception]", e);
                if (e.message.indexOf('(reading \'switchChain\')') > 0) {
                    console.log("privy need login")
                    reject('need login');
                } else {
                    reject('Transaction Failed');
                }
            });
        } catch (error) {
            console.log("[getNftMetadata exception]", error);
            if (error.message.indexOf('(reading \'switchChain\')') > 0) {
                console.log("privy need login")
                reject('need login');
            } else {
                reject('Transaction Failed');
            }
        }
    })
}

const creatMemeClub = ({ wallets, coinId, initBuyAmount_, callId, value, memeConf_, signature, timestamp, validFor, creationFee_ }) => {
    // console.log(wallets);

    return new Promise(async (resolve, reject) => {
        try {
            const { contract, addr, gasConfig, contractInfo, signer } = await getmemeClubContract(wallets, coinId, true);
            console.log(callId, initBuyAmount_, memeConf_, stringToHex(atob(signature)), gasConfig, `value:`, value, timestamp, validFor, creationFee_);
            const embeddedWallet = wallets.find((wallet) => (wallet.walletClientType === 'privy'));
            if (!contractInfo){
                reject("get config error");
                return;
            }
            const coinInfo = memeChainToken.find(i => i?.ID === coinId)
            const contractAddr = contractInfo?.memeFactoryContract;
            if (coinInfo?.isNative === 0 && (coinInfo?.ID === LfgMainnetId || coinInfo?.ID === LfgTestnetId)) {
                let currentAllowance = await getLfgAllowance(embeddedWallet, contractAddr, contractInfo?.coinContract);
                console.log('[getLfgAllowance]', { currentAllowance: ethers.utils.formatEther(currentAllowance), value });
                if (ethers.utils.formatEther(currentAllowance) < value) {
                    let amount = ethers.utils.parseEther(Number.MAX_SAFE_INTEGER.toString());
                    await lfgApprove(wallets, amount, contractInfo?.coinContract, coinInfo?.chainId, contractAddr); //approve lfg for auction As MANY as Possible
                }
            }

            const cfg = {
                ...gasConfig,
                value:ethers.utils.parseEther(limitDecimals(value.toString(), 18))
            };

            console.log(cfg);
            // contract.populateTransaction.newMemeClub(callId, initBuyAmount_, creationFee_, memeConf_, timestamp, validFor, stringToHex(atob(signature)), cfg).then(tx => {
            //     signer.sendTransaction(tx).then(res=>{
            //         resolve(res)
            //     }).catch(error => {
            //         reject(error);
            //     })

            // }).catch(e => {
            //     console.log("[creatMemeClub exception]", e);
            //     if (e.message.indexOf('(reading \'switchChain\')') > 0) {
            //         console.log("privy need login")
            //         reject('need login');
            //     } else {
            //         reject('Transaction Failed');
            //     }
            // });
            contract.newMemeClub(callId, initBuyAmount_, creationFee_, memeConf_, timestamp, validFor, stringToHex(atob(signature)), cfg).then(res => {
                console.log("[creatMemeClub] ", res);
                resolve(res)
            }).catch(e => {
                console.log("[creatMemeClub exception]", e);
                if (e.message.indexOf('(reading \'switchChain\')') > 0) {
                    console.log("privy need login")
                    reject('need login');
                } else {
                    reject('Transaction Failed');
                }
            });

        } catch (error) {
            console.log("[creatMemeClub exception]", error);
            if (error.message.indexOf('(reading \'switchChain\')') > 0) {
                console.log("privy need login")
                reject('need login');
            } else {
                reject('Transaction Failed');
            }
        }
    })

}


// const creatMemeClub = async ({ wallets, coinId, initBuyAmount_, callId, value, memeConf_, signature, timestamp, validFor, creationFee_ }) => {
//     return new Promise(async (resolve, reject) => {
//         const chainId = 30732;
//         const rpcUrl = 'https://mevm.devnet.imola.movementlabs.xyz';
//         const privateKey = '3897f17bfa121bf3faf7a9ce16390650f3d62691ca8a1132b1b95bc4003bbabb'; // 替换为您的私钥
//         const contractAddress = '0x8a88Bb7E65f639d0b7a0F8348926741C72B4575e'; // 替换为您的合约地址
//         const provider = new ethers.providers.JsonRpcProvider(rpcUrl, {
//             chainId: chainId,
//             name: 'MEVM',
//         });
//         // const signer = provider.getSigner();
//         // const gasConfig = await getGasConfig(signer);
//         // 创建一个钱包实例
//         const wallet = new ethers.Wallet(privateKey, provider);

//         const contract = new ethers.Contract(contractAddress, abi_memeClub, wallet);

//         const cfg = {
//             gasLimit: 25000000,
//             value:ethers.utils.parseEther(limitDecimals(value.toString(), 18))
//         };

//         contract.newMemeClub(callId, initBuyAmount_, creationFee_, memeConf_, timestamp, validFor, stringToHex(atob(signature)), cfg).then(res => {
//             console.log("[creatMemeClub] ", res);
//             resolve(res)
//         }).catch(e => {
//             console.log("[creatMemeClub exception]", e);
//             if (e.message.indexOf('(reading \'switchChain\')') > 0) {
//                 console.log("privy need login")
//                 reject('need login');
//             } else {
//                 reject('Transaction Failed');
//             }
//         });
//     })
// }



export {
    mintMemeToken,
    creatMemeClub,
    checkTransaction,
    getAssetTransfers,
    getNftMetadata,
}
