import React, {memo, useEffect, useMemo, useRef, useState} from "react";
import {connect} from "react-redux";
import {Tabs, Modal, Button, Checkbox, Slider, Spin, Drawer} from 'antd';
import s from "./index.module.less";
import {
    post_hire_list,
    getAvatarFromProfile,
    displayFilter,
    stringToNumber,
    chgFn,
    getBattlePointRankImg,
    searchuser,
    formatCardDisplayName,
    maxCharLength,
    convertTimestamp,
    convertWalletBalance,
} from "@/utils/common";
import CustomIcon from "src/common/CustomIcon";
import {useNavigate, useLocation, useParams} from "react-router-dom";
import {useTranslation, Trans} from 'react-i18next';
import ButtonFactory from "@/common/ButtonFactory";
import MainHeader from "src/common/MainHeader";
// import bannerBg from "@/assets/images/COQ.png";
import Banner from "src/common/Banner";
import HireSort from "@/drawer/HireSort";
import HireFilter from "@/drawer/HireFilter";
import {
    mainNftNftPanelPath,
    publicChatsPath,
    mainClubSweepClubsPath,
    balancePath,
} from "@/routes/config";
import {isNumber} from "mathjs";
import InfiniteScroll from "react-infinite-scroll-component";
import SearchUser from "src/common/SearchUser";
import SearchUserList from "@/common/SearchUserList";
import AntDrawer from "@/common/AntDrawer";
import AntButton from "@/common/AntButton";

const AllClubs = (
    {
        userInfo,
        setUserInfo,
        earnInfo,
        allClubCardType,
        setAllClubCardType,
        setHideRefreshBtn,
    }
) => {
    const {t, i18n} = useTranslation();
    //const ref = useRef();
    const navigate = useNavigate();
    const {
        portfolio,
        platformData,
        selfData,
        holding,
        holdersNum,
        walletAddr,
        managerProfile,
    } = useMemo(() => {
        return userInfo || {}
    }, [userInfo]);
    const {
        balance,
        buyFirstKey,
        wishNeedClaim,
        payEntryFee,
        claimCoin,
        tierId,
        power,
        points,
    } = useMemo(() => {
        return selfData || {}
    }, [selfData]);

    //const maxBatchSelectNum = 10;

    const [loading, setLoading] = useState(true);
    const [list, setList] = useState([]);

    //const [showModelTrans, setShowModelTrans] = useState(false);
    //const [showModelTransSign, setShowModelTransSign] = useState(false);

    //const [disableHire, setDisableHire] = useState(true);
    const [checkedArr, setCheckedArr] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    //const [done, setDone] = useState(false);
    //const [subLoading, setSubLoading] = useState(false);
    const [showSort, setShowSort] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    //const [cardType, setCardType] = useState(allClubCardType || 0);//0: players, 1: nfts

    const showList = useMemo(() => {
        if (list?.length > 0) {
            let needEmpty = 4;
            let res = [...list];
            for (let i = 0; i < needEmpty; i++) {
                res.push({
                    isEmptyObj: true
                })
            }
            return res;
        } else {
            return list;
        }
    }, [list])

    /*const onChange = (checkedValues) => {
        if (checkedValues.length > maxBatchSelectNum) {
            return;
        }
        setCheckedArr([...checkedValues]);
        setDisableHire(checkedValues.length === 0);
    };
    const onChangeRank = (value) => {
        let arr = [];
        if (value) {
            for (let i = 0; i < value; i++) {
                arr.push(list[i]?.userId)
            }
        }
        setCheckedArr([...arr])
        setDisableHire(arr.length === 0);
    }*/

    useEffect(() => {
        let price = 0;
        checkedArr?.length > 0 && checkedArr.map(item => {
            const u = list.find(i => i.userId === item);
            price += Number(u?.needBuyTotalPrice);
        })
        setTotalPrice(price);
    }, [checkedArr]);

    const nextCursor = useRef(0);
    const [cursor, setCursor] = useState("")
    //---------------------------------------------------------------------------------//
    const [sortKey, setSortKey] = useState(() => {
        const localData = localStorage.getItem('all_club_sortKey');
        return localData ? JSON.parse(localData) : 1;
    });
    useEffect(() => {
        localStorage.setItem('all_club_sortKey', JSON.stringify(sortKey));
    }, [sortKey])
    //---------------------------------------------------------------------------------//
    const [sortVol, setSortVol] = useState(() => {
        const localData = localStorage.getItem('all_club_sortVol');
        return localData ? JSON.parse(localData) : 1;
    });
    useEffect(() => {
        localStorage.setItem('all_club_sortVol', JSON.stringify(sortVol));
    }, [sortVol])
    //---------------------------------------------------------------------------------//
    const [filterPrice, setFilterPrice] = useState(() => {
        const localData = localStorage.getItem('all_club_filterPrice');
        return localData ? JSON.parse(localData) : [0, 100];
    });
    useEffect(() => {
        localStorage.setItem('all_club_filterPrice', JSON.stringify(filterPrice));
    }, [filterPrice])
    //---------------------------------------------------------------------------------//
    const [filterHolding, setFilterHolding] = useState(() => {
        const localData = localStorage.getItem('all_club_filterHolding');
        return localData ? JSON.parse(localData) : [0, 200];
    });
    useEffect(() => {
        localStorage.setItem('all_club_filterHolding', JSON.stringify(filterHolding));
    }, [filterHolding])
    //---------------------------------------------------------------------------------//
    const [filterHolder, setFilterHolder] = useState(() => {
        const localData = localStorage.getItem('all_club_filterHolder');
        return localData ? JSON.parse(localData) : [0, 200];
    });
    useEffect(() => {
        localStorage.setItem('all_club_filterHolder', JSON.stringify(filterHolder));
    }, [filterHolder])
    //---------------------------------------------------------------------------------//
    const [filterLevel, setFilterLevel] = useState(() => {
        const localData = localStorage.getItem('all_club_filterLevel');
        return localData ? JSON.parse(localData) : [1, 15];
    });
    useEffect(() => {
        localStorage.setItem('all_club_filterLevel', JSON.stringify(filterLevel));
    }, [filterLevel])
    //---------------------------------------------------------------------------------//
    const [filterTwitterFollower, setFilterTwitterFollower] = useState(() => {
        const localData = localStorage.getItem('all_club_filterTwitterFollower');
        return localData ? JSON.parse(localData) : [0, 200000];
    });
    useEffect(() => {
        localStorage.setItem('all_club_filterTwitterFollower', JSON.stringify(filterTwitterFollower));
    }, [filterTwitterFollower])
    //---------------------------------------------------------------------------------//
    const [selMyholders, setSelMyholders] = useState(() => {
        const localData = localStorage.getItem('all_club_selMyholders');
        return localData ? JSON.parse(localData) : false;
    });
    useEffect(() => {
        localStorage.setItem('all_club_selMyholders', JSON.stringify(selMyholders));
    }, [selMyholders])
    //---------------------------------------------------------------------------------//
    const [selMyholding, setSelMyholding] = useState(() => {
        const localData = localStorage.getItem('all_club_selMyholding');
        return localData ? JSON.parse(localData) : false;
    });
    useEffect(() => {
        localStorage.setItem('all_club_selMyholding', JSON.stringify(selMyholding));
    }, [selMyholding])
    //---------------------------------------------------------------------------------//
    const [selIgnoreGuestUsers, setSelIgnoreGuestUsers] = useState(() => {
        const localData = localStorage.getItem('all_club_selIgnoreGuestUsers_D15');
        return localData ? JSON.parse(localData) : true;
    });
    useEffect(() => {
        localStorage.setItem('all_club_selIgnoreGuestUsers_D15', JSON.stringify(selIgnoreGuestUsers));
    }, [selIgnoreGuestUsers])
    //---------------------------------------------------------------------------------//
    const [selIgnoreWishUsers, setSelIgnoreWishUsers] = useState(() => {
        const localData = localStorage.getItem('all_club_selIgnoreWishUsers_D15');
        return localData ? JSON.parse(localData) : true;
    });
    useEffect(() => {
        localStorage.setItem('all_club_selIgnoreWishUsers_D15', JSON.stringify(selIgnoreWishUsers));
    }, [selIgnoreWishUsers])
    //---------------------------------------------------------------------------------//

    const setSort = (type, key) => {
        if (type === 'v') {
            setSortVol(key)
        } else {
            setSortKey(key)
        }
    }

    const getMoreHireList = (cs = "") => {
        const priceRange = filterPrice[1] === 100 ? [filterPrice[0], -1] : filterPrice
        const holdersRange = filterHolder[1] === 200 ? [filterHolder[0], -1] : filterHolder
        const holdingRange = filterHolding[1] === 200 ? [filterHolding[0], -1] : filterHolding
        const levelRange = filterLevel
        const twitterFollowerRange = filterTwitterFollower[1] === 200000 ? [filterTwitterFollower[0], -1] : filterTwitterFollower
        post_hire_list({
            cursor: cs === "" ? "" : `${cursor}`,
            sortType: sortKey,
            isAsc: !sortVol,
            priceRange,
            holdersRange,
            holdingRange,
            levelRange,
            twitterFollowerRange,
            prioritizeMyHolders: false,
            prioritizeMyHolding: false,
            prioritizeIgnoreGuestUsers: selIgnoreGuestUsers,
            prioritizeIgnoreWishUsers: selIgnoreWishUsers,
        }).then(data => {
            setList(cs === "" ? (data?.usersData || []) : [...list, ...data?.usersData || []]);
            let _nextCursor = data?.nextCursor;
            nextCursor.current = _nextCursor;
            setCursor(_nextCursor)
            setLoading(false);
        }).catch(err => {
            setLoading(false);
        });
    }

    const getMoreAllClubList = () =>{
        getMoreHireList(cursor);
    }

    useEffect(() => {
        getMoreHireList();
    }, []);

    /*const refreshHireList = () => {
        setCursor("");
        setList([]);
        setCheckedArr([]);
        setDisableHire(true);
        getMoreHireList(0);
    }*/

    /*const buyList = useRef({})
    const buyData = useRef([])
    const [singleBuy, setSingleBuy] = useState(null)
    const HireClick = () => {
        buyList.current = {};
        buyData.current = [];
        checkedArr?.length > 0 && checkedArr.map(item => {
            const u = list.find(i => i.userId === item);
            buyList.current[item] = u?.needBuyKeyNum < 1 ? 1 : u?.needBuyKeyNum;
            buyData.current.push(u)
        })
        setShowModelTrans(true);
    }
    const onSingleBuy = (userId, needBuyKeyNum) => {
        const params = {}
        params[userId] = needBuyKeyNum < 1 ? 1 : needBuyKeyNum
        setSingleBuy(params)
        setShowModelTransSign(true);
    }*/

    return (
        <div className={`${s.wrap} mainWrap`}>
            <MainHeader title={t('CLUBS')} hasRightMenu={false}></MainHeader>
            <div className={s.banner}>
                <Banner navToLive={true} isHomeBanner={true} hasCurndown={true} showTimer={true} bgUrl={'/UI/Banner/UI_Banner_TurnUp_01.webp'}>
                </Banner>
            </div>
            <div className={s.content}>
                <div className={s.topPart}>
                    <div className={s.topPartLeft}>
                        {/* <div className={s.cardTypeSegmented}>
                            <div
                                className={`${allClubCardType === 0 ? s.cardTypeSelected : s.cardTypeUnselect} fb`}
                                onClick={() => {
                                    if (allClubCardType !== 0) {
                                        setAllClubCardType(0);
                                        // setGenesisAuctionState(0);
                                        // let param = getFilterParam();
                                        // setNextCursor(0);
                                        // setList([]);
                                        // getList(1, param.rankRange, param.maticPriceRange, param.lfgPriceRange, param.includeTopPriceIsSelf, rangeRise, 0);
                                    }
                                }}>
                                {t('Players')}
                            </div>
                            <div
                                className={`${allClubCardType === 1 ? s.cardTypeSelected : s.cardTypeUnselect} fb`}
                                onClick={() => {
                                    if (allClubCardType !== 1) {
                                        setAllClubCardType(1);
                                        // setGenesisAuctionState(1);
                                        // let param = getFilterParam();
                                        // setNextCursor(0);
                                        // setList([]);
                                        // getList(2, param.rankRange, param.maticPriceRange, param.lfgPriceRange, param.includeTopPriceIsSelf, rangeRise, 0);
                                    }
                                }}>
                                {t('NFTs')}
                            </div>
                        </div> */}
                    </div>
                    <div className={`flex_center_end`}>
                        {
                            allClubCardType === 0 &&
                            <>
                                <CustomIcon onClick={() => {
                                    setShowSort(true);
                                }} className="iconShadow" width={30} height={30} imgName={'Button/UI_Button_UpAndDown_01'}/>
                                <CustomIcon onClick={() => {
                                    setShowFilter(true);
                                }} className="iconShadow" width={30} height={30} imgName={'Button/UI_Button_ThreeTieRod_01'}/>
                            </>
                        }
                        <AntButton className={`${s.sweepBtn} btn_yellow`} onClick={() => {
                            if (buyFirstKey){
                                navigate(`${mainClubSweepClubsPath}/${allClubCardType}`);
                            }
                            else{
                                navigate(balancePath);
                            }
                        }}>
                            <div style={{fontSize: `16px`, color: `rgba(0, 0, 0, 1)`, fontWeight: 'bold'}}>
                                {t('Sweep')}
                            </div>
                        </AntButton>
                    </div>
                </div>
                {
                    allClubCardType === 0 &&
                    <div className={s.clubPart} id="clubPartRoot">
                        <InfiniteScroll
                            dataLength={list?.length || 0}
                            next={getMoreAllClubList}
                            hasMore={cursor !== ""}
                            loader={<div
                                style={{
                                    textAlign: "center",
                                    width: "100%",
                                }}
                            ><Spin/></div>}
                            style={{
                                display: "flex",
                                flexWrap: "wrap",
                                justifyContent: `${list?.length > 1 ? 'space-between' : 'flex-start'}`,
                                width: '100%',
                            }}
                            endMessage={
                                <div style={{
                                    textAlign: "center",
                                    width: "100%",
                                    color: "var(--color-gray)",
                                    fontSize: '12px',
                                    marginTop: '10px'
                                }}>{t('No More Data')}</div>
                            }
                            scrollableTarget={'clubPartRoot'}
                        >
                            {
                                showList?.map((item, index) => {
                                    const {
                                        friendliness,
                                        buyPrice,
                                        sellPrice,
                                        historyPrice,
                                        userId,
                                        accountType,
                                        managerOwnKeyNum,
                                        needBuyKeyNum,
                                        needBuyTotalPrice,
                                        profile,
                                        managerProfile,
                                        ownKeyNum,
                                        tierId,
                                        followerNum,
                                        bpLevel,
                                        onLive,
                                        coverImageURL,
                                        liveTitle,
                                        liveNextTime,
                                    } = item;
                                    const {displayName} = profile || {};
                                    const originalAvatarUrl = getAvatarFromProfile(profile, 1);
                                    const avatarUrl = getAvatarFromProfile(profile);
                                    const chg = chgFn(sellPrice || 0, historyPrice || 0);
                                    const isUp = chg === '-' ? true : chg >= 0 ? true : false;
                                    return (
                                        <>
                                            {
                                                item?.isEmptyObj ?
                                                    <div className={s.emptyItem}>
                                                    </div> :
                                                    <div className={s.clubItemRoot} onClick={() => {
                                                        navigate(`${publicChatsPath}/${userId}`, true)
                                                    }}>
                                                        <div className={s.clubAvatar}
                                                             style={{backgroundImage: `url(/UI/Picture/UI_Picture_DefaultImage_BG_01.png)`}}>
                                                        </div>
                                                        <div className={s.clubAvatar}
                                                             style={{backgroundImage: `url(${coverImageURL || originalAvatarUrl || avatarUrl})`}}>
                                                        </div>
                                                        <div className={s.clubCard}
                                                             style={{backgroundImage: `url(/UI/Picture/UI_Picture_CardWindow_01.png)`}}>
                                                            <div className={s.cardTop}>
                                                                <div className={s.cardName}>
                                                                    {formatCardDisplayName(displayFilter(displayName))}
                                                                </div>
                                                                <div className={s.cardRank}>
                                                                    <CustomIcon className="" width={16} height={16}
                                                                                imgName={`Picture/Rank/RankStar_${tierId}`}/>
                                                                    <CustomIcon className="ml2" width={16} height={16}
                                                                                imgName={`Picture/BPTier/${getBattlePointRankImg(bpLevel || 1)}`}/>
                                                                </div>
                                                            </div>
                                                            <div className={s.cardBottom}>
                                                                <div
                                                                    className={`${s.cardBottomPublic} ${s.cardBottomLeft}`}>
                                                                    <CustomIcon width={20} height={20}
                                                                                imgName={'Picture/UI_Picture-Currency_MATIC_01'}></CustomIcon>
                                                                    {stringToNumber(buyPrice, '4')}
                                                                </div>
                                                                <div
                                                                    className={`${s.cardBottomPublic} ${s.cardBottomRight}`}>
                                                                    {
                                                                        isUp ?
                                                                            <>
                                                                                <CustomIcon width={20} height={20}
                                                                                            imgName={'Picture/UI_Picture_PriceUP_01'}></CustomIcon>
                                                                                <div style={{color: `#00FF14`}}>
                                                                                    {`${chg}%`}
                                                                                </div>
                                                                            </> :
                                                                            <>
                                                                                <CustomIcon width={20} height={20}
                                                                                            imgName={'Picture/UI_Picture_PriceDown_01'}></CustomIcon>
                                                                                <div style={{color: `#f63030`}}>
                                                                                    {`${chg}%`}
                                                                                </div>
                                                                            </>
                                                                    }
                                                                </div>
                                                            </div>
                                                            {
                                                                accountType === "wish" &&
                                                                <div className={s.wishPart}
                                                                     style={{backgroundImage: `url(/UI/Picture/UI_Picture-CardWish_01.png)`}}>
                                                                </div>
                                                            }
                                                            {
                                                                onLive &&
                                                                <div className={s.livePart}
                                                                     style={{backgroundImage: `url(/UI/Picture/UI_Picture-CardLive_01.png)`}}>
                                                                </div>
                                                            }
                                                            {
                                                                liveNextTime !== 0 && typeof liveNextTime !== "undefined" && !onLive &&
                                                                <div className={s.preLivePart}>
                                                                    <div className="fs12 lh14 color-primary-text fb">
                                                                        {!liveTitle ? t('Stream Scheduled') : maxCharLength(liveTitle, 20)}
                                                                    </div>
                                                                    {!onLive && <div
                                                                        className="fs10 color-primary-text">{t('Starts at xx', {time: convertTimestamp(liveNextTime * 1000 || 0)})}</div>}
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                            }
                                        </>
                                    );
                                })
                            }
                        </InfiniteScroll>
                    </div>
                }
                {
                    allClubCardType === 1 &&
                    <div className={s.clubPart} id="nftPartRoot">
                        <InfiniteScroll
                            dataLength={list?.length || 0}
                            next={getMoreHireList}
                            hasMore={cursor !== -1}
                            loader={<div
                                style={{
                                    textAlign: "center",
                                    width: "100%",
                                }}
                            ><Spin/></div>}
                            style={{
                                display: "flex",
                                flexWrap: "wrap",
                                justifyContent: `${list?.length > 1 ? 'space-between' : 'flex-start'}`,
                                width: '100%',
                            }}
                            endMessage={
                                <div style={{
                                    textAlign: "center",
                                    width: "100%",
                                    color: "var(--color-gray)",
                                    fontSize: '12px',
                                    marginTop: '10px'
                                }}>{t('No More Data')}</div>
                            }
                            scrollableTarget={'nftPartRoot'}
                        >
                            {
                                showList?.map((item, index) => {
                                    const {
                                        friendliness,
                                        buyPrice,
                                        sellPrice,
                                        historyPrice,
                                        userId,
                                        accountType,
                                        managerOwnKeyNum,
                                        needBuyKeyNum,
                                        needBuyTotalPrice,
                                        profile,
                                        managerProfile,
                                        ownKeyNum,
                                        tierId,
                                        followerNum,
                                        bpLevel,
                                        onLive,
                                        coverImageURL,
                                        liveTitle,
                                        liveNextTime,
                                    } = item;
                                    const {displayName} = profile || {};
                                    const originalAvatarUrl = getAvatarFromProfile(profile, 1);
                                    const avatarUrl = getAvatarFromProfile(profile);
                                    const chg = chgFn(sellPrice || 0, historyPrice || 0);
                                    const isUp = chg === '-' ? true : chg >= 0 ? true : false;
                                    return (
                                        <>
                                            {
                                                item?.isEmptyObj ?
                                                    <div className={s.emptyItem}>
                                                    </div> :
                                                    <div className={s.clubItemRoot} onClick={() => {
                                                        navigate(`${publicChatsPath}/${userId}`, true)
                                                    }}>
                                                        <div className={s.clubAvatar}
                                                             style={{backgroundImage: `url(/UI/Picture/UI_Picture_DefaultImage_BG_01.png)`}}>
                                                        </div>
                                                        <div className={s.clubAvatar}
                                                             style={{backgroundImage: `url(${coverImageURL || originalAvatarUrl || avatarUrl})`}}>
                                                        </div>
                                                        <div className={s.clubCard}
                                                             style={{backgroundImage: `url(/UI/Picture/UI_Picture_CardWindow_01.png)`}}>
                                                            <div className={s.cardTop}>
                                                                <div className={s.cardName}>
                                                                    {formatCardDisplayName(displayFilter(displayName))}
                                                                </div>
                                                                <div className={s.cardRank}>
                                                                    <CustomIcon className="" width={16} height={16}
                                                                                imgName={`Picture/Rank/RankStar_${tierId}`}/>
                                                                    <CustomIcon className="ml2" width={16} height={16}
                                                                                imgName={`Picture/BPTier/${getBattlePointRankImg(bpLevel || 1)}`}/>
                                                                </div>
                                                            </div>
                                                            <div className={s.cardBottom}>
                                                                <div
                                                                    className={`${s.cardBottomPublic} ${s.cardBottomLeft}`}>
                                                                    <CustomIcon width={20} height={20}
                                                                                imgName={'Picture/UI_Picture-Currency_LFG_01'}></CustomIcon>
                                                                    {convertWalletBalance(buyPrice)}
                                                                </div>
                                                                <div
                                                                    className={`${s.cardBottomPublic} ${s.cardBottomRight}`}>
                                                                    {
                                                                        isUp ?
                                                                            <>
                                                                                <CustomIcon width={20} height={20}
                                                                                            imgName={'Picture/UI_Picture_PriceUP_01'}></CustomIcon>
                                                                                <div style={{color: `#00FF14`}}>
                                                                                    {`${chg}%`}
                                                                                </div>
                                                                            </> :
                                                                            <>
                                                                                <CustomIcon width={20} height={20}
                                                                                            imgName={'Picture/UI_Picture_PriceDown_01'}></CustomIcon>
                                                                                <div style={{color: `#f63030`}}>
                                                                                    {`${chg}%`}
                                                                                </div>
                                                                            </>
                                                                    }
                                                                </div>
                                                            </div>
                                                            {
                                                                accountType === "wish" &&
                                                                <div className={s.wishPart}
                                                                     style={{backgroundImage: `url(/UI/Picture/UI_Picture-CardWish_01.png)`}}>
                                                                </div>
                                                            }
                                                            {
                                                                onLive &&
                                                                <div className={s.livePart}
                                                                     style={{backgroundImage: `url(/UI/Picture/UI_Picture-CardLive_01.png)`}}>
                                                                </div>
                                                            }
                                                            {
                                                                liveNextTime !== 0 && typeof liveNextTime !== "undefined" && !onLive &&
                                                                <div className={s.preLivePart}>
                                                                    <div className="fs12 lh14 color-primary-text fb">
                                                                        {!liveTitle ? t('Stream Scheduled') : maxCharLength(liveTitle, 20)}
                                                                    </div>
                                                                    {!onLive && <div
                                                                        className="fs10 color-primary-text">{t('Starts at xx', {time: convertTimestamp(liveNextTime * 1000 || 0)})}</div>}
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                            }
                                        </>
                                    );
                                })
                            }
                        </InfiniteScroll>
                    </div>
                }
            </div>
            <AntDrawer
                title={t('Drawer with extra actions')}
                placement={'right'}
                onClose={() => setShowSort(false)}
                width={245}
                open={showSort}
                className="hireSort"
            >
                <HireSort
                    sortKey={sortKey}
                    sortVol={sortVol}
                    setSort={setSort}
                    closeHandle={() => {
                        setShowSort(false)
                    }}
                    getHireList={getMoreHireList}
                    setCheckedArr={setCheckedArr}
                />
            </AntDrawer>
            <AntDrawer
                title={t('Drawer with extra actions')}
                placement={'right'}
                onClose={() => setShowFilter(false)}
                width={300}
                open={showFilter}
                className="hireFilter"
            >
                <HireFilter
                    filterPrice={filterPrice}
                    setFilterPrice={setFilterPrice}
                    filterHolder={filterHolder}
                    setFilterHolding={setFilterHolding}
                    filterHolding={filterHolding}
                    setFilterHolder={setFilterHolder}
                    selMyholders={selMyholders}
                    setSelMyholders={setSelMyholders}
                    selMyholding={selMyholding}
                    setSelMyholding={setSelMyholding}
                    closeHandle={() => {
                        setShowFilter(false)
                    }}
                    getHireList={getMoreHireList}
                    setCheckedArr={setCheckedArr}
                    filterLevel={filterLevel}
                    setFilterLevel={setFilterLevel}
                    filterTwitterFollower={filterTwitterFollower}
                    setFilterTwitterFollower={setFilterTwitterFollower}
                    selIgnoreGuestUsers={selIgnoreGuestUsers}
                    setSelIgnoreGuestUsers={setSelIgnoreGuestUsers}
                    selIgnoreWishUsers={selIgnoreWishUsers}
                    setSelIgnoreWishUsers={setSelIgnoreWishUsers}
                />
            </AntDrawer>
        </div>
    )
}
const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
        earnInfo: app.earnInfo,
        allClubCardType: app.allClubCardType,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setUserInfo: (params) => {
            return dispatch({
                type: "app/setUserInfo",
                payload: params
            });
        },
        setHideRefreshBtn: (params) => {
            return dispatch({
                type: "app/setHideRefreshBtn",
                payload: params,
            });
        },
        setAllClubCardType: (params) => {
            return dispatch({
                type: "app/setAllClubCardType",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(AllClubs));
