import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import s from "./index.module.less";
import { useTranslation, Trans } from 'react-i18next';
import CustomIcon from "@/common/CustomIcon";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
// import Banner from "../../../common/Banner";
import {
    stringToNumber,
    convertWalletBalance,
    getFriendtradeLootLastevent,
    searchuser,
    displayFilter
} from "@/utils/common"
import AntModal from "@/common/AntModal";
import ModalMemePackTrans from "@/model/ModalMemePackTrans";
import { coinList, memeTgeClublist, setFavorite, getRecommendClub, searchclub, getChainInfo } from "@/utils/memeClub";
import MainHeader from "@/common/MainHeader";
import ButtonFactory from "@/common/ButtonFactory";
import AntButton from "@/common/AntButton";
import AntDrawer from "@/common/AntDrawer";
import { gameMemeClubCreatPath, gameMemeClubDetail, gameMemeClubDetailPath } from "@/routes/config";
import AvatarInfo from "@/common/AvatarInfo";
import SearchUser from "@/common/SearchUser";
import SearchUserList from "@/common/SearchUserList";
import MemeClubFilter from "@/drawer/MemeClubFilter";
import MemeClubSort from "@/drawer/MemeClubSort";
import SearchMemeClubList from "@/common/SearchMemeClubList";
import InfiniteScroll from "react-infinite-scroll-component";
import MemeChainToken from "@/utils/json/memeChainToken.json";
import { isOnlineEnv } from "@/utils/env";
import { Spin } from "antd";
import MemeClubPack from "@/common/MemeClubPack";

const maxCoinPrice = 0.5;
const maxMarketCap = 500000;
const maxFavorite = 200;

const ClubList = (
    {
        userInfo,
        setHideRefreshBtn,
        tgeMemeClubFilterList,
        setTgeMemeClubFilterList,
    }
) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const linkTo = (id) => {
        navigate(`${gameMemeClubDetailPath}/${id}`)
    }
    const [updateLootEventTimes, setUpdateLootEventTimes] = useState(0);
    const [lootLastEventData, setLootLastEventData] = useState(null);
    const [showMemePackTrans, setShowMemePackTrans] = useState(false);
    const [memePackTransDone, setMemePackTransDone] = useState(false);
    const [memePackTransSubLoading, setMemePackTransSubLoading] = useState(false);
    const [tradeMemeClubInfo, setTradeMemeClubInfo] = useState(null);


    const [searchResult, setSearchResult] = useState([]);
    const [searchKeyValue, setSearchKeyValue] = useState('');
    const [searchCursor, setSearchCursor] = useState(-1);
    const [showSearchResultPanel, setShowSearchResultPanel] = useState(false);

    const searchUserHandle = (searchKey) => {
        if (!searchKey) {
            setHideRefreshBtn(false);
            setShowSearchResultPanel(false);

        } else {
            setHideRefreshBtn(true);
            setShowSearchResultPanel(true);
            setSearchKeyValue(searchKey)
        }
        searchKey && searchclub(searchKey, 0, 1).then(res => {
            setSearchCursor(res?.nextCursor);
            setSearchResult(res?.clubList || []);
        }).catch(e => {
            console.log(e);
        })
    }

    const getMoreSearchResult = () => {
        searchclub(searchKeyValue, searchCursor, 1).then(res => {
            setSearchCursor(res?.data?.nextCursor);
            setSearchResult([
                ...searchResult,
                ...res?.data?.userlist || []
            ]);
        }).catch(e => {
            console.log(e);
        })
    }

    const nativeTokenList = useMemo(() => {
        let list = [];
        if (MemeChainToken?.length > 0) {
            for (let i = 0; i < MemeChainToken?.length; i++) {
                let tokenInfo = MemeChainToken[i];
                if (tokenInfo?.isEnable === 1) {
                    if (isOnlineEnv() && tokenInfo?.isTestChain === 0) {
                        list.push(tokenInfo);
                    }
                    else if (!isOnlineEnv() && tokenInfo?.isTestChain === 1) {
                        list.push(tokenInfo);
                    }
                }
            }
        }
        return list;
    }, [MemeChainToken]);



    // filter sort start

    const [showSort, setShowSort] = useState(false);
    const [showFilter, setShowFilter] = useState(false);

    const sortData = [
        {
            label: t('Txn Time'),
            key: 0,
        },
        {
            label: t('Market Cap'),
            key: 3,
        },
    ]
    const [sortType, setSortType] = useState(0)
    const [coinId, setCoinId] = useState(0)
    const [isAsc, setIsAsc] = useState(false)


    const [filterPackPrice, setFilterPackPrice] = useState([0, -1]);
    const [filterMarketCap, setFilterMarketCap] = useState([0, -1]);
    const [filterFavoriteRange, setFilterFavoriteRange] = useState([0, -1]);
    const [filterOnlyFavorite, setFilterOnlyFavorite] = useState(false);
    const [filterOnlyHeld, setFilterOnlyHeld] = useState(false);
    // filter sort end
    const [list, setList] = useState([])
    const [nextCursor, setNextCursor] = useState('');
    const getList = ({
        rankType,
        coinId,
        priceRange,
        favoriteRange,
        marketCapRange,
        onlyFavorite,
        onlyHeld,
        isAsc,
        cursor = ``
    }) => {
        let tempPriceRange = [priceRange[0] * 1000, -1];
        if (priceRange[1] !== -1){
            tempPriceRange[1] = Math.floor(priceRange[1] * 1000);
        }
        memeTgeClublist({
            rankType,
            coinId,
            priceRange: tempPriceRange,
            favoriteRange,
            marketCapRange,
            onlyFavorite,
            onlyHeld,
            isAsc,
            cursor
        }).then(res => {
            if (res?.dataList) {
                setList(cursor === '' ? res?.dataList : [
                    ...list,
                    ...(res?.dataList || [])
                ])
            }
            else if (cursor === '') {
                setList([]);
            }
            setNextCursor(res?.nextCursor);
        }).catch(e => {
            console.error(e);
        })
    }




    const favoriteFn = (userId, favorite, type = 0) => {
        setFavorite(userId, favorite).then(res => {
            const { clubUserId, favorite } = res;

            const _list = list.map(item => {
                if (item.clubUserId === clubUserId) {
                    return {
                        ...item,
                        isFavorite: favorite
                    };
                } else {
                    return item
                }
            });
            setList(_list)

        }).catch(e => {
            console.error(e);
        })
    }

    const [recommendData, setRecommendData] = useState(null)



    const [isSticky, setIsSticky] = useState(false);
    const stickyRef = useRef(null);
    const containerRef = useRef(null);

    const [rotatingItems, setRotatingItems] = useState({});

    useEffect(() => {
        let filterParam = {
            rankType: sortType,
            coinId: coinId,
            priceRange: filterPackPrice,
            favoriteRange: filterFavoriteRange,
            marketCapRange: filterMarketCap,
            onlyFavorite: filterOnlyFavorite,
            onlyHeld: filterOnlyHeld,
            isAsc: isAsc,
            cursor: ``
        };

        if (tgeMemeClubFilterList){
            if (tgeMemeClubFilterList?.rankType){
                filterParam.rankType = tgeMemeClubFilterList?.rankType;
                setSortType(tgeMemeClubFilterList?.rankType);
            }
            if (tgeMemeClubFilterList?.coinId){
                filterParam.coinId = tgeMemeClubFilterList?.coinId;
                setCoinId(tgeMemeClubFilterList?.coinId);
            }
            if (tgeMemeClubFilterList?.priceRange){
                filterParam.priceRange = tgeMemeClubFilterList?.priceRange;
                setFilterPackPrice(tgeMemeClubFilterList?.priceRange);
            }
            if (tgeMemeClubFilterList?.favoriteRange){
                filterParam.favoriteRange = tgeMemeClubFilterList?.favoriteRange;
                setFilterFavoriteRange(tgeMemeClubFilterList?.favoriteRange);
            }
            if (tgeMemeClubFilterList?.marketCapRange){
                filterParam.marketCapRange = tgeMemeClubFilterList?.marketCapRange;
                setFilterMarketCap(tgeMemeClubFilterList?.marketCapRange);
            }
            if (tgeMemeClubFilterList?.onlyFavorite){
                filterParam.onlyFavorite = tgeMemeClubFilterList?.onlyFavorite;
                setFilterOnlyFavorite(tgeMemeClubFilterList?.onlyFavorite);
            }
            if (tgeMemeClubFilterList?.onlyHeld){
                filterParam.onlyHeld = tgeMemeClubFilterList?.onlyHeld;
                setFilterOnlyHeld(tgeMemeClubFilterList?.onlyHeld);
            }
            if (tgeMemeClubFilterList?.isAsc){
                filterParam.isAsc = tgeMemeClubFilterList?.isAsc;
                setIsAsc(tgeMemeClubFilterList?.isAsc);
            }
        }

        getList(filterParam);
    }, [])


    const getMoreList = () => {
        getList({
            rankType: sortType,
            coinId: coinId,
            priceRange: filterPackPrice,
            favoriteRange: filterFavoriteRange,
            marketCapRange: filterMarketCap,
            onlyFavorite: filterOnlyFavorite,
            onlyHeld: filterOnlyHeld,
            isAsc: isAsc,
            cursor: nextCursor
        })
    }

    const applyClubFilter = (item) => {
        let tempCoinId = coinId;
        if (item?.sortType !== 1 || item?.coinId !== 0) {
            tempCoinId = item?.coinId;
            setCoinId(item?.coinId);
        }
        setFilterOnlyFavorite(item?.onlyFavorite);
        setFilterOnlyHeld(item?.onlyHeld);

        let tempPackPrice = [0, -1];
        if (item?.priceRange?.[0] >= 0) {
            tempPackPrice[0] = item?.priceRange?.[0];
        }
        if (item?.priceRange?.[1] < maxCoinPrice) {
            tempPackPrice[1] = item?.priceRange?.[1];
        }
        setFilterPackPrice(tempPackPrice);

        let tempMarketCap = [0, -1];
        if (item?.marketCapRange?.[0] >= 0) {
            tempMarketCap[0] = item?.marketCapRange?.[0];
        }
        if (item?.priceRange?.[1] < maxMarketCap) {
            tempMarketCap[1] = item?.marketCapRange?.[1];
        }
        setFilterMarketCap(tempMarketCap);

        let tempFavoriteRange = [0, -1];
        if (item?.favoriteRange?.[0] >= 0) {
            tempFavoriteRange[0] = item?.favoriteRange?.[0];
        }
        if (item?.favoriteRange?.[1] < maxFavorite) {
            tempFavoriteRange[1] = item?.favoriteRange?.[1];
        }
        setFilterFavoriteRange(tempFavoriteRange);

        let filter = {
            rankType: sortType,
            coinId: tempCoinId,
            priceRange: tempPackPrice,
            favoriteRange: tempFavoriteRange,
            marketCapRange: tempMarketCap,
            onlyFavorite: item?.onlyFavorite,
            onlyHeld: item?.onlyHeld,
            isAsc: isAsc,
        }
        
        getList({
            ...filter,
            cursor: ''
        })

        setTgeMemeClubFilterList(filter);
    }

    const applyClubSort = (item) => {
        setIsAsc(item?.isAsc);
        setSortType(item?.sortType);
        let sortCoin = coinId;
        if (item?.sortType === 1) {
            sortCoin = item?.coinId;
            setCoinId(item?.coinId);
        }

        let filter = {
            rankType: item?.sortType,
            coinId: sortCoin,
            priceRange: filterPackPrice,
            favoriteRange: filterFavoriteRange,
            marketCapRange: filterMarketCap,
            onlyFavorite: filterOnlyFavorite,
            onlyHeld: filterOnlyHeld,
            isAsc: item?.isAsc,
        }
        
        getList({
            ...filter,
            cursor: ''
        })

        setTgeMemeClubFilterList(filter);
    }

    useEffect(() => {
        const container = containerRef.current;

        const handleScroll = () => {
            const stickyOffset = stickyRef.current.offsetTop;
            setIsSticky(357 < stickyOffset);
        };

        container.addEventListener('scroll', handleScroll);
        return () => {
            container.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const range = "50";

    return (
        <div className={`${s.modalWrap} mainWrap`}>
            <MainHeader leftTitle={t('TGE Meme')} headerType={2} isGameHeader={true} hasBackBtn={false} rightMenuIcon={"Button/UI_Button-D_Home_On"}></MainHeader>

            <div className={s.modal} ref={containerRef}>
                <div className={`wrap mt10 ${isSticky ? 'stickyElmentBg' : 'stickyElment'}`} ref={stickyRef}>
                    <div className={s.controlBar}>
                        <SearchUser searchUserFn={searchUserHandle} />
                        <div className={s.r}>
                            <CustomIcon onClick={() => {
                                setShowSort(true)
                            }} imgName={`Button/UI_Button_UpAndDown_01`} className="mr5" width={30} height={30}></CustomIcon>
                            <CustomIcon onClick={() => {
                                setShowFilter(true)
                            }} imgName={`Button/UI_Button_ThreeTieRod_01`} className="mr5" width={30} height={30}></CustomIcon>

                        </div>
                    </div>

                </div>
                <div className={s.content}>
                    <div className={s.wrap}>
                        {
                            showSearchResultPanel &&
                            <SearchMemeClubList
                                favoriteFn={favoriteFn}
                                searchCursor={searchCursor}
                                linkTo={linkTo}
                                searchResult={searchResult}
                                getMoreSearchResult={getMoreSearchResult} />
                        }
                        {
                            !showSearchResultPanel &&
                            <div className={s.list}>
                                <InfiniteScroll
                                    dataLength={list?.length || 0}
                                    next={getMoreList}
                                    hasMore={nextCursor !== ""}
                                    loader={<div
                                        style={{
                                            textAlign: "center",
                                            width: "100%",
                                        }}
                                    ><Spin /></div>}
                                    height={"100%"}
                                    style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        width: "100%",
                                        justifyContent: "space-between",
                                        alignContent: "flex-start"
                                    }}

                                >
                                    {
                                        list?.length > 0 &&
                                        list?.map(item => {
                                            const {
                                                easeChatLogNum,
                                                favoriteNum,
                                                isFavorite,
                                                clubProfile,
                                                clubSymbolImageUrl,
                                                liquidityWorth,
                                                Price24Change,
                                                clubUserId,
                                                marketCap,
                                                priceUSD,
                                                clubSymbolName,
                                                onAlive,
                                                announceStartTime,
                                                announceTitle,
                                            } = item
                                            const { displayName, avatarUrl, coinId } = clubProfile;

                                            const chainInfo = getChainInfo(coinId)

                                            const handleFavoriteClick = (userId, isFavorite) => {
                                                setRotatingItems(prevState => ({ ...prevState, [userId]: true }));
                                                setTimeout(() => {
                                                    setRotatingItems(prevState => ({ ...prevState, [userId]: false }));
                                                }, 500);
                                                favoriteFn(userId, !isFavorite);
                                            };
                                            const _marketCap = marketCap === 0 ? '--' : marketCap < 0.000001 ? '<0.000001' : convertWalletBalance(marketCap)
                                            const _priceUSD = priceUSD === 0 ? '--' : priceUSD < 0.000001 ? '<0.000001' : convertWalletBalance(priceUSD)
                                            const _liquidityWorth = liquidityWorth === 0 ? '--' : liquidityWorth < 0.000001 ? '<0.000001' : convertWalletBalance(liquidityWorth)
                                            const _Price24Change = Price24Change === 0 ? '--' : Price24Change < 0.000001 ? '<0.000001' : convertWalletBalance(Price24Change)
                                            return (
                                                <div className={s.item} key={clubUserId} onClick={() => {
                                                    linkTo(clubUserId)
                                                }}>
                                                    <div className={s.l}>
                                                        <div className={s.ll}>
                                                            <img src={clubSymbolImageUrl} className="" width="46" height="46" style={{borderRadius: '8px'}}></img>
                                                            <div>${clubSymbolName}</div>
                                                        </div>
                                                        <div className={s.lr}>
                                                            <div className={`ellipse fb fs12 mb5 ${s.displayName}`}>{displayFilter(displayName)}</div>
                                                            <div className={s.line}>
                                                                <div className="color-blue-light fs12 fb">{t('TGE LP')}</div>
                                                                <span className="ml5 fs12" style={{ alignSelf: 'end' }}> 
                                                                <CustomIcon imgName={`Picture/${chainInfo?.icon}`} className="mr5" width={12} height={12}></CustomIcon>
                                                                {_liquidityWorth} {chainInfo?.displayCoinName} 
                                                                </span>
                                                            </div>
                                                            <div className={s.line}>
                                                                <div className="color-blue-light fs12 fb">{t('Market Cap')}</div>
                                                                <span className="ml5 fs12" style={{ alignSelf: 'end' }}>$ {_marketCap}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={s.r}>
                                                        <div className={s.line}>
                                                            <div className="color-blue-light fs12 fb">{t('Token Price')}</div>
                                                            <span className="ml5 fs12" style={{ alignSelf: 'end' }}>$ {_priceUSD}</span>
                                                        </div>
                                                        <div className="mt5 mb5 flex_center_center">
                                                            <CustomIcon imgName={Price24Change < 0 ? 'Picture/UI_Picture_PriceDown_01' : 'Picture/UI_Picture_PriceUP_01'} className="mr5" width={28} height={15}></CustomIcon>
                                                            <span className={Price24Change < 0 ? 'color-red fs12' : 'color-green fs12'}>{_Price24Change}%</span>
                                                        </div>
                                                    </div>
                                                    <div className={`${s.rightTopPart} flex_center_end`}>
                                                        {
                                                            onAlive ?
                                                            <div className={`${s.liveIcon} flex_center_center`}>
                                                                <CustomIcon imgName={`Picture/UI_Picture_Icon_Live_01`} className="mr4" width={12} height={12}></CustomIcon>
                                                                <span className={`fs10 mb2 lh1`}>
                                                                    {t('Live')}
                                                                </span>
                                                            </div> :
                                                            announceStartTime > 0 ?
                                                            <div className={`${s.liveScheduledIcon} flex_center_center`}>
                                                                <CustomIcon imgName={`Picture/UI_Picture_Icon_Live_01`} className="mr4" width={12} height={12}></CustomIcon>
                                                                <span className={`fs10 mb2 lh1`}>
                                                                    {t('Scheduled')}
                                                                </span>
                                                            </div> :
                                                            <></>
                                                        }
                                                        <CustomIcon
                                                            onClick={() => handleFavoriteClick(clubUserId, isFavorite)}
                                                            imgName={isFavorite ? 'Picture/UI_Picture_Collect_01' : 'Picture/UI_Picture_Uncollect_01'}
                                                            className={rotatingItems[clubUserId] ? 'rotate' : ''} width={20} height={20}></CustomIcon>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </InfiniteScroll>

                            </div>
                        }
                    </div>

                </div>
            </div>

            <AntDrawer
                title={t('Drawer with extra actions')}
                placement={'right'}
                onClose={() => setShowSort(false)}
                width={245}
                open={showSort}
                destroyOnClose={true}
                className="hireSort"
            >
                <MemeClubSort
                    sortData={sortData}
                    nativeTokenList={nativeTokenList}
                    sortType={sortType}
                    setSortType={setSortType}
                    coinId={coinId}
                    setCoinId={setCoinId}
                    isAsc={isAsc}
                    setIsAsc={setIsAsc}
                    coinList={coinList}
                    applyFn={(item) => {
                        applyClubSort(item);
                        setShowSort(false);
                    }}
                />
            </AntDrawer>
            <AntDrawer
                title={t('Drawer with extra actions')}
                placement={'right'}
                onClose={() => setShowFilter(false)}
                width={245}
                open={showFilter}
                destroyOnClose={true}
                className="hireSort"
            >
                <MemeClubFilter
                    maxCoinPrice={maxCoinPrice}
                    maxMarketCap={maxMarketCap}
                    maxFavorite={maxFavorite}
                    filterPackPrice={filterPackPrice}
                    filterMarketCap={filterMarketCap}
                    filterFavoriteRange={filterFavoriteRange}
                    filterOnlyFavorite={filterOnlyFavorite}
                    filterOnlyHeld={filterOnlyHeld}
                    coinId={coinId}
                    applyFn={(item) => {
                        applyClubFilter(item);
                        setShowFilter(false);
                    }}
                    coinList={coinList}
                    isSortByPrice={sortType === 1}
                    showTgeProcess={false}
                    showPrice={false}
                />
            </AntDrawer>
            <AntModal
                width='330px'
                title=''
                className="confirmModalWrap transModal"
                centered={true}
                open={showMemePackTrans}
                destroyOnClose={true}
                onOk={() => setShowMemePackTrans(false)}
                onCancel={() => setShowMemePackTrans(false)}
                keyboard={!memePackTransSubLoading || memePackTransDone}
                maskClosable={!memePackTransSubLoading || memePackTransDone}
            >
                <ModalMemePackTrans
                    type={1}
                    tradeMemeClubInfo={tradeMemeClubInfo}
                    closeHandle={() => {
                        setShowMemePackTrans(false);
                    }}
                    done={memePackTransDone}
                    setDone={setMemePackTransDone}
                    subLoading={memePackTransSubLoading}
                    setSubLoading={setMemePackTransSubLoading}
                />
            </AntModal>
        </div>

    )
}

const mapStateToProps = ({ app }) => {
    return {
        userInfo: app.userInfo,
        defaultInviteCode: app.defaultInviteCode,
        tgeMemeClubFilterList: app.tgeMemeClubFilterList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setHideRefreshBtn: (params) => {
            return dispatch({
                type: "app/setHideRefreshBtn",
                payload: params,
            });
        },
        setTgeMemeClubFilterList: (params) => {
            return dispatch({
                type: "app/setTgeMemeClubFilterList",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(ClubList));
