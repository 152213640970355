import React, {memo, useMemo, useEffect} from "react";
import s from "./index.module.less";
import {connect} from "react-redux";
import CustomIcon from "../../common/CustomIcon";
import {useState} from "react";
import {
    unit, 
    withdraw, 
    stringToNumber, 
    sliceStr, 
    sliceAddr,
    getChainInfoById,
    convertWalletBalance,
    getTransferEstGasPrice,
} from "@/utils/common";
import {
    BaseChainId,
    BaseSepoliaChainId,
    AmoyChainId,
    PolygonChainId,
} from "@/utils/env";
import {Button} from "antd";
import {useWallets} from "@privy-io/react-auth";
import {useTranslation, Trans} from 'react-i18next';
import AntButton from "@/common/AntButton";

const WithdrawDialog = (
    {
        closeHandle,
        marketInfo = {},
        amount,
        toAddr,
        balance,
        walletAddr,
        getearninfoFn,
        chainTokenInfo,
    }
) => {
    const {t, i18n} = useTranslation();
    const chainInfo = getChainInfoById(chainTokenInfo?.chainId);
    const [done, setDone] = useState(false);
    const [transStart, setTransStart] = useState(false);
    const [fastGasPrice, setFastGasPrice] = useState(0);
    const [showMoreInfo, setShowMoreInfo] = useState(false);
    const coinUsd = useMemo(() => {
        let chainId = chainTokenInfo?.chainId
        if ((chainId === PolygonChainId || chainId === AmoyChainId) && marketInfo?.quotes?.MATIC > 0){
            return marketInfo?.quotes?.MATIC;
        }
        else if((chainId === BaseChainId || chainId === BaseSepoliaChainId) && marketInfo?.quotes?.ETH > 0){
            return marketInfo?.quotes?.ETH;
        }
        return 0;
    }, [marketInfo]);
    const dataReady = useMemo(() => {
        // console.log(coinUsd,fastGasPrice)
        return fastGasPrice
    }, [coinUsd, fastGasPrice]);
    const closeFn = () => {
        setDone(false);
        closeHandle()
    }

    const {wallets} = useWallets();

    const withdrawFn = () => {
        if (dataReady && !transStart) {
            setTransStart(true);
            withdraw(wallets, toAddr, amount.toString(), chainTokenInfo?.chainId).then(res => {
                setTransStart(false);
                setDone(true);
                getearninfoFn()
            }).catch(e => {
                console.log(e);
                setTransStart(false);
                setDone(false);
            })
        }

    }

    const showMoreInfoFn = () => {
        setShowMoreInfo(!showMoreInfo)
    }

    useEffect(() => {
        getTransferEstGasPrice(wallets, chainTokenInfo?.chainId).then(res => {
            console.log("getTransferEstGasPrice",res, res.toString());
            setFastGasPrice(res);
        }).catch(e => {
            console.log(e);
        })
    }, [])

    return (

        <div className={s.dialog}>
            <div className={s.content}>
                <div className="fs30 mtb10">$ {stringToNumber(coinUsd * amount, 4)}</div>
                <div>{amount} {chainInfo?.displayCoinName}</div>
                {/*<div className="fs15 c-title mb10">Transfer to another wallet</div>*/}

                <div className={`${s.info} ${s.borderWrap}`}>
                    <div className={s.details}>
                        <div className="fs15 c-title">{t('Transaction Details')}</div>
                        <div className={s.right}>
                            <div className="mr5 c-title fs15">$ {stringToNumber(amount * coinUsd, 4)}</div>
                            <CustomIcon width={19} height={12}
                                        imgName={showMoreInfo ? 'UI_TT_Icon_ZhanKai' : 'UI_TT_Icon_Shouqi'}
                                        onClick={showMoreInfoFn}/>
                        </div>
                    </div>
                    {
                        showMoreInfo && <>
                            <div className={s.item}>
                                <div className="fs15">{t('From')}</div>
                                <div className={s.bg}>{sliceStr(walletAddr, 4)}</div>
                            </div>
                            <div className={s.item}>
                                <div className="fs15">{t('To')}</div>
                                <div className={s.bg}>{sliceStr(toAddr, 4)}</div>
                            </div>
                            <div className={s.item}>
                                <div className="fs15 c-title">{t('Action')}</div>
                                <div className={s.bg}>{t('Withdraw')}</div>
                            </div>
                            <div className={s.item}>
                                <div className="fs15 c-title">{t('Est.Fess')}</div>
                                <div className="fs15">USD <span
                                    className="fs15 c-title">$ {stringToNumber(fastGasPrice * coinUsd, 4)}</span></div>
                            </div>
                            <div className="fs12 ml0">{stringToNumber(fastGasPrice, 4)} {unit}</div>
                        </>
                    }
                    <div className={s.total}>
                        <div className="fs15 c-title">{t('Total(including fees)')}</div>
                        <div>USD $ <span
                            className="fs15 c-title">{stringToNumber((Number(amount) + Number(fastGasPrice)) * coinUsd, 4)}</span>
                        </div>
                    </div>
                </div>
                <div className={`${s.wallet} ${s.borderWrap}`}>
                    <div className={s.walletLeft}>
                        <CustomIcon width={16} height={14} imgName={'UI_TT_Icon_CardHolder'}
                                    onClick={showMoreInfoFn}/>
                        <div className="fs15">{sliceAddr(walletAddr, 3)}</div>
                    </div>
                    <div className={s.balance}>{convertWalletBalance(chainTokenInfo?.balance)} {t('Available')}</div>
                </div>

                {!done && <AntButton loading={transStart}
                                     className={`${s.btn} ${dataReady ? "btn_public" : 'btn_disabled'}`}
                                     onClick={withdrawFn}>{transStart ? t('Submitting...') : t('WITHDRAW')}</AntButton>}
                {done &&
                    <AntButton className={s.btn + " btn_public"} onClick={closeFn}>{t('All Done')}</AntButton>}
            </div>
        </div>

    )
}
const mapStateToProps = ({app}) => {
    return {
        curentOtherUserInfo: app.curentOtherUserInfo
    };
};

const mapDispatchToProps = (dispatch) => {
    return {}
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(WithdrawDialog));
// export default memo(SellDialog);
