import React, {memo, useState, useMemo, useEffect} from "react";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';

const ModelPermission = (
    {
        curentOtherUserInfo,
        type
    }
) => {
    const {t, i18n} = useTranslation();
    return (
        <div className={s.wrap}>

            <div className="color-white fs12">
                <span className="color-yellow">{t('Step 1.')}</span>&nbsp;
                {t('Please go to \'System Settings\'')} &gt; {t('Find Safari/Chrome to enable xx permission', {ty: type})}
            </div>
            <div className="color-white fs12">
                <span className="color-yellow">{t('Step 2.')}</span>&nbsp;
                {t('Go to \'Browser Settings\'')} &gt; {t('Find Website Settings')} &gt; {t('enable www.turnup.so website permission')}
            </div>
        </div>

    )
}
export default memo(ModelPermission);
