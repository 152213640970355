import React, {memo, useState, useMemo, useEffect} from "react";
import {connect} from "react-redux";
import s from "./index.module.less";
import {Input, Button, Modal} from "antd";
import {
    officialInviteCode,
    joinChartPre,
    taPush,
    referralcode_bind,
} from "@/utils/common";
import CustomIcon from "../../common/CustomIcon";
import {useTranslation, Trans} from 'react-i18next';
import ButtonFactory from "src/common/ButtonFactory";
import AntModal from "@/common/AntModal";
import {useWallets} from "@privy-io/react-auth";
import memeTradeVolSharingRate from "@/utils/json/memeTradeVolSharingRate.json";

const ModalMemeSharingDetail = (
    {
        userInfo,
        tradevolInfo,
        closeFn = () =>{}
    }
) => {
    const {t, i18n} = useTranslation();
    const {wallets} = useWallets();
    const tradeConfig = useMemo(() => {
        let res = null;
        if (memeTradeVolSharingRate?.length > 0 && tradevolInfo){
            for (let i = 0; i < memeTradeVolSharingRate?.length; i++){
                if (memeTradeVolSharingRate[i]?.maxVolume > tradevolInfo?.tradeVolUsd || memeTradeVolSharingRate[i]?.sort === -1){
                    res = memeTradeVolSharingRate[i];
                    break;
                }
            }
        }
        return res;
    }, [tradevolInfo]);
    const tradeVolumeShowList = useMemo(() => {
        let list = [];
        if (memeTradeVolSharingRate) {
            if (!tradeConfig || tradeConfig?.level < 4) {
                for (let i = 0; i < Math.min(memeTradeVolSharingRate.length, 4); i++) {
                    list.push(memeTradeVolSharingRate[i]);
                }
            } else {
                let startIndex = tradeConfig?.level - 2;
                if (startIndex > memeTradeVolSharingRate.length - 3) {
                    startIndex = memeTradeVolSharingRate.length - 3;
                }
                for (let i = startIndex; i < startIndex + 3; i++) {
                    list.push(memeTradeVolSharingRate[i]);
                }
            }
        }
        return list;
    }, [tradeConfig]);

    return (
        <div className={`${s.wrap} flex_center_start_col`}>
            <div className={`${s.totalLine} flex_center_start`}>
                <span className={`fs16 fb`}>
                    {t('Current Sharing Levels:')}
                </span>&nbsp;
                <span className={`fs16 fb color-yellow`}>
                    {tradeConfig?.level || 0}
                </span>
            </div>
            <div className={`${s.des} mt10`}>
                {t('Your level is based on your total trading volume. Higher volumes place you in higher levels, with better benefits. Your level updates automatically with your trading progress.')}
            </div>
            <div className={`${s.totalLine} flex_center_start`} style={{marginTop: '24px'}}>
                <span className={`fs16 fb`}>
                    {t('Current Sharing Rates:')}
                </span>&nbsp;
                <span className={`fs16 fb color-yellow`}>
                    {`${(tradeConfig?.sharingRate || 0) / 100}%`}
                </span>
            </div>
            <div className={`${s.des} mt10`}>
                {t('Each level has a different sharing rate. Higher levels offer higher rates and greater potential earnings.')}
            </div>

            <div className={`${s.totalLine} flex_center_start`} style={{marginTop: '24px'}}>
                <span className={`fs16 fb`}>
                    {t('Payout Method:')}
                </span>
            </div>
            <div className={`${s.des} mt10`}>
                {t('After TGE of the governance token $IYKYK, your expected earnings will be paid out in $IYKYK tokens. The amount of tokens you receive will be equivalent to the USD value of your earnings based on the market price of $IYKYK at that time.')}
            </div>

            <div className={`${s.tradeVolumeTableRoot} flex_center_center`}>
                <div className={`${s.tradeVolumeTable} flex_center_start_col`}>
                    <div className={`${s.lineHead} flex_center_between`}>
                        <div className={`${s.colLevel} fs12`}>{t('Level')}</div>
                        <div className={`${s.colVolume} fs12`}>{t('Req. Volume')}</div>
                        <div className={`${s.colRate} fs12`}>{t("Sharing Rate")}</div>
                    </div>
                    {tradeVolumeShowList?.length < 4 && (
                        <div className={`${s.lineValue} flex_center_between`}>
                            <div className={s.colLevel}>...</div>
                            <div className={s.colVolume}>...</div>
                            <div className={s.colRate}>...</div>
                        </div>
                    )}
                    {tradeVolumeShowList?.map((item, index) => (
                        <div key={index} className={`${s.lineValue} ${item.level === tradeConfig?.level ? s.currentLine : s.lineValue} flex_center_between`}>
                            <div className={s.colLevel}>{item.level}</div>
                            <div className={s.colVolume}>{`$${item.minVolume}`}</div>
                            <div className={s.colRate}>{`${(item.sharingRate || 0) / 100}%`}</div>
                        </div>))
                    }
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(ModalMemeSharingDetail));
