import React, {memo, useEffect, useMemo, useRef, useState} from "react";
import {connect} from "react-redux";
import {Tabs, Modal, Button, Checkbox} from 'antd';
import s from "./index.module.less";
import {
    getfriendtrade_rank_selfdata,
    convertWalletBalance,
    vCoinString,
} from "@/utils/common";
import CustomIcon from "src/common/CustomIcon";
import {useNavigate, useLocation} from "react-router-dom";
import {useTranslation, Trans} from 'react-i18next';
import {usePrivy, useWallets} from "@privy-io/react-auth";

const customMaticIcon = 'Picture/UI_Picture-Currency_MATIC_01';
const customLfgIcon = 'Picture/UI_Picture-Currency_LFG_01';
const iykykIcon = 'Picture/UI_Picture-Currency_UP_01';

const GetCoinAni = (
    {
        coinType = 1,//1:lfg; 2:matic; 3:lfg&matic; 4:iykyk
        className = '',
        coinNum = 8,
        width = 24,
    }
) => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const {wallets} = useWallets();

    const iconImageNameList = useMemo(() =>{
        let res = [];
        if (coinType === 1){
            for (let i = 0; i < coinNum; i++){
                res.push(customLfgIcon);
            }
        }
        else if (coinType === 2){
            for (let i = 0; i < coinNum; i++){
                res.push(customMaticIcon);
            }
        }
        else if (coinType === 3){
            for (let i = 0; i < coinNum; i++){
                if (i % 2 === 1){
                    res.push(customMaticIcon);
                }
                else {
                    res.push(customLfgIcon);
                }
            }
        }
        else if (coinType === 4){
            for (let i = 0; i < coinNum; i++){
                res.push(iykykIcon);
            }
        }
        return res;
    }, [coinType]);

    const height = useMemo(() =>{
        return width;
    }, [width]);

    return (
        <div className={`${s.coinRoot} ${className}`}>
            {
                iconImageNameList?.length > 0 && 
                iconImageNameList.map((item, index) => {
                  return (
                    <div key={index}>
                        <CustomIcon width={width} height={height} className={`${s.commonCoin} ${s[`earnCoin${index + 1}`]}`}
                            imgName={item}></CustomIcon>
                    </div>
                  );  
                })
            }
        </div>

    )
}
const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setUserInfo: (params) => {
            return dispatch({
                type: "app/setUserInfo",
                payload: params
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(GetCoinAni));
