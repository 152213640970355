import React, {memo, useEffect, useId, useMemo} from "react";
import s from "./index.module.less";
import AvatarInfo from "../AvatarInfo";
import {displayFilter, renderTime} from '@/utils/common'

const LiveMessageLeft = (
    {
        message,
        userId,
        index,
        scrollBottom
    }
) => {
    const {msg, ext, time, from, status} = message;
    const isSelfChat = useMemo(() => {
        return userId.toString() === from
    }, [userId, from])
    // useEffect(()=>{
    scrollBottom()
    // },[])
    return (
        <div className={`${s.modalWrap}`}>

            <div className={`${s.item} fs16`}>
                {status === 'failed' && (<span className={s.failed}>Send Failed</span>)}
                <span className="color-hosts-red fs16">{isSelfChat ? "[Host]" : ''}</span><span
                className={`${s.name} fs16`}>{displayFilter(ext?.displayName)}</span>: <span
                className={s.msg}>{msg}</span>
            </div>

        </div>


    )
}
export default memo(LiveMessageLeft);
