import React, {memo, useMemo} from "react";
import {connect} from "react-redux";
import s from "./index.module.less";
import CustomIcon from "../CustomIcon";

import {useNavigate, useLocation} from "react-router-dom";
import {joinChat} from "@/utils/common";
import TaskConf from "@/utils/json/TaskConf.json";
import {useTranslation, Trans} from 'react-i18next';
import {displayNewbieTaskStartId} from "@/utils/env";
import {
    balancePath,
    gameTrillionaireAssetsPath,
    gameTrillionaireBattlePath,
    gameTrillionaireEcosystemPath,
    gameTrillionaireMissionPath,
    mainExplorePath,
    mainHomePath,
    mainMyclubsPath,
    mainProfilePath,
    publicChats,
    publicChatsPath,
    publicWishClaim,
    publicWishClaimPath
} from "@/routes/config";

const Navs = (
    {
        userInfo,
        setShowBalance,
    }
) => {
    const navigate = useNavigate();
    const location = useLocation();
    const profileToolTipTaskId = 2013;
    const {userId, selfData} = useMemo(() => {
        return userInfo || {}
    }, [userInfo]);
    const {
        buyFirstKey, payEntryFee, task
    } = useMemo(() => {
        return selfData || {}
    }, [selfData]);

    const newbieTaskList = useMemo(() => {
        let list = [];
        let tempTaskId = displayNewbieTaskStartId;
        while (tempTaskId !== 0) {
            let tempTask = TaskConf.find((v) => v.id === tempTaskId);
            if (tempTask) {
                list.push(tempTask);
                tempTaskId = tempTask.nextTaskID;
            } else {
                tempTaskId = 0;
            }
        }
        return list;
    }, []);

    const currentMissionConfig = useMemo(() => {
        let res = null;
        if (userInfo?.selfData?.task?.oneShotTasks && userInfo?.selfData?.task?.oneShotTasks.length > 0 && newbieTaskList?.length > 0) {
            let oneShotTasksList = userInfo?.selfData?.task?.oneShotTasks;
            for (let i = 0; i < newbieTaskList.length; i++) {
                let tempTaskConfig = newbieTaskList[i];
                let tempTask = oneShotTasksList.find(v => v.taskId === tempTaskConfig.id);
                if (tempTask && !tempTask.finish && tempTaskConfig?.isBattleToolTip === 1) {
                    res = tempTaskConfig;
                    res.oneShotTask = tempTask;
                    res.showLabel = 'Battle';
                    break;
                }
                if (tempTask && !tempTask.finish && tempTaskConfig.id === profileToolTipTaskId) {
                    res = tempTaskConfig;
                    res.oneShotTask = tempTask;
                    res.showLabel = 'Profile';
                    break;
                }
            }
        }
        return res;
    }, [userInfo]);

    const {t, i18n} = useTranslation();

    const navArr = [
        {
            icon: 'Button/UI_Button-D_Battle',
            label: 'Battle',
            labelName: t('Battle'),
            path: gameTrillionaireBattlePath
        }, {
            icon: 'Button/UI_Button-D_Mission',
            label: 'Mission',
            labelName: t('Mission'),
            path: gameTrillionaireMissionPath
        }, , {
            icon: 'Button/UI_Button-D_Ecosystem',
            label: 'Ecosystem',
            labelName: t('Ecosystem'),
            path: gameTrillionaireEcosystemPath
        }, {
            icon: 'Button/UI_Button-D_Assets',
            label: 'Assets',
            labelName: t('Assets'),
            path: gameTrillionaireAssetsPath
        },
    ]
    const linkToPage = (link, label) => {
        // if ((label === "Chat") && (!(buyFirstKey && payEntryFee) || userInfo?.selfData?.wishNeedClaim)) {
        //   if (userInfo?.selfData?.wishNeedClaim) {
        //     navigate(publicWishClaimPath)
        //   } else if (!(buyFirstKey && payEntryFee)) {
        //     navigate(balancePath)
        //   } else {
        //     navigate(balancePath)
        //   }
        // } else {
        //   if (label === 'Chat') {
        //     joinChat(userId, navigate);
        //   } else {
        //     window.location.replace("#/" + link);
        //     navigate(link, { replace: true });

        //   }
        // }
        navigate(link);
    }

    const canISlack = (taskId) => {
        const target = task?.oneShotTasks.find(v => v.taskId === taskId)
        if (!target) return
        return target.newBie === 1 && !target.finish
    }

    return (
        <div className={s.footNavWrap}>
            <div className={s.footNav}>
                {
                    navArr.map((item, ind) => {
                        return (
                            <div className={`${s.item} ${location.pathname === item.path ? s.active : ''}`} key={ind}
                                 onClick={() => {
                                     linkToPage(item.path, item.label)
                                 }}>
                                {/* {location.pathname === item.path && <div className={s.dot}></div>} */}
                                <div className={`${s.iconWrap} ${item.label === 'Battle' && currentMissionConfig?.showLabel === 'Battle' ? s.hasAfter : ''}`}>
                                    {
                                        item.label === 'Battle' && currentMissionConfig?.showLabel === 'Battle' &&
                                        <div className={`${s.showTip} textnowrap`}>
                                            {t('Loot xx to Earn yy', {
                                                times: currentMissionConfig.param > 1 ? `${currentMissionConfig.param} times` : '',
                                                lfg: `${currentMissionConfig.rewardNum / 10000} $LFG`
                                            })}
                                        </div>
                                    }
                                    {
                                        item.label === 'Profile' && currentMissionConfig?.showLabel === 'Profile' &&
                                        <div className={`${s.profileShowTip} textnowrap`}>
                                            {t('Connect Socials')}
                                        </div>
                                    }
                                    <CustomIcon
                                        className={`${item.label === currentMissionConfig?.showLabel ? 'zoomer' : ''}`}
                                        width={32} height={32}
                                        imgName={`${location.pathname.indexOf(item.path) > -1 ? item.icon + '_On' : item.icon}`}
                                        name={`${location.pathname === item.path ? item.icon + '_On' : item.icon}`}/>
                                </div>
                                <div className={`${s.label} fb`}>{item.labelName}</div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
        showBalance: app.showBalance
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setShowCharContent: (params) => {
            return dispatch({
                type: "app/setShowCharContent",
                payload: params,
            });
        },
        setCurentOtherUserInfo: (params) => {
            return dispatch({
                type: "app/setCurentOtherUserInfo",
                payload: params,
            });
        },
        setShowBalance: (params) => {
            return dispatch({
                type: "app/setShowBalance",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(Navs));
