import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import s from "./index.module.less";
import { useTranslation, Trans } from 'react-i18next';
import {useNavigate, useParams} from "react-router-dom";
import CustomIcon from "@/common/CustomIcon";
import { connect } from "react-redux";
import { AutoStrangeRule } from "@/utils/strangeRule";
import { airdropTypeData, getChainInfo, getHolderList, detailInfoShowHolderNum, calcTotalValue, memePriceFormular, calcNumberCardVal } from "@/utils/memeClub";
import AvatarInfo from "@/common/AvatarInfo";
import { convertWalletBalance, stringToNumber } from "@/utils/common";
import ButtonFactory from "@/common/ButtonFactory";
import MemeClubPack from "@/common/MemeClubPack";
import { 
    gameMemeClubRevealMemePackPath,
} from "@/routes/config";

const Detail = (
    {
        clubBasicInfo,
        chainInfo,
        clubId,
        showTransModelFn,
    }
) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [showMoreMetaData, setShowMoreMetaData] = useState(false)
    const [holder, setHolder] = useState([])
    // const a = useMemo(()=>{},[])

    const { 
        currentCardNum, 
        clubInfo, 
        clubUserId,
        historyPrice, 
        holdNum, 
        maxCardNum, 
        sellPrice, 
        buyPrice 
    } = useMemo(() => {
        return clubBasicInfo || {}
    }, [clubBasicInfo])


    const {
        airdropPercent,
        airdropType,
        cardsTotalSupply,
        coinToNFTRatio,
        ftTotalSupply,
        description,
        attrs,
        coinId,
        imageUrl,
        symbolImageUrl,
        paramA,
        paramB,
        curveType,
        symbolName,
        openPackRatio,
        tgLink,
        twitterLink,
    } = useMemo(() => {
        return clubInfo || {}
    }, [clubInfo])


    const totalVal = useMemo(()=>{
        if(!coinId) return 0
        // console.log(coinId,curveType);
        // console.log(memePriceFormular.find(i=>i?.chainTokenConfigId === coinId && i?.formulaEnum === curveType));
        return calcTotalValue(memePriceFormular.find(i=>i?.chainTokenConfigId === coinId && i?.formulaEnum === curveType),paramA,paramB,cardsTotalSupply)
    },[coinId,paramA,paramB,curveType,cardsTotalSupply]) 

    const eachCoinValue = useMemo(()=>{
       let _ = totalVal / ( ftTotalSupply * (100-airdropPercent) / 100 );
       return _ < 0.000001 ? '<0.000001' : _.toFixed(6)
    },[totalVal])

    const getHolderListFn = (clubUserId, nextCursor) => {
        getHolderList(clubUserId, nextCursor).then(res => {
            const _list = (res?.holderList || []).slice(0, detailInfoShowHolderNum);
            setHolder(_list)
        }).catch(e => {
            console.error(e);
        })
    }

    const foundformular = memePriceFormular.find(i => i?.chainTokenConfigId === coinId && i?.formulaEnum === curveType);
    const formular = foundformular ? foundformular.formular : null;
    const ratioShow = foundformular ? foundformular.ratioShow : null;
    const startPrice = calcNumberCardVal(curveType, paramA, paramB, 1, foundformular.divParamC, foundformular.mulParamD);
    const tgePrice = calcNumberCardVal(curveType, paramA, paramB, cardsTotalSupply, foundformular.divParamC, foundformular.mulParamD);



    useEffect(() => {
        getHolderListFn(clubId, 0)
    }, [clubId])

    const attrList = useMemo(() => {
        let attrType1 = [], attrType2 = [];
        attrs?.map(item => {
            const { type } = item;
            if (type === 1) {
                attrType1.push(item)
            }
            if (type === 2) {
                attrType2.push(item)
            }
        })
        const _attrType1 = attrType1.sort((a, b) => {
            return a?.attrName?.toString().length - b?.attrName?.toString().length
        })
        return [
            ..._attrType1,
            ...attrType2
        ]
    }, [attrs])

    const evenCardToToken = useMemo(()=>{
        return parseInt(ftTotalSupply * (airdropPercent / 10000) / cardsTotalSupply * 2)
    },[ftTotalSupply,airdropPercent,cardsTotalSupply])

    const [showBigAvatar, setShowBigAvatar] = useState(false)

    return (
        <div className={`${s.box}`}>
            
            <div className={`w100p flex_center_between`}>
                <div className={s.partTitle}>
                    <CustomIcon width={17} height={39} className="mr5"
                        imgName={'Picture/UI_Picture_Title-Yellow_01'} />
                    {AutoStrangeRule(t('Meme Info'))}
                </div>
                
                <div className="flex_center_center">
                    {tgLink && 
                    <a href={tgLink}>
                    <CustomIcon width={22} height={22} className=""
                        imgName={'Button/UI_Button_White_TurnUp_01'} />
                    </a>
                    } 
                    
                    {twitterLink && 
                    <a href={twitterLink}>
                    <CustomIcon width={22} height={22} className="ml15"
                        imgName={'Button/UI_Button_White_Twitter_01'} />
                    </a>
                    }
                </div>
            </div>
            <div className={`flex_start_start w100p mt15 ${s.infoWrap} ${showBigAvatar ? s.vBig : ''}`}>
                <img 
                    onClick={() => {
                        setShowBigAvatar(!showBigAvatar)
                        }} 
                    src={symbolImageUrl}
                    width={showBigAvatar ? 300 : 72} 
                    height={showBigAvatar ? 300 : 72 }
                    style={{borderRadius: '8px'}}>
                </img>
                <div className={`fs12 fl tll flex_start_center ${s.des}`}>
                    {description ? description : t(`This person is very lazy and didn't leave anything behind.`)}
                </div>
            </div>
             
            <div className={`${s.partTitle}`}>
                <CustomIcon width={17} height={39} className="mr5"
                    imgName={'Picture/UI_Picture_Title-Yellow_01'} />
                {AutoStrangeRule(t('Airdrop Rules'))}
            </div>
            <div className={s.blockWrap}>
                
                <div className={s.line}>
                    <div className={s.label}>{t('Total Packs')}</div>
                    <div className={s.val}>
                        <CustomIcon width={21} height={21} className="mr5"
                            imgName={'Picture/UI_Picture_Cardpack_01'} />
                        {cardsTotalSupply}
                    </div>
                </div>
                <div className={s.line}>
                    <div className={s.label}>{t('TotalAAA')} ${symbolName} {t('Supply: ')}</div>
                    <div className={s.val}>
                        <img src={symbolImageUrl} className="mr5" width="18" height="18" style={{borderRadius: '9px'}}></img>
                        {ftTotalSupply?.toLocaleString()}
                    </div>
                </div>
                <div className={s.line}>
                        <div className={s.label}>{t('Airdrop / Liquidity Pool:')}</div>
                        <div className={s.val}>{parseInt(airdropPercent / 100)}% ~ {100-parseInt(airdropPercent / 100)}%</div>
                    </div>
                <div className={s.line}>
                    <div className={s.label}>{t('DNA Each Pack Offers')}</div>
                    <div className={s.val}>
                    <img src={symbolImageUrl} className="mr5" width="18" height="18" style={{borderRadius: '9px'}}></img>
                    {`${parseInt(evenCardToToken * ( 1 / (openPackRatio+1)))?.toLocaleString()}-
                    ${parseInt(evenCardToToken * ( openPackRatio / (openPackRatio+1)))?.toLocaleString()} `}
                    </div>
                </div>
                { !clubInfo?.isFT && (
                    <div className={s.line}>
                        <div className={s.label}>{t('Generate 1 NFT for Every:')}</div>
                        <div className={s.val}>
                            <img src={symbolImageUrl} className="mr5" width="18" height="18" style={{borderRadius: '9px'}}></img>
                            {Math.ceil(coinToNFTRatio)?.toLocaleString()} ${symbolName}
                        </div>
                    </div>
                )}
                
            </div>
            {/* <div className={s.progress}>
                <div className={s.proWrap}>
                    <div className={s.l}>
                        <div className={s.proVal} style={{
                            width: `${Math.round(currentCardNum / maxCardNum * 100)}%`
                        }}>

                        </div>
                        <div className={s.val}>{`${currentCardNum}/${maxCardNum}`}</div>
                    </div>

                </div>
            </div> */}
            
            { !clubInfo?.isFT && (
                <>
                    <div className={`${s.partTitle}`}>
                        <CustomIcon width={17} height={39} className="mr5"
                            imgName={'Picture/UI_Picture_Title-Yellow_01'} />
                        {AutoStrangeRule(t('NFT Info'))}
                    </div>
                    <div className="w100p tll fs12 mt15">
                        <Trans
                            i18nKey="MemeClub Details Rules Info"
                            defaults="Generate 1 NFT for every <active>{{count2}} ${{symbolName}}</active> you hold. If you hold less than the required ${{symbolName}} for NFT generation, your NFT will be burnt. TGE will occur once <active>{{count1}}</active> packs have been sold. Liquidity will be auto-added to the DEX and locked."
                            values={{
                                count1: maxCardNum, 
                                symbolName:symbolName,
                                count2: Math.ceil(coinToNFTRatio)?.toLocaleString()
                            }}
                            components={{ active: <span className="color-yellow fs12"></span> }}
                        />
                    </div>
                    <div className={s.metaDataWrap}>
                    <div className="flex_center_between">
                        <div>{t('TGE NFT Attributes:')}</div>
                        <CustomIcon width={21} height={11} className="mr5" onClick={() => {
                            setShowMoreMetaData(!showMoreMetaData)
                        }}
                            imgName={showMoreMetaData ? 'Button/UI_Button_Arrow_Up_01' : 'Button/UI_Button_Arrow_Down_01'} />
                    </div>
                    {
                        showMoreMetaData &&
                        <div className={s.list}>
                            {attrList?.map((item, ind) => {
                                const { attrName, enumStrs, maxNum, minNum, type } = item;
                                const vLen = attrName.toString().length;
                                return (
                                    <div key={ind} className={`${s.attr} ${vLen <= 15 ? s.lenType1 : s.lenType2} ${s['attrType' + type]}`}>
                                        <div className={`${s.label} ${s.metaData}`}>{attrName}</div>
                                        <div className={`${s.val}`}>
                                            {
                                                type === 1 ?
                                                    `${convertWalletBalance(minNum)}-${convertWalletBalance(maxNum)}` :
                                                    <>
                                                        {
                                                            enumStrs?.map((attr, index) => {
                                                                const { enumName, weight } = attr
                                                                return (
                                                                    <div className={s.enum} key={index}>
                                                                        {enumName} ({weight})
                                                                    </div>
                                                                )

                                                            })
                                                        }
                                                    </>
                                            }
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    }
                    </div>
                </>
                
            )}  

            
            
            <div className={`${s.partTitle}`}>
                <CustomIcon width={17} height={39} className="mr5"
                    imgName={'Picture/UI_Picture_Title-Yellow_01'} />
                {AutoStrangeRule(t('Price Curve'))}
            </div>
            
            <div className={s.priceCurve}>
                <div className={s.item}>
                    <img 
                        width="100%" 
                        src={`./UI/BG/UI_Background_Back_Curve0${curveType}.webp`}
                        style={{ borderRadius: '10px' }}
                        ></img>
                </div>
                <div className={s.formularContainer}>
                    <div className={s.formular}>
                        <div className={s.params}>{t('Start Price')} = </div> {startPrice} {chainInfo?.displayCoinName}
                    </div>
                    <div className={s.formular}>
                        <div className={s.params}>{t('TGE Price')} = </div> {tgePrice} {chainInfo?.displayCoinName}
                    </div>
                    <div className={s.formular}>
                        <div className={s.params}>{t('TGE LP')} = </div> {totalVal} {chainInfo?.displayCoinName}
                    </div>
                </div>
                
            </div>
        </div>

    )
}

const mapStateToProps = ({ app }) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => {
    return {}
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(Detail));
