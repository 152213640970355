import {Avatar, Image} from "antd";
import DefaultAvatar from '@/assets/images/avatar_default.png';
import DefaultServer from '@/assets/images/avatar_default.png';
import CustomIcon from "../CustomIcon";
import style from './index.module.less';
import {memo} from 'react'

const DefaultAvaIcon = () => {
    return (
        <img src={DefaultAvatar} alt="avatar"/>
    )
}
const DefaultServerIcon = () => {
    return (
        <img src={DefaultServer} alt="avatar"/>
    )
}

const AvatarInfo = ({dot, upper, size, src, online, isServer, shape = "square", noBorder = false, className, onClick=null}) => {
    const setSrc = (e) => {
        e.currentTarget.src = isServer ? DefaultServer : DefaultAvatar;
    }
    return (
        <div className={`${style.avatarCon} avatarInfo ${noBorder && 'noBorder'} ${className}`}
            onClick = {(e)=>{
                onClick && e.stopPropagation()
                onClick && onClick()
            }}
             style={{minHeight: `${size || 40}px`}}>
            {src ?
                <Avatar shape={shape} size={size || 40} src={<Image preview={false} src={src} onError={(e) => {
                    setSrc(e);
                }}/>} icon={isServer ? <DefaultServerIcon/> : <DefaultAvaIcon/>}/> :
                <Avatar shape={shape} size={size || 40} icon={isServer ? <DefaultServerIcon/> : <DefaultAvaIcon/>}/>}
            {dot && <div className={style.dot}></div>}
            {upper &&
                <CustomIcon width={16} height={16} className={style.upper} imgName={'Button/UI_Round_Button_Upper'}
                            name={'Button/UI_Round_Button_Upper'}/>}
        </div>
    );
};

export default memo(AvatarInfo);
