import React, {memo, useRef} from "react";
import s from "./index.module.less";

import {Button, message} from "antd";
import CustomIcon from "../../common/CustomIcon";
import {TwitterShareButton} from 'react-twitter-embed';
import {useTranslation, Trans} from 'react-i18next';

function copyFn(text) {
    copyText(text, function () {
            message.success({content: 'copied!'})
        }
    );
}

const copyText = function (button, content, success) {
    if (!button) {
        return;
    }

    if (typeof content == 'function') {
        success = content;
        content = null;
    }

    success = success || function () {
    };

    // 是否降级使用
    var isFallback = !navigator.clipboard;

    if (typeof button == 'string' && !content) {
        if (content === false) {
            isFallback = true;
        }
        content = button;
        button = null;
    }

    var eleTextarea = document.querySelector('#tempTextarea');
    if (!eleTextarea && isFallback) {
        eleTextarea = document.createElement('textarea');
        eleTextarea.style.width = 0;
        eleTextarea.style.position = 'fixed';
        eleTextarea.style.left = '-999px';
        eleTextarea.style.top = '10px';
        eleTextarea.setAttribute('readonly', 'readonly');
        document.body.appendChild(eleTextarea);
    }


    var funCopy = function (text, callback) {
        callback = callback || function () {
        };

        if (!isFallback) {
            navigator.clipboard.writeText(text).then(function () {
                callback();
                // 成功回调
                success(text);
            }, function () {
                // 禁止写入剪切板后使用兜底方法
                copyText(text, false);
                callback();
                // 成功回调
                success(text);
            });

            return;
        }

        eleTextarea.value = text;
        eleTextarea.select();
        document.execCommand('copy', true);

        callback();
        // 成功回调
        success(text);
    };

    var strStyle = '.text-popup { animation: textPopup 1s both; -ms-transform: translateY(-20px); color: #01cf97; user-select: none; white-space: nowrap; position: absolute; z-index: 99; }@keyframes textPopup {0%, 100% { opacity: 0; } 5% { opacity: 1; } 100% { transform: translateY(-50px); }}'

    var eleStyle = document.querySelector('#popupStyle');
    if (!eleStyle) {
        eleStyle = document.createElement('style');
        eleStyle.id = 'popupStyle';
        eleStyle.innerHTML = strStyle;
        document.head.appendChild(eleStyle);
    }

    if (!button) {
        funCopy(content);
        return;
    }

    // 事件绑定
    button.addEventListener('click', function (event) {
        var strCopy = content;
        if (content && content.tagName) {
            strCopy = content.textContent || content.value;
        }
        // 复制的文字内容
        if (!strCopy) {
            return;
        }

        funCopy(strCopy, function () {
            copyText.tips(event);
        });
    });
};

const ModelReferralShare = (
    {
        inviteCode
    }
) => {
    const {t, i18n} = useTranslation();
    const copyWrap = useRef(null);
    const copy = () => {
        copyFn(copyWrap.current.innerText);
    }

    const shareWords = [];
    shareWords.push(t('I’m completing missions and battles while looting #CryptoTrillionaires on @GoTurnUp, a social gaming ecosystem. Use my ref code to start playing:'));
    shareWords.push(`${t('Are you ready to')} #TURNUP? #SocialGaming`);

    return (
        <div className={s.wrap}>
            <div className="fs18 color-white mt10" style={{lineHeight: '1.0'}}>
                {t('Share Your Referral Code')}
            </div>
            <div className="fs18 color-white mtb10" style={{lineHeight: '1.0'}}>
                {t('Through X(Twitter)')}
            </div>
            <div className={s.copyContent}>
                <div ref={copyWrap}>
                    {shareWords[0]}<br/>
                    {inviteCode}<br/>
                    {shareWords[1]}
                </div>
                <div className={s.copy} onClick={copy}>
                    <CustomIcon width={40} height={40} imgName={'Button/UI_Button_CopyNumberWordName_01'}
                                name={'Button/UI_Button_CopyNumberWordName_01'}/>
                </div>

            </div>
            <br/>
            <TwitterShareButton
                onLoad={() => {
                }}
                options={{
                    size: 'large',
                    text: `${shareWords[0]}\n${inviteCode}\n${shareWords[1]}`
                }}
                url={' '}
            />
            {/*<AntButton shape="round" className={`btn_public ${s.okey} mt20`} onClick={()=>{ window.location.href = "https://twitter.com/"; }}>
        POST NOW
        </AntButton>*/}
            {/*<div className="mtb10 fs12">
          Refer friends and secure <span className="color-green fb fs14">100%</span> of what the system gains from them during the event! Start inviting now and reap the rewards
	  </div>*/}
        </div>
    )
}
export default memo(ModelReferralShare);
