import React, {memo, useState, useMemo} from "react";
import s from "./index.module.less";
import {Input, Button, Modal} from "antd";
import {unit, siteName, getmatic_market, stringToNumber, chainName} from "@/utils/common";
import CustomIcon from "../../common/CustomIcon";
import WithdrawDialog from "../WithdrawDialog";
import {sitchI18n, languageEnum} from '@/i18n';
import WorkResultConfig from "@/utils/json/WorkResultConfig.json";
import {supportedLng} from "@/utils/env";
import ButtonFactory from "src/common/ButtonFactory";

const ModelSwitchLanguage = (
    {
        setShowSwitchLanguage
    }
) => {

    const switchLanguage = (language) => {
        sitchI18n(language);
    };

    const isLngSupport = (lng) => {
        let res = false;
        if (supportedLng && supportedLng.length > 0) {
            for (let i = 0; i < supportedLng.length; i++) {
                if (lng === supportedLng[i]) {
                    res = true;
                    break;
                }
            }
        }
        return res;
    }

    return (
        <div className={s.modalWrap}>
            {isLngSupport("en") && <ButtonFactory
                onClick={() => {
                    switchLanguage(languageEnum.EN);
                    setShowSwitchLanguage(false);
                }}
            >
                ENGLISH
            </ButtonFactory>}
            {isLngSupport("zh") && <ButtonFactory
                isMirror={true}
                onClick={() => {
                    switchLanguage(languageEnum.ZHTW);
                    setShowSwitchLanguage(false);
                }}
            >
                中文（繁體）
            </ButtonFactory>}
            {isLngSupport("pt") && <ButtonFactory
                onClick={() => {
                    switchLanguage(languageEnum.PT);
                    setShowSwitchLanguage(false);
                }}
            >
                português
            </ButtonFactory>}
            {isLngSupport("ja") && <ButtonFactory
                isMirror={true}
                onClick={() => {
                    switchLanguage(languageEnum.JA);
                    setShowSwitchLanguage(false);
                }}
            >
                日本語
            </ButtonFactory>}
        </div>
    )
}
export default memo(ModelSwitchLanguage);
