import React, {memo, useCallback, useEffect} from "react";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import CustomIcon from "../CustomIcon";
import {useState} from "react";
import {connect} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Button, Select, Modal, message, Input} from "antd";
import {
    getProfile, getuserinfo, joinChat, post_wish_create, post_wish_query, sliceStr,
    getAvatarFromProfile
} from "@/utils/common";
import ModelWishCreate from "src/model/ModelWishCreate"
import AvatarInfo from "../AvatarInfo";
import ButtonFactory from "src/common/ButtonFactory";
import MainHeader from "src/common/MainHeader";
import AntButton from "@/common/AntButton";
import AntModal from "@/common/AntModal";
import {AutoStrangeRule} from "@/utils/strangeRule";

const WishCreate = (
    {}
) => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [showModelWishCreate, setShowModelWishCreate] = useState(false)
    const ChangeHandler = (event) => {
        setUsername(event.target.value);
    }

    const [platformSelectNow, setPlatformSelectNow] = useState('1');
    const platformList = [
        {
            label: <><CustomIcon width={20} height={20} imgName={'Button/UI_Button_Ball-White_Twitter_On'}
                                 className="mr5" name={'Button/UI_Button_Ball-White_Twitter_On'}/>X
                (Twitter)</>,
            value: '1'
        },
        // { label: 'Tiktok', value: '2' },
    ];

    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [existUserinfo, setExistUserinfo] = useState({});
    const [searchUserinfo, setSearchUserinfo] = useState(null);
    const [searchUserAvatar, setSearchUserAvatar] = useState('');
    const [searchUserName, setSearchUserName] = useState('');

    const submitClick = () => {
        if (searchUserinfo?.userId !== 0) {
            joinChat(searchUserinfo?.userId, navigate, searchUserinfo?.accountType);
        } else {
            setLoadingSubmit(true);
            post_wish_create(platformSelectNow, username).then(data => {
                const {userId, accountType, isExist, isCreated} = data;

                if (isExist && userId) {
                    getuserinfo(userId).then((data) => {
                        setExistUserinfo(data);
                        setLoadingSubmit(false);
                        setShowModelWishCreate(true);
                    }).catch((err) => {
                        setLoadingSubmit(false);
                    })
                } else {
                    setLoadingSubmit(false);
                    joinChat(userId, navigate, accountType);
                }
            }).catch(err => {
                setLoadingSubmit(false);
            })
        }
    }

    const [loadingSearch, setLoadingSearch] = useState(false);
    const submitSearch = () => {
        if (username.indexOf(' ') !== -1) {
            message.info({content: t('CreateWishUsernameIllegal')})
            return false
        }
        setLoadingSearch(true);
        post_wish_query(platformSelectNow, username).then(data => {
            setSearchUserinfo(data);
            if (data?.userId !== 0) {
                getuserinfo(data?.userId).then((data) => {
                    const profile = getProfile(data?.platformData);

                    setSearchUserAvatar(getAvatarFromProfile(profile));
                    setSearchUserName(profile?.displayName);
                });
            } else {
                setSearchUserAvatar(getAvatarFromProfile(data));
                setSearchUserName(data?.displayName);
            }
            setLoadingSearch(false);
        }).catch(err => {
            setLoadingSearch(false);
        });
    }

    return (
        <>
            <MainHeader title={t('CREATE A WISH')} hasRightMenu={false} headerType={2}></MainHeader>
            <div className={`${s.modalWrap}`}>

                <div className={s.modal}>
                    <div className={s.bt}>
                        <div className={s.btl}>
                            <div className={`${s.partTitle} fb`}>
                                <CustomIcon width={17} height={39} className=""
                                            imgName={'Picture/UI_Picture_Title-Yellow_01'}
                                            name={'Button/UI_Button_Arrow_01'}/>
                                {AutoStrangeRule(t('Select Platform'))}
                            </div>
                        </div>
                    </div>
                    <div className={s.item}>
                        <Select
                            size="large"
                            value={platformSelectNow}
                            options={platformList}
                            popupClassName="liveSettingOption"
                            open={false}
                            className='liveSetting wish'
                            onChange={(value) => {
                                setPlatformSelectNow(value);
                            }}
                        />
                    </div>
                    <div className={s.item}>
                        {/* <div className={s.label}>
                        Handle
                    </div> */}
                        <div className={s.search}>
                            <Input className={`searchInput ${s.input}`} suffix={
                                <div className={s.searchBtn}
                                     style={{backgroundImage: `url(/UI/Button/UI_Button_Yellow-Search_01.png)`}}>
                                </div>} placeholder={t('Username')} onChange={ChangeHandler} value={username}/>
                        </div>
                    </div>
                    <div className={s.innerWrap}>
                        <ButtonFactory onClick={submitSearch}
                                       loading={loadingSearch}>
                            {t('Search For User')}
                        </ButtonFactory>
                    </div>


                    <div className={`${s.info} mt20 fs16 fl`}>
                        {t('Before a creator is onboarded on TURNUP, you can trade Wishes, which will be converted into Keys upon their platform entry.')}
                        <br/>
                        {t('We\'ll reserve 20 keys specifically for Wish account owners, priced at 24.7 $MATIC upon onboarding. Pre-sale initiates from the 21st Wish onward.')}
                    </div>
                    {searchUserinfo && <div className={s.searchResult}>
                        <div className={s.bt}>
                            <div className={s.btl}>
                                <div className={`${s.partTitle} fb`}>
                                    <CustomIcon width={17} height={39} className=""
                                                imgName={'Picture/UI_Picture_Title-Yellow_01'}
                                                name={'Button/UI_Button_Arrow_01'}/>
                                    {AutoStrangeRule(t('Search Result'))}
                                </div>
                            </div>
                        </div>
                        <div className={s.searchResultCon}>
                            <AvatarInfo src={searchUserAvatar} size={60}/>
                            <div className={s.right}>
                                <div className={`fs16 fb ${s.username}`}>
                                    {sliceStr(searchUserName, 'all')}
                                    {searchUserinfo?.userId !== 0 && searchUserinfo?.accountType === 'wish' &&
                                        <div className={`${s.bottomTagitem} ${s.wish} ml5`}>
                                            {/*<CustomIcon width={12} height={13} className="mr5" imgName={'icon_wish'} name={'icon_wish'} />*/}
                                            {t('WISH')}
                                        </div>}
                                    {searchUserinfo?.userId === 0 && <div className={`${s.bottomTagitem} ml5`}>
                                        {t('NEW')}
                                    </div>}
                                </div>
                                <div className={`fs12 ${s.accountName}`}>
                                    @{searchUserinfo?.pfAccName}
                                    <CustomIcon className="ml5" width={16} height={16}
                                                imgName={'Button/UI_Button_Ball-White_Twitter_On'}
                                                name={'Button/UI_Button_Ball-White_Twitter_On'}/>


                                </div>
                                {searchUserinfo?.userId !== 0 && <div
                                    className="fs12 color-white">{searchUserinfo?.holderNum > 1 ? t('Members') : t('Member')}
                                    <span className="fb">{searchUserinfo?.holderNum}</span>
                                    {searchUserinfo?.accountType !== 'wish' && <>
                                        <Trans
                                            i18nKey="Joined sb."
                                            defaults="Joined <sp>{{name}}</sp>"
                                            values={{name: searchUserinfo?.holdingNum}}
                                            components={{sp: <span className="fb"></span>}}
                                        /></>}
                                </div>}
                                {searchUserinfo?.displayName === '' && searchUserinfo?.userId === 0 && <>
                                    <div className="fs12">{t('This accout doesn\'t exist')}</div>
                                    <div className="fs12 mt10">{t('Try searching for another.')}</div>
                                </>}
                            </div>
                            <ButtonFactory onClick={submitClick}
                                           className="modelBottomBtn"
                                           loading={loadingSubmit}>
                                {searchUserinfo?.userId !== 0 && t('View Details')}
                                {searchUserinfo?.userId === 0 && t('Create A Wish')}
                            </ButtonFactory>
                        </div>
                    </div>}
                </div>

                {/* <AntButton className={`btn_public`} shape="round" onClick={submitSearch} loading={loadingSearch}>
                Search By Username
            </AntButton> */}


                <AntModal
                    width='330px'
                    title={t('ERROR')}
                    className="confirmModalWrap"
                    centered={true}
                    open={showModelWishCreate}
                    destroyOnClose={true}
                    onOk={() => setShowModelWishCreate(false)}
                    onCancel={() => setShowModelWishCreate(false)}
                >
                    <ModelWishCreate
                        wishUserInfo={existUserinfo}
                        result={false}
                    />
                </AntModal>
            </div>
        </>
    )
}
const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
        customChatMap: app.customChatMap,
        showCharContent: app.showCharContent,
        curentOtherUserInfo: app.curentOtherUserInfo,
        curRtcInfo: app.curRtcInfo,
        showOtherTransContent: app.showOtherTransContent
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setShowCharContent: (params) => {
            return dispatch({
                type: "app/setShowCharContent",
                payload: params,
            });
        },
        setShowOtherTransContent: (params) => {
            return dispatch({
                type: "app/setShowOtherTransContent",
                payload: params,
            });
        },
        setCurentOtherUserInfo: (params) => {
            return dispatch({
                type: "app/setCurentOtherUserInfo",
                payload: params,
            });
        },
        deleteChatCustomMessage: (params) => {
            return dispatch({
                type: "app/deleteChatCustomMessage",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(WishCreate));
