import React, {memo, useEffect, useState, useMemo} from "react";
import s from "./index.module.less";
import {
    copyFn,
    openMoonPay,
    unit,
    getChainTokenInfoById,
    getQuotes,
} from "@/utils/common";
import CustomIcon from "../../common/CustomIcon";
import {usePrivy, useWallets} from "@privy-io/react-auth";
import {Button} from "antd";
import {
    moonpayBasicDollar, 
    isOnlineEnv,
    BaseChainId,
    PolygonChainId,
} from "@/utils/env";
import {useTranslation, Trans} from 'react-i18next';
import moonpayImg from "@/assets/images/bgImg/UI_Picture_MoonPay.png"
import uniswapImg from "@/assets/images/bgImg/UI_Picture_UNISWAP.png"

const ModelDeposit = (
    {
        topUpCode,
        setShowDepositPanel,
        walletAddr,
        chainTokenInfo,
    }
) => {
    const {t, i18n} = useTranslation();
    const {wallets} = useWallets();
    const [marketInfo, setMarketInfo] = useState(null);

    useEffect(() => {
        getQuotes([chainTokenInfo?.coinName]).then(res => {
            setMarketInfo(res);
        }).catch(e => {
            console.log(e);
        })
    }, [])

    const getAmount = () =>{
        let res = moonpayBasicDollar;
        if (chainTokenInfo?.chainId === PolygonChainId && marketInfo?.quotes?.MATIC > 0){
            res = Number((moonpayBasicDollar / marketInfo?.quotes?.MATIC).toFixed(2));
        }
        else if(chainTokenInfo?.chainId === BaseChainId && marketInfo?.quotes?.ETH > 0){
            res = Number((moonpayBasicDollar / marketInfo?.quotes?.ETH).toFixed(2));
        }
        return res;
    }

    return (
        <div className={s.wrap}>
            <div className={s.item}>
                <div className={s.contentWrap}>
                    <div className="fs18 tlc">
                        {t('Deposit unit to your TURNUP Wallet Address', {unit: chainTokenInfo ? chainTokenInfo?.displayCoinName:"Crypto"})}
                    </div>
                    <div className={`${s.addr}`}>
                        <div className="fs14 tlc color-blue-light" style={{wordBreak: 'break-all'}}>{walletAddr}</div>
                        <div className="ml10" onClick={() => {
                            copyFn(walletAddr)
                        }}><CustomIcon width={18} height={18} imgName={'Button/UI_Button_CopyNumberWordName_01'}
                                       name={'Button/UI_Button_CopyNumberWordName_01'}/></div>
                    </div>
                </div>
            </div>
            {
                (!chainTokenInfo || chainTokenInfo?.moonpayCurrencyCode) &&
                <div className={s.btnGroup}>
                    <div className={s.titleTip}>
                        {t('or try')}
                    </div>
                    <div className={s.i} onClick={() => {
                        openMoonPay(wallets, getAmount(), chainTokenInfo);
                    }}>
                        <div className={s.l} style={{backgroundImage: `url(${moonpayImg})`}}></div>
                    </div>
                </div>    
            }
        </div>
    )
}
export default memo(ModelDeposit);
