import React, {memo, useState, useMemo, useRef, useEffect} from "react";
import {connect} from "react-redux";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import {Button, Modal, Spin} from "antd";
import CustomIcon from "../../common/CustomIcon";
import imgUrl from "@/assets/images/FingersCrossed.png"
import AvatarInfo from "../../common/AvatarInfo";
import CrackModelAttackIntro from "../CrackModelAttackIntro";
import Countdown from "../../common/Countdown";
import {
    sliceStr,
    stringToNumber,
    getAvatarFromProfile,
    getFriendtrade_loot_employeelist,
    formatCardDisplayName,
    displayFilter,
    getBattlePointRankImg,
    convertWalletBalance,
    post_history_friends,
    joinChat,
    getAvatarFromUserInfo,
    getProfile,
} from "@/utils/common";
import {
    mainClubSweepClubsPath,
    balancePath,
} from "@/routes/config";
import {useNavigate} from "react-router-dom";
import {mainHome, mainHomePath} from "@/routes/config";
import ButtonFactory from "@/common/ButtonFactory";
import MainHeader from "@/common/MainHeader";
import AntModal from "@/common/AntModal";
import InfiniteScroll from "react-infinite-scroll-component";
import AntButton from "@/common/AntButton";
import {AutoStrangeRule} from "@/utils/strangeRule";
import HistoryActivitiesInfo from "@/common/HistoryActivitiesInfo";

const type = "hire-friends-tran-list";

const CrackModelClubList = (
    {
        userInfo,
        closeFn,
        showType = 0,//0:all activities, 1:mission, 2:battle
    }
) => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const [tranList, setTranList] = useState(null);
    const [loading, setLoading] = useState(true);

    const {
        selfData,
        userId, 
        platformData,
    } = useMemo(() => {
        return userInfo || 0;
    }, [userInfo]);

    const {avatarUrl, originalAvatarUrl} = useMemo(() => {
        return getAvatarFromUserInfo(userInfo)
    }, [platformData, userInfo]);

    const profile = useMemo(() => {
        return getProfile(platformData) || {}
    }, [platformData])

    const list = useMemo(() => {
        return tranList?.data?.list || []
        //return [tranList?.data?.list[0], tranList?.data?.list[1]]
    }, [tranList]);

    const nextCursor = useMemo(() => {
        return tranList?.data?.nextCursor || -1
    }, [tranList])

    console.log("tranList", tranList);

    const getDefaultData = () => {
        post_history_friends(showType).then(res => {
            const {nextCursor} = res || -1;
            setLoading(false);
            setTranList({
                type, data: {
                    list: res?.logs,
                    nextCursor
                }
            })
        }).catch(e => {
            setLoading(false);
            console.log(e);
        })
    }

    const getMore = () => {
        // getkeyhistoryglobal(tranList[type]?.nextCursor).then(res => {
        //     const {nextCursor} = res || -1;
        //     setTranList({
        //         type, data: {
        //             list: [
        //                 ...tranList[type].list,
        //                 ...res?.txlist,
        //             ],
        //             nextCursor
        //         }
        //     })
        // }).catch(e => {
        //     console.log(e);
        // })
    }

    useEffect(() => {
        getDefaultData()
    }, []);

    const toTranD = (userId, accountType) => {
        if(userId > 0 ){
            joinChat(userId, navigate, accountType);
        }
    }

    const getHeaderText = () =>{
        if (showType === 1){
            return t('Mission History');
        }
        else if (showType === 2){
            return t('Battle History');
        }
        else{
            return t('Activities');
        }
    }

    return (
        <>
            <div className={`${s.wrap} mainwrap`}>
                <MainHeader title={getHeaderText()} hasRightMenu={false} headerType={2}
                            onBack={closeFn}></MainHeader>
                {
                    !loading ?
                    <div className={s.content}>
                        {
                            list.length > 0 ? list.map((item, ind) => {
                                const {logType, txData, friendTradeData} = item;
                                if (logType === 1) {
                                    const {
                                        amount,
                                        time,
                                        txType,
                                        totalPrice,
                                        keyId,
                                        userId,
                                        keyAccountType,
                                        keyProfile,
                                        userAccountType,
                                        userProfile
                                    } = txData;

                                    //let type = item?.type;
                                    //if (!type) type = 0;

                                    return (
                                        <div className={`${s.item} ${s.itemBg}`} key={ind}>
                                            <div className={s.tranAvatar}>
                                                <div className={s.onwerAvatar} onClick={() => {
                                                    toTranD(userId, userAccountType)
                                                }}>
                                                    <AvatarInfo size={40} src={getAvatarFromProfile(userProfile)}/>
                                                </div>
                                                <div className={s.sponsorAvatar} onClick={() => {
                                                    toTranD(keyId, keyAccountType)
                                                }}>
                                                    <AvatarInfo size={40} src={getAvatarFromProfile(keyProfile)}/>
                                                </div>
                                            </div>
                                            <div className={s.tranInfo}>
                                                <div className={`${s.info} fs14`}>
                                                    <HistoryActivitiesInfo 
                                                        userInfo={userInfo}
                                                        textType={0}
                                                        data={item}>
                                                    </HistoryActivitiesInfo>
                                                </div>
                                                <div className={s.price}>
                                                    <HistoryActivitiesInfo 
                                                        userInfo={userInfo}
                                                        textType={1}
                                                        data={item}>
                                                    </HistoryActivitiesInfo>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                } else {
                                    const {
                                        type,
                                        managerId,
                                        managerAccountType,
                                        managerProfile,
                                        employeeId,
                                        employeeAccountType,
                                        employeeProfile,
                                        coin,
                                        time,
                                        workName,
                                        bpChangeVal
                                    } = friendTradeData;
                                    return (
                                        <div className={`${s.item} ${s.itemBg}`} key={ind}>
                                            <div className={s.tranAvatar}>
                                                {type === 8 || type === 9 || type === 1? <>
                                                    <div className={s.onwerAvatar} onClick={() => {
                                                        toTranD(employeeId, employeeAccountType)
                                                    }}>
                                                        <AvatarInfo size={40}
                                                                    src={getAvatarFromProfile(employeeProfile)}/>
                                                    </div>
                                                    <div className={s.sponsorAvatar} onClick={() => {
                                                        toTranD(type === 5 ? userId : managerId, managerAccountType)
                                                    }}>
                                                        <AvatarInfo size={40}
                                                                    src={type === 5 ? avatarUrl : getAvatarFromProfile(managerProfile)}/>
                                                    </div>
                                                </> : type === 14 ? <>
                                                    <div className={s.onwerAvatar} onClick={() => {
                                                        toTranD(userId, 'key')
                                                    }}>
                                                        <AvatarInfo size={40}
                                                                    src={getAvatarFromProfile(profile)}/>
                                                    </div>
                                                </> : <>
                                                    <div className={s.onwerAvatar} onClick={() => {
                                                        toTranD(type === 5 ? userId : managerId, managerAccountType)
                                                    }}>
                                                        <AvatarInfo size={40}
                                                                    src={type === 5 ? avatarUrl : getAvatarFromProfile(managerProfile)}/>
                                                    </div>
                                                    <div className={s.sponsorAvatar} onClick={() => {
                                                        toTranD(employeeId, employeeAccountType)
                                                    }}>
                                                        <AvatarInfo size={40}
                                                                    src={getAvatarFromProfile(employeeProfile)}/>
                                                    </div>
                                                </>}

                                            </div>
                                            <div className={s.tranInfo}>
                                                <div className={`${s.info} fs14`}>
                                                    <HistoryActivitiesInfo 
                                                        className={''}
                                                        userInfo={userInfo}
                                                        textType={0}
                                                        data={item}>
                                                    </HistoryActivitiesInfo>
                                                </div>
                                                <div className={s.price}>
                                                    <div className="color-blue-light fs12">
                                                        <HistoryActivitiesInfo 
                                                            className={''}
                                                            userInfo={userInfo}
                                                            textType={1}
                                                            data={item}>
                                                        </HistoryActivitiesInfo>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            }) : <div className="fs-12">{t('No Data')}</div>
                        }
                        <div className={`${s.loadMore} ${nextCursor !== -1 ? s.show : ''}`}
                            onClick={getMore}>
                            {t('Load More')}
                        </div>
                    </div> :
                    <div className="">
                        {t('Loading...')}
                    </div>
                }
            </div>
        </>
    )
}
const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setUserInfo: (params) => {
            return dispatch({
                type: "app/setUserInfo",
                payload: params
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(CrackModelClubList));
