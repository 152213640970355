import React, {memo, useMemo} from "react";
import {connect} from "react-redux";
import s from "./index.module.less";
import CustomIcon from "../CustomIcon";

import {useNavigate, useLocation} from "react-router-dom";
import TaskConf from "@/utils/json/TaskConf.json";
import {useTranslation, Trans} from 'react-i18next';
import {displayNewbieTaskStartId} from "@/utils/env";
import {
    gameTrillionaireBattlePath,
    gameTrillionaireMissionPath,
} from "@/routes/config";

const ReferralNavs = (
    {
        userInfo,
        setShowBalance,
        path,
        setPath,
    }
) => {
    const {t, i18n} = useTranslation();

    const navArr = [
        {
            icon: 'Button/UI_Button-D_MyClubs',
            label: 'My Group',
            labelName: t('My Group'),
        }, {
            icon: 'Button/UI_Button-D_Assets',
            label: 'Bonus',
            labelName: t('ReferralNav 2'),
        },
    ]

    return (
        <div className={s.footNavWrap}>
            <div className={s.footNav}>
                {
                    navArr.map((item, ind) => {

                        return (
                            <div className={`${s.item} ${path === item.label ? s.active : ''}`} key={ind}
                                 onClick={() => {
                                     setPath(item.label)
                                 }}>
                                <div className={`${s.iconWrap} `}>
                                    <CustomIcon
                                        width={32} height={32}
                                        imgName={`${path === item.label ? item.icon + '_On' : item.icon}`}
                                        name={`${path === item.label ? item.icon + '_On' : item.icon}`}/>
                                </div>
                                <div className={`${s.label} fb`}>{item.labelName}</div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
        showBalance: app.showBalance
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setShowCharContent: (params) => {
            return dispatch({
                type: "app/setShowCharContent",
                payload: params,
            });
        },
        setCurentOtherUserInfo: (params) => {
            return dispatch({
                type: "app/setCurentOtherUserInfo",
                payload: params,
            });
        },
        setShowBalance: (params) => {
            return dispatch({
                type: "app/setShowBalance",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(ReferralNavs));
