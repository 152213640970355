import React, {useEffect, useMemo, useState, useRef, memo} from "react";
import {connect} from "react-redux";
import s from "./index.module.less"
import {useTranslation, Trans} from 'react-i18next';
import MainHeader from "src/common/MainHeader";
import AvatarInfo from "src/common/AvatarInfo";
import {
    getProfile,
    getAvatarFromUserInfo,
    stringToNumber,
    sliceAddr,
    getuserinfo,
    convertWalletBalance,
    copyFn,
    getearninfo,
    refreshAvatar,
    updateUserInfo,
    unit,
    LFG,
    GetSupportedChainList,
    getTokenBalanceOf,
} from "@/utils/common";
import CustomIcon from "src/common/CustomIcon";
import {useNavigate} from "react-router-dom";
import {linkPath, mainClubSweepClubsPath, mainMyclubsPath, mainNftBidMynftPath} from "@/routes/config";
import ButtonFactory from "src/common/ButtonFactory";
import {Modal} from "antd";
import ModelDeposit from "@/model/ModelDeposit";
import ModalSwapLfg from "@/model/ModalSwapLfg";
import ModelWithdraw from "@/model/ModelWithdraw";
import AntModal from "@/common/AntModal";
import {getnft_ownList} from "@/utils/bidNft";
import {AutoStrangeRule} from "@/utils/strangeRule";
import ModelChangeAvatar from "@/model/ModelChangeAvatar";
import {
    isOnlineEnv,
    LfgMainnetId,
    LfgTestnetId,
    PolygonChainId,
    AmoyChainId,
} from "@/utils/env";
import MemeChainToken from "@/utils/json/memeChainToken.json";
import ModelLfgTransfer from "@/model/ModelLfgTransfer";
// import SettingsRedPacket from "@/views/main/Profile/SettingsRedPacket";

const LINKDATA = [
    {
        label: 'X (TWITTER)',
        icon: 'Button/UI_Button_Ball-White_Twitter',
        activeIcon: "Button/UI_Button_Ball-White_Twitter_On",
        available: true,
        type: 1,
    }, {
        label: 'TIKTOK',
        icon: 'Button/UI_Button_Ball-White_Snapchat_Unlock',
        activeIcon: "Button/UI_Button_Ball-White_Snapchat",
        available: false,
        type: 2,
    }, {
        label: 'INSTAGRAM',
        icon: 'Button/UI_Button_Ball-White_Tiktok_Unlock',
        activeIcon: "Button/UI_Button_Ball-White_Tiktok",
        available: false,
        type: 3,
    }, {
        label: 'SNAPCHAT',
        icon: 'Button/UI_Button_Ball-White_Instagram_Unlock',
        activeIcon: "Button/UI_Button_Ball-White_Instagram",
        available: false,
        type: 4,
    },
]
const Profile = (
    {
        userInfo,
        earnInfo,
        walletBalanceList,
        setWalletBalanceList,
    }
) => {
    const {
        walletAddr,
        topUpCode,
        userId,
        referralTotalEarn,
        platformData,
        selfData,
    } = useMemo(() => {
        return userInfo || {}
    }, [userInfo]);

    const {
        portfolio,
        boostCD,
        totalPortfolio,
        tradeEarned,
        claimCoin,
        keyPrice
    } = useMemo(() => {
        return earnInfo || {}
    }, [earnInfo])

    const {
        balance,
        lfgBalance,
    } = useMemo(() => {
        return selfData || {}
    }, [selfData]);

    const displayTokenWithChainList = useMemo(() =>{
        let list = GetSupportedChainList();
        for (let i = 0; i < list?.length; i++){
            list[i].supportedToken = [];
        }
        if (MemeChainToken?.length > 0){
            for(let i = 0; i < MemeChainToken?.length; i++){
                let tokenInfo = MemeChainToken[i];
                for (let j = 0; j < list?.length; j++){
                    if (tokenInfo?.chainId === list[j]?.id && tokenInfo?.isEnable){
                        let tempTokenInfo = tokenInfo;
                        tempTokenInfo.balance = walletBalanceList?.[tokenInfo?.ID];
                        list[j].supportedToken.push(tempTokenInfo);
                        break;
                    }
                }
            }
        }
        return list;
    }, [MemeChainToken, walletBalanceList]); 

    const {t, i18n} = useTranslation();
    const [showDepositPanel, setShowDepositPanel] = useState(false);
    const [showWithdrawPanel, setShowWithdrawPanel] = useState(false);
    const [withdrawPanelChainInfo, setWithdrawPanelChainInfo] = useState(displayTokenWithChainList?.[0]);
    const [depositPanelChainInfo, setDepositPanelChainInfo] = useState(null);
    const [lfgTransferTokenInfo, setLfgTransferTokenInfo] = useState(null);
    const [showSwapLfg, setShowSwapLfg] = useState(false);
    const [nftData, setNftData] = useState({})
    const [lock, setLock] = useState(false)
    const [showLfgTransferPanel, setShowLfgTransferPanel] = useState(false)
    const getearninfoFn = () => {
        getearninfo().then(res => {
        }).catch(e => {
            console.log(e);
        })
    }

    const getNftData = () => {
        getnft_ownList().then(res => {
            setNftData(res);
        }).catch(err => {
            console.error(err.message);
        })
    }

    const getWalletBalance = () =>{
        let tokenList = [];
        if (MemeChainToken?.length > 0){
            for(let i = 0; i < MemeChainToken?.length; i++){
                let tokenInfo = MemeChainToken[i];
                if (tokenInfo?.isEnable){
                    tokenList.push(tokenInfo?.ID);
                }
            }
        }
        getTokenBalanceOf(walletAddr, tokenList).then(res => {
            setWalletBalanceList(res?.balances);
        }).catch(err => {
            console.error(err.message);
        })
    }

    useEffect(() => {
        getearninfo().then(res => {
        }).catch(e => {
            console.log(e);
        })
        // getNftData()
        getWalletBalance();
    }, [])

    const navigate = useNavigate()

    const {platformMap, useType, defaultName} = useMemo(() => {
        return platformData || {}
    }, [platformData])

    const {displayName, accountName, profileDeepLink} = getProfile(platformData)
    const [showChangeAvatar, setShowChangeAvatar] = useState(false)

    const {avatarUrl, originalAvatarUrl} = useMemo(() => {
        return getAvatarFromUserInfo(userInfo)
    }, [platformMap, useType, userInfo])

    const refresh = () => {
        !lock && refreshAvatar().then(() => {
            setLock(true)
            setTimeout(() => {
                setLock(false)
            }, 5000)
            getuserinfo(userId).then(res => {
                updateUserInfo(res)
            })
        }).catch(e => {

        })

    }

    return (
        <div className={`${s.wrap} mainWrap`}>
            <MainHeader hasBackBtn={false} leftTitle={t('Profile')}></MainHeader>
            <div className={s.head}>
                <div className={s.ht}>
                    <div className={s.avatar}>
                        <AvatarInfo src={avatarUrl || originalAvatarUrl} size={70}></AvatarInfo>
                        <div className={s.changeAvatar} onClick={() => {
                            setShowChangeAvatar(true)
                        }}>
                            <CustomIcon width={31} height={26}
                                        imgName='Button/UI_Button_Refresh'/>
                        </div>

                    </div>
                    <div className={s.htr}>
                        <div className="fs18">{displayName}</div>
                        <div className="color-blue-light fs16">{accountName}</div>
                        <div className="fs16">UID:{userId}</div>
                    </div>
                </div>
                <div className={s.hb}>
                    <div className={s.list}>
                        {LINKDATA?.map((item, index) => {
                            const {label, icon, available, type, activeIcon} = item
                            return (
                                <div className={s.item} key={index}>
                                    <div className={`f1 ${s.left}`}>
                                        <CustomIcon width={36} height={36}
                                                    imgName={useType === type ? activeIcon : icon}/>
                                    </div>

                                    {/* <AntButton onClick={() => openLinkPanel(label, available)} className={`${available ? 'btn_public' : 'btn_gray'} ${s.btn}`} shape="round">{available ? t('LINK') : t('COMING SOON')}</AntButton> */}
                                </div>
                            )
                        })}

                    </div>
                    {!profileDeepLink &&
                        <ButtonFactory
                            size={21}
                            onClick={() => navigate(linkPath)}
                        >
                            {t('LINK SOCIALS')}
                        </ButtonFactory>
                    }
                </div>
            </div>
            {/* <div className={s.mid}>
                <div className={s.item}>
                    <ButtonFactory
                        size={33}
                        color={3}
                        iconName={'UI_Picture-Currency_MATIC_01'}
                    >
                        <div className="ml10 color-black fs18 fb">{stringToNumber(portfolio, 4)}</div>
                    </ButtonFactory>
                    <div className={s.label}>{t('Portfolio Value')}</div>
                </div>
                <div className={s.item}>
                    <ButtonFactory
                        size={33}
                        color={3}
                        iconName={'UI_Picture-Currency_MATIC_01'}
                    >
                        <div className="ml10 color-black fs18 fb">{stringToNumber(tradeEarned, 4)}</div>
                    </ButtonFactory>
                    <div className={s.label}>{t('Fees Earned')}</div>
                </div>
                <div className={s.item}>
                    <ButtonFactory
                        size={33}
                        color={3}
                        iconName={'UI_Picture-Currency_MATIC_01'}
                    >
                        <div className="ml10 color-black fs18 fb">{keyPrice}</div>
                    </ButtonFactory>
                    <div className={s.label}>{t('Card Price')}</div>
                </div>
            </div> */}
            <div className={s.bottom}>
                <div className={s.bt}>
                    <div className={s.btl}>
                        <div className={`${s.partTitle} fb`}>
                            <CustomIcon width={17} height={39} className=""
                                        imgName={'Picture/UI_Picture_Title-Yellow_01'}
                                        name={'Button/UI_Button_Arrow_01'}/>
                            {AutoStrangeRule(t('Wallet Balance'))}
                        </div>
                    </div>
                    <div className={s.btr}>
                        <div className="fs16 mr5">{sliceAddr(walletAddr, 6)}</div>
                        <CustomIcon imgName={'Button/UI_Button_CopyNumberWordName_01'} width={22} height={22}
                                    onClick={() => {
                                        copyFn(walletAddr)
                                    }}></CustomIcon>
                    </div>
                </div>
                <div className={s.bList}>
                    {
                        displayTokenWithChainList?.length > 0 && displayTokenWithChainList.map((chainItem) =>{
                            if (chainItem?.supportedToken?.length > 0)
                            {
                                return (
                                    <div className={s.chainItem} key={chainItem?.ID}>
                                        <div className={s.chainName}>
                                            {chainItem?.name}
                                        </div>
                                        {
                                            chainItem.supportedToken.map((tokenItem,index) =>{
                                                return (
                                                    <div className={s.chainTokenItem} key={index}>
                                                        <div className={s.chainTokenItemLeft}>
                                                            {
                                                                tokenItem?.icon && 
                                                                <CustomIcon className="mr10" imgName={`Picture/${tokenItem?.icon}`} width={22}
                                                                        height={22}></CustomIcon>
                                                            }
                                                            <div className="fs18 fb">
                                                                {
                                                                    tokenItem?.balance !== undefined && tokenItem?.balance != null ?
                                                                        convertWalletBalance(tokenItem.balance):
                                                                        '--'
                                                                }
                                                            </div>
                                                            <CustomIcon className="ml10" imgName={'Button/UI_Button_Extract-White_01'} width={22}
                                                                        onClick={() => {
                                                                            if (tokenItem?.isNative){
                                                                                setShowWithdrawPanel(true);
                                                                                setWithdrawPanelChainInfo(tokenItem);
                                                                            }
                                                                            else if (tokenItem?.ID === LfgMainnetId || tokenItem?.ID === LfgTestnetId){
                                                                                setShowLfgTransferPanel(true);
                                                                                setLfgTransferTokenInfo(tokenItem);
                                                                            }
                                                                        }}
                                                                        height={22}></CustomIcon>
                                                        </div>
                                                        {
                                                            (tokenItem?.isNative || tokenItem?.ID === LfgMainnetId) &&
                                                            <ButtonFactory
                                                                size={21}
                                                                onClick={() => {
                                                                    if (tokenItem?.isNative){
                                                                        setShowDepositPanel(true);
                                                                        setDepositPanelChainInfo(tokenItem);
                                                                    }
                                                                    else if (tokenItem?.ID === LfgMainnetId){
                                                                        setShowSwapLfg(true);
                                                                    }
                                                                }}
                                                            >
                                                                <span className="fs14 fb color-black">
                                                                    {t(`GET MORE {{coin}}`, {coin: tokenItem?.displayCoinName})}
                                                                </span>
                                                            </ButtonFactory>
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                        {
                                            (chainItem.id === PolygonChainId || chainItem.id === AmoyChainId) && 
                                            <div className={s.chainNft}>
                                                <div className={s.chainNftLeft}>
                                                    <div className={s.avatarGroup}>
                                                        {
                                                            nftData?.nftList?.length > 0 ?
                                                                nftData?.nftList?.map((item, index) => {
                                                                    const {image, tokenAddr, tokenId} = item
                                                                    return (
                                                                        index < 3 ? <AvatarInfo className={s.ila} src={image} key={index}
                                                                                                size={30}></AvatarInfo> : null
                                                                    )
                                                                }) : t('No NFT')
                                                        }
                                                    </div>
                                                    <div
                                                        className="fs18">{nftData?.nftList?.length > 0 ? `${t('Have')} ${nftData?.nftNum} NFTs` : ''}
                                                    </div>
                                                </div>
                                                <ButtonFactory
                                                    size={21}
                                                    onClick={() => navigate(mainNftBidMynftPath)}
                                                >
                                                    <span className="fs14 fb color-black">
                                                        {t('MY COLLECTIONS')}
                                                    </span>
                                                </ButtonFactory>
                                            </div>
                                        }
                                    </div>
                                )
                            }
                            else{
                                return <></>
                            }
                        })
                    }
                    {/*<div className={s.bLItem}>
                        <div className={s.il}>
                            <CustomIcon className="mr10" imgName={'Picture/UI_Picture-Currency_MATIC_01'} width={22}
                                        height={22}></CustomIcon>
                            <div className="fs18 fb">{convertWalletBalance(balance)}</div>
                            <CustomIcon className="ml10" imgName={'Button/UI_Button_Extract-White_01'} width={22}
                                        onClick={() => {
                                            setShowWithdrawPanel(true);
                                            //setWithdrawPanelChainInfo(PloygonInfo);
                                        }}
                                        height={22}></CustomIcon>
                        </div>
                        <div className={s.ibtn}>
                            <ButtonFactory
                                size={21}
                                onClick={() => setShowDepositPanel(true)}
                            >
                                {t('GET MORE MATIC')}
                            </ButtonFactory>
                        </div>
                    </div>
                    <div className={s.bLItem}>
                        <div className={s.il}>
                            <CustomIcon className="mr10" imgName={'Picture/UI_Picture-Currency_LFG_01'} width={22}
                                        height={22}></CustomIcon>
                            <div className="fs18 fb">{convertWalletBalance(lfgBalance)}</div>
                            <CustomIcon className="ml10" imgName={'Button/UI_Button_Extract-White_01'} width={22}
                                        onClick={() => {
                                            setShowLfgTransferPanel(true)
                                        }}
                                        height={22}></CustomIcon>
                        </div>
                        <div className={s.ibtn}>
                            <ButtonFactory
                                size={21}
                                onClick={() => setShowSwapLfg(true)}
                            >
                                {t('GET MORE $LFG')}
                            </ButtonFactory>
                        </div>
                    </div>
                    <div className={s.bLItem}>
                        <div className={s.il}>
                            <div className={s.avatarGroup}>
                                {
                                    nftData?.nftList?.length > 0 ?
                                        nftData?.nftList?.map((item, index) => {
                                            const {image, tokenAddr, tokenId} = item
                                            return (
                                                index < 3 ? <AvatarInfo className={s.ila} src={image} key={index}
                                                                        size={30}></AvatarInfo> : null
                                            )
                                        }) : t('No NFT')
                                }
                            </div>
                            <div
                                className="fs18">{nftData?.nftList?.length > 0 ? `${t('Have')} ${nftData?.nftNum} NFTs` : ''}
                            </div>
                        </div>
                        <div className={s.ibtn}>
                            <ButtonFactory
                                size={21}
                                onClick={() => navigate(mainNftBidMynftPath)}
                            >
                                {t('MY COLLECTIONS')}
                            </ButtonFactory>
                        </div>
                    </div>*/}
                    {/* <SettingsRedPacket/> */}
                </div>
            </div>


            <AntModal
                width='330px'
                title={t('Get More {{coin}}', {coin: depositPanelChainInfo?.displayCoinName})}
                className="confirmModalWrap"
                centered={true}
                open={showDepositPanel}
                onOk={() => setShowDepositPanel(false)}
                onCancel={() => setShowDepositPanel(false)}
                destroyOnClose={true}
                zIndex={2000}
            >
                <ModelDeposit 
                    topUpCode={topUpCode} 
                    walletAddr={walletAddr} 
                    setShowDepositPanel={setShowDepositPanel}
                    chainTokenInfo={depositPanelChainInfo}/>
            </AntModal>

            <AntModal
                width='330px'
                title={t('SWAP $MATIC AND $LFG')}
                className="confirmModalWrap"
                centered={true}
                open={showSwapLfg}
                onOk={() => setShowSwapLfg(false)}
                onCancel={() => setShowSwapLfg(false)}
                destroyOnClose={true}
                zIndex={7777}
            >
                <ModalSwapLfg userInfo={userInfo} closeFn={() => {
                    setShowSwapLfg(false);
                }}/>
            </AntModal>

            <AntModal
                width='330px'
                title={t('WITHDRAW xx', {unit: withdrawPanelChainInfo?.displayCoinName})}
                className="confirmModalWrap"
                centered={true}
                open={showWithdrawPanel}
                onOk={() => setShowWithdrawPanel(false)}
                onCancel={() => setShowWithdrawPanel(false)}
                destroyOnClose={true}
            >
                <ModelWithdraw  
                    getearninfoFn={getearninfoFn} 
                    userInfo={userInfo}
                    setShowWithdrawPanel={setShowWithdrawPanel}
                    chainTokenInfo={withdrawPanelChainInfo}/>
            </AntModal>

            <AntModal
                width='330px'
                title={t('WITHDRAW xx', {unit: LFG})}
                className="confirmModalWrap"
                centered={true}
                open={showLfgTransferPanel}
                destroyOnClose={true}
                onOk={() => setShowLfgTransferPanel(false)}
                onCancel={() => setShowLfgTransferPanel(false)}
            >
                <ModelLfgTransfer 
                    getearninfoFn={getearninfoFn} 
                    userInfo={userInfo}
                    setShowLfgTransferPanel={setShowLfgTransferPanel}
                    lfgTokenInfo={lfgTransferTokenInfo}/>
            </AntModal>

            <AntModal
                width='330px'
                title={t('SELECT YOUR AVATAR')}
                className="confirmModalWrap"
                centered={true}
                open={showChangeAvatar}
                onOk={() => setShowChangeAvatar(false)}
                onCancel={() => setShowChangeAvatar(false)}
                destroyOnClose={true}
                zIndex={2000}
            >
                <ModelChangeAvatar
                    userInfo={userInfo}
                    refreshIcon={refresh}
                    closeFn={() => {
                        setShowChangeAvatar(false);
                    }}
                />
            </AntModal>
        </div>
    )
}
const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
        earnInfo: app.earnInfo,
        walletBalanceList: app.walletBalanceList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setInviteList: (params) => {
            return dispatch({
                type: "app/setInviteList",
                payload: params
            });
        },
        setWalletBalanceList: (params) => {
            return dispatch({
                type: "app/setWalletBalanceList",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(Profile));
