import React, {memo, useEffect, useState, useMemo} from "react";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import CustomIcon from "../../../../common/CustomIcon";
import {connect} from "react-redux";
import {Button, Modal, Switch} from 'antd';
import {bannerData} from "@/utils/taskJson";
import {
    sliceStr, stringToNumber, unit,
    getuserinfo,
    getAvatarFromProfile,
    logoutSelf,
    TransactionError, sliceAddr
} from "@/utils/common"
import {useLocation, useNavigate, useParams} from "react-router-dom";
import AvatarInfo from "../../../../common/AvatarInfo";
import Countdown from "../../../../common/Countdown";
import {usePrivy, useWallets} from "@privy-io/react-auth";
import {
    getnft_detail, getnft_bid_status, getnft_bid_history, getRarityText, coinObj, getRarityBgColor, getRarityFontColor,
    getnft_ownList,
    set_nftavatar,
    getRarityRankIcon,
    getRarityImageBg,
    getBidStatus,
    delay
} from "@/utils/bidNft";
// import {auctionBid} from "@/utils/auctionWeb3"
import {bid, claim} from "@/utils/bidNftWeb3"
import ModalSwapLfg from "@/model/ModalSwapLfg";
import ModalInsufficientCrpto from "@/model/ModalInsufficientCrpto";
import {mainHomePath} from "@/routes/config";
import MainHeader from "src/common/MainHeader";
import AntButton from "@/common/AntButton";
import AntModal from "@/common/AntModal";

const BidItemDetail = (
    {
        userInfo,
        setShowLink,
        setTranNowSubType,
        setTranNowType,
        currentAuctionBatchCfg,
        bidSlippage,
    }
) => {
    const {walletAddr, selfData, userId} = userInfo;
    const {wallets} = useWallets();
    const {balance, lfgBalance} = selfData;
    const [selfNFTList, setSelfNFTList] = useState(null);
    const [currentNftAvatarUsed, setCurrentNftAvatarUsed] = useState(false);
    const {t, i18n} = useTranslation();
    const {tokenAddr, tokenId} = useParams();
    // const [linkUrl, setLinkUrl] = useState('');
    const navigate = useNavigate();
    const [showIntro, setShowIntro] = useState(false)
    const [showInd, setShowInd] = useState(0)
    const [switchAvatarLoading, setSwitchAvatarLoading] = useState(true);
    const [selfNftLoading, setSelfNftLoading] = useState(true);
    const [subLoading, setSubLoading] = useState(false)
    const [claimSucc, setClaimSucc] = useState(false)
    const [nftInfo, setNftInfo] = useState({})
    const [nftHistoryList, setNftHistoryList] = useState([])
    const [nftState, setNftState] = useState({})
    const [done, setDone] = useState(false);
    const [bidSuccess, setBidSuccess] = useState(false)
    const isAvailable = useMemo(() => {
        if (JSON.stringify(nftState) === '{}') return false
        if (Number(balance) < 0.1) return false
        return Number(nftState?.nextPrice) < (nftState?.coinType === 0 ? Number(lfgBalance) : Number(balance))
    }, [balance, nftState, lfgBalance])
    const bidCountWithSpluppage = useMemo(() => {
        return Number(nftState?.nextPrice) * (Number(bidSlippage) + 100) / 100;
    }, [nftState]);
    const [noSuccessText, setNoSuccessText] = useState(t('BidFailed'));
    const {
        useNftInfo
    } = useMemo(() => {
        return userInfo || {}
    }, [userInfo]);

    const timeFn = (time) => {
        time = parseInt(new Date().getTime() / 1000) - time;
        let d = parseInt(time / 60 / 60 / 24);
        let h = parseInt(time / 60 / 60 % 24);
        let m = parseInt(time / 60 % 60);
        let str = '';
        if (d > 0) {
            str = d + "d";
            return str
        }
        if (h > 0) {
            str = h + "h";
            return str
        }
        if (m > 0) {
            str = m + "m";
            return str
        }
        return t('few seconds');

        // return `${d}${h}${m}`
    }

    const checkResult = (txHash) => {
        return new Promise((resolve, reject) => {
            getBidStatus(txHash).then(async res => {
                console.log(txHash, res);
                const {result} = res
                if (result?.length === 0) {
                    await delay(2000)
                    checkResult(txHash)
                } else {
                    resolve(result)
                }

            }).catch(e => {
                reject(e)
            })
        })

    }
    const getState = (tokenAddr, tokenId) => {
        getnft_bid_status({tokenAddr, tokenId: Number(tokenId)}).then(res => {
            setNftState(res)
        }).catch(e => {
            console.error(e);
        })
    }
    const getnftHistory = (tokenAddr, tokenId) => {
        getnft_bid_history({tokenAddr, tokenId: Number(tokenId), cursor: 0}).then(res => {
            setNftHistoryList(res?.bidHistory || [])
        }).catch(e => {
            console.error(e);
        })
    }
    useEffect(() => {
        if (tokenAddr && tokenId) {
            // console.log(tokenAddr,tokenId);
            getState(tokenAddr, tokenId)
            getnft_detail({tokenAddr, tokenId: Number(tokenId)}).then(res => {
                setNftInfo(res)
            }).catch(e => {
                console.error(e);
            })
            getnftHistory(tokenAddr, tokenId)
        }

    }, [tokenAddr, tokenId])

    useEffect(() => {
        getnft_ownList().then(res => {
            setSelfNftLoading(false);
            setSelfNFTList(res?.nftList);
        }).catch(err => {
            setSelfNftLoading(false);
            console.error(err.message);
        })
        let useNftInfo = userInfo?.useNftInfo;
        setCurrentNftAvatarUsed(useNftInfo?.useNft && useNftInfo?.tokenAddr == tokenAddr && useNftInfo?.tokenId == tokenId);
        setSwitchAvatarLoading(false);
    }, []);

    const onAvatarSwitchChange = (checked) => {
        setCurrentNftAvatarUsed(checked);
        setSwitchAvatarLoading(true);
        set_nftavatar({tokenAddr: checked ? tokenAddr : '', tokenId: checked ? Number(tokenId) : 0}).then(res => {
            setSwitchAvatarLoading(false);
            getuserinfo(userId);
        }).catch(err => {
            setSwitchAvatarLoading(false);
            setCurrentNftAvatarUsed(!checked);
            console.error(err.message);
        })
    }

    const attrList = useMemo(() => {
        if (nftInfo?.attributes?.length) {
            return nftInfo?.attributes.sort((a, b) => {
                return a?.value?.toString().length - b?.value?.toString().length
            })

        }
    }, [nftInfo])


    const isEnded = useMemo(() => {
        if (nftState) {
            return nftState?.endTime * 1000 <= (new Date()).getTime()
        }
        return false
    }, [nftState])
    const isSelfNft = () => {
        let res = false;
        if (selfNFTList?.length > 0 && tokenAddr && tokenId) {
            for (let i = 0; i < selfNFTList?.length; i++) {
                let tempNft = selfNFTList[i];
                if (tempNft?.tokenAddr == tokenAddr && tempNft?.tokenId == tokenId) {
                    res = true;
                    break;
                }
            }
        }
        return res;
    }
    const [showSwapLfg, setShowSwapLfg] = useState(false);
    const [showInsufficientCrpto, setShowInsufficientCrpto] = useState(false);
    const {logout} = usePrivy();
    const bidNft = () => {
        if (subLoading) return
        let expectedPrice = (Number(nftState?.nextPrice) || 0) + 0.0001;
        console.log("expectedPrice", expectedPrice);
        if (!isAvailable) {
            if (nftState?.coinType === 1 && Number(nftState?.nextPrice) > Number(balance)) {
                setShowInsufficientCrpto(true);
            } else if (nftState?.coinType === 0 && Number(nftState?.nextPrice) > Number(lfgBalance)) {
                setShowSwapLfg(true);
            }
            return;
        }
        setSubLoading(true)

        bid(wallets, tokenAddr, tokenId, expectedPrice, Number(bidSlippage), Number(balance), nftState?.coinType).then(async res => {
            const resultArr = await checkResult(res.hash)
            if (resultArr[0]?.bidSucc) {
                setSubLoading(false)
                setDone(true)
                setBidSuccess(true)
                console.log(res);
                setTimeout(() => {
                    getState(tokenAddr, tokenId)
                    getnftHistory(tokenAddr, tokenId)
                }, 2000)
            } else {
                setDone(true)
                setSubLoading(false)
                setNoSuccessText(t(`BidFailedReason_${resultArr[0]?.errCode}`))
            }
        }).catch(e => {
            if (e?.reason != null) {
                for (const transactionErrorElement in TransactionError) {
                    if (e?.reason.indexOf(transactionErrorElement) !== -1) {
                        setNoSuccessText(t(`TransactionError_${transactionErrorElement}`))
                        break;
                    }
                }
            }
            setDone(true)
            setSubLoading(false)
            console.error(e);
        })
    }
    const claimNft = () => {
        if (subLoading) return
        setSubLoading(true)
        claim(wallets, tokenAddr, tokenId).then(res => {
            setSubLoading(false)
            setDone(true)
            setClaimSucc(true)
            setTimeout(() => {
                // getState(tokenAddr, tokenId)
                getnftHistory(tokenAddr, tokenId)
            }, 2000)
            console.log(res);
        }).catch(e => {
            setDone(true)
            setSubLoading(false)
            console.error(e);
        })
    }
    const location = useLocation()
    const reset = () => {
        getState(tokenAddr, tokenId)
        setDone(false)
        setSubLoading(false)
        setBidSuccess(false)
        setClaimSucc(false)

    }

    const closeFn = () => {
        if (setNoSuccessText === 'need login') {
            logout();
            logoutSelf();
        }
        if (location.key !== "default") {
            navigate(-1);
        } else {
            navigate(mainHomePath, {replace: true});
        }
    }

    const getWinnerNameLabel = () => {
        if (nftState?.highestProfile?.displayName) {
            return nftState?.highestProfile?.displayName;
        } else if (nftState?.highestWallet) {
            let walletStr = nftState?.highestWallet;
            if (walletStr.length < 11) {
                return walletStr;
            } else {
                return `${walletStr.substring(0, 6)}...${walletStr.substring(walletStr - 5)}`;
            }
        } else {
            return 'None';
        }
    }

    return (
        // <Spin
        //   className="loadPageSpin"
        //   indicator={
        //     <LoadingOutlined
        //       style={{
        //         fontSize: 90,
        //       }}
        //     />
        //   }
        //   tip="Loading..."
        //   spinning={loading}
        // >
        // </Spin>
        <>
            <div className={`${s.modalWrap} abs`}>
                <MainHeader title={nftInfo?.name} hasRightMenu={false} headerType={2}></MainHeader>
                <div className={s.block}>
                    <div className={s.avatar}>
                        {
                            (!isEnded && currentAuctionBatchCfg?.auctionBatchType === 2) &&
                            <div className={s.bidItemBg}
                                 style={{backgroundImage: `url(/img/${getRarityImageBg(nftInfo?.rarity)}.png)`}}>
                            </div>
                        }
                        <AvatarInfo size={170} src={nftInfo?.image} className={'roundAvatar'}/>
                        {/* <div className={`${s.tag} bidRarity_${nftState?.rarity}`}>{getRarityText(nftState?.rarity)}</div> */}
                        {
                            (!isEnded && currentAuctionBatchCfg?.auctionBatchType === 2) ?
                                <div className={`${s.rankTag} bidRarity_${nftInfo?.rarity}`} style={{
                                    backgroundColor: `${getRarityBgColor(nftInfo?.rarity)}`,
                                    color: `${getRarityFontColor(nftInfo?.rarity)}`
                                }}>
                                    <CustomIcon width={18} height={18} imgName={getRarityRankIcon(nftInfo?.rarity)}
                                                name={getRarityRankIcon(nftInfo?.rarity)}/>
                                    {nftInfo?.rankIdx || '--'}
                                </div> :
                                getRarityText(nftInfo?.rarity) != null ?
                                    <div className={`${s.tag} bidRarity_${nftInfo?.rarity}`} style={{
                                        backgroundColor: `${getRarityBgColor(nftInfo?.rarity)}`,
                                        color: `${getRarityFontColor(nftInfo?.rarity)}`
                                    }}>
                                        {getRarityText(nftInfo?.rarity)}
                                    </div> :
                                    <></>
                        }
                    </div>

                    {
                        !isSelfNft() && !selfNftLoading &&
                        <>
                            <div className={`${s.leftTime} ${isEnded ? s.bgWhite : ''}`}>
                                <CustomIcon width={16} height={16} className="mr5"
                                            imgName={isEnded ? 'Picture/UI_Picture_Time_01' : 'Picture/UI_Picture_Time_01'}
                                            name={isEnded ? 'Picture/UI_Picture_Time_01' : 'Picture/UI_Picture_Time_01'}/>
                                <div>{isEnded ? t('Ended') : nftState?.endTime &&
                                    <Countdown workEndTimestamp={nftState?.endTime} refresh={() => {
                                        getState()
                                    }}/>}</div>
                            </div>
                            {isEnded && <div className={s.winner}>
                                <div className={s.winnerInfo}>
                                    {t('Winner')}
                                    <span className="color-wihite ml5">
                                    {getWinnerNameLabel()}
                                </span>
                                </div>
                                <div className={s.winnerPrice}>
                                    <CustomIcon
                                        width={24}
                                        height={24}
                                        className="mr5"
                                        imgName={coinObj[nftState?.coinType]?.iconName}
                                        name={coinObj[nftState?.coinType]?.iconName}
                                    />
                                    <span className={s.price}>
                                    {stringToNumber(nftState?.highestPrice, 2)}
                                </span>
                                </div>
                            </div>}
                        </>
                    }
                    {isSelfNft() && !selfNftLoading && <div className={s.usePart}>
                        <div className={s.usePartText}>
                            {t('Use as Avatar')}
                        </div>
                        <Switch
                            loading={switchAvatarLoading}
                            className={`useAvatarSwitch ${s.useAvatarSwitch}`}
                            checkedChildren={<div className={s.useAvatarSwitchChecked}>
                                <CustomIcon width={16} height={16} imgName={'UseAvatarIcon'} name={'UseAvatarIcon'}
                                            onClick={() => {
                                            }}/>
                            </div>}
                            unCheckedChildren={<div className={s.useAvatarSwitchUnChecked}>
                                <CustomIcon width={16} height={16} imgName={'UnuseAvatarIcon'} name={'UnuseAvatarIcon'}
                                            onClick={() => {
                                            }}/>
                            </div>}
                            defaultChecked={currentNftAvatarUsed}
                            checked={currentNftAvatarUsed}
                            onChange={onAvatarSwitchChange}
                        />
                    </div>
                    }
                    <div className={s.border}></div>
                    <div className={s.filter}>
                        <AntButton className={`${s.btnW} ${showInd === 0 ? 'btn_blue' : 'btn_blue_light'}`}
                                   onClick={() => {
                                       setShowInd(0)
                                   }}>{t('BidDetailInformation')}</AntButton>
                        <AntButton className={`ml10 ${s.btnW} ${showInd === 1 ? 'btn_blue' : 'btn_blue_light'}`}
                                   onClick={() => {
                                       setShowInd(1)
                                   }}>{t('BidDetailAttributes')}</AntButton>
                        <AntButton className={`ml10 ${s.btnW} ${showInd === 2 ? 'btn_blue' : 'btn_blue_light'}`}
                                   onClick={() => {
                                       setShowInd(2)
                                   }}>{t('BidDetailHistory')}</AntButton>
                    </div>

                    <div className={s.content}>
                        {
                            showInd === 0 &&
                            <div className={s.wrap}>
                                <div className={`${s.attr} ${s.type1}`}>
                                    <div className={s.label}>{t('BidTextTokenID')}</div>
                                    <div className={s.val}>{nftInfo?.tokenId}</div>
                                </div>
                                <div className={`${s.attr} ${s.type1}`}>
                                    <div className={s.label}>{t('BidTextEdition')}</div>
                                    <div className={s.val}>{nftInfo?.edition}</div>
                                </div>
                                <div className={`${s.attr} ${s.type2}`}>
                                    {/* <div className={s.label}>Edition</div> */}
                                    {/* <div className={s.val}>Genesis v1</div> */}
                                    <div className={s.des}>
                                        {nftInfo?.description}
                                    </div>
                                </div>
                                <div className={s.desWithPic}>
                                    <div className={s.desPic}
                                         style={{backgroundImage: `url(/img/BigPicture/UI_Picture_HammerAndNFT_01.webp)`}}>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            showInd === 1 &&
                            <div className={s.wrap}>

                                {attrList?.map((item, ind) => {
                                    const {trait_type, value} = item;
                                    const vLen = value.toString().length;
                                    return (
                                        <div key={ind} className={`${s.attr} ${vLen <= 10 ? s.type1 : s.type2}`}>
                                            <div className={`${s.label} ${s.metaData}`}>{trait_type}</div>
                                            <div className={`${vLen < 40 ? s.val : s.des}`}>{value}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        }
                        {
                            showInd === 2 &&
                            <div className={s.historyWrap}>

                                {nftHistoryList?.map((item, ind) => {
                                    const {userId, userProfile, bidTime, userBidPrice, wallet} = item;
                                    return (
                                        <div key={ind} className={`${s.historyItem}`}>
                                            <div>
                                                <AvatarInfo size={40} src={getAvatarFromProfile(userProfile)}/>
                                            </div>
                                            <div
                                                className={`${s.log} f1 tll`}>{userId === 0 ? sliceStr(wallet, 4) : userProfile?.displayName}
                                                <span className="color-blue-light"> {t('placed bid of')} </span>
                                                <span
                                                    className={`${nftState?.coinType === 0 ? "color-lfg" : "color-purple"} `}>{stringToNumber(userBidPrice, 2)} {coinObj[nftState?.coinType]?.unit}</span>
                                                <br/><span
                                                    className="color-blue-light">{t('xx ago', {time: timeFn(bidTime)})}</span>
                                            </div>
                                        </div>
                                    )
                                })}
                                {nftHistoryList?.length === 0 && <div>{t('No Data')}</div>}
                            </div>
                        }
                    </div>
                    {selfNftLoading ?
                        <></> :
                        isSelfNft() ?
                            <div className={s.bottom}>
                            </div> :
                            <div className={s.bottom}>
                                <div style={{
                                    // paddingTop: '20px',
                                    paddingLeft: '30px',
                                    paddingRight: '30px',
                                    paddingBottom: '30px'
                                }}>
                                    <div className={`${s.wallet} ${s.borderWrap}`}>
                                        <div className={s.walletLeft}>
                                            <div className="fs18 color-white">{sliceAddr(walletAddr)}</div>
                                        </div>
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'flex-start',
                                            justifyContent: 'flex-end',
                                            flexDirection: 'column'
                                        }}>
                                            <span className={`fs12`}>
                                                {t('Available')}
                                            </span>
                                            <div className={`${s.balance}`}>
                                            <span className="df alc">
                                                <CustomIcon
                                                    width={24}
                                                    height={24}
                                                    imgName={coinObj[nftState?.coinType]?.iconName}
                                                    name={coinObj[nftState?.coinType]?.iconName}
                                                />
                                                <span className={`ml10 fs16 mr5`}>
                                                    {nftState?.coinType === 0 ? stringToNumber(lfgBalance, 4) : stringToNumber(balance, 4)}
                                                </span>
                                            </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={s.btn}>
                                        {nftState?.highestUserId !== userId && !isEnded &&
                                            <>{done ?
                                                <AntButton
                                                    onClick={() => {
                                                        !bidSuccess ? reset() : closeFn()
                                                    }}
                                                >
                                                    {bidSuccess ? t('BidSuccess') : noSuccessText}
                                                </AntButton> :
                                                <AntButton
                                                    className={`btn_public hasIconMargin`}
                                                    onClick={bidNft}>

                                                    {subLoading ? <div className="web3LoadingLine color-black">
                                                        <CustomIcon rotating={true} className="ml5" width={26}
                                                                    height={26}
                                                                    imgName={'Picture/UI_Picture_Loading_01'}
                                                                    name={'Picture/UI_Picture_Loading_01'}/>
                                                        <span className="fs14 ml5 color-black">{t('PROCESSING')}</span>
                                                    </div> : <div className="web3LoadingLine color-black">
                                                        {t('BidDetailPLACEBID')}
                                                        <CustomIcon width={26} height={26} className="ml5 mr5"
                                                                    imgName={coinObj[nftState?.coinType]?.iconName}
                                                                    name={coinObj[nftState?.coinType]?.iconName}/>
                                                        {stringToNumber(nftState?.nextPrice, 2)}
                                                        <span className={`${s.upToBtnSpan} color-blue`}>
                                                    {t('(Up to xx)', {count: Number(bidCountWithSpluppage.toFixed(2))})}
                                                </span>
                                                    </div>}
                                                </AntButton>}
                                            </>
                                        }

                                        {
                                            nftState?.highestUserId !== userId && !isEnded &&

                                            <div className={`${isAvailable ? "" : "color-red"} mt5`}>
                                                {isAvailable ?
                                                    t('You will get xx unit refund when outbid', {
                                                        returnAmount: stringToNumber(nftState?.nextPrice * 1.05, 2),
                                                        unit: coinObj[nftState?.coinType]?.unit
                                                    }) :
                                                    t('Insufficient balance or gas fee')}
                                            </div>
                                        }


                                        {nftState?.highestUserId === userId && isEnded && nftState?.canClaimed &&
                                            <>
                                                {done ?
                                                    <AntButton
                                                        className="btn_public"
                                                        onClick={() => {
                                                            !claimSucc ? reset() : closeFn()
                                                        }}
                                                    >
                                                        {claimSucc ? t('ClaimSuccess') : t('ClaimFailed')}
                                                    </AntButton> :
                                                    <AntButton className="btn_public" onClick={claimNft}>
                                                        {subLoading ? <div className="web3LoadingLine">
                                                            <CustomIcon rotating={true} className="ml5" width={16}
                                                                        height={16}
                                                                        imgName={'Picture/UI_Picture_Loading_01'}
                                                                        name={'Picture/UI_Picture_Loading_01'}/>
                                                            <span
                                                                className="fs14 ml5 color-black">{t('PROCESSING')}</span>
                                                        </div> : t('BidDetailCLAIMNFT')}
                                                    </AntButton>}
                                            </>
                                        }

                                        {nftState?.highestUserId !== userId && isEnded &&
                                            <AntButton className="btn_disabled">
                                                {t('BidDetailAUCTIONLOST')}
                                            </AntButton>
                                        }

                                        {nftState?.highestUserId === userId && !isEnded &&
                                            <AntButton className="btn_disabled hasIconMargin">
                                                <div className="web3LoadingLine">
                                                    {t('LEADING WITH BID')}
                                                    <CustomIcon width={26} height={26} className="ml5 mr5"
                                                                imgName={coinObj[nftState?.coinType]?.iconName}
                                                                name={coinObj[nftState?.coinType]?.iconName}/>
                                                    {stringToNumber(nftState?.highestPrice, 2)}
                                                </div>

                                            </AntButton>
                                        }

                                        {/* {done && bidSuccess && <AntButton className="btn_public" shape="round">
                                        {t('BidSuccess')}
                                    </AntButton>
                                } */}

                                    </div>
                                </div>
                            </div>
                    }
                </div>

            </div>
            <AntModal
                width='330px'
                title={t('SWAP $MATIC AND $LFG')}
                className="confirmModalWrap"
                centered={true}
                open={showSwapLfg}
                onOk={() => setShowSwapLfg(false)}
                onCancel={() => setShowSwapLfg(false)}
                destroyOnClose={true}
                zIndex={7777}
            >
                <ModalSwapLfg insufficientAmount={Number(nftState?.nextPrice)} userInfo={userInfo} closeFn={() => {
                    setShowSwapLfg(false);
                }}/>
            </AntModal>
            <AntModal
                width='330px'
                title={t('INSUFFICIENT BALANCE')}
                className="confirmModalWrap"
                centered={true}
                open={showInsufficientCrpto}
                onOk={() => setShowInsufficientCrpto(false)}
                onCancel={() => setShowInsufficientCrpto(false)}
                destroyOnClose={true}
                zIndex={7777}
            >
                <ModalInsufficientCrpto
                    insufficientAmount={Number((stringToNumber(nftState?.nextPrice, 2) - Number(balance)).toFixed(2))}
                    userInfo={userInfo} closeFn={() => {
                    setShowInsufficientCrpto(false);
                }}/>
            </AntModal>
        </>

    )
}

const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
        initTopUpAmount: app.initTopUpAmount,
        currentAuctionBatchCfg: app.currentAuctionBatchCfg,
        bidSlippage: app.bidSlippage,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setTranNowSubType: (params) => {
            return dispatch({
                type: "app/setTranNowSubType",
                payload: params,
            });
        },
        setTranNowType: (params) => {
            return dispatch({
                type: "app/setTranNowType",
                payload: params,
            });
        },
        setShowLink: (params) => {
            return dispatch({
                type: "app/setShowLink",
                payload: params
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(BidItemDetail));
