import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import s from "./index.module.less";
import { useTranslation, Trans } from 'react-i18next';
import CustomIcon from "@/common/CustomIcon";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
// import Banner from "../../../common/Banner";
import {
    stringToNumber,
    convertWalletBalance,
    sliceStr,
    getAvatarFromProfile,
    getTokenBalanceOf,
    copyFn,
    sliceAddr,
    logoutSelf,
} from "@/utils/common"
import {
    gameMemeClubChatPath,
} from "@/routes/config";
import MainHeader from "@/common/MainHeader";
import ButtonFactory from "@/common/ButtonFactory";
import AntModal from "@/common/AntModal";
import AntButton from "@/common/AntButton";
import AvatarInfo from "@/common/AvatarInfo";
import { Tabs } from "antd";
import Detail from "./Club/Detail";
import { memeClubInfo, getChainInfo, setFavorite } from "@/utils/memeClub";
import Activity from "./Club/Activity";
import Holders from "./Club/Holders";
import { AutoStrangeRule } from "@/utils/strangeRule";
import ModelTrans from "@/model/ModelTrans";
import ModalMemePackTrans from "@/model/ModalMemePackTrans";
import Active from "./TGE/Active";
import Info from "./TGE/Info";
import Trade from "./TGE/Trade";
import MemeClubPack from "@/common/MemeClubPack";
import NFTs from "@/views/games/MemeClub/ClubDetail/TGE/NFTs";
import { getBalance } from "@/utils/uniswapWeb3";
import { useWallets, usePrivy } from "@privy-io/react-auth";
import memeChainInfo from "@/utils/json/memeChainInfo.json";
import ModalWalletDisconnect from "@/model/ModalWalletDisconnect";
import Chat from "../Chat";
const ClubDetail = (
    {
        userInfo,
        walletBalanceList,
        setWalletBalanceList
    }
) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { clubId } = useParams();
    const { wallets } = useWallets();
    const { logout } = usePrivy()
    const [clubBasicInfo, setClubBasicInfo] = useState(null)
    const [showWalletDisconnect, setShowWalletDisconnect] = useState(false);
    const { walletAddr } = useMemo(() => {
        return userInfo || {}
    }, [userInfo]);
    const getMemeClubInfo = (clubId) => {
        memeClubInfo(clubId).then(res => {
            console.log(`getMemeClubInfo----->`, res);
            setClubBasicInfo(res)
        }).catch(e => {
            console.error(e);
        })
    }



    useEffect(() => {
        clubId && getMemeClubInfo(clubId)
    }, [clubId])


    const reFreshClubInfo = () => {
        clubId && getMemeClubInfo(clubId)
    }

    const {
        currentCardNum,
        clubInfo,
        historyPrice,
        holdNum,
        maxCardNum,
        sellPrice,
        buyPrice,
        isFavorite,
        inMarket,
        creatorProfile,
        creatorUserId,
        coinTokenAddr,
        onAlive,
        announceStartTime,
        announceTitle,
    } = useMemo(() => {
        return clubBasicInfo || {}
    }, [clubBasicInfo])

    const range = useMemo(() => {
        if (sellPrice && historyPrice) {
            return Math.round(((sellPrice || 0) - (historyPrice || 0)) / (historyPrice || 0) * 100)
        }
        return 0
    }, [sellPrice, historyPrice])

    const { clubName, imageUrl, coinId, symbolName, symbolImageUrl } = useMemo(() => {
        return clubInfo || {}
    }, [clubInfo])

    const chainInfo = useMemo(() => {
        let _ = {}
        _ = coinId && getChainInfo(coinId)
        return _
    }, [coinId])

    const [exploreScanUrl, holdScanUrl] = useMemo(() => {
        if (memeChainInfo && chainInfo?.chainId) {
            const chainInfoMatch = memeChainInfo.find(i => i?.chainId === chainInfo.chainId);
            return [chainInfoMatch?.exploreScanUrl, chainInfoMatch?.holdScanUrl];
        }
        return [undefined, undefined];
    }, [memeChainInfo, chainInfo]);


    const [coinBalance, setCoinBalance] = useState(0)

    const getWalletBalance = ({ walletAddr, chainInfo }) => {
        const { ID, isNative } = chainInfo
        let tokenList = [ID];
        if (isNative === 0) {
            tokenList.push(Math.round(Math.floor(ID / 100) * 100));
        }
        getTokenBalanceOf(walletAddr, tokenList).then(res => {
            let tempBalance = walletBalanceList || {};
            for (let i = 0; i < tokenList.length; i++) {
                tempBalance[tokenList[i]] = res?.balances?.[tokenList[i]];
            }
            setWalletBalanceList({
                ...tempBalance,
                ...res?.balances
            });
        }).catch(err => {
            console.error(err.message);
        })
    }

    const reFreshBalance = () => {
        /*const embeddedWallet = wallets.find((wallet) => (wallet.walletClientType === 'privy'));
        if (!embeddedWallet) {
            setShowWalletDisconnect(true);
            return;
        }*/
        getWalletBalance({ walletAddr, chainInfo })
        getBalance({ wallets, addr: coinTokenAddr, chainId: chainInfo?.chainId }).then(res => {
            setCoinBalance(res)
        }).catch(e => {
            console.error(e);
        })
    }

    useEffect(() => {
        if (!coinTokenAddr || !chainInfo) return
        reFreshBalance();
    }, [coinTokenAddr, chainInfo])

    const [showMemePackTrans, setShowMemePackTrans] = useState(false);
    const [memePackTransType, setMemePackTransType] = useState(1);
    const showTransModelFn = useCallback((type) => {
        setShowMemePackTrans(true);
        setMemePackTransType(type);
    }, [])

    const items = [
        {
            key: 4,
            label: `${AutoStrangeRule(t('Chat'))}`,
            children: <Chat
                showTransModelFn={showTransModelFn}
                clubId={clubId}
                clubBasicInfo={clubBasicInfo}
                chainInfo={chainInfo}
            ></Chat>,
            // children: <div>Chat</div>,
        },
        {
            key: 1,
            label: `${AutoStrangeRule(t('DETAILS'))}`,
            children: <Detail
                clubId={clubId}
                clubBasicInfo={clubBasicInfo}
                chainInfo={chainInfo}
                showTransModelFn={showTransModelFn}
            ></Detail>,
        }, {
            key: 2,
            label: `${AutoStrangeRule(t('Holders'))}`,
            children: <Holders clubId={clubId} clubBasicInfo={clubBasicInfo} chainInfo={chainInfo}></Holders>,
        }
    ]

    const items_tge = [
        {
            key: 4,
            label: `${AutoStrangeRule(t('Chat'))}`,
            children: <Chat
                showTransModelFn={showTransModelFn}
                clubId={clubId}
                clubBasicInfo={clubBasicInfo}
                chainInfo={chainInfo}
            ></Chat>,
            // children: <div>Chat</div>,
        },
        {
            key: 1,
            label: `${AutoStrangeRule(t('Trade'))}`,
            children: <Trade coinBalance={coinBalance} reFreshBalance={reFreshBalance} walletBalanceList={walletBalanceList} clubId={clubId} clubBasicInfo={clubBasicInfo} chainInfo={chainInfo}></Trade>,
        }
        , {
            key: 2,
            label: `${AutoStrangeRule(t('NFTs'))}`,
            children: <NFTs
                clubBasicInfo={clubBasicInfo}
                coinBalance={coinBalance}>
            </NFTs>,
        }
        /*, {
            key: 2,
            label: `${AutoStrangeRule(t('Info'))}`,
            children: <Info clubId={clubId} clubBasicInfo={clubBasicInfo} chainInfo={chainInfo}></Info>,
        }, {
            key: 3,
            label: `${AutoStrangeRule(t('Active'))}`,
            children: <Active clubId={clubId} chainInfo={chainInfo}></Active>
        },*/
    ]

    const tradeMemeClubInfo = useMemo(() => {
        return {
            userId: clubBasicInfo?.clubUserId,
            clubName: clubBasicInfo?.clubInfo?.clubName,
            coinId: clubBasicInfo?.clubInfo?.coinId,
            currentCardNum: clubBasicInfo?.currentCardNum,
            maxCardNum: clubBasicInfo?.maxCardNum,
            holdNum: clubBasicInfo?.holdNum,
            imageUrl: clubBasicInfo?.clubInfo?.imageUrl,
            symbolName: clubBasicInfo?.clubInfo?.symbolName,
            ftTotalSupply: clubBasicInfo?.clubInfo?.ftTotalSupply,
            airdropPercent: clubBasicInfo?.clubInfo?.airdropPercent,
            openPackRatio: clubBasicInfo?.clubInfo?.openPackRatio,
            coinToNFTRatio: clubBasicInfo?.clubInfo?.coinToNFTRatio,
            isFT: clubBasicInfo?.clubInfo?.isFT,
            symbolImageUrl: clubBasicInfo?.clubInfo?.symbolImageUrl,
        }
    }, [clubBasicInfo])

    const [rotating, setRotating] = useState(false)

    const favoriteFn = (userId, favorite) => {
        setFavorite(userId, favorite).then(res => {
            reFreshClubInfo()
        }).catch(e => {
            console.error(e);
        })
    }
    const handleFavoriteClick = () => {
        setRotating(true);
        setTimeout(() => {
            setRotating(false);
        }, 500);
        favoriteFn(clubId, !isFavorite);
    };

    const linkToChat = () => {
        navigate(`${gameMemeClubChatPath}/${clubId}`);
    }

    const [activekey, setAcicekey] = useState(4)
    const [headerTitle, setHeaderTitle] = useState(t('Chat'))

    return (
        <div className={`${s.modalWrap} ${inMarket || activekey === 4 ? "" : 'mainMemeDetailWrap'}`}>
            <MainHeader headerType={1} isGameHeader={true} hasRightMenu={false} hasBackBtn={true} rightMenuIcon={"Button/UI_Button-D_Home_On"} title={headerTitle}></MainHeader>
            <div className={s.modal}>
                <div className={s.head}>
                    {!inMarket ?
                        <div className={s.hl}>
                            <div className={s.avatar}>
                                <MemeClubPack width={44} height={70}
                                    imageUrl={imageUrl}
                                    packName={symbolName} >
                                </MemeClubPack>
                            </div>
                        </div>
                        :
                        <div className={s.hl}>
                            <img src={symbolImageUrl} className="" width="46" height="46" style={{ borderRadius: '8px' }}></img>
                            <div className="fb">${symbolName}</div>
                        </div>
                    }
                    {!inMarket ?
                        <div className={s.hr}>
                            <div className={s.hrt}>
                                <div className={s.hrl}>
                                    <div className="flex_center_start">
                                        {sliceStr(clubName, 15, true)}
                                        <CustomIcon imgName={`Picture/${chainInfo?.icon}`} className="ml5" width={16} height={16}></CustomIcon>
                                    </div>
                                    <div className="flex_center_start mt5 mb5">
                                        <Trans
                                            i18nKey="You own num card pack/card packs"
                                            defaults="You own <active> {{count}} {{sth}}</active>"
                                            values={{
                                                count: holdNum,
                                                sth: holdNum > 1 ? t('card pack') : t('card packs')
                                            }}
                                            components={{ active: <span style={{ color: 'var(--TP-yellow)', padding: '0 5px' }}> </span> }}
                                        />
                                    </div>
                                </div>
                                <div className={s.hrr}>
                                    <div className="">{convertWalletBalance(buyPrice)} {chainInfo?.displayCoinName}</div>
                                    <div className="mt5 mb5 flex_center_center">
                                        <CustomIcon imgName={range < 0 ? 'Picture/UI_Picture_PriceDown_01' : 'Picture/UI_Picture_PriceUP_01'} className="mr5" width={28} height={15}></CustomIcon>
                                        <span className={range < 0 ? 'color-red fs12' : 'color-green fs12'}>{range}%</span>
                                    </div>
                                    {/* <div className={s.btn}>
                                        <AntButton className="btn_white">
                                            {t('CHAT')}
                                        </AntButton>
                                    </div> */}
                                </div>
                            </div>
                            <div className={s.progress}>
                                <div className={s.proWrap}>
                                    <div className="mr5">{t('meme details Progress')}</div>
                                    <div className={s.l}>
                                        <div className={s.proVal} style={{
                                            width: `${Math.round(currentCardNum / maxCardNum * 100)}%`
                                        }}>

                                        </div>
                                        <div className={s.val}>{`${currentCardNum}/${maxCardNum}`}</div>
                                    </div>

                                </div>
                            </div>
                        </div> :
                        <div className={s.hr_tge}>
                            <div className={s.hLine}>
                                <div className="flex_center_start">
                                    {sliceStr(clubName, 15, true)}
                                    <CustomIcon imgName={`Picture/${chainInfo?.icon}`} className="ml5" width={16} height={16}></CustomIcon>
                                </div>
                                <div className="flex_center_start">
                                    <div className={s.tag}>
                                        {t('TGE')}
                                    </div>
                                    <CustomIcon
                                        onClick={() => handleFavoriteClick()}
                                        imgName={isFavorite ? 'Picture/UI_Picture_Collect_01' : 'Picture/UI_Picture_Uncollect_01'}
                                        className={`ml5 ${rotating ? 'rotate' : ''}`} width={20} height={20}></CustomIcon>
                                    {/* <CustomIcon imgName={isFavorite ? 'Picture/UI_Picture_Collect_01' : 'Picture/UI_Picture_Uncollect_01'} className="ml5" width={22} height={22}></CustomIcon> */}
                                </div>
                            </div>
                            <div className={s.hLine}>
                                <div className="flex_center_start fs12">
                                    <span className="color-blue-light fs12">{t('Created by')}</span>
                                    <AvatarInfo className="ml5 mr5" size={16} src={getAvatarFromProfile(creatorProfile)}></AvatarInfo>
                                    {sliceStr(creatorProfile?.displayName, 'all')}
                                </div>
                                <div className="flex_center_start fs12">
                                    <span className="color-blue-light fs12 mr5">{t('Your Balance')}</span>
                                    {convertWalletBalance(coinBalance)}
                                </div>
                            </div>
                            <div className={`${s.hBtnLine} mt10`}>
                                <div className={`${s.copyBtn} flex_center_center`}>
                                    <CustomIcon className="mr5" width={12} height={12}
                                        onClick={() => copyFn(coinTokenAddr)}
                                        imgName={'Picture/UI_Picture_Icon_Copy_01'} />
                                    <div className={``} style={{ fontSize: '10px', fontWeight: 'bold' }}>
                                        {sliceAddr(coinTokenAddr, 6)}
                                    </div>
                                </div>
                                <div className={`${s.linkBtn} flex_center_center ml10`}
                                    onClick={() => {
                                        window.location.href = `${exploreScanUrl}${coinTokenAddr}`
                                    }}>
                                    <div className={s.link}>
                                        <span className="fs10">{t('EXP')}</span>
                                        <CustomIcon imgName={`Picture/UI_Picture_Icon_Share_01`} className="ml2 mr2" width={12} height={12}></CustomIcon>
                                    </div>
                                </div>
                                <div className={`${s.linkBtn} flex_center_center`}
                                    onClick={() => {
                                        window.location.href = `${holdScanUrl}${coinTokenAddr}`
                                    }}>
                                    <div className={s.link}>
                                        <span className="fs10">{t('HLD')}</span>
                                        <CustomIcon imgName={`Picture/UI_Picture_Icon_Share_01`} className="ml2 mr2" width={12} height={12}></CustomIcon>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                </div>
                {/* <div className={`${s.chatAbbreviation} flex_center_start_col`}>
                    <div className="mt25">
                    </div>
                    <div className={`${s.chatLine} flex_center_between`}>
                        <div className={`flex_center_start`}>
                            <CustomIcon imgName={`Picture/UI_Picture_Icon_Chat_01`} className="" width={21} height={22}></CustomIcon>
                            <div className={`${s.chatTitle}`}>
                                {t('Let\'s Meme and Mingle!')}
                            </div>
                        </div>
                        <div className={`flex_center_end`}>
                            {
                                onAlive ?
                                    <div className={`${s.goLiveBtn} flex_center_center`} onClick={() => {
                                        linkToChat();
                                    }}>
                                        <CustomIcon imgName={`Picture/UI_Picture_Icon_Live_01`} className="mr10" width={18} height={18}></CustomIcon>
                                        <span className={`fs14 fb`}>
                                            {t('Live')}
                                        </span>
                                    </div> :
                                    <div className={`${s.chatBtn} flex_center_center`} onClick={() => {
                                        linkToChat();
                                    }}>
                                        <span className={`fs14 fb color-black`}>
                                            {t('To Chat')}
                                        </span>
                                    </div>
                            }
                            {
                                (!onAlive && announceStartTime > 0) &&
                                <div className={`${s.scheduledPart} flex_center_center_col`}>
                                    <CustomIcon imgName={`Picture/UI_Picture_Icon_Live_01`} className="" width={12} height={12}></CustomIcon>
                                    <span className={`fs10`}>
                                        {t('Scheduled')}
                                    </span>
                                </div>
                            }
                        </div>
                    </div>
                </div> */}
                <div className={s.content}>
                    <div className={s.wrap}>
                        {
                            !inMarket ?
                                <Tabs
                                    defaultActiveKey={4}
                                    items={items}
                                    className="tptabs tpPlr15"
                                    onChange={activeKey => {
                                        setAcicekey(activeKey)
                                        switch (activeKey) {
                                            case 1:
                                                setHeaderTitle(AutoStrangeRule(t('DETAILS'))) 
                                                break;
                                            case 2:
                                                setHeaderTitle(AutoStrangeRule(t('Holders')))
                                                break;
                                            case 4:
                                                setHeaderTitle(AutoStrangeRule(t('Chat')))
                                                break;
                                            default:
                                                break;
                                        }
                                    }}
                                    destroyInactiveTabPane={true}>
                                </Tabs> :
                                <Tabs
                                    defaultActiveKey={4}
                                    items={items_tge}
                                    className="tptabs tpPlr15"
                                    onChange={activeKey => {
                                        switch (activeKey) {
                                            case 1:
                                                setHeaderTitle(AutoStrangeRule(t('Trade'))) 
                                                break;
                                            case 2:
                                                setHeaderTitle(AutoStrangeRule(t('NFTs')))
                                                break;
                                            case 4:
                                                setHeaderTitle(AutoStrangeRule(t('Chat')))
                                                break;
                                            default:
                                                break;
                                        }
                                    }}
                                    destroyInactiveTabPane={true}>
                                </Tabs>
                        }
                    </div>
                </div>
            </div>
            {(!inMarket && activekey !== 4) &&
                <div className={`${s.bottomWrap} ${holdNum ? s.hasKey : ''}`}>
                    {!holdNum ?
                        <>
                            <div className="df aic jcc mb20">
                                <Trans
                                    i18nKey="You need a xx to access"
                                    defaults="You need a <coinImg></coinImg> to access"
                                    values={{ what: 'world' }}
                                    components={{
                                        coinImg: <CustomIcon className="ml5 mr5" width={16} height={16}
                                            imgName={'Picture/UI_Picture_Cardpack_01'}
                                            name={'Picture/UI_Picture_Cardpack_01'} />
                                    }}
                                />
                            </div>
                            <AntButton className="fs16 btn_public" onClick={() => {
                                showTransModelFn(1)

                            }} style={{ margin: '0 auto' }}>{t('BUY NOW')}</AntButton>
                        </> :
                        <>
                            <div className="color-blue-light fb">{t('You Own')}</div>
                            <div className="df aic jc-spaceBetween mr20 ml20">
                                <ButtonFactory
                                    size={32} //1:L, 2:M, 3:S, 11:L1, 21:M1, 22:M2, 23:M3, 31:S1, 32:S2, 33:S3
                                    scale={1.6}
                                    isMirror={true}
                                    color={3}
                                    onClick={() => holdNum && showTransModelFn(2)}>
                                    {t('SELL')}
                                </ButtonFactory>
                                <div className={s.keyNumber}><CustomIcon className="ml5 mr5" width={50} height={50}
                                    imgName={'Picture/UI_Picture_Cardpack_01'}
                                />x {holdNum}</div>
                                <ButtonFactory
                                    size={32} //1:L, 2:M, 3:S, 11:L1, 21:M1, 22:M2, 23:M3, 31:S1, 32:S2, 33:S3
                                    scale={1.6}
                                    onClick={() => showTransModelFn(1)}>
                                    {t('BUY')}
                                </ButtonFactory>
                            </div>
                        </>
                    }
                </div>
            }

            <AntModal
                width='330px'
                title=''
                className="confirmModalWrap transModal"
                centered={true}
                open={showMemePackTrans}
                destroyOnClose={true}
                zIndex={4000}
                onOk={() => setShowMemePackTrans(false)}
                onCancel={() => setShowMemePackTrans(false)}
            >
                <ModalMemePackTrans
                    type={memePackTransType}
                    tradeMemeClubInfo={tradeMemeClubInfo}
                    reFreshClubInfo={reFreshClubInfo}
                    closeHandle={() => {
                        setShowMemePackTrans(false);
                    }}
                />
            </AntModal>

            <AntModal
                width='330px'
                title={t('Notice')}
                className="confirmModalWrap transModal"
                centered={true}
                open={showWalletDisconnect}
                destroyOnClose={true}
                zIndex={4000}
                onOk={() => logoutSelf()}
                onCancel={() => logoutSelf()}
            >
                <ModalWalletDisconnect />
            </AntModal>
        </div>

    )
}

const mapStateToProps = ({ app }) => {
    return {
        userInfo: app.userInfo,
        defaultInviteCode: app.defaultInviteCode,
        walletBalanceList: app.walletBalanceList
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setWalletBalanceList: (params) => {
            return dispatch({
                type: "app/setWalletBalanceList",
                payload: params,
            });
        }
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(ClubDetail));
