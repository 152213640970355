import http from "@/utils/axios";
import {url} from "@/utils/configUri";
import moment from "moment";
import {message} from "antd";
import WebIM from "./WebIM";
import store from "@/store";
import {
    envConfig, 
    localurl, 
    tweetRedirectUrl, 
    version, 
    isOnlineEnv,
    PolygonRPC, 
    PolygonAmoyRPC, 
    BaseRPC,
    BaseSepoliaRPC,
    PolygonChainId,
    AmoyChainId,
    MoveE
} from "./env"
import {ethers} from "ethers";
import {abi_trand} from "@/utils/abi";
// import defaultAvatar from "@/assets/images/avatar_default.png";
import defaultAvatar from "@/assets/images/avatar_default.png";
import {redirectDocument} from "react-router-dom";
import errorCode from "./json/ErrorCode.json";
import displayList from "@/utils/json/Wordsfliter";
import BPLevelConfig from '@/utils/json/BPLevelConfig.json';
import NFTRarityInfo from "@/utils/json/NFTRarityInfo.json";
import {floor, isNumber} from "mathjs";
import {publicChatsPath, publicWishesPath} from "@/routes/config";
import {getGasConfig} from "@/utils/gas";
import MemeChainToken from "@/utils/json/memeChainToken.json";
import {PrivyProvider, addRpcUrlOverrideToChain } from '@privy-io/react-auth';
import {polygon, polygonAmoy, baseSepolia, base } from 'viem/chains';
import {movementTestnet} from "./movementTestnet.ts";
const {dispatch, getState} = store;

const officialInviteCode = 'turnup.so/@turnupdotxyz';
const unit = '$MATIC';
const LFG = '$LFG';
// console.log(object);
const joinChartPre = "@";
const deeplink = 'deeplink=true';
const chainName = "BSC";
const timerDelay = 30000;
const checkDelay = 300; //延时检查Token是否过期，单位：秒
const siteName = 'TURNUP';
const SCROLL_WARP_ID = "scrollMsgWrapId";
const LIVE_WARP_ID = "liveMsgWrapId";
const RANK_WARP_ID = 'rankWrapId';
const CHAT_TYPE = {
    single: "singleChat",
    groupChat: "groupChat",
    chatRoom: "chatRoom",
};
const ERROR_CODE = {
    noAuth: "token not assign error",
    loginFailed: "login failed",
    notLogin: "not login",
    recallTimeout: "exceed recall time limit",
    userNotFound: "user not found",
    invalidPassword: "invalid password",
    registerUnique: "duplicate_unique_property_exists",
    sendMsgBlock: "blocked",
    muted: "you were muted",
    trafficLimit: "traffic limit",
    notInRoom: 'not in group or chatroom',
};

const PLATFORM_DATA = {
    1: {
        iconName: 'Button/UI_Button_Ball-White_Twitter_On',
        label: "X(Twitter)",
    },
    2: {
        iconName: 'tiktokMaskImage',
        label: "TikTok",
    },
}

const baseChain = (BaseRPC && BaseRPC.length > 5) ? addRpcUrlOverrideToChain(base, BaseRPC) : base;
const polygonChain      = (PolygonRPC && PolygonRPC.length > 5)         ? addRpcUrlOverrideToChain(polygon, PolygonRPC)         : polygon;
//const baseSepoliaChain = baseSepolia;
const baseSepoliaChain = (BaseSepoliaRPC && BaseSepoliaRPC.length > 5) ? addRpcUrlOverrideToChain(baseSepolia, BaseSepoliaRPC) : baseSepolia;
const polygonAmoyChain  = (PolygonAmoyRPC && PolygonAmoyRPC.length > 5) ? addRpcUrlOverrideToChain(polygonAmoy, PolygonAmoyRPC) : polygonAmoy;
//const polygonAmoyChain = polygonAmoy;

const moveEVMChain = movementTestnet;

const GetSupportedChainList = () =>{
    return [moveEVMChain,polygonChain, polygonAmoyChain, baseChain, baseSepoliaChain];
}

const GetSupportedMaticChainTokenInfo = () =>{
    let info = null;
    if (MemeChainToken?.length > 0){
        for (let i=0;i<MemeChainToken?.length;i++){
            if (isOnlineEnv() && MemeChainToken[i].chainId === PolygonChainId && MemeChainToken[i].isNative){
                info = MemeChainToken[i];
                break;
            }
            else if (!isOnlineEnv() && MemeChainToken[i].chainId === AmoyChainId && MemeChainToken[i].isNative){
                info = MemeChainToken[i];
                break;
            }
        }
    }
    return info;
}

const chainOpenseaUrl = {
    137: "https://opensea.io/assets/matic/",//Polygon
    80002: "https://testnets.opensea.io/assets/amoy/",//Amoy
    84532: "https://testnets.opensea.io/assets/base-sepolia/",//Base Sepolia
    8453: "https://opensea.io/assets/base/",//Base Mainnet
}

const PAGE_SIZE = 50;
const MIN_TXT_AMOUNT = 20;
const MESSAGE_ITEM_SOURCE = {
    single: "singleChat",
    groupChat: "groupChat",
    threadChat: "threadChat",
    threadParentMsg: "threadParentMsg"
};


const depositUrl = envConfig?.depositUrl;
const therdConfig = {
    google: {
        baseurl: 'https://accounts.google.com/o/oauth2/v2/auth?',
        scope: 'openid email profile',
        include_granted_scopes: 'true',
        response_type: 'code',
        state: "state_parameter_passthrough_value",
        redirect_uri: window.location.origin + '/',
        client_id: '183907759337-oqki6f0sd5s6a646qudiimb5vpjk8pmj.apps.googleusercontent.com',
    },
    tweet: {
        baseurl: 'https://twitter.com/i/oauth2/authorize?',
        outh1Url: 'https://api.twitter.com/oauth/authorize?',
        scope: 'tweet.read%20users.read%20offline.access',
        code_challenge_method: 'plain',
        code_challenge: '',
        response_type: 'code',
        // state: tweetState,
        redirect_uri: tweetRedirectUrl,
        // redirect_uri: 'http://127.0.0.1' + tweetRedirectUrl,
        client_id: envConfig?.tweetClientId,
    }
}
const googleUrl = `${therdConfig.google.baseurl}scope=${therdConfig.google.scope}&include_granted_scopes=${therdConfig.google.include_granted_scopes}
&response_type=${therdConfig.google.response_type}&state=${therdConfig.google.state}&redirect_uri=${therdConfig.google.redirect_uri}&client_id=${therdConfig.google.client_id}`;

const maticIconUrl = "Picture/UI_Picture-Currency_MATIC_01";
const lfgIconUrl = "Picture/UI_Picture-Currency_LFG_01";

const getBindTweetUrl = (code_challenge) => {

    const {tweet} = therdConfig;
    const token = JSON.parse(localStorage.getItem('_TT_token'))?.token;
    const tweetState = localurl + '$' + tweetRedirectUrl + '$' + token;
    return `${tweet.baseurl}response_type=${tweet.response_type}&redirect_uri=${tweet.redirect_uri}&scope=${tweet.scope}&code_challenge=${code_challenge}&code_challenge_method=${tweet.code_challenge_method}&state=${tweetState}&client_id=${tweet.client_id}`
}

const getTweetOuth1aUrl = (oauth_token) => {
    const {tweet} = therdConfig;
    return `${tweet.outh1Url}oauth_token=${oauth_token}`
}

const toAsyncAwait = (promise, fromatResult = true) => {
    if (!fromatResult) {
        return promise;
    } else {
        return promise.then((res) => ({error: null, result: res})).catch((err) => ({error: err, result: null}));
    }
}

function getUrlParams(key) {
    let urlStr = window.location.href.split('?')[1];
    if (!urlStr) {
        return ''
    }
    let obj = {};
    let paramsArr = urlStr.split('&')
    for (let i = 0, len = paramsArr.length; i < len; i++) {
        let arr = paramsArr[i].split('=')
        obj[arr[0]] = arr[1];
    }
    return obj[key] || ''
}

const sliceStr = (str, num, ignoreTail) => {
    str = displayFilter(str)
    if (str) {
        if (str.length < 8) {
            return str
        }
        if (num === 'all') {
            return str.slice(0, 8) + '…'
        }
        if (isNumber(num) && str.length < Number(num)) {
            return str
        }
        if (ignoreTail) {
            return str.slice(0, num) + '…'
        }
        if (num < 6) {
            return str.slice(0, 5) + '…'
        } else if (num < 8) {
            return str.slice(0, 6) + '…' + str.slice(-num)
        }
        return str.slice(0, 6) + '…' + str.slice(-(num - 6))
    }
    return ''
}

const sliceAddr = (str) => {
    str = displayFilter(str)
    if (str) {
        return str.slice(0, 6) + '…' + str.slice(-4)
    }
    return ''
}

const getTokenBalanceOf = (walletAddr, tokens) =>{
    return new Promise((resolve, reject) => {
        http('post', url.balanceOf, {
            address: walletAddr,
            tokens: tokens
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data);
            } else {
                reject(res);
            }
        }).catch(e => {
            reject(e);
        });
    })
}

const updateUserInfo = (userInfoObj) => {
    dispatch.app.setUserInfo(userInfoObj);
}

const getPrice = async (userId) => {
    if (!userId) {
        return
    }
    const {result: buyPriceObj} = await toAsyncAwait(gettrade_price(1, userId));
    const {result: sellPriceObj} = await toAsyncAwait(gettrade_price(2, userId));
    updateCurentOtherUserInfo({
        buyPriceObj,
        sellPriceObj
    })
}

const updateSelfUser = async () => {
    const {userId} = getState().app.userInfo;
    const {selfData} = await getuserinfo(userId);
    updateUserInfo({
        balance: selfData?.balance
    })
}

const updateOtherUser = async (userId) => {
    console.log(userId);
    const otherUserInfo = await getuserinfo(userId);
    console.log(`otherUserInfo-----`, otherUserInfo);
    updateCurentOtherUserInfo(otherUserInfo)
}

const updateCurentOtherUserInfo = (userInfoObj) => {
    dispatch.app.setCurentOtherUserInfo({
        ...getState().app.curentOtherUserInfo,
        ...userInfoObj
    })
}

const getConfirmModalConf = (
    {
        title = "",
        content = "",
        okText = "",
        cancelText = "",
        onOk = () => {
        },
        onCancel = () => {
        },
        closable = true,
        centered = true,
        addcancelButtonClass = '',
        maskClosable = true,
    }
) => {
    return {
        className: "confirmModalWrap",
        width: '80%',
        title,
        okText,
        closable,
        centered,
        cancelText,
        keyboard: true,
        autoFocusButton: null,
        maskClosable,
        closeIcon: <div style={{fontSize: "30px", color: "#fff"}}>X</div>,
        cancelButtonProps: {
            className: "circleBtnGray " + addcancelButtonClass
        },
        okButtonProps: {
            className: "circleBtn"
        },
        icon: null,
        content,
        onOk: () => {
            onOk();
        },
        onCancel: () => {
            onCancel();
        }
    };
};
const loginFn = (account, type = 0, param = '') => {

    return new Promise((resolve, reject) => {
        http('post', url.auth, {
            account,
            type,
            param,
        }).then((res) => {
            if (res.code === 0) {
                resolve(res)
            } else {
                // message.error({ content: `get userInfo failed` })
                reject('')
            }
        }).catch(e => {
            // message.error({ content: `get userInfo failed` })
            reject(e)
        });
    })

}

const checkcodeFn = (inviteCode) => {

    return new Promise((resolve, reject) => {
        http('post', url.checkcode, {
            inviteCode
        }).then((res) => {
            if (res.code === 0) {
                resolve(res)
            } else {
                // message.error({ content: `checkcode failed` })
                reject('checkcode failed`')
            }
        }).catch(e => {
            // message.error({ content: `checkcode failed` })
            reject(e)
        });
    })

}

const getcodestate = () => {
    return new Promise((resolve, reject) => {
        http('post', url.getcodestate, {}).then((res) => {
            if (res.code === 0) {
                resolve(res)
            } else {
                // message.error({ content: `getcodestate failed` })
                reject('getcodestate failed`')
            }
        }).catch(e => {
            // message.error({ content: `getcodestate failed` })
            reject(e)
        });
    })
}

const getTranList = (userId, labelType, cursor = 0) => {
    return new Promise((resolve, reject) => {
        http('post', url.getkeyinfo, {
            userId,
            labelType,
            cursor,
        }).then((res) => {
            if (res.code === 0) {
                resolve(res)
            } else {
                // message.error({ content: `getTranList failed` })
                reject('getTranList failed`')
            }
        }).catch(e => {
            // message.error({ content: `getTranList failed` })
            reject(e)
        });
    })
}

//不使用当前文本，使用i18n: TransactionError_{id}
export const TransactionError = {
    '0xf6b2911f': 'Invalid wallet address',                    //   error InvalidZeroAddress();
    '0x012dcc29': 'Error, this Wish already exists',                          //   error ExistingWish(address wisher);
    '0xc074f594': 'Error, this Wish already bound',                      //   error WishAlreadyBound(address wisher);
    '0x659df8e4': 'Error, this Wish does not exist',                          //   error WishNotFound();
    '0x5214d7e8': 'Unable to claim',              //   error ClaimRewardShouldBeFalse();
    '0x1ab0efa8': 'Transaction Failed Due to Price Fluctuations',           //   error TransactionFailedDueToPrice();
    '0x12a07dab': 'Only the keys\' owner can buy the first key',           //   error OnlyKeysOwnerCanBuyFirstKey();
    '0x9102ee37': 'Transaction Failed Due to Wish bound',                //   error BoundCannotBeBuyOrSell();
    '0x2c5211c6': 'Invalid amount',                         //   error InvalidAmount();
    '0xaffb7bfb': 'Insufficient keys',                      //   error InsufficientKeys(uint256 balance);
    '0x05c6ac37': 'You cannot sell your last key',                     //   error CannotSellLastKey();
    '0x9f69b1d2': 'Error, Protocol Fee Destination error',          //   error ProtocolFeeDestinationNotSet();
    '0x853bfc07': 'Error, Protocol Fee Percent error',              //   error ProtocolFeePercentNotSet();
    '0xd0430e1b': 'Error, Subject Fee Percent error',               //   error SubjectFeePercentNotSet();
    '0x89f9d50e': 'Error, Subject Does Not Match',                   //   error SubjectDoesNotMatch(address subject);
    '0xa7777a3d': 'Insufficient funds',                     //   error UnableToSendFunds();
    '0xb44b5b25': 'Unable to claim Reward',                   //   error UnableToClaimReward();
    '0x62e5ad84': 'Reserve Quantity Too Large',               //   error ReserveQuantityTooLarge();
    '0x49986e73': 'Error, please set a valid amount',                           //   error WrongAmount();
    '0x7fbb41b0': 'Error, Zero Reserved Quantity',                  //   error ZeroReservedQuantity();
    '0x0d2e8472': 'Error, Zero Reserved Wish',                      //   error ZeroReservedWish();
    '0x9ad58f15': 'Invalid Wish address, unable to purchase',                           //   error InvalidWish(address wisher);
    '0x47fac6c1': 'Error, you are not the operator',                        //   error NotTheOperator();
    '0x9561827d': 'Error, operator not set',                        //   error OperatorNotSet();
    '0x71ccbc72': 'Insufficient keys/wishes',                       //   error ExcessiveAmount(uint256 supply, uint256 amount);
    '0xfa007570': 'InvalidDailyMintedAmounts',   					//   error InvalidDailyMintedAmounts();
    '0xfe62b21a': 'PendingRequest',   					//   error PendingRequest();
    '0x900bb2c9': 'SignatureAlreadyUsed',   					//   error SignatureAlreadyUsed();
    '0x0819bdcd': 'SignatureExpired',   					//   error SignatureExpired();
    '0x5632045d': 'CapReachedForTurnUp',   					//   error CapReachedForTurnUp();
    '0xb4760d19': 'AmountExceedsStake',   					//   error AmountExceedsStake();
    '0x3e8b8db2': 'LockShouldBeInTheFuture',   					//   error LockShouldBeInTheFuture();
    '0xe805e5bb': 'InvalidNewLock',   					//   error InvalidNewLock();
    '0x593eb11d': 'MaxLockPeriodIs365Days',   					//   error MaxLockPeriodIs365Days();
    '0xb9e6bca9': 'InvalidMinLockTime',   					//   error InvalidMinLockTime();
    '0x1f2a2005': 'ZeroAmount',   					//   error ZeroAmount();
    '0xcd12c24c': 'InvalidLockInternal',   					//   error InvalidLockInternal();
    '0xabf20e8f': 'InvalidLockTime',   					//   error InvalidLockTime();
    '0x2160b356': 'PriceChanged',   					//   error InvalidLockTime();
    '0x5f0ae8b5': 'AuctionIsOver',   					//   error InvalidLockTime();
    '0x356680b7': 'InsufficientFunds',   					//   error InvalidLockTime();
    '0x13be252b': 'InsufficientAllowance',   					//   error InvalidLockTime();


}

export const PrivyLoginStatusError = 'Please relogin first'

const keyWishClaimBuy = (wallets, keyId, keyAddr, amount, price) => {
    console.log(keyId, amount);
    return new Promise(async (resolve, reject) => {
        console.log(keyId, keyAddr, amount, price);

        http('post', url.key_pretrade, {
            keyId
        }).then(async (res) => {
            try {
                const embeddedWallet = wallets.find((wallet) => (wallet.walletClientType === 'privy'));

                const {contractAddr, chainId} = await getConfig();

                await embeddedWallet.switchChain(chainId);
                console.log("contractAddr: " + contractAddr, "chainId: " + chainId);

                console.log(embeddedWallet);

                const provider = await embeddedWallet.getEthersProvider();

                const signer = provider.getSigner(); // ethers signer object

                const gasData = await getGasConfig(signer);

                const contract = new ethers.Contract(contractAddr, abi_trand, provider).connect(signer);
                contract.populateTransaction.claimReservedWishPass({
                    ...gasData,
                    value: ethers.utils.parseEther(price),
                }).then(unsignedTx => {
                    signer.sendTransaction(unsignedTx).then(
                        resp => resolve(resp)
                    ).catch(
                        e => reject(e)
                    );

                }).catch(e => {
                    console.log("buyWishClaim e:" + e);
                    reject(e);
                });
            } catch (e) {
                console.log("buyWishClaim e:" + e)
                if (e.message.indexOf('(reading \'switchChain\')') > 0) {
                    console.log("privy need login")
                    reject('need login');
                } else {
                    reject(e);
                }
            }
        }).catch(e => {
            // message.error({ content: `keyBuy key_pretrade failed` });
            reject(e);
        });
    })
}

const keyBuy = (wallets, keyId, keyAddr, amount, price) => {
    console.log(keyId, amount);
    return new Promise(async (resolve, reject) => {
        console.log(keyId, keyAddr, amount, price);

        http('post', url.key_pretrade, {
            keyId
        }).then(async (res) => {
            try {
                const embeddedWallet = wallets.find((wallet) => (wallet.walletClientType === 'privy'));

                const {contractAddr, chainId} = await getConfig();

                await embeddedWallet.switchChain(chainId);
                console.log("contractAddr: " + contractAddr, "chainId: " + chainId);

                console.log(embeddedWallet);

                const provider = await embeddedWallet.getEthersProvider();

                const signer = provider.getSigner(); // ethers signer object

                const gasData = await getGasConfig(signer);

                const contract = new ethers.Contract(contractAddr, abi_trand, provider).connect(signer);
                contract.populateTransaction.buyShares(keyAddr, amount, {
                    ...gasData,
                    value: ethers.utils.parseEther(price),
                }).then(unsignedTx => {
                    signer.sendTransaction(unsignedTx).then(
                        resp => resolve(resp)
                    ).catch(
                        e => reject(e)
                    );

                }).catch(e => {
                    console.log("buyShares e:" + e);
                    reject(e);
                });
            } catch (e) {
                console.log("buyShares e:" + e);
                if (e.message.indexOf('(reading \'switchChain\')') > 0) {
                    console.log("privy need login")
                    reject('need login');
                } else {
                    reject(e);
                }
            }
        }).catch(e => {
            // message.error({ content: `keyBuy key_pretrade failed` });
            reject(e);
        });
    })
}

const keyBatchBuy = (wallets, keyIdList, keyAddrList, amountList, price, expectedPricesList) => {
    console.log(keyIdList, amountList);

    return new Promise(async (resolve, reject) => {
        expectedPricesList = expectedPricesList.map(v => ethers.utils.parseEther(v))
        console.log(keyIdList, keyAddrList, amountList, price, expectedPricesList);
        http('post', url.key_mulpretrade, {
            keyIds: keyIdList,
        }).then(async (res) => {
            try {
                const embeddedWallet = wallets.find((wallet) => (wallet.walletClientType === 'privy'));

                const {contractAddr, chainId} = await getConfig();

                await embeddedWallet.switchChain(chainId);
                console.log("contractAddr: " + contractAddr, "chainId: " + chainId);

                console.log(embeddedWallet);

                const provider = await embeddedWallet.getEthersProvider();

                const signer = provider.getSigner(); // ethers signer object

                const gasData = await getGasConfig(signer);

                const contract = new ethers.Contract(contractAddr, abi_trand, provider).connect(signer);

                console.info(keyAddrList, amountList, expectedPricesList);
                contract.populateTransaction.batchBuyShares(keyAddrList, amountList, expectedPricesList, {
                    ...gasData,
                    value: ethers.utils.parseEther(price),
                }).then(unsignedTx => {
                    signer.sendTransaction(unsignedTx).then(
                        resp => resolve(resp)
                    ).catch(
                        e => reject(e)
                    );

                }).catch(e => {
                    console.log("keyBatchBuy e:" + e);
                    reject(e);
                });
            } catch (e) {
                console.log("keyBatchBuy e:" + e);
                if (e.message.indexOf('(reading \'switchChain\')') > 0) {
                    console.log("privy need login")
                    reject('need login');
                } else {
                    reject(e);
                }
                ;
            }
        }).catch(e => {
            // message.error({ content: `keyBuy key_pretrade failed` });
            reject(e);
        });
    })
}


const keySell = (wallets, keyId, keyAddr, amount) => {
    return new Promise(async (resolve, reject) => {
        http('post', url.key_pretrade, {
            keyId
        }).then(async (res) => {
            try {
                const embeddedWallet = wallets.find((wallet) => (wallet.walletClientType === 'privy'));

                const {contractAddr, chainId} = await getConfig();

                await embeddedWallet.switchChain(chainId);

                console.log(embeddedWallet);

                const provider = await embeddedWallet.getEthersProvider();

                const signer = provider.getSigner(); // ethers signer object

                const gasPrice = await signer.getGasPrice();
                console.log('getGasPrice ' + gasPrice);

                const _maxFeePerGas = gasPrice.mul(5); //ethers.BigNumber.from(5000000000); //5GWei
                const _maxPriorityFeePerGas = gasPrice; //1GWei;

                const contract = new ethers.Contract(contractAddr, abi_trand, provider).connect(signer);
                contract.populateTransaction.sellShares(keyAddr, amount, await getGasConfig(signer)).then(unsignedTx => {
                    signer.sendTransaction(unsignedTx).then(
                        resp => resolve(resp)
                    ).catch(
                        e => reject(e)
                    );
                }).catch(e => {
                    reject(e);
                });
            } catch (e) {
                if (e.message.indexOf('(reading \'switchChain\')') > 0) {
                    console.log("privy need login")
                    reject('need login');
                } else {
                    reject(e);
                }
            }
        }).catch(e => {
            // message.error({ content: `sellBuy key_pretrade failed` })
            reject(e);
        });
    })
}
const commonGet = (url) =>{
    return new Promise(async(resolve, reject) => {
        try{
            const response = await fetch(url);
            const res = await response.json();
            resolve(res);
        }
        catch (e) {
            reject(e);
        }
    })
}

const getConfig = () => {
    return new Promise((resolve, reject) => {
        http('post', url.config, {}).then((res) => {
            if (res.code === 0) {
                if (res.data?.clientVer) {
                    // version < res.data?.clientVer
                    let flag = false;
                    const clientVerArr = res.data?.clientVer.split('.');
                    const versionArr = version.split('.');
                    if (clientVerArr.length === versionArr.length) {
                        versionArr.map((item, i) => {
                            if (Number(item) < Number(clientVerArr[i])) {
                                flag = true;
                                window.location.reload();
                            }
                        })
                        if (!flag) {
                            resolve(res.data)
                        }
                    }
                    dispatch.app.setInitTopUpAmount(res.data?.initialTopUpAmount)
                } else {
                    resolve(res.data)
                }
            } else {
                // message.error({ content: `getConfig failed` })
                reject('getConfig failed`')
            }
        }).catch(e => {
            // message.error({ content: `getConfig failed` })
            reject(e)
        });
    })
}

const searchuser = (searchKey, cursor) => {
    return new Promise((resolve, reject) => {
        http('post', url.searchuser, {
            searchKey,
            cursor
        }).then((res) => {
            if (res.code === 0) {
                resolve(res)
            } else {
                // message.error({ content: `searchuser failed` })
                reject('searchuser failed`')
            }
        }).catch(e => {
            // message.error({ content: `searchuser failed` })
            reject(e)
        });
    })
}


const getuserinfo = (userId) => {
    return new Promise((resolve, reject) => {
        let param = {
            userId: parseInt(userId),
        };

        if (!userId) {
            param = {
                userId: 0,
            };
        }

        // const data = {
        //     ...param,
        //     ...JSON.parse(localStorage.getItem('_TT_token')),
        // }
        // fetch(url.userinfo, {
        //     method: "POST",
        //     cache: "no-cache",
        //     headers: {
        //         "Content-Type": "application/json",
        //     },
        //     body: JSON.stringify(data),
        //     priority:"high",
        // }).then(rsp=>{
        //     // const {code , data} = rsp.json() || {}
        //     console.log(`fetch-----`,rsp.json());  //返回的是一个Promis对象
        //     if (rsp.status == 200) {
        //         resolve(rsp.json());
        //     }
        //     // else{
        //     //     reject(code)
        //     // }
        // }).catch(e => {
        //     reject(e)
        // });

        http('post', url.userinfo, param).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                // message.error({ content: `getuserinfo failed` })
                reject('getuserinfo failed`')
            }
        }).catch(e => {
            // message.error({ content: `getuserinfo failed` })
            reject(e)
        });
    })
}

const getkeyhistoryself = () => {
    return new Promise((resolve, reject) => {
        http('post', url.key_history_self, {}).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                // message.error({ content: `get your keys list failed` })
                reject('get your keys list failed`')
            }
        }).catch(e => {
            // message.error({ content: `get your keys list failed` })
            reject(e)
        });
    })
}
const getkeyhistoryfriends = () => {
    return new Promise((resolve, reject) => {
        http('post', url.key_history_friends, {}).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                // message.error({ content: `get friends list failed` })
                reject('get friends list failed`')
            }
        }).catch(e => {
            // message.error({ content: `get friends list failed` })
            reject(e)
        });
    })
}
const getkeyhistoryglobal = (cursor) => {
    return new Promise((resolve, reject) => {
        http('post', url.key_history_global, {
            cursor
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                // message.error({ content: `get global list failed` })
                reject('get global list failed`')
            }
        }).catch(e => {
            // message.error({ content: `get global list failed` })
            reject(e)
        });
    })
}

const getearninfo = () => {
    return new Promise((resolve, reject) => {
        http('post', url.earninfo, {}).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
                dispatch.app.setEarnInfo({...res.data, mintEndTime: new Date().getTime() / 1000 + res.data.mintLeftSec})
            } else {
                // message.error({ content: `get earninfo failed` })
                reject('get earninfo failed')
            }
        }).catch(e => {
            // message.error({ content: `get earninfo failed` })
            reject(e)
        });
    })
}

const getpri_key = () => {
    return new Promise((resolve, reject) => {
        http('post', url.pri_key, {}).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                // message.error({ content: `get getpri_key failed` })
                reject('get getpri_key failed`')
            }
        }).catch(e => {
            // message.error({ content: `get getpri_key failed` })
            reject(e)
        });
    })
}
const getmatic_market = () => {
    return new Promise((resolve, reject) => {
        http('post', url.matic_market, {}).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                // message.error({ content: `get getmatic_market failed` })
                reject('get getmatic_market failed`')
            }
        }).catch(e => {
            // message.error({ content: `get getmatic_market failed` })
            reject(e)
        });
    })
}

const getQuotes = (symbols) =>{
    return new Promise((resolve, reject) => {
        http('post', url.quotes, {
            symbols:symbols
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject('get quotes failed`')
            }
        }).catch(e => {
            reject(e)
        });
    })
}

const withdraw = (wallets, payeeAddress, amount, chainId, sendTransaction = () =>{}) => {
    return new Promise(async (resolve, reject) => {
        // http('post', url.withdraw, {
        //     payeeAddress,
        //     amount
        // }).then((res) => {
        //     if (res.code === 0) {
        //         resolve(res.data)
        //     } else {
        //         message.error({ content: `get getmatic_market failed` })
        //         reject('get getmatic_market failed`')
        //     }
        // }).catch(e => {
        //     message.error({ content: `get getmatic_market failed` })
        //     reject(e)
        // });

        try {
            const embeddedWallet = wallets.find((wallet) => (wallet.walletClientType === 'privy'));
            const provider = await embeddedWallet.getEthersProvider(); // ethers provider object

            //const {chainId} = await getConfig();
            //const chainTokenInfo = getChainTokenInfoById(chainTokenId);
            //const chainId = chainTokenInfo?.chainId;
            console.log("chainId", chainId);
            await embeddedWallet.switchChain(chainId);
            console.log(embeddedWallet);
            
            let balance = await provider.getBalance(embeddedWallet.address);
            console.log('before ' + ethers.utils.formatEther(balance));

            const signer = provider.getSigner(); // ethers signer object

            let txRequest = {
                from: embeddedWallet.address,
                to: payeeAddress,
                value: ethers.utils.parseEther(amount),
            };
            /*let txRequest = {
                //from: embeddedWallet.address,
                to: payeeAddress,
                value: ethers.utils.parseEther(amount)._hex,
            };*/

            /*const gasPrice = await signer.getGasPrice();
            console.log('getGasPrice ' + gasPrice);

            let _maxFeePerGas = ethers.BigNumber.from(20000000000); //5GWei
            let _maxPriorityFeePerGas = _maxFeePerGas;

            const gasForTx = await signer.estimateGas(txRequest);
            console.log('gasForTx ' + gasForTx);

            txRequest.maxFeePerGas = _maxFeePerGas;
            txRequest.maxPriorityFeePerGas = _maxPriorityFeePerGas;
            txRequest.gasLimit = gasForTx;*/
            const gasData = await getGasConfig(signer);
            
            txRequest = {
                ...txRequest,
                ...gasData
            }
            
            /*const tx = await sendTransaction(txRequest).then(res => {
                resolve(res);
            }).catch(e => {
                reject(e);
            });*/

            const tx = await signer.sendTransaction(txRequest).then(res => {
                resolve(res);
            }).catch(e => {
                reject(e);
            });
        } catch (e) {
            reject(e);
        }
    })
}

const gettrade_price = (tradeType, keyId, keyNum = 1) => {
    return new Promise((resolve, reject) => {
        http('post', url.trade_price, {
            tradeType, // 交易类型, 1 买, 2 卖
            keyId: parseInt(keyId),
            keyNum: parseInt(keyNum)
        }).then((res) => {
            if (res.code === 0) {
                res.data.keyAddr = res.data.walletAddr;
                resolve(res.data)
            } else {
                // message.error({ content: `get trade_price failed` })
                reject(res)
            }
        }).catch(e => {
            // message.error({ content: `get trade_price failed` })
            reject(e)
        });
    })
}


const logoutSelf = () => {
    window.localStorage.clear();
    window.location.replace(window.location.origin);
}

function copyFn(text) {
    let copyInput = document.createElement("input");
    document.body.appendChild(copyInput);
    copyInput.setAttribute("value", text);
    copyInput.select();
    document.execCommand("Copy");
    copyInput.remove();
    message.destroy();
    message.success({content: 'copied!'})
}

async function sleep(millis) {
    return new Promise((resolve) => setTimeout(resolve, millis));
}

function stringToNumber(str, fixedLen) {
    fixedLen = fixedLen === '4' ? 4 : fixedLen;
    if (Number(str)) {
        let old = Number(str).toFixed(fixedLen)
        if (parseFloat(old) === parseFloat(str)) {
            return parseFloat(str).toString()
        } else {
            return old
        }
    }
    return 0
}

const getRtcTokenUri = (keyId,groupType=0) => {

    return new Promise((resolve, reject) => {
        http('post', url.getRtcTokenUri, {
            keyId: parseInt(keyId),
            groupType: parseInt(groupType)
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                // message.error({ content: `get rtcToken failed` })
                reject(res)
            }
        }).catch(e => {
            // message.error({ content: `get rtcToken failed` })
            reject(e)
        });
    })
}

const agora_setchantype = (agoraChanType) => {

    return new Promise((resolve, reject) => {
        http('post', url.agora_setchantype, {
            agoraChanType: agoraChanType ? 1 : 0
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                // message.error({ content: `get rtcToken failed` })
                reject(res)
            }
        }).catch(e => {
            // message.error({ content: `get rtcToken failed` })
            reject(e)
        });
    })
}

const getRankList = (rankType, cursor = 0) => {

    return new Promise((resolve, reject) => {
        http('post', url.rank_toplist, {
            rankType: parseInt(rankType),
            cursor
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                // message.error({ content: `get rtcToken failed` })
                reject(res)
            }
        }).catch(e => {
            // message.error({ content: `get rtcToken failed` })
            reject(e)
        });
    })
}
const agoraHostLivestate = (alive,keyId,groupType=0) => {

    return new Promise((resolve, reject) => {
        http('post', url.agora_host_livestate, {
            alive,
            keyId,
            groupType
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        }).catch(e => {
            reject(e)
        });
    })
}

const getFitSilderWidthPercent = (height, width, maxValue, currentValue) =>{
    if (currentValue >= maxValue){
        return '100%';
    }
    else if (currentValue === 0){
        return '0%';
    }
    else{
        let minPercent = height / width;
        let resultPercent = currentValue / maxValue;
        return `${Math.max(minPercent, resultPercent) * 100}%`;
    }
}

const getAgoraChannelInfo = (channelOwnerId) => {

    return new Promise((resolve, reject) => {
        http('post', url.agora_channel_info, {
            channelOwnerId: parseInt(channelOwnerId)
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        }).catch(e => {
            reject(e)
        });
    })
}

const getEaseToken = (userId) => {
    return new Promise((resolve, reject) => {
        http('post', url.ease_token, {}).then((res) => {
            if (res.code === 0) {
                const {access_token, expires_in} = res.data;

                const now = new Date().getTime();
                const imLoginData = JSON.parse(localStorage.getItem("_TT_imLoginData")) || {};
                localStorage.setItem("_TT_imLoginData", JSON.stringify({
                    ...imLoginData,
                    imToken: access_token,
                    userId,
                    expireTimes: now + (expires_in - (checkDelay || 300)) * 1000
                }))
                WebIM.conn.context.accessToken = access_token;
                // WebIM.conn.renewToken(access_token)
                resolve(res.data)
            } else {
                reject(res)
            }
        }).catch(e => {
            reject(e)
        });
    })
}

const easeJoinGroup = (keyId,groupType = 0) => {
    return new Promise((resolve, reject) => {
        http('post', url.ease_join_group, {
            keyId: parseInt(keyId),
            groupType: parseInt(groupType)
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        }).catch(e => {
            reject(e)
        });
    })
}

// const getWebImInfo = () => {
//     const {userId} = getState().app.userInfo;
//     userId && getEaseToken().then(res => {
//         const { access_token,expires_in } = res;
//         const now = new Date().getTime();
//         const imLoginData = JSON.parse(localStorage.getItem("_TT_imLoginData")) || {};
//         localStorage.setItem("_TT_imLoginData", JSON.stringify({
//             ...imLoginData,
//             imToken: access_token,
//             expireTimes: now + (expires_in - (checkDelay || 300)) * 1000
//         }))
//         // window.localStorage.setItem("_TT_accessToken", JSON.stringify(access_token))
//     }).catch(e => {
//         console.log(e);
//     })
// }

const clgFn = (type = 'log', color = 'green', msg) => {
    console.log(`%c [${type}] log`, `color: ${color ? color : 'green'}`, msg);
}

const scrollToBottom = (dom) => {
    dom?.scrollTo({
        top: dom?.scrollHeight
    });
};
// 创建消息
const createMsg = (opt) => {
    return WebIM.message.create(opt);
};
// 发送消息
const deliverMsg = (msgObj) => {

    const {msg, needShow, keyId} = msgObj;
    if (!msg?.msg) return
    if (needShow) {//需要本地展示消息

        dispatch.app.insertChatMessage({
            chatType: msg.chatType,
            fromId: msg.to,
            messageInfo: {list: [{...msg, from: WebIM.conn.user, status: "sending"}]}
        });

        dispatch.app.setLiveMessage({
            messageInfo: [{...msg, from: WebIM.conn.user, status: "sending"}],
            reset: false,
        })
    }
    return new Promise((resolve, reject) => {
        WebIM.conn
            .send(msg)
            .then((res) => {
                resolve(res);
            })
            .catch((e) => {
                if (e.message === ERROR_CODE.notLogin) {
                    console.log("not login");
                } else if (e.reason === ERROR_CODE.muted) {
                    message.info("You have been banned from speaking!");
                } else if (e.message === ERROR_CODE.trafficLimit) {
                    message.info("Frequent message sending, please try again later!");
                }
                if (e.type === 602 || e.message === ERROR_CODE.notInRoom) {
                    easeJoinGroup(keyId).then(res => {
                        // deliverMsg(msgObj)
                    })
                }
                console.error(e);
                dispatch.app.updateChatMessageId({
                    id: msg.id,
                    to: msg.to,
                    status: "failed",
                    chatType: CHAT_TYPE['groupChat']
                })
                reject(e);
            });
    });
};
const getUsersimpleinfo = (userIds) => {
    return new Promise((resolve, reject) => {
        http('post', url.usersimpleinfo, {
            userIds
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        }).catch(e => {
            reject(e)
        });
    })

}
//格式化消息的时间，也可以传入第二个参数并根据参数返回响应格式时间
//renderTime(threadOriginalMsg.time, "MMM D, YYYY, HH:mm")
const renderTime = (time, timeStyle) => {
    if (!time || isNaN(Number(time))) return "";
    const localStr = new Date(Number(time));
    const localMoment = moment(localStr);
    const localFormat = timeStyle
        ? localMoment.format(timeStyle)
        : localMoment.format("MM-DD HH:mm");
    return localFormat;
}

// 获取store fromId
const getMessageFromId = (message) => {
    const {to, from, chatType} = message;
    if (chatType === CHAT_TYPE.chatRoom) {
        return `${to}`;
    }
    return chatType === CHAT_TYPE.groupChat ? `${to}` : `${from}`;
};

const convertToMessage = (e) => {
    var t = (function () {
        var t = [],
            r = document.createElement("div");
        r.innerHTML = e;
        // r.innerHTML = e.replace(/\\/g, "###h###");
        for (
            var n = r.querySelectorAll("img"),
                a = r.querySelectorAll("div"),
                i = n.length,
                o = a.length;
            i--;
        ) {
            var s = document.createTextNode(n[i].getAttribute("data-key"));
            n[i].parentNode.insertBefore(s, n[i]);
            n[i].parentNode.removeChild(n[i]);
        }
        // eslint-disable-next-line no-unused-expressions
        for (; o--;) t.push(a[o].innerHTML), a[o].parentNode.removeChild(a[o]);
        var c = (t = t.reverse()).length ? "\n" + t.join("\n") : t.join("\n");
        return (r.innerText + c)
            .replace(/###h###/g, "&#92;")
            .replace(/<br>/g, "\n")
            .replace(/&amp;/g, "&");
    })();
    new RegExp("(^[\\s\\n\\t\\xa0\\u3000]+)|([\\u3000\\xa0\\n\\s\\t]+$)", "g");
    return t.replace(/&nbsp;/g, " ").trim();
}

const ease_heartbeat = (keyId, online) => {
    http('post', url.ease_heartbeat, {
        keyId: parseInt(keyId),
        online
    }).then((res) => {
        //    console.log(res);
    }).catch(e => {
        console.log(e);
    });
}

const getTweetChallenge = () => {
    return new Promise((resolve, reject) => {
        http('post', url.tweet_challenge, {}).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject('getTweetChallenge failed')
            }
        }).catch(e => {
            reject(e)
        });
    })

}

const tweetAuthorize = (code) => {
    if (code.indexOf('#') > -1) {
        code = code.slice(0, code.indexOf('#'));
    }
    return new Promise((resolve, reject) => {
        http('post', url.tweet_authorize, {
            code,
            redirectUri: window.location.origin + tweetRedirectUrl,
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject('tweetAuthorize failed')
            }
        }).catch(e => {
            reject(e)
        });
    })

}

const relogin = () => {

    return new Promise((resolve, reject) => {
        http('post', url.relogin, {}).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e)
        });
    })

}

const chgFn = (now, history) => {
    if (!history || Number(history) === 0) return '-'
    return ((now - history) / history * 100).toFixed(2);
}

const tweetcode = () => {

    return new Promise((resolve, reject) => {
        http('post', url.tweetcode, {}).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject('')
            }
        }).catch(e => {
            reject(e)
        });
    })

}

const tweetcodeState = () => {

    return new Promise((resolve, reject) => {
        http('post', url.tweetcodeState, {}).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject('')
            }
        }).catch(e => {
            reject(e)
        });
    })

}

const loginIntoIM = async () => {
    const {userId} = getState().app.userInfo;
    // const accessToken = getState().app.accessToken;
    const {imToken} = JSON.parse(localStorage.getItem("_TT_imLoginData")) || {};
    if (!imToken) {
        setTimeout(() => {
            getEaseToken(userId).then(res => {
                loginIntoIM()
            }).catch(e => {
                console.error(e);
                loginIntoIM()
            })
        }, 30000)
        return

    }
    // if(!accessToken){
    //     await getWebImInfo();
    //     setTimeout(()=>{
    //         loginIntoIM();
    //         return
    //     },1000)

    // }else{
    userId && imToken && !getState().app.webIMLogined && WebIM.conn
        .open({
            user: userId.toString(),
            accessToken: imToken,
        })
        .then(res => {
            clgFn('WebIM open', '', res)
        })
        .catch(async e => {
            // getEaseToken().then(res=>{
            //     loginIntoIM()
            // }).catch(e=>{
            //     console.error(e);
            //     loginIntoIM()
            // })
        })
    // }
}
const borwserOS = () => {
    if (/android/i.test(navigator.userAgent)) {
        // console.log("Android");//Android
        return 'android'
    }
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        // console.log("iOS");//iOS
        return 'iOS'
    }
}
const borwserPlatform = () => {
    var ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf('applewebkit') > -1 && ua.indexOf('mobile') > -1 && ua.indexOf('safari') > -1 &&
        ua.indexOf('android') === -1 && ua.indexOf('chrome') === -1 &&
        ua.indexOf('ios') === -1 && ua.indexOf('browser') === -1) {
        return 'safari';
    } else {
        return 'other';
    }
}

const getTwitterOauthtoken = () => {
    return new Promise((resolve, reject) => {
        http('post', url.twitter_oauthtoken, {
            callback_url: tweetRedirectUrl,
            client_url: localurl
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject('')
            }
        }).catch(e => {
            reject(e)
        });
    })
}

const get_trivia_run_data = (userId) => {
    return new Promise((resolve, reject) => {
        http('post', url.trivia_run_data, {
            hostId: parseInt(userId),
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                console.log("trivia_run_data body: " + res)
                reject('')
            }
        }).catch(e => {
            reject(e)
        });
    })
}

const get_trivia_cond = (userId) => {
    return new Promise((resolve, reject) => {
        http('post', url.trivia_cond, {
            hostId: parseInt(userId),
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                console.log("trivia_cond body: " + res)
                reject('')
            }
        }).catch(e => {
            reject(e)
        });
    })
}

const post_trivia_answer_choice = (userId, questionId, choice) => {
    return new Promise((resolve, reject) => {
        http('post', url.trivia_answer_choice, {
            hostId: parseInt(userId),
            questionId: parseInt(questionId),
            answerId: parseInt(choice),
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            }
            if (res.code === 569) {
                reject('SpotsFilled')
            } else {
                console.log("trivia_answer_choice body: " + res)
                reject(res.code)
            }
        }).catch(e => {
            console.error("post_trivia_answer_choice", e)
            reject('network error')
        });
    })
}

const post_friendtrade_boost_claimcoin = () => {
    return new Promise((resolve, reject) => {
        http('post', url.friendtrade_boost_claimcoin, {}).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            }
            if (res.code === 569) {
                reject('friendtrade_boost_claimcoin Filled')
            } else {
                console.log("friendtrade_boost_claimcoin body: " + res)
                reject(res.code)
            }
        }).catch(e => {
            console.error("friendtrade_boost_claimcoin", e)
            reject('network error')
        });
    })
}

const post_trivia_start = () => {
    return new Promise((resolve, reject) => {
        http('post', url.trivia_start, {}).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                console.log("trivia_start body: " + res)
                reject('')
            }
        }).catch(e => {
            reject(e)
        });
    })
}

const post_trivia_question_next = () => {
    return new Promise((resolve, reject) => {
        http('post', url.trivia_question_next, {}).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                console.log("trivia_question_next body: " + res)
                reject('')
            }
        }).catch(e => {
            reject(e)
        });
    })
}

const post_trivia_answer_pub = () => {
    return new Promise((resolve, reject) => {
        http('post', url.trivia_answer_pub, {}).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                console.log("trivia_answer_pub body: " + res)
                reject('')
            }
        }).catch(e => {
            reject(e)
        });
    })
}

const post_trivia_question_pub = () => {
    return new Promise((resolve, reject) => {
        http('post', url.trivia_question_pub, {}).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                console.log("trivia_question_pub body: " + res)
                reject('')
            }
        }).catch(e => {
            reject(e)
        });
    })
}

const post_trivia_rank_pub = () => {
    return new Promise((resolve, reject) => {
        http('post', url.trivia_rank_pub, {}).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                console.log("trivia_rank_pub body: " + res)
                reject('')
            }
        }).catch(e => {
            reject(e)
        });
    })
}

const post_trivia_stop = () => {
    return new Promise((resolve, reject) => {
        http('post', url.trivia_stop, {}).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                console.log("trivia_stop body: " + res)
                reject('')
            }
        }).catch(e => {
            reject(e)
        });
    })
}

const home_showlist = () => {
    return new Promise((resolve, reject) => {
        http('post', url.home_showlist, {}).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject('')
            }
        }).catch(e => {
            reject(e)
        });
    })
}

const countDownTime = (startTime, type = 0) => {
    const nowtime = new Date(); //获取当前时间
    let timestamp = startTime - nowtime.getTime(); //距离结束时间的毫秒数
    if (timestamp < 0 || isNaN(timestamp)) {
        return ""
    }
    let D = Math.floor(timestamp / (1000 * 60 * 60 * 24)); //计算天数
    let H = type === 0 ? Math.floor(timestamp / (1000 * 60 * 60)) : Math.floor(timestamp / (1000 * 60 * 60) % 24); //计算小时数
    let m = Math.floor(timestamp / (1000 * 60) % 60); //计算分钟数
    let s = Math.floor(timestamp / 1000 % 60); //计算秒数
    if (H < 10) {
        H = '0' + H
    }
    if (m < 10) {
        m = '0' + m
    }
    if (s < 10) {
        s = '0' + s
    }
    if (type === 1) {
        return `${D} days ${H} hours ${m} minutes ${s} seconds`
    } else if (type === 2) {
        return `${D}d ${H}h ${m}m ${s}s`
    } else {
        return `${H} hours ${m} minutes ${s} seconds`
    }
}

const refreshAvatar = () => {
    return new Promise((resolve, reject) => {
        http('post', url.twitter_update, {}).then((res) => {
            if (res.code === 0) {
                resolve()
            } else {
                reject('')
            }
        }).catch(e => {
            reject(e)
        });
    })
}

const agora_channel_joinstate = (keyId, joinState) => {
    return new Promise((resolve, reject) => {
        http('post', url.agora_channel_joinstate, {
            keyId: parseInt(keyId),
            joinState
        }).then((res) => {
            if (res.code === 0) {
                resolve()
            } else {
                resolve('')
            }
        }).catch(e => {
            resolve(e)
        });
    })
}

const isMobile = () => {
    return /Mobi|Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent);
}

const post_live_announce_replace = (content) => {
    return new Promise((resolve, reject) => {
        http('post', url.live_announce_replace, content).then((res) => {
            if (res.code === 0) {
                resolve()
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e)
        });
    })
}

const post_live_announce_del = (content) => {
    return new Promise((resolve, reject) => {
        http('post', url.live_announce_del, content).then((res) => {
            if (res.code === 0) {
                resolve()
            } else {
                reject('')
            }
        }).catch(e => {
            reject(e)
        });
    })
}

const post_live_announce_query = (content) => {
    return new Promise((resolve, reject) => {
        http('post', url.live_announce_query, content).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject('')
            }
        }).catch(e => {
            reject(e)
        });
    })
}

const post_live_title_replace = (content) => {
    return new Promise((resolve, reject) => {
        http('post', url.live_title_replace, content).then((res) => {
            if (res.code === 0) {
                resolve()
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e)
        });
    })
}

function convertTimestamp(timestamp) {
    const dateObj = new Date(timestamp);

    const months = ['Jan.', 'Feb.', 'Mar.', 'Apr.', 'May', 'Jun.', 'Jul.', 'Aug.', 'Sep.', 'Oct.', 'Nov.', 'Dec.'];
    const hours = dateObj.getHours() > 12 ? dateObj.getHours() - 12 : dateObj.getHours();
    const period = dateObj.getHours() >= 12 ? 'pm' : 'am';
    const monthName = months[dateObj.getMonth()];
    const day = dateObj.getDate();

    const ordinalIndicator = (day) => {
        if (day > 3 && day < 21) return 'th';
        switch (day % 10) {
            case 1:
                return "st";
            case 2:
                return "nd";
            case 3:
                return "rd";
            default:
                return "th";
        }
    }

    return `${hours}${period} ${monthName}${day}${ordinalIndicator(day)}`;
}

const post_rank_unitdata = (userId) => {
    return new Promise((resolve, reject) => {
        http('post', url.rank_unitdata, {
            userId: parseInt(userId),
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                console.log("rank_unitdata body: " + res)
                reject('')
            }
        }).catch(e => {
            reject(e)
        });
    })
}

const joinChat = (userId, navigate, type = 0, replace = false) => {
    dispatch.app.setCurentOtherUserInfo(undefined);

    setTimeout(() => {
        if (type === "wish" || type === 1) {
            navigate(`${publicWishesPath}/${userId}`, {replace: replace});
        } else {
            navigate(`${publicChatsPath}/${userId}`, {replace: replace});
        }
    }, 300);
}

const use_platform = (useType) => {

    return new Promise((resolve, reject) => {
        http('post', url.use_platform, {
            useType: parseInt(useType),
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                console.log("use_platform: " + res)
                reject('')
            }
        }).catch(e => {
            reject(e)
        });
    })
}

const post_wish_create = (platform, username) => {
    return new Promise((resolve, reject) => {
        http('post', url.wish_create, {
            pfType: parseInt(platform),
            pfAccName: username,
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                console.log("wish_create: " + res)
                reject(res.code)
            }
        }).catch(e => {
            reject(e)
        });
    })
}

const referralcode_bind = (referralCode) => {
    return new Promise((resolve, reject) => {
        http('post', url.referralcode_bind, {
            referralCode
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                console.log("referralcode_bind: " + res)
                reject(res.code)
            }
        }).catch(e => {
            reject(e)
        });
    })
}

const addJoinChartPre = (code) => {
    const index = code?.indexOf('turnup.so/');
    if (index > -1) {
        return 'turnup.so/' + joinChartPre + code.substr(10)
    }
    return code
}

const referralcode_earndata = (cursor) => {
    return new Promise((resolve, reject) => {
        http('post', url.referralcode_earndata, {
            cursor
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                console.log("referralcode_earndata: " + res)
                reject(res.code)
            }
        }).catch(e => {
            reject(e)
        });
    })
}

const referralcode_inviteuser = (cursor) => {
    return new Promise((resolve, reject) => {
        http('post', url.referralcode_inviteuser, {
            cursor
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                console.log("referralcode_inviteuser: " + res)
                reject(res.code)
            }
        }).catch(e => {
            reject(e)
        });
    })
}

const referralcode_regen = () => {
    return new Promise((resolve, reject) => {
        http('post', url.referralcode_regen, {}).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                console.log("referralcode_regen: " + res)
                reject(res.code)
            }
        }).catch(e => {
            reject(e)
        });
    })
}

const activity_info = (activityType) => {
    return new Promise((resolve, reject) => {
        http('post', url.activity_info, {
            activityType
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                console.log("activity_info: " + res)
                reject(res.code)
            }
        }).catch(e => {
            reject(e)
        });
    })
}

const getProfile = (platformData) => {
    const {platformMap, useType, defaultName} = platformData || {};
    if (useType !== 0 && platformMap && JSON.stringify(platformMap) !== '{}') {
        return platformMap[useType]
    } else {
        return {
            displayName: defaultName,
            accountName: "",
            avatarUrl: "",
            originalAvatarUrl: "",
            platformType: 0,
            profileDeepLink: "",
        }
    }
}

const post_wish_query = (platform, username) => {
    return new Promise((resolve, reject) => {
        http('post', url.wish_query, {
            pfType: parseInt(platform),
            pfAccName: username,
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                console.log("wish_query: " + res)
                reject(res.code)
            }
        }).catch(e => {
            reject(e)
        });
    })
}

const post_batch_price = (buyList) => {
    return new Promise((resolve, reject) => {
        http('post', url.batch_price, {
            keys: buyList
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                console.log("batch_price: " + res)
                reject(res.code)
            }
        }).catch(e => {
            reject(e)
        });
    })
}

// 计算字节长度
function mbStringLength(s) {
    var totalLength = 0;
    var i;
    var charCode;
    for (i = 0; i < s.length; i++) {
        charCode = s.charCodeAt(i);
        if (charCode < 0x007f) {
            totalLength = totalLength + 1;
        } else if ((0x0080 <= charCode) && (charCode <= 0x07ff)) {
            totalLength += 2;
        } else if ((0x0800 <= charCode) && (charCode <= 0xffff)) {
            totalLength += 3;
        }
    }
    return totalLength;
}

// 根据字节长度分隔字符
function reBytesStr(str, len = 10) {
    if (!str || str == undefined) return '';
    let num = 0;
    let result = '';
    for (let i = 0; i < str.length; i++) {
        num += ((str.charCodeAt(i) > 255) ? 2 : 1); // 中文两个字节，英文一个字节
        if (num > len) {
            break;
        } else {
            result = str.substring(0, i + 1);
        }
    }
    return result;
}

const maxCharLength = (word, len = 10) =>
    mbStringLength(word) > len ? reBytesStr(word, len) + '...' : word

const post_hire_list = (params) => {
    return new Promise((resolve, reject) => {
        http('post', url.hire_list, params).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                console.log("hire_list: " + res)
                reject(res.code)
            }
        }).catch(e => {
            reject(e)
        });
    })
}

const post_portfolio = ({userId, cursor}) => {
    return new Promise((resolve, reject) => {
        http('post', url.portfolio, {
            userId: parseInt(userId),
            cursor,
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                console.log("portfolio: " + res)
                reject(res.code)
            }
        }).catch(e => {
            reject(e)
        });
    })
}

const post_take_coin = (num) => {
    return new Promise((resolve, reject) => {
        const param = num ? {
            mintCoin: Number(num)
        } : {}
        http('post', url.take_coin, param).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                console.log("take_coin: " + res)
                reject(res.code)
            }
        }).catch(e => {
            reject(e)
        });
    })
}

const post_dispatch_emplyees = (emplyeeIds, workId) => {
    return new Promise((resolve, reject) => {
        http('post', url.dispatch_emplyees, {
            emplyeeIds,
            workId,
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                console.log("dispatch_emplyees: " + res)
                reject(res.code)
            }
        }).catch(e => {
            reject(e)
        });
    })
}

const post_work_list = (workId) => {
    let param = {}
    if (workId) {
        param = {
            workIds: [workId]
        }
    }

    return new Promise((resolve, reject) => {
        http('post', url.work_list, param).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                console.log("work_list: " + res)
                reject(res.code)
            }
        }).catch(e => {
            reject(e)
        });
    })
}

const post_work_unlock = (workId) => {
    return new Promise((resolve, reject) => {
        http('post', url.unlock_work, {
            workId,
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                console.log("unlock_work: " + res)
                reject(res.code)
            }
        }).catch(e => {
            reject(e)
        });
    })
}

const post_dispatch_detail = (emplyeeIds, workId) => {
    return new Promise((resolve, reject) => {
        http('post', url.dispatch_detail, {
            emplyeeIds,
            workId,
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                console.log("dispatch_detial: " + res)
                reject(res.code)
            }
        }).catch(e => {
            reject(e)
        });
    })
}

const post_global_production_info = (emplyeeIds, workId) => {
    return new Promise((resolve, reject) => {
        http('post', url.global_production_info, {
            emplyeeIds,
            workId,
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                console.log("global_production_info: " + res)
                reject(res.code)
            }
        }).catch(e => {
            reject(e)
        });
    })
}

function vCoinString(numOrigin) {
    let num = Number(numOrigin);

    if (isNaN(num)) {
        return numOrigin;
    }
    var numStr = Number((Math.floor(num * 10) / 10).toFixed(1)).toLocaleString("en-US");
    return numStr;
}

function hireENumber(num) {
    if (num > 0) {
        return '+' + num.toString();
    } else if (num < 0) {
        return num.toString();
    } else {
        return '+0';
    }
}

function post_history_friends(type = 0) {
    return new Promise((resolve, reject) => {
        http('post', url.history_friends, {
            type: type
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                console.log("history_friends: " + res)
                reject(res.code)
            }
        }).catch(e => {
            reject(e)
        });
    })
}

function post_trivia_revival(userId) {
    return new Promise((resolve, reject) => {
        http('post', url.trivia_revival, {
            hostId: parseInt(userId),
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                console.log("trivia_revival: " + res)
                reject(res.code)
            }
        }).catch(e => {
            reject(e)
        });
    })
}

function getglobal_chat_userid() {
    return new Promise((resolve, reject) => {
        http('post', url.global_chat_userid, {}).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                console.log("getglobal_chat_userid: " + res)
                reject(res.code)
            }
        }).catch(e => {
            reject(e)
        });
    })
}

function getfriendtrade_rank_selfdata() {
    return new Promise((resolve, reject) => {
        http('post', url.friendtrade_rank_selfdata, {}).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e)
        });
    })
}

function getfriendtrade_rank_claim(cursor, type, levelRange) {
    return new Promise((resolve, reject) => {
        http('post', url.friendtrade_rank_claim, {
            type, cursor, levelRange
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e)
        });
    })
}

const getfriendtrade_loot_nftrank = (cursor) => {
    return new Promise((resolve, reject) => {
        http('post', url.friendtrade_loot_nftrank, {
            cursor
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e)
        });
    })
}

const getFriendtrade_loot_employeelist = () => {
    return new Promise((resolve, reject) => {
        http('post', url.friendtrade_loot_employeelist, {}).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e)
        });
    })

}

const getFriendtrade_loot_tarinfo = (tarUserId) => {
    return new Promise((resolve, reject) => {
        http('post', url.friendtrade_loot_tarinfo, {
            tarUserId: tarUserId,
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e)
        });
    })
}

const getFriendtrade_loot_info = (employeeId, tarUserId) => {
    return new Promise((resolve, reject) => {
        http('post', url.friendtrade_loot_info, {
            employeeId: Number(employeeId),
            tarUserId: Number(tarUserId)
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e)
        });
    })

}

const getfriendtrade_loot_crack = (employeeId, tarUserId, putCoin) => {
    return new Promise((resolve, reject) => {
        http('post', url.friendtrade_loot_crack, {
            employeeId: Number(employeeId),
            tarUserId: Number(tarUserId),
            putCoin: Number(putCoin),
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e)
        });
    })

}

const getfriendtrade_loot_randomsearch = (lootSearchClubLvRange, employeeId = 0) => {
    return new Promise((resolve, reject) => {
        http('post', url.friendtrade_loot_randomsearch, {
            tierIdRange: lootSearchClubLvRange,
            employeeId: employeeId
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e)
        });
    })

}

const getfriendtrade_takeworkcoin = (employeeId) => {
    return new Promise((resolve, reject) => {
        http('post', url.friendtrade_takeworkcoin, {
            employeeId: Number(employeeId)
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e)
        });
    })

}

const getfriendtrade_workinfo = (employeeId) => {
    return new Promise((resolve, reject) => {
        http('post', url.friendtrade_workinfo, {
            employeeId: Number(employeeId)
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e)
        });
    })

}

const createTwitterLink = (htmlTemplate, imageContent, name) => {
    return new Promise((resolve, reject) => {
        http('post', url.twitter_link_create, {
            htmlTemplate: htmlTemplate,
            imageContent: imageContent,
            name: name
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e)
        });
    })
}

const GetDomain = (url) => {
    var domain = url.split('/'); //以“/”进行分割
    if (domain[2]) {
        domain = domain[2];
    } else {
        domain = ''; //如果url不正确就取空
    }
    if (domain) {
        domain = domain.split(':');
        if (domain[0]) {
            domain = domain[0];
        }
    }
    return domain;
}

const createTwitterHtml = (card, site, title, description, creator, inviteUrl) => {
    if (!inviteUrl) {
        inviteUrl = 'https://turnup.so';
    }
    return `<!DOCTYPE html>
    <html>
     <head>
      <title>share</title>
      <meta name="twitter:card" content="${card}" />
      <meta name="twitter:site" content="@${site}" />
      <meta name="twitter:title" content="${title}" />
      <meta name="twitter:description" content="${description}" />
      <meta name="twitter:creator" content="@${creator}" />
      <meta name="twitter:image" content="**image**" />
      <meta http-equiv="refresh" content="0;URL='${inviteUrl}'" />
     </head>
     <body>
     </body>
    </html>`;
}

const formatNumberWithUnit = (num, digits = 1) => {
    if (typeof digits === 'number') {
        digits = digits + ""
    }
    if (num < 10000) {
        return parseInt(num)
    }
    if (num < 1000 * 1000) {
        return `${floorToFix(num / 1000, digits).toFixed(digits)}k`
    }
    if (num < 1000 * 1000 * 10000) {
        return `${floorToFix(num / 1000 / 1000, digits).toFixed(digits)}m`
    }
    return ''
}

const floorToFix = (num, digits) => {
    if (num) {
        let tempNum = num;
        for (let i = 0; i < digits; i++) {
            tempNum = tempNum * 10;
        }
        tempNum = Math.floor(tempNum);
        for (let i = 0; i < digits; i++) {
            tempNum = tempNum / 10;
        }
        return tempNum;
    }
    return 0;
}

const getMintSignature = (num) => {
    return new Promise((resolve, reject) => {
        // http('post', url.twitter_link_create, {
        //     htmlTemplate: htmlTemplate,
        //     imageContent: imageContent,
        //     name: name
        // }).then((res) => {
        //     if (res.code === 0) {
        //         resolve(res.data)
        //     } else {
        //         reject(res.code)
        //     }
        // }).catch(e => {
        //     reject(e)
        // });
        resolve(`signature ${num}`)
    })
}

const convertUsdValue = (value) => {
    if (value > 100){
        return Number(stringToNumber(value, 0)).toLocaleString("en-US");
    }
    else if (value > 0){
        return Number(stringToNumber(value, 2)).toLocaleString("en-US");
    }
    return 0;
}

const convertWalletBalance = (balance) => {
    if (balance >= 1000000000) {
        return `${Number(floorToFix(Number(balance / 1000000000).toFixed(1), 1))}b`;
    } else if (balance >= 1000000) {
        return `${Number(floorToFix(Number(balance / 1000000).toFixed(1), 1))}m`;
    } else if (balance >= 1000) {
        return `${Number(floorToFix(Number(balance / 1000).toFixed(1), 1))}k`;
    } else if (balance >= 10) {
        return `${Number(floorToFix(Number(balance), 2).toFixed(2))}`;
    } else if (balance > 0.0001) {
        return `${Number(floorToFix(Number(balance), 4).toFixed(4))}`;
    } else if (balance > 0) {
        return `<0.0001`; 
    } else {
        return 0;
    }
}

const convertScore = (balance) => {
    if (balance >= 1000000000) {
        return `${floorToFix(Number(balance / 1000000000).toFixed(2), 2)}b`;
    } else if (balance >= 1000000) {
        return `${floorToFix(Number(balance / 1000000).toFixed(2), 2)}m`;
    } else if (balance >= 1000) {
        return `${floorToFix(Number(balance / 1000).toFixed(2), 2)}k`;
    } else if (balance >= 10) {
        return `${floorToFix(Number(balance), 2).toFixed(0)}`;
    } else if (balance > 0) {
        return `${floorToFix(Number(balance), 4).toFixed(0)}`;
    } else {
        return 0;
    }
}

function stringToHex(str) {
    const val = [...str].map(c => c.charCodeAt(0).toString(16).padStart(2, 0)).join``
    return '0x' + val;
}


const formatEther = (value) => {
    if (!value) return value
    return ethers.utils.formatEther(value)
}

const deWeightThree = (arr, name) => {
    // let name = 'name';
    let map = new Map();
    for (let item of arr) {
        if (!map.has(item[name])) {
            map.set(item[name], item);
        }
    }
    return [...map.values()];
}

const getBurnParam_ulock = ({workId, costType}) => {
    // type: 1: unlockWork , 2: loot
    return new Promise((resolve, reject) => {
        http('post', url.friendtrade_chain_unlockwork, {
            workId,
            costType
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e)
        });
        // resolve(`signature ${bornType} ${workId}`)
    })
}

const getBurnParam_loot = ({employeeId, tarUserId, bornNum, costType}) => {
    // type: 1: unlockWork , 2: loot
    return new Promise((resolve, reject) => {
        http('post', url.friendtrade_chain_lootcrack, {
            employeeId,
            tarUserId,
            amount: Number(bornNum),
            costType
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e)
        });
    })
}

const bornCheck_loot = (orderId) => {
    return new Promise((resolve, reject) => {
        http('post', url.friendtrade_chain_orderresult, {
            orderId: Number(orderId)
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e)
        });

    })
}

const bornCheck_unlock = (orderId) => {
    return new Promise((resolve, reject) => {
        http('post', url.friendtrade_chain_orderresult, {
            orderId: Number(orderId)
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e)
        });
        // resolve(orderId)
    })
}

const taPush = (event_name, event_param) => {
    if (!envConfig.taEnable) return;
    window.ta.track(event_name, event_param);
}

const post_dispatch_emplyees_mutil = (emplyeeIds,workType) => {
    return new Promise((resolve, reject) => {
        http('post', url.dispatch_emplyees_mutil, {
            emplyeeIds,
            workType:Number(workType)
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                console.log("dispatch_emplyees_mutil: " + res)
                reject(res.code)
            }
        }).catch(e => {
            reject(e)
        });
    })
}

const post_dispatch_detail_mutil = (emplyeeIds,workType) => {
    return new Promise((resolve, reject) => {
        http('post', url.dispatch_detail_mutil, {
            emplyeeIds,
            workType:Number(workType)
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                console.log("dispatch_detail_mutil: " + res)
                reject(res.code)
            }
        }).catch(e => {
            reject(e)
        });
    })
}

const getfriendtrade_takeworkcoin_all = () => {
    return new Promise((resolve, reject) => {
        http('post', url.friendtrade_takeworkcoin_all, {}).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e)
        });
    })

}

const displayFilter = (str) => {
    if (!str) return ''
    const ind = displayList?.find(item => {
        return str.indexOf(item) > -1
    })
    if (ind) {
        return '**'
    }
    return str
}

const getPowerXY = (power) => {
    if (power) {
        return (Math.pow(power / 7, 2) / 50).toFixed(2)
    }
    return power


}

const getFriendtrade_takecoininfo = () => {
    return new Promise((resolve, reject) => {
        http('post', url.friendtrade_takecoininfo, {}).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e)
        });
    })
}

const getFriendtradeVirtualLfg = () => {
    return new Promise((resolve, reject) => {
        http('post', url.friendtrade_virtual_lfg, {}).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const getFriendtradeChainStake = (amount) => {
    return new Promise((resolve, reject) => {
        http('post', url.friendtrade_chain_stake, {
            amount
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const filterErrorCode = (code) => {
    var res = '';
    for (const item of errorCode) {
        if (item.id == code) {
            res = item?.tips;
            break;
        }
    }
    return res;
}

const getFriendtradeLocklfgInfo = () => {
    return new Promise((resolve, reject) => {
        http('post', url.friendtrade_locklfg_info, {}).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const getFriendtradeMintunlocklfg = () => {
    return new Promise((resolve, reject) => {
        http('post', url.friendtrade_mintunlocklfg, {}).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const getStakeYieldClaimed = (userId, offset = 0) => {
    console.log("getStakeYieldClaimed", userId, offset)
    return new Promise((resolve, reject) => {
        http('post', url.stake_yield_claimed, {
            userId,
            offset
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const getFriendtradeLootMatchlist = () => {
    return new Promise((resolve, reject) => {
        http('post', url.friendtrade_loot_matchlist, {}).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const getFriendtradeLootLastevent = () => {
    return new Promise((resolve, reject) => {
        http('post', url.friendtrade_loot_lastevent, {}).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const getTaskGetreward = (taskId) => {
    return new Promise((resolve, reject) => {
        http('post', url.task_getreward, {
            taskId: taskId
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const formatTimestampToTime = (timestamp, type = 0) => {
    let res = '';
    let date = new Date(timestamp * 1000);
    let year = date.getUTCFullYear();
    let month = date.getUTCMonth() + 1;
    let day = date.getUTCDate();
    let hour = date.getUTCHours();
    let minute = date.getUTCMinutes();
    let second = date.getUTCSeconds();
    return `${year}/${month}/${day} ${hour < 10 ? '0' : ''}${hour}:${minute < 10 ? '0' : ''}${minute}:${second < 10 ? '0' : ''}${second}`;
}

const getWeb3Config = async () => {
    let config = getState().app.web3Config;
    console.log("config from redux", config);
    if (!config){
        config = await getConfig();
        console.log("get config from server", config);
        dispatch.app.setWeb3Config(config);
    }
    return config;
}

const getBattlePointRankImg = (level) => {
    if (BPLevelConfig) {
        let levelInfo = BPLevelConfig.find(item => item.ID === level);
        if (levelInfo?.image) {
            return levelInfo?.image;
        } else {
            return BPLevelConfig[0]?.image || '';
        }
    }
    return '';
}

const getTransactionErrorMsgId = (reason) => {
    let errMsg = 'Transaction failed due to other unknown reason';
    if (reason != null) {
        for (const transactionErrorElement in TransactionError) {
            if (reason.indexOf(transactionErrorElement) !== -1) {
                errMsg = `TransactionError_${transactionErrorElement}`;
                break;
            }
        }
    }
    return errMsg;
}

const compareNftRarity = (r1, r2) => {
    let r1n = nftRarityEnum[r1] || 0;
    let r2n = nftRarityEnum[r2] || 0;
    return r1n - r2n;
}

const nftRarityEnum = {
    Common: 1,
    Uncommon: 2,
    Rare: 3,
    Epic: 4,
    Legendary: 5
}

const getRarityByValue = (value) =>{
    if (NFTRarityInfo?.length > 0 && value){
        for (let i = 0; i < NFTRarityInfo?.length; i++){
            if (NFTRarityInfo[i]?.name?.toLowerCase() === value?.toLowerCase()){
                return NFTRarityInfo[i]?.rarity || 0;
            }
        }
    }
    return 0;
};

const getAvatarFromProfile = (profile, size = 0) => {
    if (!profile) {
        return '';
    }
    if (profile.useNftInfo?.useNft) {
        return profile.useNftInfo.image;
    } else {
        if (size === 1) {
            return profile.originalAvatarUrl;
        } else {
            return profile.avatarUrl;
        }
    }
}

const getAvatarFromUserInfo = (userInfo) => {
    let avatarUrl = '';
    let originalAvatarUrl = '';
    let platformMap = userInfo?.platformData?.platformMap;
    let useType = userInfo?.platformData?.useType;
    if (userInfo?.useNftInfo?.useNft) {
        avatarUrl = userInfo?.useNftInfo?.image || '';
        originalAvatarUrl = userInfo?.useNftInfo?.image || '';
    } else if (platformMap && JSON.stringify(platformMap) !== '{}') {
        avatarUrl = platformMap[useType]?.avatarUrl || '';
        originalAvatarUrl = platformMap[useType]?.originalAvatarUrl || '';
    }
    return {avatarUrl: avatarUrl, originalAvatarUrl: originalAvatarUrl}
}

const post_batch_users_profile = (idType, ids) => {
    return new Promise((resolve, reject) => {
        http('post', url.batch_users_profile, {
            idType,
            ids,
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const numberClamp = (count, min, max) => {
    if (count > max) {
        return max;
    } else if (count < min) {
        return min;
    } else {
        return count;
    }
}

const openMoonPay = async (wallets, amount = 50, chainTokenInfo = null) => {
    const fundWalletConfig = {
        paymentMethod: 'credit_debit_card', // Purchase with credit or debit card
        uiConfig: {accentColor: '#696FFD', theme: 'light'}, // Styling preferences for MoonPay's UIs
    };
    let currencyCode = '';
    if (chainTokenInfo?.moonpayCurrencyCode){
        currencyCode = chainTokenInfo?.moonpayCurrencyCode;
    }
    if (currencyCode){
        fundWalletConfig.quoteCurrencyAmount = amount; // Purchase 0.05 ETH
        fundWalletConfig.currencyCode = currencyCode; // Purchase ETH on Ethereum mainnet
    }
    const embeddedWallet = wallets.find((wallet) => (wallet.walletClientType === 'privy'));

    if (embeddedWallet) {
        await embeddedWallet.fund({config: fundWalletConfig});
    }
    else{
        logoutSelf();
    }
}

const formatCardDisplayName = (name, len = 7) => {
    if (name?.length > len) {
        return `${name.substring(0, len)}...`;
    } else {
        return name;
    }
}

const mintNftProtectlfg = (mintLfg) => {
    return new Promise((resolve, reject) => {
        http('post', url.nft_protectlfg_mint, {
            mintLfg: Number(mintLfg)
        }).then((res) => {
            if (res.code === 0) {
                resolve(res.data)
            } else {
                reject(res.code)
            }
        }).catch(e => {
            reject(e);
        })
    })
}

const getTransferEstGasPrice = (wallets, chainId) => {
    return new Promise(async (resolve, reject) => {
        try {
            const embeddedWallet = wallets.find((wallet) => (wallet.walletClientType === 'privy'));
            await embeddedWallet.switchChain(chainId);
            const provider = await embeddedWallet.getEthersProvider();
            const gasPrice = await provider.getGasPrice();
            const value = ethers.utils.formatEther(gasPrice.toString());
            resolve(value);
        } catch (error) {
            reject(error);
        }
    })
}

const getChainInfoById = (id) =>{
    let info = null;
    let supportedChainList = GetSupportedChainList();
    if (supportedChainList?.length > 0){
        for (let i = 0; i < supportedChainList?.length; i++){
            if (supportedChainList[i]?.id === id){
                info = supportedChainList[i];
                break;
            }
        }
    }
    return info;
}

const getChainTokenInfoById = (id) =>{
    let info = null;
    if (MemeChainToken?.length > 0){
        for (let i = 0; i < MemeChainToken?.length; i++){
            if (MemeChainToken[i]?.ID === id){
                info = MemeChainToken[i];
                break;
            }
        }
    }
    return info;
}

export {
    GetSupportedChainList,
    GetSupportedMaticChainTokenInfo,
    getTokenBalanceOf,
    officialInviteCode,
    polygonChain,
    baseSepoliaChain,
    polygonAmoyChain,
    baseChain,
    depositUrl,
    unit,
    LFG,
    PLATFORM_DATA,
    siteName,
    timerDelay,
    therdConfig,
    googleUrl,
    chainName,
    checkDelay,
    SCROLL_WARP_ID,
    CHAT_TYPE,
    PAGE_SIZE,
    MIN_TXT_AMOUNT,
    MESSAGE_ITEM_SOURCE,
    LIVE_WARP_ID,
    defaultAvatar,
    RANK_WARP_ID,
    joinChartPre,
    deeplink,
    maticIconUrl,
    lfgIconUrl,
    use_platform,
    compareNftRarity,
    addJoinChartPre,
    nftRarityEnum,
    chainOpenseaUrl,
    getTweetChallenge,
    agora_channel_joinstate,
    commonGet,
    refreshAvatar,
    tweetAuthorize,
    easeJoinGroup,
    getConfirmModalConf,
    loginFn,
    checkcodeFn,
    copyFn,
    sleep,
    sliceStr,
    getcodestate,
    getTranList,
    searchuser,
    getuserinfo,
    keyBuy,
    keySell,
    getkeyhistoryself,
    getkeyhistoryfriends,
    getkeyhistoryglobal,
    logoutSelf,
    getearninfo,
    getpri_key,
    getmatic_market,
    getFriendtrade_loot_tarinfo,
    getQuotes,
    withdraw,
    stringToNumber,
    updateUserInfo,
    gettrade_price,
    getPrice,
    updateCurentOtherUserInfo,
    updateSelfUser,
    updateOtherUser,
    getUrlParams,
    getRtcTokenUri,
    agora_setchantype,
    getRankList,
    agoraHostLivestate,
    getAgoraChannelInfo,
    getEaseToken,
    clgFn,
    scrollToBottom,
    deliverMsg,
    createMsg,
    getUsersimpleinfo,
    renderTime,
    getMessageFromId,
    convertToMessage,
    ease_heartbeat,
    getBindTweetUrl,
    chgFn,
    getWeb3Config,
    numberClamp,
    tweetcode,
    tweetcodeState,
    loginIntoIM,
    borwserOS,
    borwserPlatform,
    getTweetOuth1aUrl,
    getTwitterOauthtoken,
    get_trivia_run_data,
    getTaskGetreward,
    get_trivia_cond,
    post_trivia_answer_choice,
    post_friendtrade_boost_claimcoin,
    post_trivia_start,
    post_trivia_question_next,
    post_trivia_answer_pub,
    post_trivia_question_pub,
    post_trivia_rank_pub,
    post_trivia_stop,
    post_global_production_info,
    home_showlist,
    relogin,
    getConfig,
    countDownTime,
    isMobile,
    post_live_announce_replace,
    post_live_announce_del,
    post_live_announce_query,
    convertTimestamp,
    post_rank_unitdata,
    joinChat,
    post_wish_create,
    referralcode_bind,
    referralcode_earndata,
    referralcode_inviteuser,
    referralcode_regen,
    activity_info,
    getProfile,
    keyWishClaimBuy,
    post_live_title_replace,
    post_wish_query,
    post_batch_price,
    keyBatchBuy,
    maxCharLength,
    post_hire_list,
    post_portfolio,
    post_take_coin,
    post_dispatch_emplyees,
    post_work_list,
    post_work_unlock,
    post_dispatch_detail,
    vCoinString,
    hireENumber,
    post_history_friends,
    post_trivia_revival,
    getglobal_chat_userid,
    getfriendtrade_rank_selfdata,
    getfriendtrade_rank_claim,
    getfriendtrade_loot_nftrank,
    getFriendtrade_loot_employeelist,
    getFriendtrade_loot_info,
    getfriendtrade_loot_crack,
    getfriendtrade_loot_randomsearch,
    getfriendtrade_takeworkcoin,
    getfriendtrade_workinfo,
    createTwitterLink,
    createTwitterHtml,
    GetDomain,
    formatNumberWithUnit,
    getMintSignature,
    convertWalletBalance,
    convertUsdValue,
    formatEther,
    deWeightThree,
    getRarityByValue,
    getBurnParam_ulock,
    getBurnParam_loot,
    bornCheck_loot,
    bornCheck_unlock,
    taPush,
    formatCardDisplayName,
    post_dispatch_emplyees_mutil,
    post_dispatch_detail_mutil,
    getfriendtrade_takeworkcoin_all,
    displayFilter,
    getAvatarFromProfile,
    getAvatarFromUserInfo,
    getPowerXY,
    getFriendtrade_takecoininfo,
    getFriendtradeVirtualLfg,
    getFriendtradeChainStake,
    getFriendtradeLocklfgInfo,
    getFriendtradeLootMatchlist,
    getFriendtradeLootLastevent,
    filterErrorCode,
    getFriendtradeMintunlocklfg,
    floorToFix,
    getStakeYieldClaimed,
    formatTimestampToTime,
    getBattlePointRankImg,
    getTransactionErrorMsgId,
    post_batch_users_profile,
    convertScore,
    openMoonPay,
    sliceAddr,
    mintNftProtectlfg,
    getFitSilderWidthPercent,
    getTransferEstGasPrice,
    getChainInfoById,
    getChainTokenInfoById,
    moveEVMChain,
}
