import React, {memo, useEffect, useMemo, useState} from "react";
import {useTranslation, Trans} from 'react-i18next';
import {connect} from "react-redux";
import {Button, Slider, message} from 'antd';
import s from "./index.module.less";
import ButtonFactory from "src/common/ButtonFactory";
import {
    getfriendtrade_rank_claim,
    getAvatarFromProfile,
    displayFilter,
    getBattlePointRankImg,
    convertWalletBalance,
    getfriendtrade_loot_randomsearch,
    formatCardDisplayName,
    numberClamp,
    getFriendtrade_loot_employeelist,
    getfriendtrade_rank_selfdata,
    formatNumberWithUnit,
    vCoinString,
    getFriendtrade_takecoininfo,
    getearninfo,
    getFriendtradeLocklfgInfo,
    getFriendtradeLootMatchlist,
    getFriendtradeLootLastevent,
    getAvatarFromUserInfo,
    getTaskGetreward,
    getuserinfo,
    getFitSilderWidthPercent,
} from "@/utils/common";
import {
    gameTrillionaireBattleLeaderBoardPath,
    gameTrillionaireBattlingPath,
    publicChatsPath
} from "@/routes/config";
import CustomIcon from "src/common/CustomIcon";
import {useNavigate} from "react-router-dom";
import MainHeader from "src/common/MainHeader";
import AntButton from "@/common/AntButton";
import {AutoStrangeRule} from "@/utils/strangeRule";
import RankReward from '@/utils/json/RankReward.json';
import TaskConf from "@/utils/json/TaskConf.json";
import Countdown from "src/common/Countdown";
import ModelRankReward from 'src/model/ModelRankReward';
import AntModal from "@/common/AntModal";
import ModelHowToEarn from "@/model/ModelHowToEarn";
import ModelApplyMint from "src/model/ModelApplyMint";
import {displayNewbieTaskStartId} from "@/utils/env";
import AvatarInfo from "src/common/AvatarInfo";
import GetCoinAni from "@/model/GetCoinAni";
import CrackModelHistoryActivities from "@/model/CrackModelHistoryActivities";

const BattleHome = (
    {
        userInfo,
        earnInfo,
        setLootTargetUserInfo,
        lootSearchClubLvRange,
        setLootSearchClubLvRange,
        lootEmployeeUserInfo,
        setLootEmployeeUserInfo,
    }
) => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const [lootList, setLootList] = useState(null);
    const [claimInfo, setClaimInfo] = useState(null);
    const [showRankRewardPanel, setShowRankRewardPanel] = useState(false);
    const [showApplyMint, setShowApplyMint] = useState(false);
    const [showHowToEarn, setShowHowToEarn] = useState(false);
    const [lockLfgInfo, setLockLfgInfo] = useState(null);
    const [mintInfo, setMintInfo] = useState(null);
    const [updateLootEventTimes, setUpdateLootEventTimes] = useState(0);
    const [lootLastEventData, setLootLastEventData] = useState(null);
    const [showGetCoinAni, setShowGetCoinAni] = useState(false);
    const [showHistoryActivities, setShowHistoryActivities] = useState(false);

    const {
        selfData,
        platformData,
        userId
    } = useMemo(() => {
        return userInfo || {}
    }, [userInfo]);

    const {
        task
    } = useMemo(() => {
        return selfData || {}
    }, [selfData]);

    const {platformMap, useType} = useMemo(() => {
        return platformData || {}
    }, [platformData])

    const {avatarUrl, originalAvatarUrl} = useMemo(() => {
        return getAvatarFromUserInfo(userInfo)
    }, [platformMap, useType, userInfo])

    const newbieTaskList = useMemo(() => {
        let list = [];
        let tempTaskId = displayNewbieTaskStartId;
        while (tempTaskId !== 0) {
            let tempTask = TaskConf.find((v) => v.id === tempTaskId);
            if (tempTask) {
                list.push(tempTask);
                tempTaskId = tempTask.nextTaskID;
            } else {
                tempTaskId = 0;
            }
        }
        //list.push({id: -1, bgImagePath: "/img/howtoearn/-1.webp"});
        return list;
    }, []);

    const currentTask = useMemo(() => {
        let res = null;
        let oneShotTasks = task?.oneShotTasks;
        if (oneShotTasks && oneShotTasks.length > 0 && newbieTaskList && newbieTaskList.length > 0) {
            for (let i = 0; i < newbieTaskList.length; i++) {
                let tempTask = oneShotTasks.find((v) => v.taskId === newbieTaskList[i].id);
                if (tempTask) {
                    res = {
                        taskConfig: newbieTaskList[i],
                        taskInfo: tempTask,
                    };
                    break;
                }
            }
        }
        return res;
    }, [task, task?.oneShotTasks, newbieTaskList]);

    const {
        claimCoin
    } = useMemo(() => {
        return earnInfo || {}
    }, [earnInfo]);

    const {
        rankIdx,
        leftSendRewardSec
    } = useMemo(() => {
        return claimInfo || {}
    }, [claimInfo]);

    const updateLootList = () => {
        getFriendtradeLootMatchlist().then(data => {
            let tempList = [];
            if (data?.matchUnits?.length > 0) {
                for (let i = 0; i < 3 && i < data.matchUnits.length; i++) {
                    tempList.push({...data.matchUnits[i], isEmpty: false});
                }
            }
            while (tempList.length < 3) {
                tempList.push({isEmpty: true});
            }
            setLootList(tempList);
        }).catch(err => {
            console.error(err);
        });
    }

    const currentTaskFinished = () =>{
        if (currentTask){
            return currentTask?.taskInfo?.count >= currentTask?.taskConfig?.param;
        }
        return false;
    }

    const canGetReward = () =>{
        return !!currentTask?.taskInfo?.finish;
    }

    useEffect(() => {
        updateLootEvent();
    }, [updateLootEventTimes])

    const updateLootEvent = () =>{
        getFriendtradeLootLastevent().then(data => {
            setLootLastEventData(data?.lastLootEventData);
            setTimeout(() => {
                setUpdateLootEventTimes(updateLootEventTimes+1); 
            }, 10000);
        }).catch(err => {
            console.error(err);
            setTimeout(() => {
                setUpdateLootEventTimes(updateLootEventTimes+1); 
            }, 10000);
        });
    }

    useEffect(() => {
        updateLootList();
        /*if (!lootSearchClubLvRange || lootSearchClubLvRange.length < 2) {
            let startRange = 1;
            let endRange = 15;
            if (userInfo?.selfData?.tierId) {
                startRange = numberClamp(userInfo?.selfData?.tierId - 1, 1, 15);
                endRange = numberClamp(userInfo?.selfData?.tierId + 1, 1, 15);
            }
            setLootSearchClubLvRange([startRange, endRange]);
        }*/

        getSelfEmployeeList();
        getClaimInfo();
        getTakeCoinInfo();
        getearninfo().then(res => {
        }).catch(e => {
            console.log(e);
        });
        updateLocklfgInfo();
    }, [])

    const getClaimInfo = () => {
        getfriendtrade_rank_selfdata().then(res => {
            setClaimInfo(res);
        }).catch(err => {
            console.error(err.message);
        })
    }

    const updateLocklfgInfo = () => {
        getFriendtradeLocklfgInfo().then(res => {
            setLockLfgInfo(res);
        }).catch(e => {
            console.log(e);
        });
    }

    const getTakeCoinInfo = () => {
        getFriendtrade_takecoininfo().then(res => {
            setMintInfo(res);
            //setNum(Math.floor(res?.chainApplyMinCoin))
            //setChainApplyOrderInfo(res?.chainApplyOrderInfo)
        }).catch(e => {
            console.error(e);
        });
    }

    const getMissionReward = () =>{
        getTaskGetreward(currentTask?.taskConfig?.id).then(res => {
            if (res?.rewards?.[0]?.num > 0){
                message.info({content: (<div className="flex_center_center color-black">
                    {t('Successfully claimed reward')}:
                    <CustomIcon width={20} height={20} className="ml2 mr2"
                                                    imgName={'Picture/UI_Picture-Currency_LFG_01'}/>
                    {res?.rewards?.[0]?.num}
                </div>)});
                playGetCoinAni();
            }
            getuserinfo();
        }).catch(e => {
            console.error(e);
        });
    }

    const getSelfEmployeeList = () => {
        setLootEmployeeUserInfo(null);
        getFriendtrade_loot_employeelist().then(res => {
            if ((!lootEmployeeUserInfo || !(lootEmployeeUserInfo?.userId > 0)) && res?.employees && res?.employees?.length > 0) {
                for (let i = 0; i < res.employees.length; i++) {
                    let tempEmployee = res.employees[i];
                    if (tempEmployee.energy >= 5 && tempEmployee.attackCD === 0) {
                        setLootEmployeeUserInfo(tempEmployee);
                        break;
                    }
                }
            }
        }).catch(e => {
            console.error(e);
        })
    }

    const startLoot = (item) => {
        if (item) {
            console.log("home loot", item);
            setLootTargetUserInfo(item);
            navigate(gameTrillionaireBattlingPath);
        }
    }

    const randomLoot = () => {
        getfriendtrade_loot_randomsearch([0, -1], lootEmployeeUserInfo?.userId || 0).then(res => {
            if (res?.searchData && res?.searchData?.userId > 0) {
                setLootTargetUserInfo(res?.searchData);
                navigate(gameTrillionaireBattlingPath);
            }
        }).catch(e => {
            console.error(e);
        })
    }

    const playGetCoinAni = () =>{
        setShowGetCoinAni(true);
        setTimeout(() => {
            setShowGetCoinAni(false);
        }, 1000);
    }

    return (
        <div className={`${s.wrap} mainWrap`}>
            <MainHeader isGameHeader={true} hasBackBtn={false} leftTitle={t('Battle')} rightMenuIcon ={"Button/UI_Button-D_Home_On"}></MainHeader>
            <div className={s.topContent}>
                {
                    lootLastEventData?.eventId > 0 &&
                    <div className={s.head} style={{backgroundImage: `url(/UI/Picture/UI_Picture-Main_TopBlackMask_01.png)`}}>
                        <CustomIcon width={31.68} height={26.64} className={s.headLineIcon}
                                            imgName={'Picture/UI_Picture-Currency_Horn_01'}/>
                        <div className={`${s.headLine}`}>
                            <div className={`${s.headScroll} ${s.headScrollAniRoot} flex_center_center textnowrap color-blue-light`}>
                                <Trans
                                    i18nKey="userA Looted userB and earned:"
                                    defaults="<userA>{{userA}}</userA> Looted <userB>{{userB}}</userB> and earned:"
                                    values={{ userA: lootLastEventData?.employeeProfile?.displayName,
                                        userB: lootLastEventData?.managerProfile?.displayName }}
                                    components={{ userA: <span className="color-white ml5 mr5"></span>,
                                        userB: <span className="color-white ml5 mr5"></span>}}
                                />
                                <CustomIcon width={20} height={20} className="ml2 mr2"
                                            imgName={'Picture/UI_Picture-Currency_LFG_01'}/>
                                <div className={`color-white`}>
                                    {convertWalletBalance(Number(lootLastEventData?.coin)) || 0}
                                </div>
                                <CustomIcon width={20} height={20} className="ml2 mr2"
                                            imgName={'Picture/UI_Picture-Currency_Power_01'}/>
                                <div className={`color-white`}>
                                    {lootLastEventData?.bpChangeVal || 0}
                                </div>
                            </div>
                        </div>
                        <CustomIcon width={22} height={22} className={s.headActivitiesIcon} imgName={'Button/UI_Button_YellowRound-CopyNumberWordName_01'}
                            onClick={()=>{
                                setShowHistoryActivities(true);
                            }}/>
                    </div>
                }
                <div className={s.selfHead} style={{backgroundImage: `url(/UI/Picture/UI_Picture-Main_Top_01.png)`}}>
                    <div className={`${s.headLine} flex_center_between`}>
                        <AvatarInfo onClick={()=>{navigate(publicChatsPath+'/'+userId)}} src={avatarUrl || originalAvatarUrl} size={70}></AvatarInfo>
                        <div className={`flex_center_center_col`}>
                            <div className={`flex_center_start`}>
                                <div className={`${s.estReward} flex_center_start`}>
                                    <CustomIcon width={20} height={20} className="ml5 mr5"
                                                imgName={'Picture/UI_Picture-Currency_UP_01'}/>
                                    <div className={`tll`} style={{minWidth: '40px'}}>
                                        {formatNumberWithUnit(RankReward.find(v => rankIdx >= v.rankMin && rankIdx <= v.rankMax)?.rewardNum || 0) || '--'}
                                    </div>
                                    <span className="fs14 color-white textnowrap" style={{minWidth: '120px'}}>
                                        {
                                            leftSendRewardSec &&
                                            <Trans
                                                i18nKey="Setties in sometime"
                                                defaults="Setties in <time/>"
                                                components={{
                                                    time: leftSendRewardSec && <Countdown leftSecond={leftSendRewardSec}/>
                                                }}
                                            />
                                        }
                                    </span>
                                </div>
                                <CustomIcon width={18} height={18} imgName={'Button/UI_Button_QuestionMark_01'}
                                            onClick={() => {
                                                setShowRankRewardPanel(true);
                                            }}/>
                            </div>
                            <div className={`mt10 flex_center_start`}>
                                <div className={`${s.lfgPoolLine} flex_center_start`}>
                                    {
                                        (claimCoin !== undefined && claimCoin !== null && mintInfo?.chainApplyMinCoin > 0) &&
                                        <div
                                            className={`${s.lfgPoolContent} ${claimCoin >= mintInfo?.chainApplyMinCoin ? s.lfgPoolContentYellow : s.lfgPoolContentBlue}`}
                                            style={{
                                                width: getFitSilderWidthPercent(22, 194, mintInfo?.chainApplyMinCoin || 0, claimCoin || 0)
                                            }}>
                                        </div>
                                    }
                                    <div className={`${s.lfgPoolSliderContent} flex_center_start`}>
                                        <CustomIcon width={20} height={20} className="ml5 mr5"
                                                imgName={'Picture/UI_Picture-Currency_LFG_01'}/>
                                        <div className={`${s.lfgPoolText} fb`}
                                            style={{color: claimCoin >= mintInfo?.chainApplyMinCoin ? 'rgba(0,0,0,1)' : 'rgba(255,255,255,1)'}}>
                                            {
                                                claimCoin >= mintInfo?.chainApplyMinCoin ?
                                                <>
                                                    {`${convertWalletBalance(Number(claimCoin) || 0)}`}
                                                </> :
                                                <>
                                                    {`${convertWalletBalance(Number(claimCoin) || 0)}/${vCoinString(mintInfo?.chainApplyMinCoin) || 0}`}
                                                </>
                                            }
                                        </div>
                                        {
                                            showGetCoinAni && 
                                            <>
                                            <GetCoinAni 
                                                className={s.getCoinAniRoot} 
                                                coinType={1}></GetCoinAni>
                                            </>
                                        }
                                    </div>
                                </div>
                                <CustomIcon width={21} height={20} imgName={'Button/UI_Button_Extract-White_01'}
                                            onClick={() => {
                                                setShowApplyMint(true);
                                            }}/>
                            </div>
                        </div>
                        <div className={`flex_center_center_col`}>
                            <CustomIcon width={40} height={40} imgName={'Picture/UI_Picture-Currency_Rank_01'}
                                onClick={()=>{navigate(gameTrillionaireBattleLeaderBoardPath);}}/>
                            <div className={`fs20 color-yellow`}>
                                {`${rankIdx > 10000 ? '9999+' : rankIdx || '--'}`}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${s.content}`} style={{marginTop: `${lootLastEventData?.eventId > 0?'170px':'110px'}`}}>
                <div className={s.titleLine}>
                    <div className={s.titleLineLeft}>
                        <CustomIcon width={17} height={39} className="mr5"
                                    imgName={'Picture/UI_Picture_Title-Yellow_01'}/>
                        {AutoStrangeRule(t('Match Clubs'))}
                    </div>
                    <div className={s.titleLineRight}>
                        <AntButton className={`btn_white`} onClick={() => {
                            navigate(gameTrillionaireBattleLeaderBoardPath);
                        }}>
                            <div className="fs14 color-black fb">
                                {t('LEADERBOARD')}
                            </div>
                        </AntButton>
                    </div>
                </div>
                <div className={`${s.rankPart} flex_center_between`}>
                    {
                        lootList?.map((item, index) => {
                            const {
                                userId,
                                profile,
                                tierId,
                                bpLevel,
                                battlePoints,
                                claimCoin,
                                isEmpty,
                                power,
                            } = item;
                            const {displayName, accType} = profile || {};
                            const originalAvatarUrl = getAvatarFromProfile(profile, 1);
                            const avatarUrl = getAvatarFromProfile(profile);
                            return (
                                <div className={`${s.topClubRoot}`} key={index}>
                                    {
                                        isEmpty ?
                                            <div className={s.emptyCard}
                                                 style={{backgroundImage: `url(/UI/Picture/UI_Picture_CardWindowPlus_01.png)`}}
                                            >
                                            </div> :
                                            <div className={s.clubItemRoot} onClick={() => {
                                                startLoot(item);
                                            }}>
                                                <div className={s.clubAvatar}
                                                     style={{backgroundImage: `url(/UI/Picture/UI_Picture_DefaultImage_BG_01.png)`}}>
                                                </div>
                                                <div className={s.clubAvatar}
                                                     style={{backgroundImage: `url(${originalAvatarUrl || avatarUrl})`}}>
                                                </div>
                                                <div className={s.clubCard}
                                                     style={{backgroundImage: `url(/UI/Picture/UI_Picture_CardWindow_01.png)`}}>
                                                    <div className={s.cardTop}>
                                                        <div className={s.cardName}>
                                                            {formatCardDisplayName(displayFilter(displayName))}
                                                        </div>
                                                        <div className={s.cardRank}>
                                                            <CustomIcon className="" width={16} height={16}
                                                                        imgName={`Picture/Rank/RankStar_${tierId}`}/>
                                                            <CustomIcon className="ml2" width={16} height={16}
                                                                        imgName={`Picture/BPTier/${getBattlePointRankImg(bpLevel || 1)}`}/>
                                                        </div>
                                                    </div>
                                                    <div className={s.cardBottom}>
                                                        <div className={`${s.cardBottomPublic} ${s.cardBottomLeft}`}>
                                                            <CustomIcon width={28} height={28}
                                                                        imgName={'Picture/UI_Picture_Power_01'}></CustomIcon>
                                                            {Number(power || 0)?.toLocaleString("en-US")}
                                                        </div>
                                                        <div className={`${s.cardBottomPublic} ${s.cardBottomRight}`}>
                                                            <CustomIcon width={28} height={28}
                                                                        imgName={'Picture/UI_Picture-Currency_LFG_01'}></CustomIcon>
                                                            {convertWalletBalance(claimCoin || 0)}
                                                        </div>
                                                    </div>
                                                    {
                                                        accType === 1 &&
                                                        <div className={s.wishPart}
                                                             style={{backgroundImage: `url(/UI/Picture/UI_Picture-CardWish_01.png)`}}>
                                                        </div>
                                                    }
                                                </div>
                                                {
                                                    accType === 2 && 
                                                    <CustomIcon className={s.nftIcon} width={32} height={32}
                                                                    imgName={'Picture/UI_Picture-NFT'}/>
                                                }
                                            </div>
                                    }
                                </div>
                            );
                        })
                    }
                </div>
            </div>
            <div className={`${s.bottomPart} flex_center_start_col`}
                 style={{backgroundImage: `url(/UI/Picture/UI_Picture-Main_Down_04.png)`}}>
                {
                    currentTask?.taskConfig?.id > 0 &&
                    <div className={`${s.missionPart} flex_center_start_col`}>
                        <div className={`${s.missionTitle} flex_center_start white-space-pre-wrap`}>
                            {
                                currentTask?.taskConfig?.descriptionType === 0 ?
                                    <Trans
                                        i18nKey={`TaskConf_${currentTask?.taskConfig?.id}_description`}
                                        defaults="xxx"
                                        components={{color: <span className={`color-white`}></span>}}
                                    /> :
                                    <Trans
                                        i18nKey={`TaskConf_${currentTask?.taskConfig?.id}_description`}
                                        defaults="xxx"
                                        values={{times: `${currentTask?.taskConfig?.param}`}}
                                        components={{color: <span className={`color-white`}></span>}}
                                    />
                            }
                            {` +${currentTask?.taskConfig?.rewardNum / 10000} $LFG`}
                            <CustomIcon width={20} height={20} className="ml5 mr5"
                                        imgName={'Picture/UI_Picture-Currency_LFG_01'}/>
                        </div>
                        <div className={`${s.missionProgress} mt10 mb30 flex_center_start`}>
                            <div className={`${s.missionProgressText} fb`} style={{color: (currentTaskFinished() || canGetReward()) ? 'rgba(0,0,0,1)' : 'rgba(255,255,255,1)'}}>
                                {(currentTaskFinished() || canGetReward()) ? `${currentTask?.taskConfig?.param || 0}/${currentTask?.taskConfig?.param || 0}` : `${currentTask?.taskInfo?.count || 0}/${currentTask?.taskConfig?.param || 0}`}
                            </div>
                            {
                                (currentTask?.taskConfig?.param > 0) &&
                                <div className={`${(currentTaskFinished() || canGetReward())? s.missionProgressContentFinished:s.missionProgressContentUnfinish}`}
                                     style={{
                                         width: (currentTaskFinished() || canGetReward()) ? '100%' : `${currentTask?.taskInfo?.count * 100 / currentTask?.taskConfig?.param}%`
                                     }}>
                                </div>
                            }
                            {
                                (currentTaskFinished() || canGetReward()) ?
                                    <div className={`${s.missionGift}`} onClick={() => {
                                            getMissionReward();
                                        }}>
                                        <div className={`${s.missionGiftBg} flex_center_center`}
                                            style={{backgroundImage: `url(/UI/Picture/UI_Picture_Box-Light_01.png)`}}>
                                        </div>
                                        <div className={`${s.missionGiftInner}`}
                                            style={{backgroundImage: `url(/UI/Picture/UI_Picture_Box_01.png)`}}>                                           
                                        </div> 
                                    </div>  :
                                    <div className={s.missionGift}
                                        style={{backgroundImage: `url(/UI/Picture/UI_Picture_Box_01.png)`}}
                                        onClick={() => {
                                            setShowHowToEarn(true);
                                        }}>
                                    </div>
                            }
                        </div>
                    </div>
                }
                <div className={`mt5`}>
                    <ButtonFactory color={1} size={11} scale={1.25} onClick={() => {
                        randomLoot();
                    }}>
                        <div className='fb' style={{fontSize: `30px`, color: `rgba(0, 0, 0, 1)`}}>
                            {t('Quick Loot')}
                        </div>
                        <CustomIcon width={30} height={30} className="ml10"
                                    imgName={'Picture/UI_Picture_Battle_02'}/>
                    </ButtonFactory>
                </div>
            </div>
            {
                showHowToEarn &&
                <ModelHowToEarn
                    tasks={task}
                    setShowHowToEarn={setShowHowToEarn}/>
            }
            {
                showHistoryActivities &&
                <CrackModelHistoryActivities closeFn={()=>{setShowHistoryActivities(false)}} showType={2}/>
            }
            <AntModal
                width='330px'
                title={t('LEADERBOARD & $UP POINTS')}
                className="confirmModalWrap"
                centered={true}
                open={showRankRewardPanel}
                destroyOnClose={true}
                onOk={() => setShowRankRewardPanel(false)}
                onCancel={() => setShowRankRewardPanel(false)}
            >
                <ModelRankReward/>
            </AntModal>
            <AntModal
                width='330px'
                className="confirmModalWrap"
                centered={true}
                open={showApplyMint}
                title={t('$LFG Withdrawal')}
                destroyOnClose={true}
                onOk={() => setShowApplyMint(false)}
                onCancel={() => setShowApplyMint(false)}
                zIndex={1999}
            >
                <ModelApplyMint
                    lockLfgInfo={lockLfgInfo}
                    updateLocklfgInfoFn={updateLocklfgInfo}
                    closeFn={() => {
                        setShowApplyMint(false)
                    }}
                />
            </AntModal>
        </div>
    )
}
const mapStateToProps = ({app}) => {
    return {
        tranList: app.tranList,
        userInfo: app.userInfo,
        earnInfo: app.earnInfo,
        lootSearchClubLvRange: app.lootSearchClubLvRange,
        lootEmployeeUserInfo: app.lootEmployeeUserInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setTranNowType: (params) => {
            return dispatch({
                type: "app/setTranNowType",
                payload: params,
            });
        },
        setLootTargetUserInfo: (params) => {
            return dispatch({
                type: "app/setLootTargetUserInfo",
                payload: params,
            });
        },
        setLootEmployeeUserInfo: (params) => {
            return dispatch({
                type: "app/setLootEmployeeUserInfo",
                payload: params,
            });
        },
        setLootSearchClubLvRange: (params) => {
            return dispatch({
                type: "app/setLootSearchClubLvRange",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(BattleHome));
