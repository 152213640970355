import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import s from "./index.module.less";
import { useTranslation, Trans } from 'react-i18next';
import CustomIcon from "@/common/CustomIcon";
import { connect } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
// import Banner from "../../../common/Banner";
import {
    stringToNumber,
    convertWalletBalance,
    sliceStr,
    getAvatarFromProfile,
    getTokenBalanceOf,
    copyFn,
    sliceAddr,
    post_live_announce_query,
    post_live_announce_del,
    easeJoinGroup,
} from "@/utils/common"
import MainHeader from "@/common/MainHeader";
import ButtonFactory from "@/common/ButtonFactory";
import AntModal from "@/common/AntModal";
import AntButton from "@/common/AntButton";
import AvatarInfo from "@/common/AvatarInfo";
import { Tabs, Modal, message } from "antd";
import { memeClubInfo, getChainInfo, setFavorite } from "@/utils/memeClub";
import { AutoStrangeRule } from "@/utils/strangeRule";
import ModalMemePackTrans from "@/model/ModalMemePackTrans";
import MemeClubPack from "@/common/MemeClubPack";
import { getBalance } from "@/utils/uniswapWeb3";
import { useWallets, usePrivy } from "@privy-io/react-auth";
import memeChainInfo from "@/utils/json/memeChainInfo.json";
import ChatContent from "@/common/ChatContent";

// Chat && Live code 
import { leave, rtc, checkCamera, checkMicrophones } from "@/utils/basicVoiceCall";
import MemeChat from "@/common/MemeChat";
import MemeLivePanel from "@/common/MemeLivePanel";
import Nosleep from "nosleep.js";
import ModelPermission from "@/model/ModelPermission";
import { gameMemeClubPath } from "@/routes/config";
import KChart from "../KChart";
const Chat = (
    {
        userInfo,
        walletBalanceList,
        groupInfo,
        chainInfo,
        setGroupInfo,
        setWalletBalanceList,
        showTransModelFn,
        clubBasicInfo,
        clubId
    }
) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation()
    const { userId } = useMemo(() => {
        return userInfo || {}
    }, [userInfo]);

    const [showLiveTip,setShowLiveTip] = useState(true)
    const { clubInfo, holdNum, inMarket, creatorProfile, creatorUserId, coinTokenAddr, onAlive, isPrive = false } = useMemo(() => {
        return clubBasicInfo || {}
    }, [clubBasicInfo])


    const { clubName, imageUrl, coinId,symbolName } = useMemo(() => {
        return clubInfo || {}
    }, [clubInfo])

    // Chat && Live code
    const [groupId, setGroupId] = useState('')
    const isSelfChat = useMemo(() => {
        return userId === Number(creatorUserId)
    }, [userId, creatorUserId])
    const nosleep = new Nosleep();
    useEffect(() => {
        if (onAlive) {
            setShowLivePanel(false);
        }

        if (!onAlive && rtc.joinState) {
            // backFn()
            rtc.joinState = false
        }
        clubId && onAlive && !isSelfChat && setShowLivePanel(true);




    }, [onAlive, isSelfChat, clubId])

    useEffect(() => {
        if (clubId) {
            if ((!groupInfo || !groupInfo[clubId] || !groupInfo[clubId]?.group_id || groupInfo[clubId]?.expireTimes < new Date().getTime())) {
                console.log(`start call easeJoinGroupFn`);
                easeJoinGroupFn()
            } else {
                const obj = {};
                const i = groupInfo[clubId];
                obj[clubId + ''] = {
                    ...i,
                    expireTimes: new Date().getTime() + 25 * 60 * 1000
                }
                setGroupInfo(obj)
                setGroupId(groupInfo[clubId]?.group_id)
            }

        }
    }, [clubId])

    const startLive = async () => {
        const hasCamera = await checkCamera();
        console.log(hasCamera);
        if (!hasCamera) {
            setShowPermission(true);
            setShowPermissionType(t('Camera'));
            return
        }
        const hasMicrophone = await checkMicrophones();
        console.log(hasCamera);
        if (!hasMicrophone) {
            setShowPermission(true);
            setShowPermissionType(t('Microphone'));
            return
        }
        if (hasCamera && hasMicrophone) {
            // delete announce time
            post_live_announce_del({
                keyId: parseInt(clubId),
                groupType: 1
            }).then(data => {
                console.log(data);
            }).catch(e => {
                console.log(e);
            });

            setShowLivePanel(true)
        }
    }
    const [showPermission, setShowPermission] = useState(false);
    const [showPermissionType, setShowPermissionType] = useState('');
    const hasOneKey = useMemo(() => {
        if (holdNum) {
            return true
        }
        return false
    }, [holdNum])



    const [pageLoading, setPageLoading] = useState(true);
    const [nextLiveTime, setNextLiveTime] = useState(0);
    const [openTranPanel, setOpenTranPanel] = useState(false);
    const [showLivePanel, setShowLivePanel] = useState(false);

    const [title, setTitle] = useState('');
    useEffect(
        () => {
            clubId && post_live_announce_query({
                keyId: parseInt(clubId),
            }).then(data => {
                setNextLiveTime(data?.startTime || 0);
                setTitle(data?.title);
            }).catch(e => {
                console.log(e);
            });
        }, [clubId]
    )

    useEffect(() => {
        if (groupId) {
            setPageLoading(false)
        }
    }, [groupId])

    const backFn = async () => {
        if (showLivePanel) {
            if (!isSelfChat || !rtc.joinState) {
                await leave();
                setShowLivePanel(false);
            } else {

                Modal.confirm({
                    title: t('End This Live Stream'),
                    content: t('Are you sure you want to end this live stream now?'),
                    okText: t('Confirm'),
                    cancelText: t('Cancel'),
                    className: 'liveModal',
                    onOk: () => {
                        leave();
                        rtc.joinState = false;
                        nosleep.disable();
                        setShowLivePanel(false)
                    }
                });
            }

        } else {
            if (location.key !== "default") {
                navigate(-1);
            } else {
                navigate(gameMemeClubPath, { replace: true });
            }
        }

    }

    const [isLoadingEaseJoinGroup, setIsLoadingEaseJoinGroup] = useState(false);
    const [timerTrigger, setTimerTrigger] = useState(0);
    useEffect(() => {
        timerTrigger && easeJoinGroupFn();
    }, [timerTrigger])

    const easeJoinGroupFn = () => {

        if (!isLoadingEaseJoinGroup && clubId != null) {
            setIsLoadingEaseJoinGroup(true);
            easeJoinGroup(clubId, 1).then(res => {
                setIsLoadingEaseJoinGroup(false);
                const obj = {};
                obj[clubId + ''] = {
                    group_id: res?.groupId,
                    msg_types: ['txt'],
                    expireTimes: new Date().getTime() + 25 * 60 * 1000
                }
                setGroupInfo(obj)
                setGroupId(res?.groupId)
            }).catch(e => {
                setPageLoading(false);
                message.error({ content: `Join Group Failed` })
                setTimeout(() => {
                    setIsLoadingEaseJoinGroup(false);
                    setTimerTrigger(timerTrigger + 1);
                }, 5000);
            })
        }

    }

    const [chartShow,setChartShow] = useState(true)

    return (
        <div className={`${s.modalWrap}`}>
            {!inMarket && <div className={`${s.chartWrap} ${chartShow ? '' : s.chartHide}`}>
                <div className={`${s.chartTitle}`}>
                    {t('Graphic Statistics (Time Interval: 5m)')}
                    <CustomIcon className={s.chartIcon} onClick={()=>{setChartShow(!chartShow)}} imgName={chartShow ? 'Button/UI_Button_Arrow_Up_01' : 'Button/UI_Button_Arrow_Down_01'} width={22} height={12}></CustomIcon>
                </div>
                <div style={{display:chartShow?"block":"none"}}>
                    <KChart coinName={chainInfo?.displayCoinName} clubId={clubId} symbolName={clubName}></KChart>
                </div>
            </div>
            } 
            
            <div className={s.modal}>


                <div className={s.content}>
                    <MemeChat
                        keyId={userId}
                        // hasOneKey={hasOneKey}
                        // isSelfChat={isSelfChat}
                        isSelfChat={isSelfChat}
                        hasOneKey={hasOneKey}
                        groupId={groupId}
                        setPageLoading={setPageLoading}
                        startLive={startLive}
                        setNextLiveTime={setNextLiveTime}
                        setTitle={setTitle}
                        managerId={''}
                        pageLoading={pageLoading}
                        nextLiveTime={nextLiveTime}
                        nextLiveTitle={title}
                        setGroupId={setGroupId}
                        clubId={clubId}
                        inMarket={inMarket}
                        chainInfo={chainInfo}
                    />
                </div>
            </div>

            {showLivePanel && groupId &&
                <MemeLivePanel
                    isSelfChat={isSelfChat}
                    avatarurl={imageUrl}
                    setOpenTranPanel={setOpenTranPanel}
                    backFn={backFn}
                    hasOneKey={hasOneKey}
                    selfUid={userId}
                    keyId={userId}
                    groupId={groupId}
                    nosleep={nosleep}
                    profile={{
                        displayName: clubName,
                        avatarUrl: getAvatarFromProfile(creatorProfile)
                    }}
                    setShowOtherTransContent={() => { }}
                    showLivePanel={showLivePanel}
                    setNeedUpdateVoteInfo={() => { }}
                    showTransModelFn={showTransModelFn}
                    inMarket={inMarket}
                    holdNum={holdNum}
                    clubInfo={clubInfo}
                    creatorUserId={creatorUserId}
                    setGroupId={setGroupId}
                />
            }

            {!inMarket &&
                <div className={`${s.bottomWrap} ${holdNum ? s.hasKey : ''}`}>
                    {!holdNum ?
                        <>
                            <div className="df aic jcc mb20">
                                <Trans
                                    i18nKey="You need a xx to access"
                                    defaults="You need a <coinImg></coinImg> to access"
                                    values={{ what: 'world' }}
                                    components={{
                                        coinImg: <CustomIcon className="ml5 mr5" width={16} height={16}
                                            imgName={'Picture/UI_Picture_Cardpack_01'}
                                            name={'Picture/UI_Picture_Cardpack_01'} />
                                    }}
                                />
                            </div>
                            <AntButton className="fs16 btn_public" onClick={() => {
                                showTransModelFn(1)

                            }} style={{ margin: '0 auto' }}>{t('BUY NOW')}</AntButton>
                        </> :
                        <>
                            <div className="color-blue-light fb">{t('You Own')}</div>
                            <div className="df aic jc-spaceBetween mr20 ml20">
                                <ButtonFactory
                                    size={32} //1:L, 2:M, 3:S, 11:L1, 21:M1, 22:M2, 23:M3, 31:S1, 32:S2, 33:S3
                                    scale={1.6}
                                    isMirror={true}
                                    color={3}
                                    onClick={() => holdNum && showTransModelFn(2)}>
                                    {t('SELL')}
                                </ButtonFactory>
                                <div className={s.keyNumber}><CustomIcon className="ml5 mr5" width={50} height={50}
                                    imgName={'Picture/UI_Picture_Cardpack_01'}
                                />x {holdNum}</div>
                                <ButtonFactory
                                    size={32} //1:L, 2:M, 3:S, 11:L1, 21:M1, 22:M2, 23:M3, 31:S1, 32:S2, 33:S3
                                    scale={1.6}
                                    onClick={() => showTransModelFn(1)}>
                                    {t('BUY')}
                                </ButtonFactory>
                            </div>
                        </>
                    }
                </div>
            }

            {onAlive && showLiveTip && 

                <div className={`${s.liveBlock} `}>
                    <div className={`${s.bg}`} style={{
                        backgroundImage:`url(${imageUrl})`
                    }}></div>
                    {(!hasOneKey && isPrive) && <div className={s.liveBlockCon}>
                        <CustomIcon imgName="Picture/UI_Picture_Icon_Lock_01" width={26} height={26}></CustomIcon>
                        <div className="fs16">{t('Buy Packs To Access')}</div>
                    </div>} 
                    <div className={s.closeBtn} onClick={()=>{
                        setShowLiveTip(false)
                    }}>
                        <CustomIcon imgName="Button/UI_Button_Close_01" width={16} height={16}></CustomIcon>
                    </div>
                    <div className={s.liveBtn} onClick={()=>{
                        setShowLivePanel(true)
                    }}>
                        <CustomIcon imgName={`Picture/UI_Picture_Icon_Live_01`} className="mr5" width={18} height={18}></CustomIcon>
                        <span className={`fs14 fb`}>
                            {t('Live')}
                        </span>
                    </div>
                </div>
            }

            <AntModal
                width='330px'
                title={t('xx Permission Required', { ty: showPermissionType })}
                className="confirmModalWrap"
                centered={true}
                open={showPermission}
                destroyOnClose={true}
                onOk={() => setShowPermission(false)}
                onCancel={() => setShowPermission(false)}
            >
                <ModelPermission type={showPermissionType} />
            </AntModal>
        </div>

    )
}

const mapStateToProps = ({ app }) => {
    return {
        userInfo: app.userInfo,
        defaultInviteCode: app.defaultInviteCode,
        walletBalanceList: app.walletBalanceList,
        groupInfo: app.groupInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setWalletBalanceList: (params) => {
            return dispatch({
                type: "app/setWalletBalanceList",
                payload: params,
            });
        },
        setGroupInfo: (params) => {
            return dispatch({
                type: "app/setGroupInfo",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(Chat));
