import React, {useMemo, useEffect, memo, useState, useRef} from "react";
import s from "./index.module.less";
import {Button, Input} from "antd";

import {
    getMintSignature,
    TransactionError,
    getearninfo,
    PrivyLoginStatusError,
    formatEther,
    stringToNumber,
    unit,
    post_take_coin,
    formatNumberWithUnit,
    vCoinString,
    getFriendtrade_takecoininfo,
    getBattlePointRankImg,
    convertWalletBalance,
    getTransactionErrorMsgId
} from "@/utils/common";

import {applyMint} from "@/utils/lfgMintWeb3";
import {useNavigate} from "react-router-dom";
import {connect} from "react-redux";
import BPLevelConfig from '@/utils/json/BPLevelConfig.json';
import {displayLFGRefundTime} from "@/utils/env";
// import Countdown from "../Countdown";
import CustomIcon from "../../common/CustomIcon";
import {usePrivy, useWallets} from "@privy-io/react-auth";
import {useTranslation, Trans} from 'react-i18next';
import Countdown from "../../common/Countdown";
import {linkPath} from "@/routes/config";
import ButtonFactory from "src/common/ButtonFactory";

const ModelApplyMint = (
    {
        closeFn,
        userInfo,
        setShowDepositPanel,
        mintCd = 8,
        earnInfo,
        lockLfgInfo,
        updateLocklfgInfoFn
        // hasExtractingOrder = false, //预设值 已存在mint 订单， true: 不可mint false: 可mint状态
        // orderState = 1, //预设值 1: 可操作 2: 不可mint状态
        // limit = 40000,
        // minMintAmount = 1000
    }
) => {
    const {t, i18n} = useTranslation();
    // const maxNum = 20000;
    // const minMintAmount = 600;
    // const limit = 40000;
    const {logout} = usePrivy();
    const {wallets} = useWallets();
    const navigate = useNavigate();
    const [num, setNum] = useState(0);
    const inputRef = useRef(null);
    const [subFailded, setSubFailded] = useState(false);
    const [mintInfo, setMintInfo] = useState(null);
    const [done, setDone] = useState(false);
    const [subLoading, setSubLoading] = useState(false);
    const [chainApplyOrderInfo, setChainApplyOrderInfo] = useState(null)
    const [firstLoad, setFirstLoad] = useState(true);
    const [errorMsg, setErrorMsg] = useState('');

    useEffect(() => {
        getTakeCoinInfo();
    }, [])

    const getTakeCoinInfo = () => {
        getFriendtrade_takecoininfo().then(res => {
            // console.log(res);
            setMintInfo(res);
            setNum(Math.floor(res?.chainApplyMinCoin))
            setChainApplyOrderInfo(res?.chainApplyOrderInfo)
        }).catch(e => {
            console.error(e);
        });
    }

    const {walletAddr, selfData, platformData} = useMemo(() => {
        return userInfo || {}
    }, [userInfo]);
    const {
        balance,
        bpLevel,
        mintMinBP
    } = useMemo(() => {
        return selfData || {}
    }, [selfData]);

    const {
        claimCoin,
        mintLeftSec,
        mintEndTime
    } = useMemo(() => {
        return earnInfo || {}
    }, [earnInfo])

    const battlePointsShowList = useMemo(() => {
        let list = [];
        if (BPLevelConfig) {
            if (bpLevel <= 4) {
                for (let i = 0; i < Math.min(BPLevelConfig.length, 4); i++) {
                    list.push(BPLevelConfig[i]);
                }
            } else {
                let startIndex = bpLevel - 2;
                if (startIndex > BPLevelConfig.length - 3) {
                    startIndex = BPLevelConfig.length - 3;
                }
                for (let i = startIndex; i < startIndex + 3; i++) {
                    list.push(BPLevelConfig[i]);
                }
            }
        }
        return list;
    }, [bpLevel]);

    const getExtractMaxAmount = () => {
        if (BPLevelConfig) {
            let levelInfo = BPLevelConfig.find(item => item.ID === bpLevel);
            if (levelInfo?.extractLimit) {
                return levelInfo?.extractLimit;
            } else {
                return BPLevelConfig[0]?.extractLimit || 0;
            }
        }
        return 0;
    }

    const isAvailable = useMemo(() => {
        return num >= mintInfo?.chainApplyMinCoin && num <= claimCoin && num <= getExtractMaxAmount();
    }, [num, mintInfo, claimCoin])
    const amountChangeHandler = (e) => {
        let value = e.target.value.replace(/[^\d]/g, '');
        setNum(Math.floor(value));
        // document.execCommand("selectAll", false, null);
        // document.getSelection().collapseToEnd();
    }
    const amountBlurHandler = (e) => {
        setNum(Math.floor(Math.min(Math.min(num, claimCoin), getExtractMaxAmount())))
    }

    const mintLeftSecEnd = () => {
        setDone(false);
        getearninfo().then(res => {
        }).catch(e => {
            console.log(e);
        })
        getTakeCoinInfo();
        updateLocklfgInfoFn();
    }

    const applyFn = () => {
        setFirstLoad(false);
        post_take_coin(num).then(data => {
            const {newClaimCoin, mintCoin, orderId, lockSecond, timestamp, validFor, signature} = data
            setSubLoading(true)
            applyMint({
                    wallets,
                    amount: mintCoin,
                    orderId,
                    lockedUntil: lockSecond,
                    timestamp,
                    validFor,
                    signature
                }
            ).then(res => {
                    console.log(res);
                    getearninfo().then(res => {
                    }).catch(e => {
                        console.log(e);
                    })
                    updateLocklfgInfoFn();
                    setSubLoading(false);
                    setDone(true);
                    setSubFailded(false);
                }
            ).catch(e => {
                console.error(e);
                let errMsg = t(getTransactionErrorMsgId(e?.reason));
                setErrorMsg(errMsg);
                setSubLoading(false);
                setDone(true);
                setSubFailded(true)
                setTimeout(
                    () => {
                        getearninfo().then(res => {
                        }).catch(e => {
                            console.log(e);
                        })
                    }, 2000
                )
            })
        }).catch(e => {
            console.error(e);
        })
    }

    const endTime = Math.max(mintEndTime, (lockLfgInfo?.mintOrderFinishTimeStamp || 0));
    const nowTime = new Date().getTime() / 1000;

    return (
        <div className={s.content}>
            <div className={s.wrap}>
                <div className={`${s.noticetext} color-white`}>
                    {t('Extracting $LFG TIPS 01')}<br/>
                    {t('Extracting $LFG TIPS 02')}<br/>
                    <Trans
                        i18nKey="Extracting $LFG TIPS 03"
                        defaults="3.Mint your $LFG on-chain after an <red>8-hour</red> withdrawal review"
                        components={{red: <span className="color-red fb"></span>}}
                    /><br/>
                    <Trans
                        i18nKey="Extracting $LFG GUILD"
                        defaults="Click <icon></icon> on your profile to view status & claim $LFG"
                        components={{
                            icon: <CustomIcon width={20} height={20} imgName={'Button/UI_Button_Hourglass_01'}
                                              name={'Button/UI_Button_Hourglass_01'}/>
                        }}
                    /><br/>
                    {t('Extracting $LFG TIPS Add Times')}<br/>
                    <Trans
                        i18nKey='Extracting Add Times 01'
                        values={{time: 16}}
                        components={{yellow: <span className="color-yellow fb"></span>}}
                    /><br/>
                    <Trans
                        i18nKey='Extracting Add Times 02'
                        values={{time: 2}}
                        components={{yellow: <span className="color-yellow fb"></span>}}
                    /><br/>
                    <Trans
                        i18nKey='Extracting Add Times 03'
                        values={{time: 5}}
                        components={{yellow: <span className="color-yellow fb"></span>}}
                    /><br/>
                </div>
                <div className={s.amountWrap}>
                    <div className={s.t}>
                        <div className={s.i}>
                            <div className={`fs12 ${s.valname} color-blue-light`}>{t('$LFG Pool')}</div>
                            <div className={s.val}>
                                <CustomIcon width={20} height={20} imgName={'Picture/UI_Picture-Currency_LFG_01'}
                                            name={'Picture/UI_Picture-Currency_LFG_01'}/>
                                <div className={s.num}>{formatNumberWithUnit(parseInt(claimCoin))}</div>
                            </div>
                        </div>
                        <div className={s.i}>
                            <div className={`fs12 ${s.valname} color-blue-light`}>{t('Battle Points Tier')}</div>
                            <CustomIcon width={22} height={23} className="mr5 ml5"
                                        imgName={`Picture/BPTier/${getBattlePointRankImg(bpLevel)}`}
                            />
                        </div>
                    </div>
                    <div className={s.inputWrap}>
                        <CustomIcon className="ml5" width={26} height={26}
                                    imgName={'Picture/UI_Picture-Currency_LFG_01'}
                                    name={'Picture/UI_Picture-Currency_LFG_01'}/>

                        <Input className={s.input}
                               value={num.toLocaleString('US-en')}
                               onChange={amountChangeHandler}
                               onBlur={amountBlurHandler}/>
                        <div>
                            <ButtonFactory size={33} color={3} onClick={() => {
                                setNum(Math.floor(Math.min(getExtractMaxAmount(), claimCoin)))
                            }} style={{fontSize: '14px'}}>
                                {t('MAX')}
                            </ButtonFactory>
                        </div>
                    </div>
                    <div className={s.amountLimitLine}>
                        <div className={s.amountLimitLineTitle}>
                            {t('Min Amount')}:&nbsp;
                            <span className={s.amountLimitNum}>{vCoinString(mintInfo?.chainApplyMinCoin)}</span>
                        </div>
                        <div className={s.amountLimitLineTitle}>
                            {t('Max Amount')}:&nbsp;
                            <span className={s.amountLimitNum}>{getExtractMaxAmount()?.toLocaleString("en-US")}</span>
                        </div>
                    </div>
                    <div className={`${s.amountLimitTip} color-white`}>
                        {t('Your battle points tier determines your max $LFG withdrawal amount')}
                    </div>
                    <table className={s.battleTable}>
                        <thead>
                        <tr className={s.lineHead}>
                            <th className={s.colTier}>{t("Battle Points Tier")}</th>
                            <th className={s.colRange}>{t("Max $LFG Withdrawal")}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {battlePointsShowList?.length < 4 && (
                            <tr className={s.lineValue}>
                                <td>...</td>
                                <td>...</td>
                            </tr>
                        )}
                        {battlePointsShowList?.map((item, index) => (
                            <tr key={index} className={`${item.ID === bpLevel ? s.currentLine : s.lineValue}`}>
                                <td>{item.ID}</td>
                                <td>{item.extractLimit.toLocaleString("en-US")}</td>
                            </tr>))
                        }
                        </tbody>
                    </table>
                </div>
                <div className={s.errorMsgRoot}>
                    {
                        errorMsg && <div className={s.errorMessageLine}>
                            <CustomIcon className="m02" width={18} height={18}
                                        imgName={'Picture/UI_Picture_RedRound_Cross_01'}
                                        name={'Picture/UI_Picture_RedRound_Cross_01'}/>
                            <span>{errorMsg}</span>
                        </div>
                    }
                </div>
                <div className={`${s.btnWrap} modelBottomBtn`}>
                    {
                        (selfData?.lfgMintBindTwitterSwitch !== 0 && !platformData?.platformMap?.[1]) ?
                            <ButtonFactory onClick={() => {
                                navigate(linkPath);
                            }}>
                                {t('PLEASE LINK X (TWITTER)')}
                            </ButtonFactory> :
                            bpLevel < mintMinBP ?
                                <ButtonFactory disabled={true}>
                                    {t('TIER REQUIRE')}:&nbsp;<CustomIcon width={22} height={23} className="mr5 iconNoNeedFilter"
                                                                          imgName={`Picture/BPTier/${getBattlePointRankImg(mintMinBP)}`}
                                                                          name={`Picture/BPTier/${getBattlePointRankImg(mintMinBP)}`}/>
                                </ButtonFactory> :
                                nowTime < endTime && firstLoad ?
                                    <ButtonFactory disabled={true}>
                                        {
                                            <>
                                                {t('Available in:')} <Countdown workEndTimestamp={endTime}
                                                                                refresh={mintLeftSecEnd}/>
                                            </>
                                        }
                                    </ButtonFactory> :
                                    done ?
                                        subFailded ?
                                            <ButtonFactory className={`${s.transactionFailedBtn}`}
                                                           onClick={closeFn}>
                                                <div className="flex_center_center_col">
                                                    <div className={s.transactionFailedBtnTitle}>
                                                        {t('TRANSACTION FAILED')}
                                                    </div>
                                                    <div className={s.transactionFailedBtnLine}>
                                                        <CustomIcon className="mr5" width={16} height={16}
                                                                    imgName={'Picture/UI_Picture-Currency_LFG_01'}
                                                                    name={'Picture/UI_Picture-Currency_LFG_01'}/>
                                                        <div
                                                            className="fs11 fw-300 color-black">{t('xx will be refuned after yy minutes', {
                                                            lfg: convertWalletBalance(num),
                                                            time: displayLFGRefundTime
                                                        })}</div>
                                                    </div>
                                                </div>
                                            </ButtonFactory> :
                                            <ButtonFactory onClick={closeFn}>
                                                {t('TRANSACTION SUCCESSFUL')}
                                            </ButtonFactory>
                                        :
                                        <ButtonFactory disabled={!isAvailable} onClick={applyFn}>
                                            {subLoading ? <div className={s.web3LoadingLine}>
                                                <CustomIcon rotating={true} className="ml5" width={26} height={26}
                                                            imgName={'Picture/UI_Picture_Loading_01'}
                                                            name={'Picture/UI_Picture_Loading_01'}/>&nbsp;
                                                <span className="fs18 ml5 color-black">{t('PROCESSING')}</span>
                                            </div> : t('WITHDRAW')}
                                        </ButtonFactory>
                    }
                </div>
            </div>
        </div>
    );
};
const mapStateToProps = ({app}) => {
    return {
        earnInfo: app.earnInfo,
        userInfo: app.userInfo
    };
};

const mapDispatchToProps = (dispatch) => {
    return {}
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(ModelApplyMint));
