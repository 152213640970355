import React, {memo, useState, useMemo, useEffect} from "react";
import s from "./index.module.less";
import {Input, Button, Modal, Slider} from "antd";
import AntButton from "@/common/AntButton";

import {
    unit,
    siteName,
    stringToNumber,
    chainName,
    getFriendtradeChainStake,
    filterErrorCode,
    TransactionError,
    getBattlePointRankImg,
    getTransactionErrorMsgId
} from "@/utils/common";
import {applyMintAndStake} from "@/utils/lfgMintWeb3";
import {useNavigate} from "react-router-dom";
import {usePrivy, useWallets} from "@privy-io/react-auth";
import CustomIcon from "../../common/CustomIcon";
import WithdrawDialog from "../WithdrawDialog";
import {displayVLFGStakeRetryTime} from "@/utils/env";
import {useTranslation, Trans} from 'react-i18next';
import {linkPath} from "@/routes/config";
import ButtonFactory from "@/common/ButtonFactory";

const ModelWithdrawLFG = (
    {
        walletAddr,
        setShowWithdrawLFG,
        virtualLfg,
        lfgStakeState,
        updateVirtualLfgInfo,
        getMintAndStakeRequestFn,
        userInfo,
    }
) => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const {wallets} = useWallets();
    const [stakeValue, setStakeValue] = useState(0);
    const [web3SubmitDone, setWeb3SubmitDone] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const {selfData, platformData} = useMemo(() => {
        return userInfo || {}
    }, [userInfo]);
    const {
        bpLevel,
        stakeMinBP
    } = useMemo(() => {
        return selfData || {}
    }, [selfData]);
    const onSliderValueChange = (v) => {
        setStakeValue(Number(v.toFixed(2)));
    };

    useEffect(() => {
        setStakeValue(0);
    }, [virtualLfg]);

    const stakeVLFG = () => {
        setSubmitLoading(true);
        getFriendtradeChainStake(stakeValue).then(res => {
            console.log("tradechainstake", res);
            if (res) {
                if (res.amount > 0) {
                    applyMintAndStake({
                        wallets,
                        amount: res.amount,
                        orderId: res.orderId,
                        lockedUntil: res.lockedUntil,
                        stakeLockedUntil: res.stakeLockedUntil,
                        timestamp: res.timestamp,
                        validFor: res.validFor,
                        signature: res.signature,
                        walletAddr,
                    }).then(res => {
                        setSubmitLoading(false);
                        setWeb3SubmitDone(true);
                        setErrorMsg(null);
                        getMintAndStakeRequestFn();
                    }).catch(e => {
                        var errMsg = t(getTransactionErrorMsgId(e?.reason));
                        setSubmitLoading(false);
                        setWeb3SubmitDone(false);
                        setErrorMsg(errMsg);
                    });
                } else {
                    setSubmitLoading(false);
                    setWeb3SubmitDone(true);
                    setErrorMsg(null);
                }
            } else {
                setSubmitLoading(false);
                setErrorMsg(t('Transaction failed due to other unknown reason'));
            }
        }).catch(err => {
            setSubmitLoading(false);
            setErrorMsg(filterErrorCode(err));
            console.log(err);
        })
    }

    const closeFn = () => {
        updateVirtualLfgInfo();
        setShowWithdrawLFG(false);
    }

    return (
        <div className={s.wrap}>
            <div className={s.title}>
                <Trans
                    i18nKey="You have xx $LFG to withdraw or stake"
                    defaults="You have <yellow>{{flgCount}}</yellow> $LFG to withdraw or stake"
                    values={{flgCount: virtualLfg > 0 ? Number(virtualLfg.toFixed(2)).toLocaleString("en-US") : '--'}}
                    components={{yellow: <span style={{color: "rgba(250, 182, 0, 1)"}}></span>}}
                />
            </div>
            <div className={`${s.sliderContainer}`}>
                <div>
                    <div className={s.sliderProp}>{t('Stake')}</div>
                    <div className={s.sliderStake}>{Number(stakeValue.toFixed(2)).toLocaleString("en-US")}</div>
                </div>
                <div className={s.sliderWithdrawPart}>
                    <div className={s.sliderProp}>{t('Withdraw')}</div>
                    <div
                        className={s.sliderWithdraw}>{Number((Math.max(0, (Math.max(0, virtualLfg) - stakeValue))).toFixed(2)).toLocaleString("en-US")}</div>
                </div>
            </div>
            <div className={s.stakingSliderRoot}>
                <Slider
                    defaultValue={0}
                    className={`commonSlider`}
                    min={0}
                    max={Math.max(0, virtualLfg)}
                    value={stakeValue}
                    step={Math.max(0, virtualLfg) / 100}
                    onChange={onSliderValueChange}
                    tooltip={{open: false}}/>
            </div>
            <div className={s.description}>
                <div className={s.text}>{t('$LFG will be staked for 90 days.')}</div>
                <div
                    className={s.text}>{t('4% will be unlocked instantly,the remaining balance will unlock at a daily rate of 3% over the next 32 days.')}</div>
            </div>
            <div className={s.note}>
                {t('Note: The action is final and cannot be reversed once executed.')}
            </div>
            <div className={s.errorMsgRoot}>
                {
                    errorMsg && <div className={s.errorMessageLine}>
                        <CustomIcon className="m02" width={18} height={18} imgName={'Picture/UI_Picture_RedRound_Cross_01'}
                                    name={'Picture/UI_Picture_RedRound_Cross_01'}/>
                        <span>{errorMsg}</span>
                    </div>
                }
            </div>
            {
                (selfData?.lfgMintBindTwitterSwitch !== 0 && !platformData?.platformMap?.[1]) ?
                    <ButtonFactory color={1} size={11} className={`${s.btn}`} onClick={() => {
                        navigate(linkPath);
                    }}>
                        {t('PLEASE LINK X (TWITTER)')}
                    </ButtonFactory> :
                    bpLevel < stakeMinBP ?
                        <ButtonFactory className={`${s.btn}`} disabled={true}>
                            {t('TIER REQUIRE')}:&nbsp;<CustomIcon width={22} height={23} className="mr5 iconNoNeedFilter"
                                imgName={`Picture/BPTier/${getBattlePointRankImg(stakeMinBP)}`}
                                />
                        </ButtonFactory> :
                        lfgStakeState === 2 ?
                            <ButtonFactory className={`${s.btn}`} disabled={true}
                                       onClick={() => {
                                       }}>
                                {t('CLAIM')}
                            </ButtonFactory> :
                            lfgStakeState === 1 ?
                                <ButtonFactory className={`${s.btn} ${s.previous}`}
                                iconNeedFilter = {false}
                                           disabled={true} onClick={() => {
                                }}>
                                    <div className="flex_center_center_col">
                                        <div className={`${s.title} color-white`}>{t('PREVIOUS TRANSACTION PENDING')}</div>
                                        <div
                                            className={`${s.tip} color-white`}>{t('Please try again after xx minutes', {time: displayVLFGStakeRetryTime})}</div>
                                    </div>
                                </ButtonFactory> :
                                errorMsg ?
                                    <ButtonFactory className={`${s.transactionFailedBtn}`} disabled={true}
                                               onClick={closeFn}>
                                        <div className="flex_center_center_col">
                                            <div className={`${s.transactionFailedBtnTitle} color-white`}>
                                                {t('TRANSACTION FAILED')}
                                            </div>
                                            <div className={s.transactionFailedBtnLine}>
                                                <div
                                                    className="fs11 fw-300 color-white">{t('Please try again after xx minutes', {time: displayVLFGStakeRetryTime})}</div>
                                            </div>
                                        </div>
                                    </ButtonFactory> :
                                    submitLoading ?
                                        <ButtonFactory disabled={true} className={`${s.btn}`}
                                                   onClick={() => {
                                                   }}>
                                            <CustomIcon rotating={true} className="ml5 mr5" width={26} height={26}
                                                        imgName={'Picture/UI_Picture_Loading_01'}
                                                        name={'Picture/UI_Picture_Loading_01'}/>
                                            <span className="fs18 color-white">{t('PROCESSING')}</span>
                                        </ButtonFactory> :
                                        web3SubmitDone ?
                                            <ButtonFactory className={`${s.btn}`}
                                                       onClick={closeFn}>
                                                {t('TRANSACTION SUCCESSFUL')}
                                            </ButtonFactory> :
                                            virtualLfg > 0 ?
                                                <ButtonFactory className={`${s.btn}`}
                                                           onClick={stakeVLFG}>
                                                    {t('CLAIM')}
                                                </ButtonFactory> :
                                                <ButtonFactory className={`${s.btn}`}
                                                           disabled={true} onClick={() => {
                                                }}>
                                                    {t('CLAIM')}
                                                </ButtonFactory>
            }
        </div>
    )
}
export default memo(ModelWithdrawLFG);
