import React, {memo, useCallback, useEffect, useMemo} from "react";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import CustomIcon from "../CustomIcon";
import {connect} from "react-redux";
import {useState} from "react";
import {Spin, message, Button, Divider} from 'antd';
import {errorCode} from '@/utils/env'
import {Modal} from "antd";
import {taskData, bannerData} from "@/utils/taskJson";
import Banner from "../Banner";
import {
    getTweetChallenge,
    getBindTweetUrl,
    tweetcode,
    tweetcodeState,
    getTweetOuth1aUrl,
    getTwitterOauthtoken,
    siteName, getuserinfo, getProfile,
    getAvatarFromProfile
} from "@/utils/common"
// import { LoadingOutlined } from '@ant-design/icons';
import {closeAddScreen} from "@/utils/env";
import AvatarInfo from "../AvatarInfo";

import ModelLink from "../../model/ModelLink";
import ModelLinkTiktok from "../../model/ModelLinkTiktok";
import ModelLinkWishUser from "../../model/ModelLinkWishUser";

import {useLocation, useNavigate} from "react-router-dom";
import {mainHomePath} from "@/routes/config";
import ButtonFactory from "src/common/ButtonFactory";
import AntButton from "@/common/AntButton";
import AntModal from "@/common/AntModal";

const result = localStorage.getItem('_TT_bindRsult')
const filterErrorCode = (code) => {
    for (const item of errorCode) {
        if (item.id == code) {
            return item?.tips;
            break
        }
    }
    return ''
}
const LINKDATA = [
    {
        label: 'X (TWITTER)',
        icon: 'Button/UI_Button_Ball-White_Twitter_On',
        available: true,
    }, {
        label: 'TIKTOK',
        icon: 'iconTikTok',
        available: false,
    }, {
        label: 'INSTAGRAM',
        icon: 'iconIns',
        available: false,
    }, {
        label: 'SNAPCHAT',
        icon: 'iconSnapchatIcon',
        available: false,
    },
]
const Link = (
    {
        userInfo,
        setShowLink,
        tweetMaticBindResult,
    }
) => {
    const {t, i18n} = useTranslation();
    // const [linkUrl, setLinkUrl] = useState('');
    const navigate = useNavigate();
    const [openLinkType, setOpenLinkType] = useState(false)
    const [loading, setLoading] = useState(false);
    const [stateCodeLoading, setStateCodeLoading] = useState(true);

    const [errorTips, setErrorTips] = useState('');
    const [openLinkModel, setOpenLinkModel] = useState(false);
    const [openLinkTiktokModel, setOpenLinkTiktokModel] = useState(false);
    const [inviteCodeList, setInviteCodeList] = useState([]);
    const [tweetCode, setTweetCode] = useState({})
    const [points, setPoints] = useState(bannerData?.link?.points);

    const [tiktokCodeObj, setTiktokCodeObj] = useState({});
    const [tiktokCodeLoading, setTiktokCodeLoading] = useState(false)

    const [openWishUser, setOpenWishUser] = useState(false)

    const {
        platformData,
    } = useMemo(() => {
        return userInfo || {}
    }, [userInfo]);

    const {platformMap, useType, defaultName} = useMemo(() => {
        return platformData || {}
    }, [platformData])

    const {displayName, uid} = useMemo(() => {
        if (platformMap && JSON.stringify(platformMap) !== '{}') {
            return platformMap[useType]
        } else {
            return {
                displayName: defaultName
            }
        }
    }, [platformMap, useType, defaultName])


    useEffect(() => {
        if (result !== '' && result != 0) {
            // message.error({content:'bind Tweet failed!'})
            setErrorTips(filterErrorCode(result))
            localStorage.removeItem('_TT_bindRsult')
        }
        if (tweetMaticBindResult) {
            setErrorTips(filterErrorCode(tweetMaticBindResult))
        }
    }, [result, tweetMaticBindResult])

    const linkTweetAuth1a = () => {
        setLoading(true)
        localStorage.setItem('_TT_showWishLinkNeed', true);
        getTwitterOauthtoken().then(res => {
            const herf = getTweetOuth1aUrl(res?.oauth_token);
            // console.log(herf);
            window.location.href = herf;
            setLoading(false);
        }).catch(e => {
            setLoading(false);
            console.log(e);
        })
    }

    const linkTiktok = () => {
        setLoading(true)
        getTweetChallenge().then(res => {
            // console.log(getBindTweetUrl(res?.challenge));
            const herf = getBindTweetUrl(res?.challenge);
            console.log(herf);
            window.location.href = herf;

            setLoading(false);
        }).catch(e => {
            setLoading(false);
            console.log(e);
        })
    }

    const getTweetcode = () => {
        tweetcode().then(res => {
            setStateCodeLoading(false)
            // console.log(res);
            // setInviteCodeList([...res?.inviteCodes]);
            setTweetCode(res);
            // set
        }).catch(e => {

        })
    }

    useEffect(() => {
        openLinkModel && getTweetcode();

    }, [openLinkModel])

    const isTopApp = useMemo(() => {
        if (window.navigator && (window.matchMedia("(display-mode: standalone)").matches || !0 === window.navigator.standalone || window.navigator.userAgent.includes("standalone"))) {
            return true
        }
        return closeAddScreen
    }, [])

    const location = useLocation();
    const colsePage = () => {
        localStorage.setItem('_TT_showLink', false);
        if (location.key !== "default") {
            navigate(-1);
        } else {
            navigate(mainHomePath, {replace: true});
        }
    }

    const openLinkPanel = (label, available) => {
        if (!available) return
        // setOpenLinkType(true)

        switch (label) {
            case 'X (TWITTER)':
                setOpenLinkType(true)
                break;
            case 'TIKTOK':
                setOpenLinkTiktokModel(true)
                break;

            default:
                break;
        }
    }

    const [wishUserInfo, setWishUserInfo] = useState({})

    useEffect(() => {
        if (userInfo?.selfData?.wishCantLink) {
            getuserinfo(userInfo?.selfData?.wishCantLinkUid).then(res => {
                setWishUserInfo(res);
                setOpenWishUser(true);
            });
        }
        localStorage.setItem('_TT_showWishLinkNeed', false);
    }, []);

    const profile = useMemo(() => {
        return getProfile(userInfo?.platformData) || {}
    }, [userInfo])

    return (
        // <Spin
        //   className="loadPageSpin"
        //   indicator={
        //     <LoadingOutlined
        //       style={{
        //         fontSize: 90,
        //       }}
        //     />
        //   }
        //   tip="Loading..."
        //   spinning={loading}
        // >
        // </Spin>
        <>
            <div className={`${s.modalWrap} abs`}>
                <div className={s.modal}>
                    <div className={s.header}>
                        <div className="fs20 mt10 fb">{t('Bring Friends to TURNUP')}</div>
                        <div className="fs14 mt5">{t('link your socials')}</div>
                    </div>
                    <div className={s.list}>
                        {LINKDATA?.map((item, index) => {
                            const {label, icon, available} = item
                            return (
                                <div className={`${s.item} ${s['item' + index]}`} key={index}>
                                    {/*<div className="mtb10">{label}</div>*/}
                                    <ButtonFactory
                                        color={available ? 1 : 3}
                                        size={21}
                                        disabled={!available || uid}
                                        onClick={() => openLinkPanel(label, available)}
                                    >
                                        {available ? t('LINK') : t('COMING SOON')}
                                    </ButtonFactory>
                                </div>
                            )
                        })}

                    </div>

                </div>
                <div className={s.bottom}>
                    {errorTips && <div className="fs15 color-red">
                        {t('Link X failed')}
                    </div>}
                </div>
                <div className={s.bottom}>
                    <AntButton onClick={colsePage}
                               className={`${s.btnSkip} btn_white mt20`}>{t('MAYBE LATER')}</AntButton>
                </div>
            </div>
            <AntModal
                width='330px'
                title={t('Choose 1 verification method')}
                className="confirmModalWrap transModal"
                centered={true}
                open={openLinkType}
                onOk={() => setOpenLinkType(false)}
                onCancel={() => setOpenLinkType(false)}
            >
                <>
                    <div className={s.link} onClick={() => {
                        setOpenLinkModel(true)
                    }}>
                        <ButtonFactory>{t('Post a Magic Tweet on X')}</ButtonFactory>
                    </div>
                    <Divider>{t('Or')}</Divider>
                    <div className={s.link} onClick={linkTweetAuth1a}>
                        <ButtonFactory><span
                            className="fb"
                            style={{
                                fontSize: '16px',
                                color: '#000'
                            }}>{t('Verify your X (Twitter) account')}</span></ButtonFactory>
                    </div>
                </>
            </AntModal>
            <AntModal
                width='330px'
                title=""
                className="confirmModalWrap  tweetShareModal noImageTitle"
                centered={true}
                open={openLinkModel}
                destroyOnClose={true}
                onOk={() => setOpenLinkModel(false)}
                onCancel={() => setOpenLinkModel(false)}
            >
                <ModelLink tweetCodeObj={tweetCode} stateCodeLoading={stateCodeLoading}
                           setOpenLinkModel={setOpenLinkModel}/>
            </AntModal>
            <AntModal
                width='330px'
                title=''
                className="confirmModalWrap transModal"
                centered={true}
                open={openLinkTiktokModel}
                onOk={() => setOpenLinkTiktokModel(false)}
                onCancel={() => setOpenLinkTiktokModel(false)}
            >
                <ModelLinkTiktok tiktokCodeObj={tiktokCodeObj} loading={tiktokCodeLoading}/>
            </AntModal>
            <AntModal
                width='330px'
                title=''
                className="confirmModalWrap transModal"
                centered={true}
                open={openWishUser}
                onOk={() => setOpenWishUser(false)}
                onCancel={() => setOpenWishUser(false)}
            >
                <ModelLinkWishUser
                    wishUserInfo={wishUserInfo}
                    onClose={setOpenWishUser}
                />
            </AntModal>

        </>

    )
}

const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
        initTopUpAmount: app.initTopUpAmount,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

        setShowLink: (params) => {
            return dispatch({
                type: "app/setShowLink",
                payload: params
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(Link));
