import React, {memo, useRef} from "react";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import CustomIcon from "../CustomIcon";
import {Input} from "antd";
import {debounce} from "debounce";

const SearchUser = (
    {
        searchUserFn,
        onSearchClick = () =>{},
        placeHolderText = '',
    }
) => {
    const {t, i18n} = useTranslation();
    const searchKey = useRef(0);

    const searchUser = (event) => {
        const key = event.target.value;
        searchKey.current = key;
        debounce(searchUserFn(key), 200)
    }

    return (
        <div className={s.headerWrap}>
            <div className={s.header}>
                <div className={s.item}>
                    {/* <div className={s.label}>
                        Handle
                    </div> */}
                    <div className={s.search}>
                        <Input className={`searchInput ${s.input}`} suffix={
                            <div className={s.searchBtn}
                                 style={{backgroundImage: `url(/UI/Button/UI_Button_Yellow-Search_01.png)`}}
                                 onClick={(e) =>{
                                    onSearchClick(e, searchKey.current);
                                 }}>
                            </div>} onChange={searchUser} placeholder={placeHolderText ? placeHolderText : t('Search')}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(SearchUser);
