import React, {memo, useState, useMemo, useRef, useEffect} from "react";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import ButtonFactory from "@/common/ButtonFactory";

const PcPWAIntro = ({
    closeAInstall
}) => {
    const {t, i18n} = useTranslation();
    const [leftSec,setLeftSec] = useState(5)
    useEffect(()=>{
        let timer = null
        if(!timer){
            timer = setInterval(()=>{
                setLeftSec(leftSec=>{
                    const _leftSec = --leftSec
                    if(_leftSec === 0){
                        clearInterval(timer)
                        closeAInstall()
                    }
                    return leftSec--
                })
            },1000)
        }
        return ()=>{
            timer && clearInterval(timer)
        }
    },[])
    return (
        <div className={s.wrap}>
            <div className="flex_center_center">
                <img width={'80%'} src="/UI/Picture/quicksetup.png"></img>
            </div>
            <div className="mt10">
                {t('Feel free for adding TURNUP to Desktop')}
            </div>
            <div className="modelBottomBtn">
                <ButtonFactory size={21} scale={1.5} onClick={closeAInstall}>

                    <span className="fs20 color-black">{t('Go')}({leftSec})</span>
                </ButtonFactory>
            </div>
        </div>
    )
}
export default memo(PcPWAIntro);
