import React, {memo, useRef, useState, useEffect, useMemo} from "react";
import { useTranslation, Trans } from 'react-i18next';
import s from "./index.module.less";
import { convertWalletBalance } from "@/utils/common";

const MemeClubPack = ({
    packClass = "",
    packName = '',
    width,
    height = 0,
    imageUrl = '',
    onClick = null,
    isEmpty = false,
    className,
    style = {},
    children = <></>,
    allocationOverviewInfo = null,
    showAllocationOverview = false,
    blackShadow = true,
}) => {
    const { t, i18n } = useTranslation();
    const useHeight = useMemo(() =>{
        if (height === 0){
            return width;
        }
        return height;
    }, [width, height]);

    const {
        ftTotalSupply,
        airdropPercent,
        openPackRatio,
        coinToNFTRatio,
        isFT,
        maxCardNum,
    } = useMemo(() =>{
        return allocationOverviewInfo || {};
    }, [allocationOverviewInfo]);

    const evenCardToToken = useMemo(()=>{
        return parseInt(ftTotalSupply * (airdropPercent / 10000) / maxCardNum * 2);
    }, [ftTotalSupply, airdropPercent, maxCardNum])

    const currentStyle = useMemo(() =>{
        let res = {};
        if (isEmpty){
            res.backgroundColor = `rgba(0,0,0,0.8)`;
        }
        else{
            res.backgroundImage = `url(${imageUrl})`;
        }
        return res;
    }, [isEmpty,imageUrl]);

    return (
        <div className={`${s.memeClubPackRoot} ${className}`} onClick={(e) => {
            if (onClick){
                e.preventDefault();
                e.stopPropagation();
                onClick();
            }
        }} style={{width: `${typeof(width) === 'number' ? width+'px' : width}`, height: `${typeof(useHeight) === 'number' ? useHeight+'px' : useHeight}`}}>
            <div
                className={`${s.memeClubPackBg} ${packClass}`}
                style={{width: `${typeof(width) === 'number' ? width+'px' : width}`, height: `${typeof(useHeight) === 'number' ? useHeight+'px' : useHeight}`, ...style, ...currentStyle,
                    maskImage: `url(/UI/Picture/UI_Picture_CardPacks-Down_01.png)`}}
            >
                {children}
            </div>
            <div className={`${s.memeClubCover}`} style={{backgroundImage: `url(/UI/Picture/UI_Picture_CardPacks-Mask_${blackShadow?`01`:`04`}.png)`}}>
            </div>
            {
                isEmpty ? 
                <div className={`${s.emptyPart}`} style={{fontSize: `${useHeight * 0.2}px`}}>
                    {t('None')}
                </div> :
                <div className={`${s.packInfoRoot} flex_center_center_col`}>
                    <div className={`${s.packName} lh1`} style={{fontSize: `${useHeight * 0.133}px`}}>
                        {packName?'$'+packName:''}
                    </div>
                    {
                        showAllocationOverview && 
                        <>
                            <div className="lh1 mt2" style={{fontSize: `${useHeight * 0.0665}px`}}>
                                {`${convertWalletBalance(evenCardToToken * ( 1 / (openPackRatio+1)))}-${convertWalletBalance(evenCardToToken * ( openPackRatio / (openPackRatio+1)))}`}
                            </div>
                            {
                                !isFT &&
                                <div className={`${s.nftText} lh1 mt2`} style={{fontSize: `${useHeight * 0.0665}px`}}>
                                    {`(${parseInt(evenCardToToken * ( 1 / (openPackRatio+1))/ coinToNFTRatio)?.toLocaleString()}-${Math.ceil(evenCardToToken * ( openPackRatio / (openPackRatio+1))/ coinToNFTRatio)?.toLocaleString()} NFT)`}
                                </div>
                            }
                        </>
                    }
                </div>
            }
        </div>
    )
}
export default memo(MemeClubPack);
