import React, {memo, useEffect, useMemo, useRef, useState} from "react";
import {connect} from "react-redux";
import {Tabs, Modal, Button, Checkbox, Slider, Spin, Drawer} from 'antd';
import s from "./index.module.less";
import {
    post_hire_list,
    getAvatarFromProfile,
    displayFilter,
    stringToNumber,
    chgFn,
    getBattlePointRankImg,
    searchuser,
    formatCardDisplayName,
    maxCharLength,
    convertTimestamp,
    convertWalletBalance,
    getRarityByValue,
    commonGet,
} from "@/utils/common";
import CustomIcon from "src/common/CustomIcon";
import {useNavigate, useLocation, useParams} from "react-router-dom";
import { useWallets, usePrivy } from "@privy-io/react-auth";
import {useTranslation, Trans} from 'react-i18next';
import MemeChainToken from "@/utils/json/memeChainToken.json";
import MainHeader from "src/common/MainHeader";
import Banner from "src/common/Banner";
import {
    mainNftNftPanelPath,
    publicChatsPath,
    mainClubSweepClubsPath,
    balancePath,
} from "@/routes/config";
import {
    getMemeTgeNftRankList,
    getMemeTgeNftInfo,
    getMemeTgeNftCount,
} from "@/utils/memeClub";
import {
    getNftMetadata
} from "@/utils/memeClubWeb3";
import {isHelp, isNumber} from "mathjs";
import InfiniteScroll from "react-infinite-scroll-component";
import SearchUser from "src/common/SearchUser";
import SearchUserList from "@/common/SearchUserList";
import AntDrawer from "@/common/AntDrawer";
import AntButton from "@/common/AntButton";
import MemeClubUnpackCard from "@/common/MemeClubUnpackCard";
import MemeClubNftFilter from "@/drawer/MemeClubNftFilter";
import MemeClubNftSort from "@/drawer/MemeClubNftSort";
import TestMemeUnpackInfo from "@/utils/json/TestMemeUnpackInfo.json";

const NFTs = (
    {
        userInfo,
        clubBasicInfo,
        coinBalance,
    }
) => {
    const {t, i18n} = useTranslation();
    const { wallets } = useWallets();
    const navigate = useNavigate();
    const {
        portfolio,
        platformData,
        selfData,
        holding,
        holdersNum,
        walletAddr,
        managerProfile,
    } = useMemo(() => {
        return userInfo || {}
    }, [userInfo]);
    const {
        balance,
        buyFirstKey,
        wishNeedClaim,
        payEntryFee,
        claimCoin,
        tierId,
        power,
        points,
    } = useMemo(() => {
        return selfData || {}
    }, [selfData]);

    const {
        clubUserId,
        clubInfo,
        coinTokenAddr,
        holdNum,
    } = useMemo(() => {
        return clubBasicInfo || {}
    }, [clubBasicInfo])

    const {
        coinId,
        symbolImageUrl,
        symbolName,
        coinToNFTRatio,
        attrs,
    } = useMemo(() => {
        return clubInfo || {}
    }, [clubInfo])

    const chainInfo = useMemo(() =>{
        return MemeChainToken.find(i => i?.ID === Number(coinId));
    }, [coinId])

    const listRef = useRef([]);
    const [rarityCountList, setRarityCountList] = useState([]);
    const [refreshListCount, setRefreshListCount] = useState(0);//刷新界面nft列表显示
    const [showSort, setShowSort] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [displayNftDetail, setDisplayNftDetail] = useState(false);
    const [displayNftInfo, setDisplayNftInfo] = useState(null);

    const [sortType, setSortType] = useState(1);//2:tokenId, 1:rarity
    const [isAsc, setIsAsc] = useState(true);
    const [filterRarityRange, setFilterRarityRange] = useState([1, 5]);
    const [filterOnlySelfNft, setFilterOnlySelfNft] = useState(false);
    const [searchLock, setSearchLock] = useState(false);
    const [nftBaseUri, setNftBaseUri] = useState(null);
    const [getMetadataCount, setGetMetadataCount] = useState(0);//开始拉取nft metadata
    const [updateBasicListCount, setUpdateBasicListCount] = useState(0);//排序筛选后开始更新列表

    const showRarityList = [5,4,3,2,1,0];

    const showList = useMemo(() => {
        let res = [];
        for (let i = 0; i < listRef.current?.length; i++) {
            res.push(listRef.current[i]);
        }
        if (res?.length > 0) {
            let needEmpty = 8;
            for (let i = 0; i < needEmpty; i++) {
                res.push({
                    isEmptyObj: true
                })
            }
            return res;
        }
        return res;
    }, [refreshListCount])

    const [cursor, setCursor] = useState(-1);
    
    useEffect(() => {
        if (listRef.current?.length > 0 && nftBaseUri){
            for (let i = 0; i < listRef.current?.length; i++){
                if (!listRef.current[i].finishLoading){
                    let tempTokenId = listRef.current[i]?.tokenId;
                    let uri = `${nftBaseUri}/${tempTokenId}`;
                    listRef.current[i].finishLoading = true;
                    commonGet(uri).then(data => {
                        //console.log("getmetadata", data);
                        if (listRef.current?.length > 0){
                            for (let j = 0; j < listRef.current?.length; j++){
                                if (listRef.current[j].tokenId === tempTokenId){
                                    listRef.current[j].metaData = data;
                                    break;
                                }
                            }
                        }
                        setRefreshListCount(tempTokenId);
                    }).catch(e => {
                        if (listRef.current?.length > 0){
                            for (let j = 0; j < listRef.current?.length; j++){
                                if (listRef.current[j].tokenId === tempTokenId){
                                    listRef.current[j].finishLoading = false;
                                    break;
                                }
                            }
                        }
                        console.error(`get token id ${listRef.current[i]?.tokenId} error`, e);
                        if (refreshListCount > 0){
                            setRefreshListCount(-1);
                        }
                        else{
                            setRefreshListCount(refreshListCount - 1);
                        }
                    })
                }
            }
        }
        else{
            setRefreshListCount(refreshListCount + 1);
        }
    }, [getMetadataCount]);

    const getNFTList = (cs = 0) => {
        getMemeTgeNftRankList({
            clubUserId: clubUserId,
            rankType: sortType,
            rarityRange: filterRarityRange,
            onlyHeld: filterOnlySelfNft,
            isAsc: isAsc,
            cursor: cs,
        }).then(data => {
            if (cs === 0){
                listRef.current = [];
            }
            if (data?.dataList?.length > 0){
                for (let i = 0; i < data?.dataList?.length; i++){
                    listRef.current.push({
                        ...data?.dataList[i],
                        finishLoading: false,
                    })
                }
            }
            let _nextCursor = data?.nextCursor;
            setCursor(_nextCursor);
            let nftAddr = data?.nftAddr;
            if (!nftBaseUri){
                if (listRef.current?.length > 0){
                    getNftMetadata(wallets, nftAddr, listRef.current[0]?.tokenId, chainInfo?.chainId).then(res => {
                        //console.log("metadatauri", res);
                        let tempBaseUri = res.substring(0, res.lastIndexOf('/'));
                        setNftBaseUri(tempBaseUri);
                        setGetMetadataCount(getMetadataCount + 1);
                    }).catch(e => {
                        console.error(e);
                        if (refreshListCount > 0){
                            setRefreshListCount(-1);
                        }
                        else{
                            setRefreshListCount(refreshListCount - 1);
                        }
                    })
                }
            }
            else{
                setGetMetadataCount(getMetadataCount + 1);
            }
        }).catch(err => {
            console.error(err);
        });
    }

    const getMoreNFTList = () =>{
        getNFTList(cursor);
    }

    const getRarityList = () =>{
        getMemeTgeNftCount({clubUserId}).then(data => {
            setRarityCountList(data?.rarityList);
        }).catch(e => {
            console.error(`get ${clubUserId} rairty list error`, e);
        })
    }

    useEffect(() => {
        getNFTList(0);
    }, [updateBasicListCount]);

    useEffect(() => {
        getRarityList();
    }, []);

    const applyClubNFTSort = (item) => {
        if (item){
            setSortType(item?.sortType);
            setIsAsc(item?.isAsc);
        }
        setCursor(-1);
        listRef.current = [];
        setUpdateBasicListCount(updateBasicListCount + 1);
    }

    const applyClubNFTFilter = (item) => {
        if (item){
            setFilterRarityRange(item?.rarityRange);
            setFilterOnlySelfNft(item?.onlySelfNft);
        }
        setCursor(-1);
        listRef.current = [];
        setUpdateBasicListCount(updateBasicListCount + 1);
    }

    const onNftTokenSearch = (key) => {
        if (searchLock || !(key > 0)){
            return;
        }
        setSearchLock(true);
        getMemeTgeNftInfo({
            clubUserId, 
            tokenId: Number(key),
        }).then(res => {
            if (res?.isExist && nftBaseUri){
                let uri = `${nftBaseUri}/${key}`
                commonGet(uri).then(data => {
                    setDisplayNftDetail(true);
                    setDisplayNftInfo({
                        ...data,
                        rarityValue: getRarityByValue(data?.rarity),
                    });
                    setSearchLock(false);
                }).catch(e => {
                    console.error(`get tokenId ${key} metaData error`);
                    setSearchLock(false);
                })
            }
            else{
                setSearchLock(false);
            }
        }).catch(e => {
            console.error(`get tokenId ${key} from server error`);
            setSearchLock(false);
        })
    }

    return (
        <div className={`${s.wrap} flex_center_start_col`}>
            <div className={`${s.totalInfoRoot} flex_center_start_col`}>
                <div className={`${s.title}`}>
                    {t('Wallet Balance')}
                </div>
                <div className={`${s.valueLine} flex_center_start`}>
                    <div className={`${s.symbolIcon}`} style={{backgroundImage: `url(${symbolImageUrl})`}}>
                    </div>
                    <div className={`${s.coinNumber}`}>
                        {`${Number(stringToNumber(coinBalance, 2) || 0).toLocaleString("en-US")} $${symbolName}`}
                    </div>
                    <div className={`${s.rarityRoot} flex_center_end`}>
                        {
                            showRarityList.map((item, index) => {
                                let count = 0;
                                if (rarityCountList?.length > 0){
                                    for(let i = 0; i < rarityCountList?.length; i++){
                                        if (rarityCountList[i].rarity === item){
                                            count = rarityCountList[i].count;
                                        }
                                    }
                                }
                                if (count > 0){
                                    return (
                                        <div key={index} className={`${s.nftRarity}`} style={{backgroundImage: `url(/UI/Picture/UI_Picture_CardQuality_${item.toString().padStart(2, '0')}.png)`}}>
                                            <div className={`${s.nftRarityCount}`}>
                                                {count}
                                            </div>
                                        </div>
                                    );
                                }
                            })
                        }
                    </div>
                </div>
            </div>
            <div className={`${s.content} flex_center_start_col`}>
                <div className={s.topPart}>
                    <div className={s.topPartLeft}>
                        <SearchUser 
                            searchUserFn={()=>{}}
                            placeHolderText={t('Enter Token ID to search.')}
                            onSearchClick={(e, key) =>{
                                onNftTokenSearch(key);
                            }} />
                    </div>
                    <div className={`flex_center_end`}>
                        <CustomIcon onClick={() => {
                            setShowSort(true);
                        }} className="iconShadow mr10" width={30} height={30} imgName={'Button/UI_Button_UpAndDown_01'}/>
                        <CustomIcon onClick={() => {
                            setShowFilter(true);
                        }} className="iconShadow" width={30} height={30} imgName={'Button/UI_Button_ThreeTieRod_01'}/>
                    </div>
                </div>
                <div className={s.clubPart} id="clubPartRoot">
                    <InfiniteScroll
                        dataLength={listRef.current?.length || 0}
                        next={getMoreNFTList}
                        hasMore={cursor !== -1}
                        loader={<div
                            style={{
                                textAlign: "center",
                                width: "100%",
                            }}
                        ><Spin/></div>}
                        style={{
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: `${listRef.current?.length > 1 ? 'space-between' : 'flex-start'}`,
                            width: '100%',
                        }}
                        endMessage={
                            <div style={{
                                textAlign: "center",
                                width: "100%",
                                color: "var(--color-gray)",
                                fontSize: '12px',
                                marginTop: '10px'
                            }}>{t('No More Data')}</div>
                        }
                        scrollableTarget={'clubPartRoot'}
                    >
                        {
                            showList?.map((item, index) => {
                                const {
                                    metaData,
                                    tokenId,
                                    isHeld,
                                    rarity,
                                    finishLoading,
                                } = item || {};
                                const {
                                    name,
                                    image,
                                    attributes,
                                } = metaData || {};
                                if (item?.isEmptyObj){
                                    return (
                                        <div className={s.emptyItem} key={index}>
                                        </div>
                                    )
                                }
                                else if (!finishLoading){
                                    return (
                                        <div className={s.nftItemRoot} key={index}>
                                            <div className={`${s.nftCard}`} style={{backgroundImage: `url(/UI/Picture/UI_Picture_Card_0${rarity}.png)`}}>
                                                {
                                                    isHeld && 
                                                    <div className={`${s.crown}`} style={{backgroundImage: `url(/UI/Picture/UI_Picture_Crown_01.png)`}}>
                                                    </div>
                                                }
                                                <CustomIcon rotating={true} className={`${s.nftLoading}`} width={32} height={32}
                                                    imgName={'Picture/UI_Picture_Loading_02'}/>
                                                <div className={`${s.loadingText}`}>
                                                    {t('Loading...')}
                                                </div>
                                                <div className={`${s.nftName} flex_center_center_col`}>
                                                    <div className={`${s.tokenId}`}>
                                                        {`#${tokenId}`}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                else {
                                    return (
                                        <div className={s.nftItemRoot} key={index} onClick={() => {
                                            setDisplayNftDetail(true);
                                            setDisplayNftInfo({
                                                ...metaData,
                                                rarityValue: rarity,
                                            });
                                        }}>
                                            <div className={`${s.nftCard}`} style={{backgroundImage: `url(/UI/Picture/UI_Picture_Card_0${rarity}.png)`}}>
                                                {
                                                    isHeld && 
                                                    <div className={`${s.crown}`} style={{backgroundImage: `url(/UI/Picture/UI_Picture_Crown_01.png)`}}>
                                                    </div>
                                                }
                                                <div className={`${s.nftAvatar}`} style={{backgroundImage: `url(${image})`}}>
                                                </div>
                                                <div className={`${s.nftName} flex_center_center_col`}>
                                                    <div className={`${s.name}`}>
                                                        {name}
                                                    </div>
                                                    {/*<div className={`${s.tokenId}`}>
                                                        {`#${index}`}
                                                    </div>*/}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            })
                        }
                    </InfiniteScroll>
                </div>
            </div>
            {
                displayNftDetail && 
                <div className={`${s.nftDetail} flex_center_center`}>
                    <div className={`${s.closeDetail}`} onClick={() =>{
                        setDisplayNftDetail(false);
                    }}>
                        <div className={`${s.closeDetailBtn}`} style={{ backgroundImage: `url(/UI/Picture/UI_Picture_Icon_Close_01.png)`}}>
                        </div>
                    </div>
                    <div className={`${s.nftCardPart} flex_center_center`}>
                        <MemeClubUnpackCard
                            width={306}
                            selectedNftInfo={displayNftInfo}
                            attributeSetting={attrs}
                            rewardAmount={coinToNFTRatio}
                            symbolImageUrl={symbolImageUrl}
                            showEffect={false}
                            showImage={true}
                            />
                    </div>
                </div>
            }
            <AntDrawer
                title={t('Drawer with extra actions')}
                placement={'right'}
                onClose={() => setShowFilter(false)}
                width={245}
                open={showFilter}
                destroyOnClose={true}
                className="hireSort"
            >
                <MemeClubNftFilter
                    filterRarityRange={filterRarityRange}
                    filterOnlySelfNft={filterOnlySelfNft}
                    applyFn={(item) => {
                        applyClubNFTFilter(item);
                        setShowFilter(false);
                    }}
                />
            </AntDrawer>
            <AntDrawer
                title={t('Drawer with extra actions')}
                placement={'right'}
                onClose={() => setShowSort(false)}
                width={245}
                open={showSort}
                destroyOnClose={true}
                className="hireSort"
            >
                <MemeClubNftSort
                    sortType={sortType}
                    isAsc={isAsc}
                    applyFn={(item) => {
                        applyClubNFTSort(item);
                        setShowSort(false);
                    }}
                />
            </AntDrawer>
        </div>
    )
}
const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setUserInfo: (params) => {
            return dispatch({
                type: "app/setUserInfo",
                payload: params
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(NFTs));
