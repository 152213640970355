import React, { memo, useCallback, useEffect, useRef } from "react";
import s from "./index.module.less";
import { useTranslation, Trans } from 'react-i18next';
import { connect } from "react-redux";
import { useState, useMemo } from "react";
import { Spin, Button, Dropdown, Modal, message } from "antd";
import WebIM from "@/utils/WebIM";
import InfiniteScroll from "react-infinite-scroll-component";
import {
    SCROLL_WARP_ID,
    CHAT_TYPE,
    PAGE_SIZE,
    MIN_TXT_AMOUNT,
    MESSAGE_ITEM_SOURCE,
    sliceStr,
    getEaseToken,
    getTranList,
    easeJoinGroup,
    clgFn,
    scrollToBottom,
    ease_heartbeat,
    timerDelay,
    loginIntoIM,
    getglobal_chat_userid,
    getAvatarFromProfile, convertTimestamp
} from "@/utils/common";
import MessageLeft from "../MessageLeft";
import { StoreContext } from "@/contexts/StoreContext";
import MessageInput from "../MessageInput";
import CustomIcon from "../CustomIcon";
import { LoadingOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import ModelTransaction from "@/model/ModelTransaction";
import ModelLiveSelect from "@/model/ModelLiveSelect";
import AvatarInfo from "../AvatarInfo";
import RedPacketModelCreate from "@/model/RedPacket/RedPacketModelCreate";
import RedPacket from "@/common/ChatContent/RedPacket";
import { linkPath, publicChatsPath } from "@/routes/config";
import AntButton from "@/common/AntButton";
import AntModal from "@/common/AntModal";
import Reserve from "@/assets/images/reserve.png";

const deWeightThree = (arr, name) => {
    // let name = 'name';
    let map = new Map();
    for (let item of arr) {
        if (!map.has(item[name])) {
            map.set(item[name], item);
        }
    }
    return [...map.values()];
}


const ChatContent = (
    {
        chatMap,
        pushChatMessage,
        webIMLogined,
        accountType = 'key',
        userInfo,
        keyId,
        isSelfChat,
        setShowLivePanel,
        hasOneKey,
        setGroupReady,
        groupId,
        setGroupId,
        setPageLoading,
        startLive,
        hasBindTweet,
        setShowLink,
        setNextLiveTime,
        setTitle,
        pageLoading,
        managerId,
        isGlobalChatGroup,
        curentOtherUserId,
        setGroupInfo,
        groupInfo,
        openRedPacketCreatePanel,
        setOpenRedPacketCreatePanel,
        nextLiveTime,
        nextLiveTitle,
    }
) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate()
    const { db } = React.useContext(StoreContext);
    const { userId } = userInfo;
    const [quote, setQuote] = useState({})
    const [globalUserid, setGlobalUserid] = useState(0)
    const [items, setItems] = useState([])
    const [showNotice, setShowNotice] = useState(groupInfo[keyId]?.msg_types?.length === 2)


    const setCurnSelCharType = (msg_types) => {
        const obj = {};
        obj[keyId + ''] = {
            group_id: groupId,
            msg_types
        }
        setGroupInfo(obj)
    }
    useEffect(() => {
        if (!(keyId && groupId)) return
        const { msg_types } = groupInfo[keyId] || {};
        if (msg_types) {
            setShowNotice(msg_types?.length === 2)
        }
    }, [groupInfo, keyId, groupId])
    const txtNum = useRef(0)
    const [readyFristHistoryMsg, setReadyFristHistoryMsg] = useState(false)
    useEffect(() => {
        setReadyFristHistoryMsg(false)
    }, [keyId])
    const messageInfo = useMemo(() => {
        if (!groupId) {
            return {}
        }
        return chatMap[CHAT_TYPE.groupChat].get(groupId) || {};
    }, [chatMap, groupId, keyId]);


    const genItems = list => {

        return list?.map((v, i) => ({
            key: i,
            label: <div className={`chatDropdownlabel ${v?.userId == keyId ? 'selfChat' : ''}`} onClick={()=>{
                if (v?.userId != keyId) {
                    setGroupId('')
                    navigate(`${publicChatsPath}/${v?.userId}`, { replace: true })
                }
            }}>
                <div className="df alc jcc">
                    <AvatarInfo size={32} src={getAvatarFromProfile(v?.profile)} />
                    <span className="color-white ml5 mr5 fs16" style={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis"
                    }}>{sliceStr(v?.profile?.displayName, 20, true)}</span>
                    {v?.profile?.platformType === 1 &&
                        <CustomIcon width={12} height={12} imgName={'Button/UI_Button_Ball-White_Twitter_On'}
                            name={'Button/UI_Button_Ball-White_Twitter_On'} />}
                </div>
                <div className="df alc jcc">
                    <CustomIcon width={16} height={16} imgName={'Button/UI_Button_Enter_01'}
                            />
                </div>
            </div>
            
        }))
    }

    const getChatGroupList = () => {
        getTranList(userId, 2, 0).then(res => {
            getglobal_chat_userid().then(global => {
                // setGlobalUserid(global?.globalChatUserId)
                setItems(genItems([
                    {
                        profile: {
                            displayName: 'Global',
                            avatarUrl: ''
                        },
                        userId: global?.globalChatUserId
                    },
                    ...res?.data?.holding
                ]))
            }).catch(e => {

                clgFn('Error getGlobalUserid', 'red', e)
            })

        }).catch(e => {
            console.log(e);
        })
    }
    useEffect(() => {
        // keyId && easeJoinGroupFn()

        keyId && getChatGroupList()
    }, [keyId])
    useEffect(() => {

        if (!webIMLogined) {
            loginIntoIM()
        }

    }, [webIMLogined])
    // const getMoreMessage = (cursor)=>{
    //   for(let i = 0;i<200;i+=20){
    //     getHistoryMsg({ cursor:i });
    //   }

    // }
    useEffect(() => {
        clgFn('getHistoryMsg Init', '', `keyId:${keyId} groupId:${groupId} curentOtherUserId === Number(keyId):${curentOtherUserId === Number(keyId)} hasOneKey || isGlobalChatGroup:${(hasOneKey || isGlobalChatGroup)} webIMLogined:${webIMLogined}`);
        groupId && (curentOtherUserId === Number(keyId)) && (hasOneKey || isGlobalChatGroup) && webIMLogined &&
            // setTimeout(()=>{
            getHistoryMsg({ cursor: '', msgTypes: groupInfo[keyId]?.msg_types || ['txt'] })
        // },10);

    }, [groupId, hasOneKey, webIMLogined, curentOtherUserId])

    useEffect(() => {
        // if(groupId){


        const timer = setInterval(() => {
            ease_heartbeat(keyId, true)
        }, timerDelay)
        return () => {
            timer && clearInterval(timer)
        }
        // }
    }, [])

    const getHistoryMsg = ({ cursor = "", msgTypes = ['txt'] }) => {
        console.log(`groupId--->`, groupId);
        WebIM.conn
            .getHistoryMessages({
                targetId: groupId,
                pageSize: PAGE_SIZE,
                chatType: "groupChat",
                cursor,
                searchOptions: {
                    msgTypes
                }
            })
            .then((res) => {
                if (!cursor) {
                    txtNum.current = 0;
                }
                setReadyFristHistoryMsg(true)
                setPageLoading(false)
                clgFn('getHistoryMsg', '', res)
                // if(res?.messages.length === 0){
                //   pushChatMessage({
                //     chatType: "groupChat",
                //     fromId: groupId,
                //     messageInfo: {
                //       list: res.messages,
                //       cursor: res.cursor,
                //       loadCount: res.messages.length
                //     },
                //     reset: cursor ? false : true
                //   });
                //   return
                // }

                // if(localMsg?.list?.length){
                //   console.log(`localMsg`,localMsg?.list);
                //   let newIndex = res.messages?.findIndex((item)=>{ return item?.id === localMsg?.list[0]?.id})
                //   // if(newIndex === 0) return
                //   if(newIndex > 0){
                //     const newArr = res.messages.slice(0,newIndex)
                //     pushChatMessage({
                //       chatType: "groupChat",
                //       fromId: groupId,
                //       messageInfo: {
                //         list: newArr,
                //         cursor: res.cursor,
                //         loadCount: res.messages.length
                //       },
                //       reset: cursor ? false : true
                //     });
                //     return
                //   }


                // }
                pushChatMessage({
                    chatType: "groupChat",
                    fromId: groupId,
                    messageInfo: {
                        list: res.messages,
                        cursor: res.cursor,
                        loadCount: res.messages.length
                    },
                    reset: cursor ? false : true
                });
                // const localMsg = chatMap[CHAT_TYPE.groupChat].get(groupId);
                // let totalArr = deWeightThree(res.messages.concat([localMsg?.message || []]),'id');
                // clgFn('totalHistoryArr', '', totalArr)
                // if(localMsg){

                // }else{
                // const arr = totalArr?.filter((item)=>{
                //   return item?.type === 'txt'
                // })
                // }
                // console.log(totalArr);
                // const arr = res?.messages?.filter((item)=>{
                //   return item?.type === 'txt'
                // })
                // clgFn('text Msg', '', arr)
                // txtNum.current =  txtNum.current?txtNum.current+arr.length:arr.length
                // clgFn('txtNum', '', txtNum)
                // if (txtNum.current < MIN_TXT_AMOUNT && res?.messages?.length === PAGE_SIZE){
                //   getHistoryMsg({
                //     cursor:res.cursor,
                //     msgTypes
                //   })
                // }
            })
            .catch(e => {
                console.log(e);
                setPageLoading(false)
                message.info({ content: 'get chat message failed' })
                setReadyFristHistoryMsg(true)
            })
    }

    const loadMoreData = (callback) => {
        getHistoryMsg({
            cursor: messageInfo?.cursor,
            msgTypes: groupInfo[keyId]?.msg_types
        });
    };

    const scrollBottom = () => {
        setTimeout(() => {
            scrollToBottom(document.getElementById(SCROLL_WARP_ID));
        }, 300);
    };

    const [openLiveSelectPanel, setOpenLiveSelectPanel] = useState(false)
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const [openMore, setOpenMore] = useState(false)

    const [showMoreInfo, setShowMoreInfo] = useState(false)
    const openMoreFn = () => {
        setShowMoreInfo(!showMoreInfo)
    }

    const ICONDATA = [
        {
            label: 'RedPack',
            icon: 'Button/UI_Button_RedPack',
            activeIcon: "Button/UI_Button_RedPack_On",
            disabledIcon: "Button/UI_Button_RedPack_Disabled",
            available: accountType === 'wish' || !hasOneKey ? false : true,
            needSelf: false,
            isActive: openRedPacketCreatePanel,
            onClick: () => {
                setOpenRedPacketCreatePanel(true)
            }

        }, {
            label: 'Video',
            icon: 'Button/UI_Button_Video',
            activeIcon: "Button/UI_Button_Video_On",
            disabledIcon: "Button/UI_Button_Video_Disabled",
            available: true,
            needSelf: true,
            isActive: openLiveSelectPanel,
            onClick: () => {

                setOpenLiveSelectPanel(true)
            }
        }, {
            label: 'Messag',
            icon: 'Button/UI_Button_Message',
            activeIcon: "Button/UI_Button_Message_On",
            disabledIcon: "Button/UI_Button_Message_Disabled",
            available: true,
            needSelf: false,
            isActive: groupInfo[keyId]?.msg_types?.length === 2,
            onClick: () => {
                if (groupInfo[keyId]?.msg_types?.length === 2) {

                    setCurnSelCharType(['txt'])
                    getHistoryMsg({ cursor: '', msgTypes: ['txt'] })
                } else {
                    setCurnSelCharType(['txt', 'custom'])
                    getHistoryMsg({ cursor: '', msgTypes: ['txt', 'custom'] })
                }
            }
        }, {
            label: 'Camera',
            icon: 'Button/UI_Button_camera_Disabled',
            activeIcon: "Button/UI_Button_camera_Disabled",
            disabledIcon: "Button/UI_Button_camera_Disabled",
            available: false,
            needSelf: false,
        }, {
            label: 'Photo',
            icon: 'Button/UI_Button_Photo_Disabled',
            activeIcon: "Button/UI_Button_Photo_Disabled",
            disabledIcon: "Button/UI_Button_Photo_Disabled",
            available: false,
            needSelf: false,
        },
    ]

    useEffect(()=>{
        if(showMoreInfo){
            setDropdownOpen(false)
        }
    },[showMoreInfo])

    useEffect(()=>{
        if(dropdownOpen){
            setShowMoreInfo(false)
        }
    },[dropdownOpen])

    return (

        groupId && (hasOneKey || isGlobalChatGroup) && readyFristHistoryMsg ? <div className={s.wrap}>
            {!hasBindTweet &&
                <div className={s.bindWrap}>
                    <div className="fs16 fb mtb10">{t('Unlock the Power of Connection - Link Your Twitter Now')}</div>
                    <div className={s.bindBtn}><AntButton className="btn_public"
                        onClick={() => navigate(linkPath)}>{t('CONNECT SOCIALS')}</AntButton>
                    </div>
                </div>
            }
            {hasBindTweet && nextLiveTime !== 0 && <div className={`${s.bindWrap} mtb10`}>
                {nextLiveTitle !== '' && <div className="fs16">{nextLiveTitle}</div>}
                <div className="fs16">
                    <img width={13} src={Reserve} />&nbsp;
                    {t('Live Stream starts at xx', { time: convertTimestamp(nextLiveTime * 1000) })}
                </div>
            </div>}

            <div id={SCROLL_WARP_ID} className={s.messageWrap}>
                <InfiniteScroll
                    inverse={true}
                    dataLength={messageInfo?.list?.length || 0}
                    next={loadMoreData}
                    hasMore={messageInfo?.loadCount >= PAGE_SIZE}
                    style={{
                        display: "flex",
                        flexDirection: "column-reverse",
                    }}
                    loader={<Spin />}
                    endMessage=''
                    scrollableTarget={SCROLL_WARP_ID}
                >
                    {messageInfo?.list?.map((item) => {
                        return (
                            item?.msg || (item?.type === 'custom' && item?.customEvent === 'systemNotify') ?
                                <div className={s.msgWrap} key={item.localId || item.id}>
                                    <MessageLeft
                                        parentId={groupId}
                                        message={item}

                                        userId={userId}
                                        keyId={keyId}
                                        setQuote={setQuote}
                                        managerId={managerId}
                                        source={MESSAGE_ITEM_SOURCE.groupChat}
                                        showNotice={showNotice}
                                    // serverId={serverId}
                                    />
                                </div> : null
                        );
                    })}
                </InfiniteScroll>

            </div>


            <div className={s.iptWrap}>
                <div className={s.itop}>
                    {/* <Dropdown menu={{items}} className={`${s.chatSelWrap} ${dropdownOpen ? s.open : ''}`}
                                  onOpenChange={(bl) => {
                                      setDropdownOpen(bl)
                                  }} placement="topLeft" overlayClassName={"chatDropdownMenu"} trigger={['click']}>

                            <div className={``}><CustomIcon width={39} height={36}
                                                            imgName={dropdownOpen ? 'Button/UI_Button_ChatNameList_01' : 'Button/UI_Button_ChatNameList_01'}/>
                            </div>
                        </Dropdown> */}
                    <MessageInput chatType={CHAT_TYPE.groupChat} fromId={keyId} groupId={groupId} quote={quote}
                        openMoreFn={openMoreFn}
                        setDropdownOpen={setDropdownOpen}
                        dropdownOpen={dropdownOpen}
                        setQuote={setQuote} scrollBottom={scrollBottom}>
                    </MessageInput>
                </div>

                {/* <div className={`${s.world}`} onClick={() => {
                        console.log(groupInfo[keyId]);
                        if (groupInfo[keyId]?.msg_types?.length === 2) {

                            setCurnSelCharType(['txt'])
                            getHistoryMsg({cursor: '', msgTypes: ['txt']})
                        } else {
                            setCurnSelCharType(['txt', 'custom'])
                            getHistoryMsg({cursor: '', msgTypes: ['txt', 'custom']})
                        }

                    }}>
                        <CustomIcon width={27} height={27} imgName={showNotice ? 'icon_notice_apply' : 'icon_notice_reject'}
                                   name={showNotice ? 'icon_notice_apply' : 'icon_notice_reject'}/>
                    </div> */}
                <div className={`${s.ibottom} ${showMoreInfo ? s.show : ''}`}>
                    {ICONDATA.map(item => {
                        const { activeIcon, label, icon, available, onClick, isActive, disabledIcon, needSelf } = item
                        return (
                            <CustomIcon width={39} className={"mr5"} height={36}
                                onClick={available && (isSelfChat || !needSelf) && onClick}
                                imgName={(!isSelfChat && needSelf || !available) ? disabledIcon : isActive ? activeIcon : icon} />
                        )
                    })}

                </div>
                <div className={`${s.ibottomChatList} ${dropdownOpen ? s.show : ''}`}>
                    {items?.map(item => {
                        const { label, key, onClick} = item
                        return (
                            <>{label}</>
                        )
                    })}

                </div>
            </div>
            {/* {isSelfChat &&
                    <div className={`${s.startVideo} ${!hasBindTweet ? s.noBind : ''}`}
                         style={{top: hasBindTweet ? '10px' : '130px'}} onClick={() => {
                        setOpenLiveSelectPanel(true)
                    }}>
                        <CustomIcon width={45} height={45} imgName={'Button/UI_Round_Button_StartVideo'}
                                    name={'Button/UI_Round_Button_StartVideo'}/>
                    </div>
                } */}
            <AntModal
                width='330px'
                title={t('Create your streaming')}
                className="confirmModalWrap"
                centered={true}
                open={openLiveSelectPanel}
                destroyOnClose={true}
                onOk={() => setOpenLiveSelectPanel(false)}
                onCancel={() => setOpenLiveSelectPanel(false)}
            >
                <ModelLiveSelect groupType={0} keyId={keyId} startLive={startLive} setOpenLiveSelectPanel={setOpenLiveSelectPanel} userId={userId}
                    setNextLiveTime={setNextLiveTime} setTitle={setTitle} />
            </AntModal>
        </div> :
            <Spin
                className="loadPageSpin"
                indicator={
                    <LoadingOutlined
                        style={{
                            fontSize: 90,
                        }}
                    />
                }
                tip={t('Loading...')}
                spinning={pageLoading || !readyFristHistoryMsg}
            >
                <div className={s.spinWrap}></div>
            </Spin>

    )
}

const mapStateToProps = ({ app }) => {
    return {
        chatMap: app.chatMap,
        userInfo: app.userInfo,
        webIMLogined: app.webIMLogined,
        accessToken: app.accessToken,
        webIMUserInfo: app.webIMUserInfo,
        curentOtherUserInfo: app.curentOtherUserInfo,
        groupInfo: app.groupInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        pushChatMessage: (params) => {
            return dispatch({
                type: "app/pushChatMessage",
                payload: params
            });
        },
        setWebIMLogined: (params) => {
            return dispatch({
                type: "app/setWebIMLogined",
                payload: params
            });
        },
        setAccessToken: (params) => {
            return dispatch({
                type: "app/setAccessToken",
                payload: params
            });
        },
        setWebIMUserInfo: (params) => {
            return dispatch({
                type: "app/setWebIMUserInfo",
                payload: params
            });
        },
        setShowLink: (params) => {
            return dispatch({
                type: "app/setShowLink",
                payload: params
            });
        },
        setGroupInfo: (params) => {
            return dispatch({
                type: "app/setGroupInfo",
                payload: params,
            });
        },
    }
}


export default memo(connect(mapStateToProps, mapDispatchToProps)(ChatContent));
