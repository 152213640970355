import React from "react";
import MemeChainToken from "@/utils/json/memeChainToken.json";

const keepList = {
    "matic": "MATIC",
    "lfg": "LFG",
    "nft": "NFT",
    "nfts": "NFTs",
    "turnup": "TURNUP",
    "$up": "$UP",
    "bp": "BP",
    "id": "ID",
    "cz": "CZ",
    "iykyk":"IYKYK",
    "$iykyk":"$IYKYK",
    "dna":"DNA"
}

if (MemeChainToken?.length > 0){
    for (let i = 0; i < MemeChainToken.length; i++){
        if (MemeChainToken[i].coinName && !keepList[MemeChainToken[i].coinName]){
            keepList[MemeChainToken[i].coinName.toLowerCase()] = MemeChainToken[i].coinName;
        }
        if (MemeChainToken[i].displayCoinName && !keepList[MemeChainToken[i].displayCoinName]){
            keepList[MemeChainToken[i].displayCoinName.toLowerCase()] = MemeChainToken[i].displayCoinName;
        }
    }
}

export function AutoStrangeRule(children) {
    if (typeof children === 'undefined' || children === null) {

    } else if (typeof children === 'string') {
        return stringProcess(children);
    } else {
        return <StrangeRule>{children}</StrangeRule>;
    }
}

const stringProcess = (text) => {
    return capitalizeEachWord(keepCase(text.toLowerCase()))
}

function escapeRegExp(string) {
    return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
}

const keepCase = (text) => {
    const words = Object.keys(keepList).map(escapeRegExp);
    const regex = new RegExp(`(${words.join('|')})`, 'gi');
    return text.replace(regex, (matched) => keepList[matched.toLowerCase()]);
}

const capitalizeEachWord = (string) => {
    return string.split(' ').map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
    }).join(' ');
}

class StrangeRule extends React.Component {
    renderChildren = children => {
        return React.Children.map(children, child => {
            if (typeof child === 'string') {
                return stringProcess(child);
            }

            if (React.isValidElement(child) && child.props.children) {
                return React.cloneElement(child, {}, this.renderChildren(child.props.children));
            }

            return child;
        });
    };

    render() {
        return <>{this.renderChildren(this.props.children)}</>;
    }
}

export default StrangeRule;
