import React, {memo, useEffect, useMemo, useRef, useState} from "react";
import {connect} from "react-redux";
import {Tabs, Modal, Button, Checkbox, Slider} from 'antd';
import s from "./index.module.less";
import {
    getfriendtrade_rank_selfdata,
    convertWalletBalance,
    searchuser,
    getAvatarFromProfile,
    joinChat,
    displayFilter,
} from "@/utils/common";
import CustomIcon from "src/common/CustomIcon";
import SearchUser from "src/common/SearchUser";
import AvatarInfo from "src/common/AvatarInfo";
import {useNavigate, useLocation} from "react-router-dom";
import {useTranslation, Trans} from 'react-i18next';
import MainHeader from "src/common/MainHeader";
// import bannerBg from "@/assets/images/COQ.png";
import Banner from "src/common/Banner";
import {
    mainNftNftPanelPath,
    mainClubAllClubsPath,
    gameMemeClubPath,
} from "@/routes/config";
import SearchUserList from "@/common/SearchUserList";

const NewExplore = (
    {
        userInfo,
        setUserInfo,
        earnInfo,
        setHideRefreshBtn,
    }
) => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();

    const [searchResult, setSearchResult] = useState([]);
    const [searchKeyValue, setSearchKeyValue] = useState('');
    const [searchIndex, setSearchIndex] = useState(-1);
    const [showSearchResultPanel, setShowSearchResultPanel] = useState(false);

    const searchUserHandle = (searchKey) => {
        if (!searchKey) {
            setHideRefreshBtn(false);
            setShowSearchResultPanel(false);

        } else {
            setHideRefreshBtn(true);
            setShowSearchResultPanel(true);
            setSearchKeyValue(searchKey)
        }
        searchKey && searchuser(searchKey, 0).then(res => {
            setSearchIndex(res?.data?.nextCursor);
            setSearchResult(res?.data?.userlist || []);
        }).catch(e => {
            console.log(e);
        })
    }

    const getMoreSearchResult = () => {
        searchuser(searchKeyValue, searchIndex).then(res => {
            setSearchIndex(res?.data?.nextCursor);
            setSearchResult([
                ...searchResult,
                ...res?.data?.userlist || []
            ]);
        }).catch(e => {
            console.log(e);
        })
    }

    return (
        <div className={`${s.wrap} mainWrap`}>
            <MainHeader hasBackBtn={false} leftTitle={t('Explore')}></MainHeader>
            <div className={s.banner}>
                <Banner navToLive={true} isHomeBanner={true} hasCurndown={true} showTimer={true} bgUrl={'/UI/Banner/UI_Banner_TurnUp_01.webp'}>
                </Banner>
            </div>
            <div className={s.content}>
                {/* <div className={s.searchRoot}>
                    <SearchUser searchUserFn={searchUserHandle}/>
                </div> */}
                {
                    showSearchResultPanel &&
                    <div className={s.serarchPanel}>
                        <SearchUserList
                            searchIndex={searchIndex}
                            searchResult={searchResult}
                            getMoreSearchResult={getMoreSearchResult}/>
                    </div>
                }
                {!showSearchResultPanel &&
                    <div className={s.gameContent}>
                        <div className={`${s.bigImageRoot}`}
                             style={{
                                 backgroundImage: `url(/UI/BG/UI_Background-S_Explore_03.webp)`,
                                 aspectRatio: 1.4654
                             }}
                             onClick={() => {
                                navigate(gameMemeClubPath)
                             }}>
                            <div className={`${s.bigImageTitle} fb`}>
                                {t('DNA Launcher')}
                            </div>
                        </div>
                        {/* <div className={`${s.bigImageRoot}`}
                             style={{
                                 backgroundImage: `url(/UI/BG/UI_Background-S_Explore_01.webp)`,
                                 aspectRatio: 1.4654
                             }}
                             onClick={() => {
                                 navigate(mainClubAllClubsPath);
                             }}>
                            <div className={`${s.bigImageTitle} fb mt10`}>
                                {t('VIEW ALL CLUBS')}
                            </div>
                        </div>
                        <div className={s.bigImageRoot}
                             style={{
                                 backgroundImage: `url(/UI/BG/UI_Background-S_Explore_02.webp)`,
                                 aspectRatio: 1.4654,
                             }}
                             onClick={() => {
                                 navigate(mainNftNftPanelPath);
                             }}>
                            <div className={`${s.bigImageTitle} fb`}>
                                {t('MARKETPLACE')}
                            </div>
                        </div> */}
                    </div>
                }
            </div>
        </div>
    )
}
const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
        earnInfo: app.earnInfo
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setUserInfo: (params) => {
            return dispatch({
                type: "app/setUserInfo",
                payload: params
            });
        },
        setHideRefreshBtn: (params) => {
            return dispatch({
                type: "app/setHideRefreshBtn",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(NewExplore));
