import React, {memo, useEffect, useMemo, useRef, useState} from "react";
import s from "./index.module.less";
import {
    stringToNumber,
    unit,
    sliceStr,
    vCoinString,
    joinChat,
    getProfile,
} from "@/utils/common";
import CustomIcon from "src/common/CustomIcon";
import {useNavigate} from "react-router-dom";
import StrangeRule from "@/utils/strangeRule";
import {useTranslation, Trans} from 'react-i18next';

const HistoryActivitiesInfo = (
    {
        className,
        userInfo,
        textType = 0,//0:detail, 1:valueAndTime
        data,
    }
) => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();

    const {
        logType, 
        txData, 
        friendTradeData
    } = useMemo(() => {
        return data || {};
    }, [data]);

    const {userId, platformData} = useMemo(() => {
        return userInfo || {};
    }, [userInfo]);
    const profile = useMemo(() => {
        return getProfile(platformData) || {};
    }, [platformData])

    const toTranD = (userId, accountType) => {
        if(userId > 0 ){
            joinChat(userId, navigate, accountType);
        }
    }

    const timeFn = (time) => {
        let d = parseInt(time / 60 / 60 / 24);
        let h = parseInt(time / 60 / 60 % 24);
        let m = parseInt(time / 60 % 60);
        let str = '';
        if (d > 0) {
            str = d + "d";
            return str
        }
        if (h > 0) {
            str = h + "h";
            return str
        }
        if (m > 0) {
            str = m + "m";
            return str
        }
        return t('few seconds');
    }

    const getActivityInfo = () =>{
        if (logType === 2){
            const {
                type,
                managerId,
                managerAccountType,
                managerProfile,
                employeeId,
                employeeAccountType,
                employeeProfile,
                coin,
                time,
                workName,
                bpChangeVal
            } = friendTradeData || {};
            if (type === 1){
                return <Trans
                    i18nKey="xx 's manager has switched to yy"
                    defaults="<click>{{employee}}</click> <gray>'s manager has switched to</gray> <wish>{{manager}}</wish>"
                    values={{
                        manager: sliceStr(managerProfile?.displayName || 'Null', 'all'),
                        employee: sliceStr(employeeProfile?.displayName || 'Null', 'all')
                    }}
                    components={{
                        click: <span className="color-white"
                                        onClick={() => {
                                            toTranD(managerId, managerAccountType)
                                        }}></span>,
                        gray: <span
                            className="color-blue-light"></span>,
                        wish: <span
                            className={`${type === 3 && 'color-wish-color'}`}
                            onClick={() => {
                                toTranD(employeeId, employeeAccountType)
                            }}></span>
                    }}
                />
            }
            else if (type === 2){
                return <Trans
                    i18nKey="xx now leads Club yy"
                    defaults="<click>{{manager}}</click> <gray>now leads Club</gray> <wish>{{employee}}</wish>"
                    values={{
                        manager: sliceStr(managerProfile.displayName, 'all'),
                        employee: sliceStr(employeeProfile.displayName, 'all')
                    }}
                    components={{
                        click: <span className="color-white"
                                        onClick={() => {
                                            toTranD(managerId, managerAccountType)
                                        }}></span>,
                        gray: <span
                            className="color-blue-light"></span>,
                        wish: <span
                            className={`${type === 3 && 'color-wish-color'}`}
                            onClick={() => {
                                toTranD(employeeId, employeeAccountType)
                            }}></span>
                    }}
                />
            }
            else if (type === 3){
                return <Trans
                    i18nKey="xx has finished the mission yy"
                    defaults="<click>{{manager}}</click> <gray>has finished the mission</gray> <wish>{{workName}}</wish>"
                    values={{
                        manager: sliceStr(managerProfile.displayName, 'all'),
                        workName: workName
                    }}
                    components={{
                        click: <span className="color-white"
                                    onClick={() => {
                                        toTranD(managerId, managerAccountType)
                                    }}></span>,
                        gray: <span
                            className="color-blue-light"></span>,
                        wish: <span
                            className={`${type === 3 && 'color-wish-color'}`}></span>
                    }}
                />
            }
            else if (type === 4 || type === 5 || type === 11){
                return <Trans
                    i18nKey="xx claimed mission returns from Club yy"
                    defaults="<click>{{manager}}</click> <gray>claimed mission returns from Club</gray> <wish>{{employee}}</wish>"
                    values={{
                        manager: sliceStr(managerProfile.displayName, 'all'),
                        employee: sliceStr(employeeProfile.displayName, 'all')
                    }}
                    components={{
                        click: <span
                            className="color-white"
                            onClick={() => {
                                toTranD(managerId, managerAccountType)
                            }}></span>,
                        gray: <span
                            className="color-blue-light"></span>,
                        wish: <span
                            className={`${type === 3 && 'color-wish-color'}`}
                            onClick={() => {
                                toTranD(employeeId, employeeAccountType)
                            }}></span>
                    }}
                />
            }
            else if (type === 6){
                return <Trans
                    i18nKey="xx 's club yy looted me"
                    defaults="<click>{{manager}}</click> <gray>'s club</gray> <wish>{{employee}}</wish> <gray>looted me</gray>"
                    values={{
                        manager: sliceStr(managerProfile.displayName, 'all'),
                        employee: sliceStr(employeeProfile.displayName, 'all')
                    }}
                    components={{
                        click: <span
                            className="color-white"
                            onClick={() => {
                                toTranD(managerId, managerAccountType)
                            }}></span>,
                        gray: <span
                            className="color-blue-light"></span>,
                        wish: <span
                            className={`${type === 3 && 'color-wish-color'}`}
                            onClick={() => {
                                toTranD(employeeId, employeeAccountType)
                            }}></span>
                    }}
                />
            }
            else if (type === 7){
                return <Trans
                    i18nKey="xx looted my club yy"
                    defaults="<click>{{manager}}</click> <gray>looted my club</gray> <wish>{{employee}}</wish>"
                    values={{
                        manager: sliceStr(managerProfile.displayName, 'all'),
                        employee: sliceStr(employeeProfile.displayName, 'all')
                    }}
                    components={{
                        click: <span
                            className="color-white"
                            onClick={() => {
                                toTranD(managerId, managerAccountType)
                            }}></span>,
                        gray: <span
                            className="color-blue-light"></span>,
                        wish: <span
                            className={`${type === 3 && 'color-wish-color'}`}
                            onClick={() => {
                                toTranD(employeeId, employeeAccountType)
                            }}></span>
                    }}
                />
            }
            else if (type === 8){
                return <Trans
                    i18nKey="Your club xx looted yy"
                    defaults="Your club <club1>{{name1}}</club1> looted <club2>{{name2}}</club2>"
                    values={{
                        name1: sliceStr(employeeProfile.displayName, 'all'),
                        name2: sliceStr(managerProfile.displayName, 'all')
                    }}
                    components={{
                        club1: <span
                            className={`${type === 3 && 'color-wish-color'}`}
                            onClick={() => {
                                toTranD(employeeId, employeeAccountType)
                            }}></span>,
                        club2: <span className="color-white" onClick={() => {
                            toTranD(managerId, managerAccountType)
                        }}></span>
                    }}
                />
            }
            else if (type === 9){
                return <Trans
                    i18nKey="xx looted the club yy"
                    defaults="<club1>{{name1}}</club1> <gray>looted the club</gray> <club2>{{name2}}</club2>"
                    values={{
                        name1: sliceStr(employeeProfile.displayName, 'all'),
                        name2: sliceStr(managerProfile.displayName, 'all')
                    }}
                    components={{
                        club1: <span
                            className={`${type === 3 && 'color-wish-color'}`}
                            onClick={() => {
                                toTranD(employeeId, employeeAccountType)
                            }}></span>,
                        club2: <span className="color-white" onClick={() => {
                            toTranD(managerId, managerAccountType)
                        }}></span>,
                        gray: <span className="color-blue-light"></span>
                    }}
                />
            }
            else if (type === 10){
                return <Trans
                    i18nKey="xx aborted mission due to manager change yy"
                    defaults="<click>{{manager}}</click> <gray>aborted mission due to manager change</gray> <wish>{{employee}}</wish>"
                    values={{
                        manager: sliceStr(managerProfile.displayName, 'all'),
                        employee: sliceStr(employeeProfile.displayName, 'all')
                    }}
                    components={{
                        click: <span
                            className="color-white"
                            onClick={() => {
                                toTranD(managerId, managerAccountType)
                            }}></span>,
                        gray: <span
                            className="color-blue-light"></span>,
                        wish: <span
                            className={`${type === 3 && 'color-wish-color'}`}
                            onClick={() => {
                                toTranD(employeeId, employeeAccountType)
                            }}></span>
                    }}
                />
            }
            else if (type === 12){
                return <Trans
                    i18nKey="xx looted the club yy"
                    defaults="your club led by <club1>{{name1}}</club1> <gray>looted club</gray> <club2>{{name2}}</club2>"
                    values={{
                        name1: sliceStr(managerProfile.displayName, 'all'),
                        name2: sliceStr(employeeProfile.displayName, 'all')
                    }}
                    components={{
                        club1: <span
                            className={`${type === 3 && 'color-wish-color'}`}
                            onClick={() => {
                                toTranD(managerId, managerAccountType)
                            }}></span>,
                        club2: <span className="color-white" onClick={() => {
                            toTranD(employeeId, employeeAccountType)
                        }}></span>,
                        gray: <span className="color-blue-light"></span>
                    }}
                />
            }
            else if (type === 13){
                return <Trans
                    i18nKey="my club survived in club B's looting"
                    defaults="my club survived in club <club>{{BClub}}</club>'s looting"
                    values={{BClub: sliceStr(managerProfile.displayName, 'all')}}
                    components={{
                        club: <span className={`color-white`}
                                    onClick={() => {
                                        toTranD(managerId, managerAccountType)
                                    }}></span>
                    }}
                />
            }
            else if (type === 14){
                return <Trans
                    i18nKey="xx received X sharing rewards"
                    defaults="<click>{{manager}}</click> <gray> received X sharing rewards</gray>"
                    values={{
                        manager: sliceStr(profile.displayName, 'all')
                    }}
                    components={{
                        click: <span
                            className="color-white"
                            onClick={() => {
                                toTranD(userId, 'key')
                            }}></span>,
                        gray: <span
                            className="color-blue-light"></span>,
                    }}
                />
            }
        }
        else if (logType === 1){
            let {
                amount,
                time,
                txType,
                totalPrice,
                keyId,
                userId,
                keyAccountType,
                keyProfile,
                userAccountType,
                userProfile
            } = txData;
            if (txType === 1){
                return <Trans
                    i18nKey="xx bought aa yy sth"
                    defaults="<user>{{name1}}</user> <gray>bought</gray> <fb>{{count}}</fb> <fbClick>{{name2}}</fbClick> <gray>{{sth}}<gray>"
                    values={{
                        name1: sliceStr(userProfile.displayName, 'all'),
                        name2: sliceStr(keyProfile.displayName, 'all'),
                        count: amount,
                        sth: keyAccountType === 'wish' ? 'wish' : 'key'
                    }}
                    components={{
                        user: <span className="color-white" onClick={() => {
                            toTranD(userId, userAccountType)
                        }}></span>,
                        gray: <span className="color-blue-light"></span>,
                        fb: <span className="color-white"></span>,
                        fbClick: <span className="color-white" onClick={() => {
                            toTranD(keyId, keyAccountType)
                        }}></span>
                    }}
                />;
            }
            else {
                return <Trans
                    i18nKey="xx sold aa yy sth"
                    defaults="<user>{{name1}}</user> <gray>sold</gray> <fb>{{count}}</fb> <fbClick>{{name2}}</fbClick> <gray>{{sth}}<gray>"
                    values={{
                        name1: sliceStr(userProfile.displayName, 'all'),
                        name2: sliceStr(keyProfile.displayName, 'all'),
                        count: amount,
                        sth: keyAccountType === 'wish' ? 'wish' : 'key'
                    }}
                    components={{
                        user: <span className="color-white" onClick={() => {
                            toTranD(userId, userAccountType)
                        }}></span>,
                        gray: <span className="color-blue-light"></span>,
                        fb: <span className="color-white"></span>,
                        fbClick: <span className="color-white" onClick={() => {
                            toTranD(keyId, keyAccountType)
                        }}></span>
                    }}
                />;
            }
        }
        else{
            return <></>;
        }
    }

    const getActicityValueAndTime = () =>{
        if (logType === 2){
            const {
                type,
                managerId,
                managerAccountType,
                managerProfile,
                employeeId,
                employeeAccountType,
                employeeProfile,
                coin,
                time,
                workName,
                bpChangeVal
            } = friendTradeData || {};

            if (type === 1 || type === 2){
                return <span className="color-blue-light fs12">
                    {t('xx ago', {time: timeFn(time)})}
                </span>
            }
            else if (type === 3){
                return <Trans
                    i18nKey="xx $LFG yielded for the manager, yy ago"
                    defaults="<coinImg></coinImg> <coin>{{amount}}</coin> $LFG yielded for the manager, {{time}} ago"
                    values={{amount: vCoinString(coin), time: timeFn(time)}}
                    components={{
                        coinImg: <CustomIcon className="" width={14}
                                            height={14}
                                            imgName={'Picture/UI_Picture-Currency_LFG_01'}
                                            name={'Picture/UI_Picture-Currency_LFG_01'}/>,
                        coin: <span className="color-lfg fs14"></span>
                    }}
                />
            }
            else if (type === 4){
                return <Trans
                    i18nKey="xx $LFG Claimed, yy ago"
                    defaults="<coinImg></coinImg> <coin>{{amount}}</coin> $LFG Claimed, {{time}} ago"
                    values={{
                        amount: vCoinString(coin),
                        time: timeFn(time)
                    }}
                    components={{
                        coinImg: <CustomIcon className="" width={14}
                                                height={14}
                                                imgName={'Picture/UI_Picture-Currency_LFG_01'}
                                                name={'Picture/UI_Picture-Currency_LFG_01'}/>,
                        coin: <span
                            className="color-lfg fs14"></span>
                    }}
                />
            }
            else if (type === 5){
                return <Trans
                    i18nKey="xx $LFG profit sharing earned, yy ago"
                    defaults="<coinImg></coinImg> <coin>{{amount}}</coin> profit sharing earned, {{time}} ago"
                    values={{
                        amount: vCoinString(coin),
                        time: timeFn(time)
                    }}
                    components={{
                        coinImg: <CustomIcon className=""
                                                width={14}
                                                height={14}
                                                imgName={'Picture/UI_Picture-Currency_LFG_01'}
                                                name={'Picture/UI_Picture-Currency_LFG_01'}/>,
                        coin: <span
                            className="color-lfg fs14"></span>
                    }}
                />
            }
            else if (type === 6){
                return <Trans
                    i18nKey="xx $LFG Pool lost, BP, yy ago"
                    defaults="<coinImg></coinImg> <coin>{{amount}}</coin> $LFG Pool lost,<BPImg></BPImg> {{BPText}}, {{time}} ago"
                    values={{
                        amount: vCoinString(coin),
                        time: timeFn(time),
                        BPText: Math.abs(bpChangeVal) > 0 ? `${bpChangeVal >= 0 ? '+' : ''}${bpChangeVal}` : t('&lt;1 BP lost')
                    }}
                    components={{
                        coinImg: <CustomIcon className=""
                                                width={14}
                                                height={14}
                                                imgName={'Picture/UI_Picture-Currency_LFG_01'}
                                                name={'Picture/UI_Picture-Currency_LFG_01'}/>,
                        coin: <span
                            className="color-lfg fs14"></span>,
                        BPImg: Math.abs(bpChangeVal) > 0 ?
                            <CustomIcon className="" width={14}
                                        height={14}
                                        imgName={'Picture/UI_Picture-Currency_Power_01'}
                                        name={'Picture/UI_Picture-Currency_Power_01'}/> : <></>
                    }}
                    shouldUnescape={true}
                />
            }
            else if (type === 7){
                return <Trans
                    i18nKey="xx in progress earnings lost, yy ago"
                    defaults="<coinImg></coinImg> <coin>{{amount}}</coin> in progress earnings lost, {{time}} ago"
                    values={{
                        amount: vCoinString(coin),
                        time: timeFn(time)
                    }}
                    components={{
                        coinImg: <CustomIcon className=""
                                                width={14}
                                                height={14}
                                                imgName={'Picture/UI_Picture-Currency_LFG_01'}
                                                name={'Picture/UI_Picture-Currency_LFG_01'}/>,
                        coin: <span
                            className="color-lfg fs14"></span>
                    }}
                />
            }
            else if (type === 8){
                return <Trans
                    i18nKey="xx looted, BP,  yy ago"
                    defaults="<coinImg></coinImg> <coin>{{amount}}</coin> looted, <BPImg></BPImg> {{BPText}}, {{time}} ago"
                    values={{
                        amount: vCoinString(coin),
                        time: timeFn(time),
                        BPText: Math.abs(bpChangeVal) > 0 ? `${bpChangeVal >= 0 ? '+' : ''}${bpChangeVal}` : t('&lt;1 BP gained')
                    }}
                    components={{
                        coinImg: <CustomIcon
                            className="" width={14}
                            height={14}
                            imgName={'Picture/UI_Picture-Currency_LFG_01'}
                            name={'Picture/UI_Picture-Currency_LFG_01'}/>,
                        coin: <span
                            className="color-lfg fs14"></span>,
                        BPImg: Math.abs(bpChangeVal) > 0 ?
                            <CustomIcon className=""
                                        width={14}
                                        height={14}
                                        imgName={'Picture/UI_Picture-Currency_Power_01'}
                                        name={'Picture/UI_Picture-Currency_Power_01'}/> : <></>
                    }}
                    shouldUnescape={true}
                />
            }
            else if (type === 9){
                return <Trans
                    i18nKey="xx profit sharing earned, yy ago"
                    defaults="<coinImg></coinImg> <coin>{{amount}}</coin> profit sharing earned, {{time}} ago"
                    values={{
                        amount: vCoinString(coin),
                        time: timeFn(time)
                    }}
                    components={{
                        coinImg: <CustomIcon
                            className="" width={14}
                            height={14}
                            imgName={'Picture/UI_Picture-Currency_LFG_01'}
                            name={'Picture/UI_Picture-Currency_LFG_01'}/>,
                        coin: <span
                            className="color-lfg fs14"></span>
                    }}
                />
            }
            else if (type === 10){
                return <Trans
                    i18nKey="xx LFG claimed, yy ago"
                    defaults="<coinImg></coinImg> <coin>{{amount}}</coin> LFG claimed, {{time}} ago"
                    values={{
                        amount: vCoinString(coin),
                        time: timeFn(time)
                    }}
                    components={{
                        coinImg: <CustomIcon
                            className=""
                            width={14}
                            height={14}
                            imgName={'Picture/UI_Picture-Currency_LFG_01'}
                            name={'Picture/UI_Picture-Currency_LFG_01'}/>,
                        coin: <span
                            className="color-lfg fs14"></span>
                    }}
                />
            }
            else if (type === 11){
                return <Trans
                    i18nKey="xx LFG owner's share earned, yy ago"
                    defaults="<coinImg></coinImg> <coin>{{amount}}</coin> $LFG owner's sharing earned, {{time}} ago"
                    values={{
                        amount: vCoinString(coin),
                        time: timeFn(time)
                    }}
                    components={{
                        coinImg: <CustomIcon
                            className=""
                            width={14}
                            height={14}
                            imgName={'Picture/UI_Picture-Currency_LFG_01'}
                            name={'Picture/UI_Picture-Currency_LFG_01'}/>,
                        coin: <span
                            className="color-lfg fs14"></span>
                    }}
                />
            }
            else if (type === 12){
                return <Trans
                    i18nKey="xx $LFG owner's sharing earned, yy ago"
                    defaults="<coinImg></coinImg> <coin>{{amount}}</coin> owner's sharing earned, {{time}} ago"
                    values={{
                        amount: vCoinString(coin),
                        time: timeFn(time)
                    }}
                    components={{
                        coinImg:
                            <CustomIcon
                                className=""
                                width={14}
                                height={14}
                                imgName={'Picture/UI_Picture-Currency_LFG_01'}
                                name={'Picture/UI_Picture-Currency_LFG_01'}/>,
                        coin: <span
                            className="color-lfg fs14"></span>
                    }}
                />
            }
            else if (type === 13){
                return <Trans
                    i18nKey="BP, yy ago"
                    defaults="<BPImg></BPImg> {{BPText}}, {{time}} ago"
                    values={{
                        time: timeFn(time),
                        BPText: Math.abs(bpChangeVal) > 0 ? `${bpChangeVal >= 0 ? '+' : ''}${bpChangeVal}` : t('&lt;1 BP gained')
                    }}
                    components={{
                        coinImg:
                            <CustomIcon
                                className=""
                                width={14}
                                height={14}
                                imgName={'Picture/UI_Picture-Currency_LFG_01'}
                                name={'Picture/UI_Picture-Currency_LFG_01'}/>,
                        coin: <span
                            className="color-lfg fs14"></span>,
                        BPImg: Math.abs(bpChangeVal) > 0 ?
                            <CustomIcon
                                className=""
                                width={14}
                                height={14}
                                imgName={'Picture/UI_Picture-Currency_Power_01'}
                                name={'Picture/UI_Picture-Currency_Power_01'}/> : <></>
                    }}
                    shouldUnescape={true}
                />
            }
            else if (type === 14){
                return <Trans
                    i18nKey="coinImg amount"
                    defaults="<coinImg></coinImg> <coin>{{amount}}</coin>, {{time}} ago"
                    values={{
                        amount: vCoinString(coin * 10000),
                        time: timeFn(time),
                    }}
                    components={{
                        coinImg:
                            <CustomIcon
                                className=""
                                width={14}
                                height={14}
                                imgName={'Picture/UI_Picture-Currency_UP_01'}
                                name={'Picture/UI_Picture-Currency_UP_01'}/>,
                        coin:
                            <span
                                className="color-white fs14"></span>,
                    }}
                    shouldUnescape={true}
                />
            }
            else{
                return <span className="color-blue-light fs12">
                    {t('xx ago', {time: timeFn(time)})}
                </span>
            }
        }
        else if (logType === 1){
            let {
                amount,
                time,
                txType,
                totalPrice,
                keyId,
                userId,
                keyAccountType,
                keyProfile,
                userAccountType,
                userProfile
            } = txData;
            return <div className={s.price}>
                <div className="color-purple">{stringToNumber(totalPrice, 4)} {unit}</div>
                <span className="color-blue-light">{`, ${t('xx ago', {time: timeFn(time)})}`}</span>
            </div>
        }
        else{
            return <></>;
        }
    }
    
    return (
        <div className={`${s.wrap} ${className}`}>
            {textType === 0 ? getActivityInfo() : getActicityValueAndTime()}
        </div>
    )
}
export default memo(HistoryActivitiesInfo);
