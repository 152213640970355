import React, {ReactNode} from "react";
import {ModalProps, Modal} from "antd";
import {AutoStrangeRule} from "@/utils/strangeRule";

interface Props extends ModalProps {
    children?: ReactNode;
    title?: ReactNode;
    id?: number;
}


function AntModal({children, id, title, ...rest}: Props) {
    return (
        <Modal title={AutoStrangeRule(title)} {...rest}>{children}</Modal>
    );
}

const MemoModal = React.memo(AntModal);

export default MemoModal;
