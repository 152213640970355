import React, { useEffect, useRef, useState } from 'react';
import { createChart } from 'lightweight-charts';
import { meme_tradelog } from '@/utils/memeClub';
import { useTranslation, Trans } from 'react-i18next';
import s from "./index.module.less";
const aggregateData = (data, interval) => {
    const result = [];
    let currentCandle = null;

    data.forEach(trade => {
        // const {sellPrice,tradePrice,timestamp} = trade
        const { sellPrice, tradePrice, timestamp } = trade
        const candleTime = Math.floor(timestamp / interval) * interval;

        if (!currentCandle || currentCandle.time !== candleTime) {
            if (currentCandle) {
                result.push(currentCandle);
            }
            currentCandle = {
                time: candleTime,
                open: result[result?.length - 1]?.close || 0,
                high: parseFloat(sellPrice),
                low: parseFloat(sellPrice),
                close: parseFloat(sellPrice),
                volume: parseFloat(tradePrice),
            };
        } else {
            currentCandle.high = Math.max(currentCandle.high, parseFloat(sellPrice));
            currentCandle.low = Math.min(currentCandle.low, parseFloat(sellPrice));
            currentCandle.close = parseFloat(sellPrice);
            currentCandle.volume += parseFloat(tradePrice);
        }
    });

    if (currentCandle) {
        result.push(currentCandle);
    }

    return result;
}
const pageSise = 200; //max 500
const layout = 30000; //update time ms
const kInterval = 300; //K line times s
let updateLegend;
const KChart = ({
    symbolName,
    coinName,
    clubId,
}) => {
    const { t, i18n } = useTranslation();

    const chartContainerRef = useRef(null);
    const chartRef = useRef(null);
    const candlestickSeriesRef = useRef(null);
    const [currentCursor, setCurrentCursor] = useState(-1);
    const [lastCursor, setLastCursor] = useState(-1);
    const [startCursor, setStartCursor] = useState(-1);
    const [endCursor, setEndCursor] = useState(-1);
    const [data, setData] = useState([])
    const [sourceData, setSourceData] = useState([])
    const [upDate, setUpdate] = useState(false)

    const formatData = (data, interval) => {
        const aggregatedData = aggregateData(data, interval);
        const _data = aggregatedData.map(bar => ({
            time: bar.time,
            low: bar.low,
            high: bar.high,
            open: bar.open,
            close: bar.close,
            volume: bar.volume,
        }));
        setData(_data)
    }

    const getMemeTradelog = (clubId, start = -1, end = -1, getLast = false) => {
        console.log(`getMemeTradelog Fn params------>`, {
            clubId,
            start,
            end,
            getLast
        });
        meme_tradelog({
            clubUserId: parseInt(clubId),
            startCursor: parseInt(start),
            endCursor: parseInt(end)
        }).then(res => {
            const { startCursor, endCursor, lastCursor, tradeLogs } = res;
            if (getLast && !(tradeLogs && tradeLogs?.length > 0)) return
            if (start === -1 && end === -1) {
                setCurrentCursor(lastCursor)
            }
            setLastCursor(lastCursor)
            console.log(`getMemeTradelog result`,{
                startCursor, endCursor, lastCursor, tradeLogs
            });
            if (getLast) {
                
                setStartCursor(lastCursor + 1)
                setEndCursor(lastCursor + pageSise)
                setCurrentCursor(lastCursor)
                setSourceData(sourceData => {
                    return [
                        ...(sourceData || []),
                        ...(tradeLogs || []),
                    ]
                })
            } else {

                setSourceData(sourceData => {
                    return [
                        ...(tradeLogs || []),
                        ...(sourceData || []),
                    ]
                })

                if (startCursor > 0) {
                    getMemeTradelog(clubId, Math.max(0, startCursor - pageSise), Math.max(startCursor - 1, endCursor - pageSise))
                } else {
                    setStartCursor(start === -1 && end === -1 ? lastCursor + 1 : currentCursor + 1)
                    setEndCursor(start === -1 && end === -1 ? lastCursor + pageSise : currentCursor + pageSise)
                    setUpdate(true)
                }
            }
        })
    }

    useEffect(() => {
        sourceData?.length > 0 && formatData(sourceData, kInterval)
    }, [sourceData])

    useEffect(() => {
        if (!clubId) return
        setCurrentCursor(-1);
        setLastCursor(-1);
        setStartCursor(-1);
        setEndCursor(-1);
        setData([]);
        setSourceData([]);
        getMemeTradelog(clubId)
    }, [clubId])


    useEffect(() => {
        let timer = null
        if (upDate && !timer) {
            timer = setInterval(() => {
                getMemeTradelog(clubId, startCursor, endCursor, true)
            }, layout)
        }
        if (!upDate) {
            timer && clearInterval(timer)
        }
        return () => {
            timer && clearInterval(timer)
        }
    }, [upDate, startCursor, endCursor])

    useEffect(() => {
        if (!chartContainerRef?.current) return
        chartRef.current = createChart(chartContainerRef.current, {
            width: chartContainerRef.current.clientWidth,
            height: 270,
            layout: { textColor: 'white', background: { color: '#1B1D28' } },
            grid: {
                vertLines: {
                    color: "#ccc",
                    visible: false
                },
                horzLines: {
                    visible: false
                }
            },
            timeScale: {
                timeVisible: true,
                borderVisible: false, // 隐藏X轴的时间线
                uniformDistribution: true,
            },
            rightPriceScale: {
                mode: 1,
                borderVisible: false,
            }
        });



        const legend = document.createElement('div');
        legend.style = `position: absolute; left: 20px; top: 12px; z-index: 1; font-size: 12px;display: flex;
        flex-direction: column;
        align-items: flex-start;`;
        legend.style.color = 'black';
        chartContainerRef.current.appendChild(legend);


        const getLastBar = series => {

            const lastIndex = series.dataByIndex(Number.MAX_SAFE_INTEGER, -1);
            // console.log(lastIndex,series.dataByIndex(lastIndex));
            return lastIndex || {
                time: parseInt(new Date().getTime() / 1000),
                value: 0,
                close: 0,
            };
        };
        const formatPrice = price => price.toFixed(8);
        const setTooltipHtml = (name, coinName, date, price) => {
            legend.innerHTML = `
            <div style="font-size: 14px;"> ${name} ${t('Pack')}</div>
            <div style="font-size: 16px;">${price} ${coinName}</div>
            <div>${date}</div>`;
        };




        candlestickSeriesRef.current = chartRef.current.addCandlestickSeries({
            upColor: '#26a69a', 
            downColor: '#ef5350', 
            borderVisible: false,
            wickUpColor: '#26a69a', 
            wickDownColor: '#ef5350',
            priceFormat: {
                type: 'custom',
                minMove: 0.00000001, // 设置最小移动单位
                formatter: (price) => {
                    // 自定义价格格式化函数
                    if (price < 1) {
                        return price.toFixed(8);
                    } else {
                        return price.toFixed(2);
                    }
                },
            },
        });

        // 设置初始数据
        // candlestickSeriesRef.current.setData(data);
        updateLegend = param => {
            const validCrosshairPoint = !(
                param === undefined || param.time === undefined || param.point.x < 0 || param.point.y < 0
            );

            const bar = validCrosshairPoint ? param.seriesData.get(candlestickSeriesRef.current) : getLastBar(candlestickSeriesRef.current);
            // time is in the same format that you supplied to the setData method,
            // which in this case is YYYY-MM-DD
            const time = bar?.time;
            const price = bar?.value !== undefined ? bar.value : bar.close;
            
            const formattedPrice = formatPrice(price);
            setTooltipHtml(symbolName, coinName, new Date(time * 1000).format("MM.dd hh:mm"), formattedPrice);
        };

        chartRef.current.subscribeCrosshairMove(updateLegend);
        if (data && data.length > 0) {
            candlestickSeriesRef.current.setData(data);
        }
        // candlestickSeriesRef.current.setData(data || []);
        // updateLegend(undefined);

        // chartRef.current.timeScale().fitContent()
        // 响应窗口大小调整
        const handleResize = () => {
            chartRef.current.applyOptions({ width: chartContainerRef.current.clientWidth });
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            chartRef.current.remove();
        };
    }, [clubId, symbolName, coinName, chartContainerRef]);


    useEffect(() => {
        
        if (candlestickSeriesRef.current) {
            if (data && data.length > 0) {
                candlestickSeriesRef.current.setData(data);
              } else {
                candlestickSeriesRef.current.setData([]);
              }

        }
        if (data && data?.length > 0) {
            setTimeout(() => {
                updateLegend(undefined)
            }, 500)

        }
    }, [data,candlestickSeriesRef]);

    return (
        <div ref={chartContainerRef} className={s.keyWrap} >
            {(!data || data.length === 0) && (
                <div className={s.nodata}>
                    {t('No Data Available')}
                </div>
            )}
        </div>
    )

};

export default KChart;
