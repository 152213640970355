import React, {memo, useEffect, useMemo, useState} from "react";
import {connect} from "react-redux";
import s from "./index.module.less";
import {Button, Modal, message, Slider} from "antd";
import CustomIcon from "src/common/CustomIcon";
import {
    vCoinString,
} from "@/utils/common";

import ModelStake from "src/views/games/Stake/ModelStake";

import {usePrivy, useWallets} from "@privy-io/react-auth";

import ModelStakingPool from "src/views/games/Stake/ModelStakingPool";

import {useTranslation, Trans} from 'react-i18next';
import {lfgStakeBalanceOf} from "@/utils/lfgStake";
import MainHeader from "@/common/MainHeader";


const SettingsStake = (
    {
        userInfo,
    }
) => {
    const {t, i18n} = useTranslation();
    const {wallets} = useWallets();
    const {
        walletAddr,
        topUpCode,

        userId,
        platformData,
        selfData,

    } = useMemo(() => {
        return userInfo || {}
    }, [userInfo]);

    const {lfgBalance} = useMemo(() => {
        return selfData || {}
    }, [selfData])
    const {platformMap, useType, defaultName} = useMemo(() => {
        return platformData || {}
    }, [platformData])

    const {avatarUrl, displayName, originalAvatarUrl, uid} = useMemo(() => {
        if (platformMap && JSON.stringify(platformMap) !== '{}') {
            return platformMap[useType]
        } else {
            return {
                displayName: defaultName
            }
        }
    }, [platformMap, useType, defaultName])


    const {logout} = usePrivy();
    const [showStake, setShowStake] = useState(false)
    const [showStakingPool, setShowStakingPool] = useState(false)

    const [lfgStakeBalance, setLfgStakeBalance] = useState('--')

    const refreshStakeInfo = () => {
        lfgStakeBalanceOf(wallets).then(
            res => {
                setLfgStakeBalance(vCoinString(res))
            }
        )
    }

    useEffect(() => {
        if (wallets.length > 0) {
            refreshStakeInfo();
        }
    }, [wallets])

    const [maskClosable, setMaskClosable] = useState(true);
    const [refresh,setReFresh] = useState(null)

    return (
        <div className={s.wrap}>
            <MainHeader isGameHeader={true} hasBackBtn={false} headerType={2} leftTitle={t('Stake')} rightMenuIcon ={"Button/UI_Button-D_Home_On"}></MainHeader>
            <div className={s.content}>
                <ModelStake LFGbalance={lfgBalance} topUpCode={topUpCode} walletAddr={walletAddr}
                            setShowStake={setShowStake} refreshStakeInfo={refreshStakeInfo}
                            setReFresh = {setReFresh}
                            setMaskClosable={setMaskClosable}/>

                <ModelStakingPool userInfo={userInfo} LFGbalance={lfgBalance} topUpCode={topUpCode}
                                refresh = {refresh}
                                  walletAddr={walletAddr} setShowStakingPool={setShowStakingPool}
                                  refreshStakeInfo={refreshStakeInfo} setMaskClosable={setMaskClosable}/>
            </div>

        </div>

    )
}
const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {}
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(SettingsStake));
