import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import s from "./index.module.less";
import { useTranslation, Trans } from 'react-i18next';
import CustomIcon from "@/common/CustomIcon";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Input, Radio, Space, Select, message } from "antd";
import { creatMemeClub } from "@/utils/memeClubWeb3";
import { useWallets } from "@privy-io/react-auth";
import {
    coinData,
    creatClub,
    chainData,
    airdropTypeData,
    coinTotalSupplyData,
    airdropPecentageData,
    coinToNFTPecentageData,
    calcTotalValue,
    memeChainToken,
    coinList,
    preferencesObj,
    preferencesItems,
    formatAttr,
    defaultAttr,
    memeGenattrs,
    checkAttrs,
    websiteAfterAddon,
    getMemeGenImage,
    calcNumberCardVal,
    setAnony
} from "@/utils/memeClub";
import New from "./New";
import View from "./View";
import Setting from "./Setting";
import { getBalance } from "@/utils/uniswapWeb3";
import memePriceFormular from "@/utils/json/memePriceFormular.json" // Price Data
import memeSettingValue from "@/utils/json/memeSettingValue.json" // Price Data
import memeNFTAttrTemplate from "@/utils/json/memeNFTAttrTemplate.json" // Attr Data
import { BigNumber, ethers } from "ethers";
import useLocalStorageState from "@/common/useLocalStorageState";
import { getTokenBalanceOf } from "@/utils/common";
const liquidityPoolLimitData = memeSettingValue.find(item => item?.value === 'liquidityPoolLimit')
// Price Data start
const cardTotalSupplyData = memeSettingValue.find(item => item?.value === 'cardTotalSupply')

// Price Data end

// Attr Data start



// Attr Data end
const Creat = (
    {
        userInfo,
        walletBalanceList,
        setWalletBalanceList
    }
) => {
    const { t, i18n } = useTranslation();
    const { walletAddr } = useMemo(() => {
        return userInfo || {}
    }, [userInfo]);

    const userChangeCount = useRef(0);
    const userChangePreferencesCount = useRef(localStorage.getItem('memeClub_selPreferencesType')?0:1);
    const navigate = useNavigate();
    const { wallets } = useWallets()
    
    const { TextArea } = Input;
    const [clubName, setClubName] = useState('');
    const [clubXlink, setClubXlink] = useState('');
    const [clubTlink, setClubTlink] = useState('');
    const [clubTicker, setClubTicker] = useState('');
    const [clubDesc, setClubDesc] = useState('')
    const [selCoinId, setSelCoinId] = useState(coinList[0]?.value)
    const [hasVisit, setHasVisit] = useState(false)
    const [loading, setLoading] = useState(false);
    const [isAnony, setIsAnony] = useState(false);
    const [memeClubSymbolUrl, setMemeClubSymbolUrl] = useState('');
    const [aiImageLoaded, setAiImageLoaded] = useState(false);
    const [updateCount, setUpdateCount] = useState(0);
    const [memeClubImgUrl, setMemeClubImgUrl] = useState('https://dev.badass.xyz/UI/Picture/UI_Picture_CardPack_Sample_01.png')
    const selCreatChain = useMemo(() => {
        return memeChainToken.find(i => i?.ID === selCoinId)
    }, [selCoinId])

    const currentMemePriceFormular = useMemo(() => {
        return memePriceFormular.filter(i => i?.chainTokenConfigId === selCreatChain?.ID)
    }, [selCreatChain])

    // New
    const [selPreferencesType, setSelPreferencesType] = useState(preferencesItems[0]?.value)

    const selPreferences = useMemo(() => {
        const selArr = preferencesObj[selPreferencesType];
        return selArr[Math.floor(Math.random() * (selArr?.length))]
    }, [selPreferencesType])

    const resetParam = ({
        activeCurve,
        cardTotalSupply,
        _paramAPercent,
        _paramBPercent
    }) => {
        const { minB, maxB, formulaEnum } = activeCurve
        const minLP = liquidityPoolLimitData?.min
        const maxLP = liquidityPoolLimitData?.max
        console.log(activeCurve);
        const ratio = activeCurve?.divParamC / activeCurve?.mulParamD / activeCurve?.ratioToUSD * 10000

        let min, max;
        switch (activeCurve?.formulaEnum) {
            case 1:
                min = Math.round((2 * minLP) / (cardTotalSupply * (cardTotalSupply + 1)) * ratio);
                max = Math.round((2 * maxLP) / (cardTotalSupply * (cardTotalSupply + 1)) * ratio);
                break;
            case 2:
                min = Math.round((6 * minLP) / (cardTotalSupply * (cardTotalSupply + 1) * (2 * cardTotalSupply + 1)) * ratio);
                max = Math.round((6 * maxLP) / (cardTotalSupply * (cardTotalSupply + 1) * (2 * cardTotalSupply + 1)) * ratio);
                break;
            case 3:
                min = Math.round(minLP / cardTotalSupply * ratio);
                max = Math.round(maxLP / cardTotalSupply * ratio);
                break;
            default:
                min = 1;
                max = 2;
        }
        min = Math.max(min, 1);
        max = Math.max(max, 10);
        setParamAMin(min)
        setParamAMax(max)
        console.log(`outSet setParamAMax---------->`,min,max);
        const newParamA = Math.max(Math.round(min + _paramAPercent * (max - min)), 1);
        setParamA(newParamA);
        console.log(`outSet newParamA---------->`,newParamA);
        if (formulaEnum !== 3) {
            const newParamB = Math.max(Math.round(minB + _paramBPercent * (maxB - minB)), 0);
            setParamB(newParamB);
            console.log(`outSet newParamB---------->`,newParamB);
        }

    }

    useEffect(() => {
        console.log(`selPreferences---->`,selPreferences);
        // userChangePreferencesCount.current += 1;
        // if (userChangePreferencesCount.current === 1) return
        // setHasVisit(true)
        const { paramARatio, paramBRatio, cardTotalSupply, coinTotalSupply, formulaEnum } = selPreferences
        const _activeCurve = currentMemePriceFormular.find(i => i?.formulaEnum === selPreferences['formulaEnum'])
        console.log(`_activeCurve`,_activeCurve)
        setActiveCurve(_activeCurve)


        setCardTotalSupply(selPreferences['cardTotalSupply'])
        setCoinTotalSupply(selPreferences['coinTotalSupply'])
        setAirdropPecentage(parseInt(selPreferences['airdropPecentage'] / 100))
        setReceiveNFTAmount(parseInt(selPreferences['coinToNFTPecentage'] * coinTotalSupply / 10000))
        setUnpackRatio(parseInt(selPreferences['coinEachPackRangeRatio']))
        setParamAPercent(paramARatio / 100);
        setParamBPercent(paramBRatio / 100);
        resetParam({
            activeCurve:_activeCurve,
            cardTotalSupply:selPreferences['cardTotalSupply'],
            _paramAPercent: paramARatio / 100,
            _paramBPercent: paramBRatio / 100
        })
    }, [selPreferences])

    //New


    const getWalletBalance = ({ walletAddr, selCoinId, selCreatChain }) => {
        let tokenList = [selCoinId];
        if (selCreatChain?.isNative === 0) {
            tokenList.push(Math.round(Math.floor(selCoinId / 100) * 100));
        }
        getTokenBalanceOf(walletAddr, tokenList).then(res => {
            let tempBalance = walletBalanceList || [];
            for (let i = 0; i < tokenList.length; i++) {
                tempBalance[tokenList[i]] = res?.balances?.[tokenList[i]];
            }
            setWalletBalanceList(tempBalance);
        }).catch(err => {
            console.error(err.message);
        })
    }

    useEffect(() => {
        console.log(`getWalletBalance---->`,{ walletAddr, selCoinId, selCreatChain })
        getWalletBalance({ walletAddr, selCoinId, selCreatChain })
    }, [selCoinId, selCreatChain])


    // Price Data start
    const [paramAMin, setParamAMin] = useState(0);
    const [paramAMax, setParamAMax] = useState(0);
    const [paramBPercent, setParamBPercent] = useState(null);
    const [paramAPercent, setParamAPercent] = useState(null);
    const [activeCurve, setActiveCurve] = useState(currentMemePriceFormular.find(i => i?.formulaEnum === selPreferences['formulaEnum']))
    const [paramA, setParamA] = useState(null);
    const [paramB, setParamB] = useState(null);
    const changeCurrentMemePriceFormular = useRef(0)
    useEffect(() => {
        // changeCurrentMemePriceFormular.current += 1;
        // if (changeCurrentMemePriceFormular.current === 1) return
        if (!currentMemePriceFormular || currentMemePriceFormular?.length === 0) {
            message.error({ content: 'not fund MemePriceFormular' })
            return
        }
        setActiveCurve(currentMemePriceFormular?.find(i=>i?.formulaEnum === activeCurve?.formulaEnum))
    }, [currentMemePriceFormular])


    const [cardTotalSupply, setCardTotalSupply] = useState(selPreferences['cardTotalSupply'])

    useEffect(() => {
        if(activeCurve&&cardTotalSupply){
            resetParam({
                activeCurve,
                cardTotalSupply,
                _paramAPercent: paramAPercent,
                _paramBPercent: paramBPercent
            })
        }
    }, [activeCurve,cardTotalSupply])


    const totalCardValue = useMemo(() => {
        console.log(`totalCardValue----->`,{
            activeCurve, paramA, paramB, cardTotalSupply
        });
        if (cardTotalSupply && activeCurve) {

            return calcTotalValue(activeCurve, paramA, paramB, cardTotalSupply)
        }
    }, [paramA, paramB, cardTotalSupply, activeCurve])


    const lastCardPrice = useMemo(() => {
        if (activeCurve) {
            return calcNumberCardVal(activeCurve['formulaEnum'], paramA, paramB, cardTotalSupply, activeCurve['divParamC'], activeCurve['mulParamD'])
        }
    }, [activeCurve, paramA, paramB, cardTotalSupply])

    const firstCardPrice = useMemo(() => {
        if (activeCurve) {
            return calcNumberCardVal(activeCurve['formulaEnum'], paramA, paramB, 1, activeCurve['divParamC'], activeCurve['mulParamD'])
        }
    }, [activeCurve, paramA, paramB, cardTotalSupply])


    // Price Data end



    // Airdrop Data start

    const [coinTotalSupply, setCoinTotalSupply] = useState(selPreferences['coinTotalSupply'])
    const [airdropPecentage, setAirdropPecentage] = useState(parseInt(selPreferences['airdropPecentage'] / 100))
    const [receiveNFTAmount, setReceiveNFTAmount] = useState(parseInt(selPreferences['coinToNFTPecentage'] * coinTotalSupply / 10000))
    const [receiveNFT, setReceiveNFT] = useState(true)
    const [airdropType, setAirdropType] = useState(1)
    const [unpackRatio, setUnpackRatio] = useState(1)
    useEffect(() => {
        if (parseInt(coinToNFTPecentageData?.min / 10000 * coinTotalSupply) > receiveNFTAmount) {
            setReceiveNFTAmount(parseInt(coinToNFTPecentageData?.min / 10000 * coinTotalSupply))
        }

        if (parseInt(coinToNFTPecentageData?.max / 10000 * coinTotalSupply) < receiveNFTAmount) {
            setReceiveNFTAmount(parseInt(coinToNFTPecentageData?.max / 10000 * coinTotalSupply))
        }

    }, [coinTotalSupply])

    // Airdrop Data end

    // Attr Data start

    const [attrs, setAttrs] = useState(null)
    const [attrsLoaded, setAttrsLoaded] = useState(false);

    const [withRarity, setWithRarity] = useState(false)

    // Attr Data end

    const [showNew, setShowNew] = useState(true)
    const [showView, setShowView] = useState(false)
    const [showSetting, setShowSetting] = useState(false)


    const [creatDone, setCreatDone] = useState(false)
    const [creatErrorMsg, setCreatErrorMsg] = useState('')

  
    const creatFn = async() => {
        if (loading) return
        setLoading(true)
        if(isAnony){
            await setAnony(true)
        } 
        creatClub({
            coinId: selCreatChain?.ID,
            clubName: clubName,
            symbolName: clubTicker,
            imageUrl: memeClubImgUrl,
            symbolImageUrl: memeClubSymbolUrl,
            description: clubDesc,
            cardsTotalSupply: cardTotalSupply,
            curveType: activeCurve?.formulaEnum,
            paramA,
            paramB,
            ftTotalSupply: coinTotalSupply,
            airdropPercent: Math.round(airdropPecentage * 100),
            openPackRatio: unpackRatio,
            anonymous: isAnony,
            isFT: !receiveNFT,
            coinToNFTRatio: receiveNFTAmount,
            withRarity:true,
            preBuyCardNum: preBuyAmount,
            twitterLink: clubXlink,
            tgLink: clubTlink,
            attrs,
        }).then(res => {
            console.log(res);

            const {
                cardsTotalSupply,
                coinId,
                clubName,
                coinToNFTRatio,
                liquidity,
                curveType,
                paramA,
                paramB,
                isFT,
                orderId,
                signature,
                symbolName,
                tokenUrl,
                createFee,
                timestamp,
                validFor
            } = res;

            creatMemeClub({
                wallets,
                coinId,
                callId: orderId,
                initBuyAmount_: preBuyAmount,
                value: (selCreatChain?.isNative === 1 ? Number(preBuyTotalValue) : 0) + Number(createFee),
                creationFee_: ethers.utils.parseEther(createFee),
                memeConf_: {
                    maxSupply: cardsTotalSupply,
                    isNative: memeChainToken.find(i => i?.ID === coinId)?.isNative === 1,
                    isFT,
                    liquidityAmount: ethers.utils.parseEther(liquidity + ''),
                    name: clubName,
                    symbol: symbolName,
                    baseURI: tokenUrl,
                    baseUnit: ethers.utils.parseEther(coinToNFTRatio + ''),
                    priceType: curveType,
                    priceArg1: ethers.utils.parseEther(paramA + ''),
                    priceArg2: ethers.utils.parseEther(paramB + ''),
                },
                timestamp,
                validFor,
                signature
            }).then(resq => {
                setCreatDone(true)
                setLoading(false);
                console.log(resq); 
            }).catch(err => {
                setCreatDone(true)
                setLoading(false)
                setCreatErrorMsg(err)
                console.error(err);
            })
        }).catch(e => {
            setCreatDone(true)
            setLoading(false)
            setCreatErrorMsg(e)
            console.error(e);
        })

    }


    const [preBuyAmount, setPreBuyAmount] = useState(5)

    const preBuyTotalValue = useMemo(() => {
        if (activeCurve) {
            const _t = calcTotalValue(activeCurve, paramA, paramB, preBuyAmount)
            // console.log(_t.toString());
            // const bigT = ethers.utils.parseEther(_t);
            // console.log(`preBuyTotalValue------>`,_t,bigT.toString());

            // const bigM = bigT.mul(102).div(100);
            // const bigMStr = ethers.utils.formatEther(bigM).toString();
            // console.log(`preBuyTotalValue X 1.02------>`,ethers.utils.formatEther(bigM).toString());
            // const bigT = ethers.BigNumber.from(bigT.toString())
            // const bigM = ethers.BigNumber.from('1.021')
            // const bigR = bigT.mul(bigM)
            // const bigR = bigT.mul(bigM)
            // console.log(bigMStr);
            return _t * 1.03
        }


    }, [paramA, paramB, preBuyAmount, activeCurve])

    const memeGenattrsFn = ()=>{
        return new Promise((resolve, reject) => {
            memeGenattrs({
                keyWord: clubName + `${clubDesc?','+clubDesc:''}`
            }).then(res => {
                resolve(true)
                setAttrs(res?.attrs ? checkAttrs(res?.attrs) : formatAttr(defaultAttr))
            }).catch(e => {
                reject(false);
                console.error(e);
            })
        })
       
    }

    const randomHandle = (maxLen,curType)=>{
        let type; 
        const _fn = ()=>{
            type = preferencesItems[Math.floor(Math.random() * maxLen)]?.value
            if(type === curType){
                _fn()
            }
        }
        _fn();
        return type
    }

    const resetPreferences = () => {
        // userChangePreferencesCount.current += 1;
        // changeCurrentMemePriceFormular.current += 1;
        // userChangeCount.current += 1;
        const _selPreferencesType = randomHandle(preferencesItems?.length,selPreferencesType)
        console.log(_selPreferencesType,selPreferencesType);
        return new Promise((resolve, reject) => {
            memeGenattrsFn().then(res=>{
                resolve(res)
                setSelPreferencesType(_selPreferencesType)
            }).catch(e=>{
                setSelPreferencesType(_selPreferencesType)
                reject(e)
            })
        })
    }


    // 

    const resetBaseInfo = () => {
        setClubTicker('')
        setClubName('')
        setClubDesc('')
        setAttrs(null)
    }
    const [enumsLoading, setEnumsLoading] = useState(false)

    useEffect(() => {
        if (attrsLoaded && aiImageLoaded){
            setEnumsLoading(false);
            setShowNew(false);
            setShowView(true);
        }
    }, [updateCount])
    
    const getAIImage = () => {
        let keyWord = `${clubName}${clubDesc?','+clubDesc:''}`;
        getMemeGenImage({
            keyWord,
            genType: 2
        }).then(res => {
            setAiImageLoaded(true);
            if (res?.imageUrls?.length > 0){
                setMemeClubImgUrl(res?.imageUrls[0]);
            }
            setUpdateCount(updateCount + 2);
        }).catch(e => {
            console.error(e);
            setAiImageLoaded(true);
            setUpdateCount(updateCount + 2);
        })
    }

    const createNew = () => {
        setEnumsLoading(true);
        if (!aiImageLoaded){
            //暂时不主动生成ai图
            //getAIImage();
            if (memeClubSymbolUrl){
                setMemeClubImgUrl(memeClubSymbolUrl);
            }
            setAiImageLoaded(true);
            setUpdateCount(updateCount + 2);
        }
        if(!attrs || JSON.stringify(attrs) === '{}'){
            setAttrsLoaded(false);
            memeGenattrs({
                keyWord: clubName + `${clubDesc?','+clubDesc:''}`
            }).then(res => {
                console.log(res);
                setAttrs(checkAttrs(res?.attrs) || formatAttr(defaultAttr))
                setAttrsLoaded(true);
                setUpdateCount(updateCount + 3);
            }).catch(e => {
                setAttrs(formatAttr(defaultAttr))
                setAttrsLoaded(true);
                setUpdateCount(updateCount + 3);
            })
        }else{
            setAttrsLoaded(true);
            setUpdateCount(updateCount + 1);
        }
       
        // setShowNew(false)
        // setShowView(true)
    }
    // 

    return (
        <div className={`${s.modalWrap}`}>
            {showNew && <New
                clubName={clubName}
                setClubName={setClubName}
                clubTicker={clubTicker}
                setClubTicker={setClubTicker}
                clubDesc={clubDesc}
                setClubDesc={setClubDesc}
                selCoinId={selCoinId}
                setSelCoinId={setSelCoinId}
                memeChainToken={memeChainToken}
                chainData={chainData}
                coinData={coinData}
                coinList={coinList}
                selPreferencesType={selPreferencesType}
                setSelPreferencesType={setSelPreferencesType}
                memeClubSymbolUrl={memeClubSymbolUrl}
                setMemeClubSymbolUrl={setMemeClubSymbolUrl}
                attrs={attrs}
                nextFn={createNew}
                enumsLoading={enumsLoading}
                clubXlink={clubXlink}
                setClubXlink={setClubXlink}
                clubTlink={clubTlink}
                setClubTlink={setClubTlink}
            ></New>}
            {showView && <View
                creatFn={creatFn}
                loading={loading}
                clubName={clubName}
                clubTicker={clubTicker}
                clubDesc={clubDesc}
                selCreatChain={selCreatChain}
                coinTotalSupply={coinTotalSupply}
                airdropPecentage={airdropPecentage}
                totalCardValue={totalCardValue}
                cardTotalSupply={cardTotalSupply}
                receiveNFTAmount={receiveNFTAmount}
                receiveNFT={receiveNFT}
                lastCardPrice={lastCardPrice}
                firstCardPrice={firstCardPrice}
                memeClubImgUrl={memeClubImgUrl}
                setMemeClubImgUrl={setMemeClubImgUrl}
                creatDone={creatDone}
                setCreatDone={setCreatDone}
                creatErrorMsg={creatErrorMsg}
                setCreatErrorMsg={setCreatErrorMsg}
                walletBalanceList={walletBalanceList}
                preBuyTotalValue={preBuyTotalValue}
                preBuyAmount={preBuyAmount}
                setPreBuyAmount={setPreBuyAmount}
                isAnony={isAnony}
                setIsAnony={setIsAnony}
                selCoinId={selCoinId}
                showSettingFn={() => {
                    setShowView(false)
                    setShowSetting(true)
                }}
                onBack={(() => {
                    setShowView(false)
                    setShowNew(true)
                })}
                resetPreferences={resetPreferences}
                resetBaseInfo={resetBaseInfo}
                clubXlink={clubXlink}
                clubTlink={clubTlink}
                unpackRatio={unpackRatio}
            ></View>}

            {showSetting && <Setting
                selCreatChain={selCreatChain}

                // Airdrop Data start
                coinTotalSupply={coinTotalSupply}
                setCoinTotalSupply={setCoinTotalSupply}
                airdropPecentage={airdropPecentage}
                setAirdropPecentage={setAirdropPecentage}
                receiveNFTAmount={receiveNFTAmount}
                setReceiveNFTAmount={setReceiveNFTAmount}
                receiveNFT={receiveNFT}
                clubTicker={clubTicker}
                setReceiveNFT={setReceiveNFT}
                airdropType={airdropType}
                setAirdropType={setAirdropType}
                coinTotalSupplyData={coinTotalSupplyData}
                airdropPecentageData={airdropPecentageData}
                coinToNFTPecentageData={coinToNFTPecentageData}
                airdropTypeData={airdropTypeData}
                unpackRatio={unpackRatio}
                setUnpackRatio={setUnpackRatio}
                lastCardPrice={lastCardPrice}
                // Airdrop Data end

                // Price Data start
                activeCurve={activeCurve}
                setActiveCurve={setActiveCurve}
                paramA={paramA}
                setParamA={setParamA}
                paramB={paramB}
                setParamB={setParamB}
                cardTotalSupply={cardTotalSupply}
                setCardTotalSupply={setCardTotalSupply}
                totalCardValue={totalCardValue}
                memePriceFormular={currentMemePriceFormular}
                cardTotalSupplyData={cardTotalSupplyData}
                paramAMin={paramAMin}
                setParamAMin={setParamAMin}
                paramAMax={paramAMax}
                setParamAMax={setParamAMax}
                paramBPercent={paramBPercent}
                paramAPercent={paramAPercent}
                setParamBPercent={setParamBPercent}
                setParamAPercent={setParamAPercent}
                // Price Data end


                // Attr Data start
                attrs={attrs}
                setAttrs={setAttrs}
                withRarity={withRarity}
                setWithRarity={setWithRarity}
                memeGenattrsFn={memeGenattrsFn}
                // Attr Data end
                showViewFn={() => {
                    setShowSetting(false)
                    setShowView(true)
                }}
            ></Setting>}
        </div>

    )
}

const mapStateToProps = ({ app }) => {
    return {
        userInfo: app.userInfo,
        walletBalanceList: app.walletBalanceList,
        defaultInviteCode: app.defaultInviteCode
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setWalletBalanceList: (params) => {
            return dispatch({
                type: "app/setWalletBalanceList",
                payload: params,
            });
        }
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(Creat));
