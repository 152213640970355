import React, {memo, useMemo} from "react";
import {connect} from "react-redux";
import s from "./index.module.less";
import CustomIcon from "../CustomIcon";

import {useNavigate, useLocation} from "react-router-dom";
import TaskConf from "@/utils/json/TaskConf.json";
import {useTranslation, Trans} from 'react-i18next';
import {displayNewbieTaskStartId} from "@/utils/env";
import {
    gameTrillionaireBattlePath,
    gameTrillionaireMissionPath,
} from "@/routes/config";

const ShareUpPointsNavs = (
    {
        buyFirstKey,
        hasLinkX,
        path,
        setPath,
        setShake
    }
) => {
    const {t, i18n} = useTranslation();

    const navArr = [
        {
            icon: 'Button/UI_Button-D_Guide',
            label: 'Guide',
            labelName: t('Guide'),
        }, {
            icon: 'Button/UI_Button-D_FeverFarm',
            label: 'Fever Farm',
            labelName:  t('Fever Farm'),
        },{
            icon: 'Button/UI_Button-D_Bonus',
            label: 'Bonus',
            labelName: t('Bonus'),
        }, {
            icon: 'Button/UI_Button-D_Leadership',
            label: 'LeaderShip',
            labelName: t('LeaderShip'),
        },
    ]

    return (
        <div className={s.footNavWrap}>
            <div className={s.footNav}>
                {
                    navArr.map((item, ind) => {

                        return (
                            <div className={`${s.item} ${path === item.label ? s.active : ''}`} key={ind}
                                 onClick={() => {
                                    hasLinkX && setPath(item.label)
                                    // !hasLinkX && setShake(true)
                                    setShake(true)
                                    setTimeout(()=>{
                                        setShake(false)
                                    },500)
                                 }}>
                                <div className={`${s.iconWrap} `}>
                                    <CustomIcon
                                        width={32} height={32}
                                        imgName={`${path === item.label ? item.icon + '_On' : item.icon}`}
                                        name={`${path === item.label ? item.icon + '_On' : item.icon}`}/>
                                </div>
                                <div className={`${s.label} fb`}>{item.labelName}</div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
        showBalance: app.showBalance
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setShowCharContent: (params) => {
            return dispatch({
                type: "app/setShowCharContent",
                payload: params,
            });
        },
        setCurentOtherUserInfo: (params) => {
            return dispatch({
                type: "app/setCurentOtherUserInfo",
                payload: params,
            });
        },
        setShowBalance: (params) => {
            return dispatch({
                type: "app/setShowBalance",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(ShareUpPointsNavs));
