import React, {memo, useState, useMemo, useRef} from "react";
import {connect} from "react-redux";
import s from "./index.module.less";
import CustomIcon from "../../common/CustomIcon";
import {Button, Slider, Checkbox} from "antd";
import {unit} from "@/utils/common";
import {useTranslation, Trans} from 'react-i18next';
import AntButton from "@/common/AntButton";
import {AutoStrangeRule} from "@/utils/strangeRule";
import {getGasType, setGasType} from "@/utils/gas";

const GasSetting = (
    {
        closeHandle,
    }
) => {
    const {t, i18n} = useTranslation();

    const [gasTypeStatus, setGasTypeStatus] = useState(getGasType());

    const changeLowGas = (e) => {
        setGasType('low');
        setGasTypeStatus('low');
    }
    const changeSuggestedGas = (e) => {
        setGasType('fast');
        setGasTypeStatus('fast');
    }

    return (
        <div className={s.wrap}>
            <div className={s.title}>
                <CustomIcon width={25} className="mr5" height={30} imgName={'Button/UI_Button_UpAndDown_01'}/>
                {AutoStrangeRule(t('Gas Mode'))}
            </div>
            <div className={s.drawCon}>
                <div className={`${s.settingLine} flex_center_start mb10`}>
                    <div className={s.settingLineLeft}>
                        {t('Gas option')}
                    </div>
                    <div className={s.settingLineRight}>
                        {t('Time')}
                    </div>
                </div>
                <div className={`${s.settingLine} flex_center_start mb10`}>
                    <div className={s.settingLineLeft}>
                        <Checkbox onChange={changeLowGas} className="hireCheckBox"
                                  checked={gasTypeStatus === 'low'}>
                            <span className="ml5">{t('Low')}</span>
                        </Checkbox>
                    </div>
                    <div className={s.settingLineRight}>
                        {t('30s+')}
                    </div>
                </div>
                <div className={`${s.settingLine} flex_center_start mb10`}>
                    <div className={s.settingLineLeft}>
                        <Checkbox onChange={changeSuggestedGas} className="hireCheckBox"
                                  checked={gasTypeStatus === 'fast'}>
                            <span className="ml5">{t('Suggested')}</span>
                        </Checkbox>
                    </div>
                    <div className={s.settingLineRight}>
                        {t('15s-30s')}
                    </div>
                </div>
            </div>
            {/*<div className={s.bottom}>
                <AntButton className={s.black} onClick={reset}>{t('RESET')}</AntButton>
                <AntButton className="btn_public" onClick={apply}>{t('APPLY')}</AntButton>
            </div>*/}
        </div>
    )
}
const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {}
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(GasSetting));
