import abi_mint_json from "@/utils/json/ABI_LFGFactory.json"
import abi_core_pool_json from "@/utils/json/ABI_CorePool.json"
import abi_token_json from "@/utils/json/ABI_LFGToken.json"
import abi_red_packet_json from "@/utils/json/ABI_RedPacket.json"
import abi_auction_json from "@/utils/json/ABI_Auction.json"
import abi_memeClub_json from "@/utils/json/ABI_MemeClubFactory.json"
import abi_erc20_json from "@/utils/json/ABI_ERC20.json"
const abi_trand = [
    {
        inputs: [],
        stateMutability: "nonpayable",
        type: "constructor",
    },
    {
        inputs: [],
        name: "BoundCannotBeBuyOrSell",
        type: "error",
    },
    {
        inputs: [],
        name: "BoundWish",
        type: "error",
    },
    {
        inputs: [],
        name: "CannotMakeASubjectABind",
        type: "error",
    },
    {
        inputs: [],
        name: "CannotSellLastKey",
        type: "error",
    },
    {
        inputs: [],
        name: "ClaimRewardShouldBeFalse",
        type: "error",
    },
    {
        inputs: [],
        name: "DAONotSetup",
        type: "error",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "wisher",
                type: "address",
            },
        ],
        name: "ExistingWish",
        type: "error",
    },
    {
        inputs: [],
        name: "ExpiredWishCanOnlyBeSold",
        type: "error",
    },
    {
        inputs: [],
        name: "Forbidden",
        type: "error",
    },
    {
        inputs: [],
        name: "GracePeriodExpired",
        type: "error",
    },
    {
        inputs: [],
        name: "InsufficientFunds",
        type: "error",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "balance",
                type: "uint256",
            },
        ],
        name: "InsufficientKeys",
        type: "error",
    },
    {
        inputs: [],
        name: "InvalidAmount",
        type: "error",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "wisher",
                type: "address",
            },
        ],
        name: "InvalidWish",
        type: "error",
    },
    {
        inputs: [],
        name: "InvalidWishedPseudoAddress",
        type: "error",
    },
    {
        inputs: [],
        name: "InvalidZeroAddress",
        type: "error",
    },
    {
        inputs: [],
        name: "NotCloseableOrAlreadyClosed",
        type: "error",
    },
    {
        inputs: [],
        name: "NotTheOperator",
        type: "error",
    },
    {
        inputs: [],
        name: "OnlyKeysOwnerCanBuyFirstKey",
        type: "error",
    },
    {
        inputs: [],
        name: "OperatorNotSet",
        type: "error",
    },
    {
        inputs: [],
        name: "ProtocolFeeDestinationNotSet",
        type: "error",
    },
    {
        inputs: [],
        name: "ProtocolFeePercentNotSet",
        type: "error",
    },
    {
        inputs: [],
        name: "ReserveQuantityTooLarge",
        type: "error",
    },
    {
        inputs: [],
        name: "SubjectCannotBeAWish",
        type: "error",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "subject",
                type: "address",
            },
        ],
        name: "SubjectDoesNotMatch",
        type: "error",
    },
    {
        inputs: [],
        name: "SubjectFeePercentNotSet",
        type: "error",
    },
    {
        inputs: [],
        name: "TooManyKeys",
        type: "error",
    },
    {
        inputs: [],
        name: "TransactionFailedDueToPrice",
        type: "error",
    },
    {
        inputs: [],
        name: "UnableToClaimParkedFees",
        type: "error",
    },
    {
        inputs: [],
        name: "UnableToClaimReward",
        type: "error",
    },
    {
        inputs: [],
        name: "UnableToSendFunds",
        type: "error",
    },
    {
        inputs: [],
        name: "UpgradedAlreadyInitialized",
        type: "error",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "wisher",
                type: "address",
            },
        ],
        name: "WishAlreadyBound",
        type: "error",
    },
    {
        inputs: [],
        name: "WishAlreadyClosed",
        type: "error",
    },
    {
        inputs: [],
        name: "WishExpired",
        type: "error",
    },
    {
        inputs: [],
        name: "WishNotExpiredYet",
        type: "error",
    },
    {
        inputs: [],
        name: "WishNotFound",
        type: "error",
    },
    {
        inputs: [],
        name: "WrongAmount",
        type: "error",
    },
    {
        inputs: [],
        name: "ZeroReservedQuantity",
        type: "error",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "dao",
                type: "address",
            },
        ],
        name: "DAOUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint8",
                name: "version",
                type: "uint8",
            },
        ],
        name: "Initialized",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "operator",
                type: "address",
            },
        ],
        name: "OperatorUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "previousOwner",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "newOwner",
                type: "address",
            },
        ],
        name: "OwnershipTransferred",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "protocolFeeDestination",
                type: "address",
            },
        ],
        name: "ProtocolFeeDestinationUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "protocolFeePercent",
                type: "uint256",
            },
        ],
        name: "ProtocolFeePercentUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "subjectFeePercent",
                type: "uint256",
            },
        ],
        name: "SubjectFeePercentUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "trader",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "subject",
                type: "address",
            },
            {
                indexed: false,
                internalType: "bool",
                name: "isBuy",
                type: "bool",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "price",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "supply",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "enum TurnupSharesV4.SubjectType",
                name: "subjectType",
                type: "uint8",
            },
        ],
        name: "Trade",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "sharesSubject",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "wisher",
                type: "address",
            },
        ],
        name: "WishBound",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "sharesSubject",
                type: "address",
            },
        ],
        name: "WishClosed",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "wisher",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "reservedQuantity",
                type: "uint256",
            },
        ],
        name: "WishCreated",
        type: "event",
    },
    {
        inputs: [],
        name: "DAO",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "DAOBalance",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "WISH_DEADLINE_TIME",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "WISH_EXPIRATION_TIME",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "afterUpgrade",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        name: "authorizedWishes",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address[]",
                name: "sharesSubjects",
                type: "address[]",
            },
            {
                internalType: "uint256[]",
                name: "amounts",
                type: "uint256[]",
            },
            {
                internalType: "uint256[]",
                name: "expectedPrices",
                type: "uint256[]",
            },
        ],
        name: "batchBuyShares",
        outputs: [],
        stateMutability: "payable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "sharesSubject",
                type: "address",
            },
            {
                internalType: "address",
                name: "wisher",
                type: "address",
            },
        ],
        name: "bindWishPass",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "sharesSubject",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
        ],
        name: "buyShares",
        outputs: [],
        stateMutability: "payable",
        type: "function",
    },
    {
        inputs: [],
        name: "claimReservedWishPass",
        outputs: [],
        stateMutability: "payable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "sharesSubject",
                type: "address",
            },
        ],
        name: "closeExpiredWish",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "sharesSubject",
                type: "address",
            },
            {
                internalType: "address",
                name: "user",
                type: "address",
            },
        ],
        name: "getBalanceOf",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "sharesSubject",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
        ],
        name: "getBuyPrice",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "sharesSubject",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
        ],
        name: "getBuyPriceAfterFee",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "supply",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
        ],
        name: "getPrice",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "pure",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "price",
                type: "uint256",
            },
        ],
        name: "getProtocolFee",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "sharesSubject",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
        ],
        name: "getSellPrice",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "sharesSubject",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
        ],
        name: "getSellPriceAfterFee",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "price",
                type: "uint256",
            },
        ],
        name: "getSubjectFee",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "sharesSubject",
                type: "address",
            },
        ],
        name: "getSupply",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "getVer",
        outputs: [
            {
                internalType: "string",
                name: "",
                type: "string",
            },
        ],
        stateMutability: "pure",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "sharesSubject",
                type: "address",
            },
            {
                internalType: "address",
                name: "user",
                type: "address",
            },
        ],
        name: "getWishBalanceOf",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "initialize",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "wisher",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "reservedQuantity",
                type: "uint256",
            },
        ],
        name: "newWishPass",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "operator",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "owner",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "protocolFeeDestination",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "protocolFeePercent",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "protocolFees",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "renounceOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "sharesSubject",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
        ],
        name: "sellShares",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "dao",
                type: "address",
            },
        ],
        name: "setDAO",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_feeDestination",
                type: "address",
            },
        ],
        name: "setFeeDestination",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_operator",
                type: "address",
            },
        ],
        name: "setOperator",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_feePercent",
                type: "uint256",
            },
        ],
        name: "setProtocolFeePercent",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_feePercent",
                type: "uint256",
            },
        ],
        name: "setSubjectFeePercent",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        name: "sharesBalance",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        name: "sharesSupply",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "subjectFeePercent",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "newOwner",
                type: "address",
            },
        ],
        name: "transferOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        name: "wishPasses",
        outputs: [
            {
                internalType: "address",
                name: "owner",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "totalSupply",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "createdAt",
                type: "uint256",
            },
            {
                internalType: "address",
                name: "subject",
                type: "address",
            },
            {
                internalType: "bool",
                name: "isClaimReward",
                type: "bool",
            },
            {
                internalType: "uint256",
                name: "reservedQuantity",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "subjectReward",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "parkedFees",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
            {
                internalType: "address",
                name: "beneficiary",
                type: "address",
            },
        ],
        name: "withdrawDAOFunds",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
        ],
        name: "withdrawProtocolFees",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
];

const abi_mint = abi_mint_json
const abi_core_pool = abi_core_pool_json
const abi_token = abi_token_json
const abi_red_packet = abi_red_packet_json
const abi_auction = abi_auction_json
const abi_memeClub = abi_memeClub_json
const abi_erc20 = abi_erc20_json

export {
    abi_mint, 
    abi_trand, 
    abi_core_pool, 
    abi_token, 
    abi_red_packet, 
    abi_auction, 
    abi_memeClub, 
    abi_erc20,
};
