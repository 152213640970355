import React, {memo, useState, useEffect, useMemo} from "react";
import s from "./index.module.less";
import {Button, Modal, Input, Spin} from "antd";
import {
    compareNftRarity, defaultAvatar, stringToNumber
} from "@/utils/common";
import {
    getnft_ownList,
    nftDetail,
    getRarityBgColor,
    getRarityFontColor,
    getRarityText, getRarityImageBg, getRarityRankIcon, coinObj
} from "@/utils/bidNft";
import CustomIcon from "../../../../common/CustomIcon";
import {useTranslation, Trans} from 'react-i18next';
import {useLocation, useNavigate} from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import {mainHomePath} from "@/routes/config";
import MainHeader from "src/common/MainHeader";
import Countdown from "src/common/Countdown";
import ButtonFactory from "@/common/ButtonFactory";

const ModelMyNFT = ({}) => {
    const {t, i18n} = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const defaultShowLength = 33;
    const [originNftlist, setOriginNftlist] = useState([]);
    const [nftList, setNFTList] = useState([]);
    const [nftShowList, setNftShowList] = useState([]);
    const [showLength, setShowLength] = useState(defaultShowLength);
    const [rarityRangeRise, setRarityRangeRise] = useState(false);
    const [searchInfo, setSearchInfo] = useState('');
    const [loading, setLoading] = useState(true);

    const compareNft = (nft1, nft2) => {
        if ((nft1?.rarity - nft2?.rarity < 0 && rarityRangeRise) ||
            (nft1?.rarity - nft2?.rarity > 0 && !rarityRangeRise) ||
            (nft1?.rarity - nft2?.rarity === 0 && nft1?.token_id <= nft2?.token_id)) {
            return -1;
        } else {
            return 1;
        }
    }

    const rangeNftList = (list) => {
        let rangList = [];
        if (list?.length > 0) {
            for (let i = 0; i < list.length; i++) {
                let tempNft = list[i];
                let front = 0;
                let end = rangList.length - 1;
                let mid = Math.floor((front + end) / 2);
                if (i === 0) {
                    rangList.push(tempNft);
                } else if (compareNft(tempNft, rangList[front]) < 0) {
                    rangList.unshift(tempNft);
                } else if (compareNft(tempNft, rangList[end]) > 0) {
                    rangList.push(tempNft);
                } else {
                    while (end - front > 1) {
                        if (compareNft(tempNft, rangList[mid] < 0)) {
                            end = mid;
                        } else {
                            front = mid;
                        }
                        mid = Math.floor((front + end) / 2);
                    }
                    rangList.splice(front + 1, 0, tempNft);
                }
            }
        }
        return rangList;
    }

    const getShowList = () => {
        let res = [];
        let index = 0;
        while (index < showLength && index < nftList.length) {
            res.push(nftList[index]);
            index++;
        }
        setNftShowList(res);
    }

    useEffect(() => {
        let rangList = rangeNftList(nftList);
        setNFTList(rangList);
    }, [rarityRangeRise]);

    useEffect(() => {
        startSearch();
    }, [originNftlist]);

    useEffect(() => {
        getShowList();
    }, [nftList, showLength]);

    useEffect(() => {
        getnft_ownList().then(res => {
            setLoading(false);
            setOriginNftlist(res?.nftList);
        }).catch(err => {
            setLoading(false);
            console.error(err.message);
        })
    }, []);

    const saveSearchInfo = (event) => {
        setSearchInfo(event.target.value);
    }

    const selectNFT = (nft) => {
        nftDetail(navigate, {tokenAddr: nft?.tokenAddr, tokenId: nft?.tokenId});
    }

    const startSearch = () => {
        let list = [];
        if (!searchInfo) {
            list = originNftlist;
        } else if (originNftlist?.length > 0) {
            let searchSting = searchInfo.toLowerCase();
            for (let i = 0; i < originNftlist.length; i++) {
                let tempNftName = originNftlist[i]?.name?.toLowerCase();
                if (tempNftName?.indexOf(searchSting) != -1) {
                    list.push(originNftlist[i]);
                }
            }
        }
        list = rangeNftList(list);
        setNFTList(list);
    }

    const backFn = () => {
        if (location.key !== "default") {
            navigate(-1);
        } else {
            navigate(mainHomePath, {replace: true});
        }
    }

    const getMore = () => {
        setShowLength(showLength + defaultShowLength);
    }

    return (<>
            <MainHeader title={t('My NFTs')} hasRightMenu={false} headerType={2}></MainHeader>
            <div className={s.wrap}>
                <div className={s.topBar}>
                    <div className={s.topBarLeft}>
                        <div className={s.item}>
                            <div className={s.search}>
                                <Input className={`searchInput ${s.input}`} suffix={
                                    <div className={s.searchBtn}
                                         onClick={() => {
                                             setShowLength(defaultShowLength);
                                             startSearch();
                                         }}
                                         style={{backgroundImage: `url(/UI/Button/UI_Button_Yellow-Search_01.png)`}}>
                                    </div>} onChange={saveSearchInfo} placeholder={t('Search')}/>
                            </div>
                        </div>
                    </div>
                    <div className={s.topBarRight} onClick={() => {
                        setRarityRangeRise(!rarityRangeRise)
                    }}>
                        <div className={s.rangeTitle}>
                            {t('Rarity')}
                        </div>
                        <div className={s.rangeIconRoot}>
                            {rarityRangeRise &&
                                <CustomIcon width={17} height={15} imgName={'Button/UI_Button_Rank_Down_01'}
                                            name={'Button/UI_Button_Rank_Down_01'}/>}
                            {!rarityRangeRise &&
                                <CustomIcon width={17} height={15} imgName={'Button/UI_Button_Rank_Up_01'}
                                            name={'Button/UI_Button_Rank_Up_01'}/>}
                        </div>
                    </div>
                </div>

                <div className={s.nftRoot}>
                    {!loading ?
                        <div className={s.bidList} id="nftListRoot">
                            <InfiniteScroll
                                dataLength={nftShowList?.length || 0}
                                next={getMore}
                                hasMore={nftShowList?.length < nftList?.length}
                                loader={<Spin className={s.aa}/>}
                                style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    justifyContent: "space-between",
                                    width: '100%'
                                }}
                                endMessage={
                                    <div style={{
                                        textAlign: "center",
                                        width: "100%",
                                        color: "var(--color-white)",
                                        fontSize: '12px',
                                        marginTop: '10px'
                                    }}>{nftShowList?.length === 0 ? t('You have no NFT') : t('No More Data')}</div>
                                }
                                scrollableTarget={'nftListRoot'}
                            >
                                {
                                    nftShowList?.map((item, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className={`${s.bidItem} ${index % 2 === 0 ? s.leftItem : s.rightItem}`}
                                                style={{backgroundImage: `url(/UI/Picture/UI_Picture_NFT-BlueWindows_02.png)`}}
                                                onClick={() => {
                                                    selectNFT(item)
                                                }}
                                            >
                                                <div className={`${s.bidItemAvatarPart}`}
                                                     style={{backgroundImage: `url(/img/${getRarityImageBg(item?.rarity)}.png)`}}>
                                                </div>
                                                <div className={s.bidItemAvatarPart}
                                                     style={{backgroundImage: `url(${item?.image || defaultAvatar})`}}
                                                     onClick={() => {
                                                         selectNFT(item)
                                                     }}>
                                                </div>
                                                <div className={s.l}></div>
                                                <div className={s.r}>
                                                    <div className={s.t}>
                                                        <div className={s.displayname}
                                                             style={{marginTop: '5px'}}>{item?.name}</div>
                                                        <div className={s.rarityRoot}>
                                                            <div className={s.rarityBg} style={{
                                                                backgroundColor: `${getRarityBgColor(item?.rarity)}`,
                                                                color: `${getRarityFontColor(item?.rarity)}`
                                                            }}>
                                                                {getRarityText(item?.rarity)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                            ;
                                    })
                                }
                            </InfiniteScroll>
                        </div> :
                        <div className="w100p tlc color-white">{t('Loading...')}</div>
                    }
                </div>
            </div>
        </>
    )
}
export default memo(ModelMyNFT);
